const makeApiRoot = subDomain =>
  `https://${subDomain}.autoremarketers.com/web/simulcast/api/services/`;

const makeIASConnectRoot = subDomain =>
  `https://${subDomain}.iasmarketplace.com`;

const makeSIAConnectRoot = subDomain =>
  `https://${subDomain}.siamarketplace.com`;

const makeCoreRoot = subdomain =>
  `https://${subdomain}.autoremarketers.com/web/simulcast/core/api/services/`;

const makeSearchRoot = (searchIndex, envTag = '') =>
  `https://cloudsearch${envTag}.iasmarketplace.com/${searchIndex}-listing/`;

const makeKeycloakUrl = (realm, envTag = '') =>
  `https://seckey${envTag}.iasmarketplace.com/auth/admin/realms/${realm}/`;

const makeSimulcastUrl = subDomain =>
  `https://${subDomain}.autoremarketers.com/web/Simulcast/LiveAuction/Home?EventId=0&Relogin=1&Ticket=`;

const makeHyperlaneUrl = subDomain =>
  `https://${subDomain}.autoremarketers.com/web/awgsimulcast/hyperlane.aspx`;

const makeBookSheetUrl = subDomain =>
  `https://${subDomain}.autoremarketers.com/web/Simulcast/LiveAuction/Booksheet`;

const makeIASTermsUrl = subDomain =>
  `https://cdn.integratedauctionsolutions.com/${subDomain}.iasmarketplace.com/terms.pdf`;

const makeSIATermsUrl = subDomain =>
  `https://cdn.integratedauctionsolutions.com/${subDomain}.siamarketplace.com/terms.pdf`;

/** AnylineJS - Barcode Scanner
 * @description determine AnylineJS license based on domain name
 * @param {string} domain 
 */
const makeAnylineLicense = domain => {
  if (/(ias|sia)marketplace\.com/i.test(domain)) {
    return ANYLINE_LICENSE_KEY;
  } else {
    return '';
  }
}
/* AnylineJS - Barcode Scanner -- License Key (clientId) */
const ANYLINE_LICENSE_KEY =
  "ewogICJsaWNlbnNlS2V5VmVyc2lvbiIgOiAiMy4wIiwKICAiZGVidWdSZXBvcnRpbmciIDogIm9uIiwKICAibWFqb3JWZXJzaW9uIiA6ICIzNyIsCiAgIm1heERheXNOb3RSZXBvcnRlZCIgOiA5MCwKICAiYWR2YW5jZWRCYXJjb2RlIiA6IHRydWUsCiAgIm11bHRpQmFyY29kZSIgOiB0cnVlLAogICJzdXBwb3J0ZWRCYXJjb2RlRm9ybWF0cyIgOiBbICJBTEwiIF0sCiAgInBsYXRmb3JtIiA6IFsgIkFuZHJvaWQiLCAiaU9TIiwgIldpbmRvd3MiLCAiV2ViIiwgIkpTIiBdLAogICJzY29wZSIgOiBbICJBTEwiIF0sCiAgInNob3dXYXRlcm1hcmsiIDogZmFsc2UsCiAgInRvbGVyYW5jZURheXMiIDogMzAsCiAgInZhbGlkIiA6ICIyMDI1LTA3LTI0IiwKICAibGljZW5zZUlkIiA6ICJXZVpVTFlXSjlZZlhLVk0ydjljWWtZS2NQRHQ3WlVoMkYyTEJmUE94ZWMiLAogICJpb3NJZGVudGlmaWVyIiA6IFsgIi4rc2lhbWFya2V0cGxhY2UuY29tIiwgIi4raWFzbWFya2V0cGxhY2UuY29tIiBdLAogICJhbmRyb2lkSWRlbnRpZmllciIgOiBbICIuK3NpYW1hcmtldHBsYWNlLmNvbSIsICIuK2lhc21hcmtldHBsYWNlLmNvbSIgXSwKICAid2luZG93c0lkZW50aWZpZXIiIDogWyAiLitzaWFtYXJrZXRwbGFjZS5jb20iLCAiLitpYXNtYXJrZXRwbGFjZS5jb20iIF0sCiAgImpzSWRlbnRpZmllciIgOiBbICIuK3NpYW1hcmtldHBsYWNlLmNvbSIsICIuK2lhc21hcmtldHBsYWNlLmNvbSIgXSwKICAid2ViSWRlbnRpZmllciIgOiBbICIuK3NpYW1hcmtldHBsYWNlLmNvbSIsICIuK2lhc21hcmtldHBsYWNlLmNvbSIgXQp9CgpVUG5MTENSS3QwZG85WmdWWDkwNllTR0xFZTkvVGJQamx1dDNxUjhXREkraG5qbnZiZlVRbU91V1FuVGFLTTdvNFFBcitzOEtPZ2t4cGtoRDgvWCtoS3lJblNLd1VrT2hndFdsQW01alhUZ0p0QjJHUnBjdXgxWlovS2F3OWtlM29vMHVZUEZ3LzlKNnJaZ3BKQUZORmRYTm94ZzB3SlFBbTdSQitnYVo2Tkl4ZmdPL2ZnS3crR1UrY2tKN2l4NDhGYnFTaFlyYkhyN2tZK3BGV3FhODQ5Mmh0ajJLWWtwMVMwM0gxVTNxRkhzUmdvbktMdFRLblM4RWJhclRaVkdpK2Rwa2NpK1ZYLzBUMlhCd1p0UXNBekR3cmUrVzJNZWkzOHN6Y1BRa2tKRlRpVE9kUDZzTjV6anlpdElkMXJ0NmU0OTMvNlBobDNtVmZjUnVNQ2ZKaGc9PQ=="

/**
 * @description Create config data for Darwin CTA - 'Stratos'
 * @param {string|undefined} mpId Marketplace ID
 * @returns {{dealerGuid: string, scriptSrc: string}} `{dealerGuid, scriptSrc}` config
 */
const makeDarwinStratosConfig = (mpId) => {
  const darwinStratosConfig = {
    dealerGuid: darwinConfigConstants.DEALER_GUID,
    scriptSrc: darwinConfigConstants.DARWIN_SRC_PRD,
  }

  switch(mpId) {
    // Lorensen QA
    case '564':
      // Mutate config here
      break;

    default:
      break;
  }

  return darwinStratosConfig;
}

/* Darwin config constants */
const darwinConfigConstants = {
  DARWIN_SRC_QA: 'https://Exos.azureedge.net/Scripts/V2/StratosLoader.min.js',
  DARWIN_SRC_PRD: 'https://Exos.azureedge.net/Scripts/V2/StratosLoader.min.js',
  DEALER_GUID: 'c0023bc8-f55d-4d31-a524-311526d8cc25',
}


const REAL_TIME_URL =
  'https://mpapi.autoremarketers.com/web/realtime/notificationHub';

const PORT = 3000;

module.exports = {
  makeApiRoot,
  makeAnylineLicense,
  makeIASConnectRoot,
  makeSIAConnectRoot,
  makeCoreRoot,
  makeSearchRoot,
  makeKeycloakUrl,
  makeSimulcastUrl,
  makeDarwinStratosConfig,
  makeHyperlaneUrl,
  makeBookSheetUrl,
  makeIASTermsUrl,
  makeSIATermsUrl,
  REAL_TIME_URL,
  PORT,
};
