
/** Object shape expected in the `request.body` for the `fetchAcertusContactForm` action
 * 
 */
export const shippingRequestBodyStatic = {
    billing_email: "",      // (this field should be validated as a valid email address)
    billing_phone: "",
    billing_company: "",
    billing_firstname: "",
    billing_lastname: "",
    billing_street: "",
    billing_state: "",
    billing_zip: "",

    vin: "",
    quote_rate: "",         //(this should be the quote amount returned when the user clicked “Get Quote”)

    pickup_company: "", 
    pickup_contact: "",
    pickup_email: "",
    pickup_phone: "",
    pickup_address: "",
    pickup_notes: "",
    
    delivery_company: "",
    delivery_contact: "",
    delivery_email: "",
    delivery_phone: "",
    delivery_address: "",
    delivery_notes: "" ,
}

