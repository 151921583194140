import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { find, get, isEmpty, some } from 'lodash';
import Modal from 'react-modal';
import {
  loadItemOffer,
  loadItemUpdateOffer,
  loadWatchlistObject,
  resetBidHistory,
  resetOffer,
} from '../actions';
import {
  commafy,
  commafyCurrency,
  getConfig,
  isValidNumber,
  checkLink,
} from '../utils/helpers';
import { localization } from '../utils/localization';
import Fees from './common/Fees';
import IDHorizontal from './common/IDHorizontal';
import { confirmAmount } from './common/confirm/CallConfirm';
import { ifSalePurchaseDisclaimer } from '../utils/constants';

const styles = {
  customStyles: {
    _deprecated__content: {
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 300,
      maxHeight: 800,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  pseudoDropdown: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: '6px 16px',
    cursor: 'default',
    color: '#555',
  },
  link: {
    color: '#4183c4',
  },
};

/* TODO:  move to src/models/constants and apply to other 'Offer' business logic */
const offerTypeConstants = {
  RAISE_OFFER: "Raise Offer",
  SELLER_COUNTER: "Seller Counter",
  BUYER_COUNTER: "Buyer Counter",
  OFFER: "Offer",
}

class OfferModal extends Component {
  state = {
    accountList: [],
    accountsWithActiveOffers: [],
    selectedAccount: {},

    paymentTypes: [],
    selectedPayment: '',

    isSubmitting: false,
    offerAmount: '',
    requiredAmount: null,
    sellerWants: null,
    validationMessage: '',
    agreed: !!getConfig('agreeToTermsAtLogin'),

    hideAccountsWithoutBidderBadges: undefined,
    hideFees: undefined,
    distanceInKM: undefined,
    isIfSaleListing: undefined,

    loaded: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userProfile !== this.props.userProfile ||
      prevProps.bidHistory !== this.props.bidHistory
    ) {
      this.loadSellerWants();
    }
  }

  onOpen = () => {
    this.loadFeatures();
    this.loadSellerWants();
  };

  loadFeatures = () => {
    const mpFeatures = (this.props.marketplaceFeatures.features || '').split(
      ','
    );
    const itemMPFeatures = (
      this.props.item.marketplaceFeatures ||
      this.props.item.mpFeatures ||
      ''
    ).split(',');
    const eventFeatures = this.props.item?.eventFeatures
      ? this.props.item.eventFeatures.split(',')
      : [];

    this.setState(
      {
        hideAccountsWithoutBidderBadges: mpFeatures.includes('469'),
        hideFees: mpFeatures.includes('548'),
        distanceInKM: mpFeatures.includes('389'),
        isIfSaleListing: eventFeatures.includes('53'),
      },
      () => {
        this.loadAccounts();
      }
    );
  };

  loadAccounts = () => {
    if (this.props.userProfile.user) {
      this.setState(
        prevState => {
          const accountList = (
            this.props.userProfile.user.accountList || []
          ).filter(account => {
            const isPersonalAccount = account.accountNumber.includes('@');
            const missingRequiredBadge =
              this.state.hideAccountsWithoutBidderBadges &&
              account.badgeNumber === null;
            return isPersonalAccount || missingRequiredBadge ? false : true;
          });

          const accountsWithActiveOffers = accountList.reduce(
            (list, account, index) => {
              // we don't need to track realtime BidHistory here
              (this.props.bidHistory.data || []).forEach(offer => {
                if (
                  account.userId === offer.userId &&
                  ['Active', 'Buyer Countered', 'Seller Countered'].includes(
                    offer.offerStatus
                  ) &&
                  list.indexOf(offer.userId) === -1
                ) {
                  list.push(offer.userId);
                }
              });

              return list;
            },
            []
          );

          const selectedAccount =
            prevState.selectedAccount && prevState.selectedAccount.accountId
              ? prevState.selectedAccount
              : accountList[0] || {};

          return { accountList, accountsWithActiveOffers, selectedAccount };
        },
        () => {
          this.loadPayments();
        }
      );
    }
  };

  loadPayments = () => {
    const { selectedAccount } = this.state;
    const paymentTypes = (selectedAccount.paymentTypesArray || []).filter(
      type => !['Pay Later', 'PayLater'].includes(type)
    );
    paymentTypes.unshift('Pay Later'); // puts Pay Later in every array as first option

    const selectedPayment =
      paymentTypes.find(
        paymentType => paymentType === selectedAccount.defaultPaymentType
      ) || paymentTypes[0];

    this.setState({ paymentTypes, selectedPayment, loaded: true });
  };

  loadSellerWants() {
    const offer = find(this.props.bidHistory.data, {
      offerId: this.props.offerId,
    });
    const sellerWants = get(offer, 'requiredAmount');
    if (sellerWants) {
      this.setState({ sellerWants });
    }
  }

  isSeller = () => {
    const accountList = get(this.props.userProfile, 'user.accountList');
    const sellerNumber = this.props.item.sellerNumber;
    return some(accountList, { accountNumber: sellerNumber });
  };

  checkAmount = () => {
    return new Promise(resolve => {
      const offerAmount = Number(this.state.offerAmount);
      const price =
        this.props.item.outrightPrice || this.props.item.buyNow || 0;

      if (offerAmount < Number(price) * 10 && offerAmount < 100000) {
        resolve(true);
      } else {
        confirmAmount(
          `Confirm you want to offer ${commafyCurrency(offerAmount)} ?`,
          { paddingTop: 200 }
        ).then(
          () => {
            resolve(true);
          },
          () => {
            this.setState({ offerAmount: '' });
            resolve(false);
          }
        );
      }
    });
  };

  makeAmountFieldPlaceHolderText = () => {
    const { offerType, offerAmount, requiredAmount } = this.props;
    const { sellerWants } = this.state;

    if (offerType === 'Raise Offer') {
      return `Enter your new offer amount greater than ${commafyCurrency(
        offerAmount
      )}`;
    }

    if (offerType === 'Seller Counter') {
      return `Enter your counter offer amount greater than ${commafyCurrency(
        offerAmount
      )}`;
    }

    if (offerType === 'Buyer Counter') {
      return `Enter your counter offer ${
        requiredAmount || sellerWants
          ? `amount less than ${
              commafyCurrency(requiredAmount) || commafyCurrency(sellerWants)
            }`
          : ''
      }`;
    }

    return 'Enter your offer';
  };

  makeValidationMessage = offerAmount => {
    // returns error message if amount the user is typing in is not valid otherwise returns ''
    // this validation is only for raises and counters
    if (
      !['Raise Offer', 'Buyer Counter', 'Seller Counter'].includes(
        this.props.offerType
      )
    ) {
      return null;
    }

    let errorMessage = '';

    if (
      this.props.offerType === 'Raise Offer' &&
      offerAmount &&
      offerAmount <= this.props.offerAmount
    ) {
      errorMessage = `You offer must be greater that ${commafyCurrency(
        this.props.offerAmount
      )}`;
    }

    if (
      this.props.offerType === 'Seller Counter' &&
      offerAmount &&
      offerAmount <= this.props.offerAmount
    ) {
      errorMessage = `Your counter offer must be greater than ${commafyCurrency(
        this.props.offerAmount
      )}`;
    }

    if (
      this.props.offerType === 'Buyer Counter' &&
      offerAmount &&
      (this.props.requiredAmount || this.state.sellerWants) &&
      offerAmount >= (this.props.requiredAmount || this.state.sellerWants)
    ) {
      errorMessage = `Your counter offer must be less that ${commafyCurrency(
        this.props.requiredAmount || this.state.sellerWants
      )}`;
    }

    return errorMessage;
  };

  handleAmountChange = e => {
    const offerAmount = String(e.target.value).replace(/\D/g, '');
    const validationMessage = this.makeValidationMessage(offerAmount);

    if (offerAmount === '' || isValidNumber(offerAmount)) {
      this.setState({ offerAmount, validationMessage });
    }
  };

  handleAccountSelect = e => {
    const userId = Number(e.target.value);
    const selectedAccount = find(this.state.accountList, { userId }) || {};

    this.setState({ selectedAccount }, () => {
      this.loadPayments();
    });
  };

  handlePaymentSelect = e => {
    const selectedPayment = e.target.value;

    if (selectedPayment) {
      this.setState({ selectedPayment });
    }
  };

  handleOfferButtonPress = (e, offerType) => {
    e.preventDefault();
    switch (offerType) {
      case offerTypeConstants.OFFER:
        this.handleOffer();
        break;
      case offerTypeConstants.SELLER_COUNTER:
        this.handleCounterOffer();
        break;
      case offerTypeConstants.RAISE_OFFER:
        this.handleCounterOffer();
        break;
      case offerTypeConstants.BUYER_COUNTER:
        this.handleCounterOffer();
        break;
      default:
        this.handleCounterOffer();
        break;
    }
  }

  handleOffer = async () => {
    const amountOk = getConfig('enableHighAmountConfirm')
      ? await this.checkAmount()
      : true;

    if (amountOk) {
      this.setState({ isSubmitting: true }, () => {
        const mpId = getConfig('marketplaceId');
        const itemId = this.props.item.itemId || this.props.item.id;
        const offerTypeId =
          this.props.item.statusId === 1 ||
          this.props.item.listingStatusId === 1
            ? 4
            : 102;
        const amount = this.state.offerAmount;
        const userId = this.state.selectedAccount.userId;
        const paymentType =
          !this.state.selectedPayment ||
          this.state.selectedPayment === 'Pay Later'
            ? ''
            : this.state.selectedPayment;
        const transportationFeeId = 0;
        const transportFee = 0;

        this.props
          .loadItemOffer(
            mpId,
            itemId,
            offerTypeId,
            amount,
            userId,
            paymentType,
            transportationFeeId,
            transportFee
          )
          .then(({ response }) => {
            if (response) {
              if (response.wsStatus === 'Success') {
                this.onOfferSuccess(response);
              } else {
                this.onOfferWarning(response);
              }
            } else {
              throw new Error('Offer Error');
            }
          })
          .catch(error => this.onOfferError(error));
      });
    }
  };

  handleCounterOffer = () => {
    this.setState({ isSubmitting: true }, () => {
      const mpId = getConfig('marketplaceId');
      const itemId = this.props.item.itemId || this.props.item.id;
      const offerId = this.props.offerId;
      const offerType = this.props.offerType;

      let statusId = 1;
      if (offerType === 'Seller Counter') statusId = 9;
      else if (offerType === 'Buyer Counter') statusId = 10;
      else if (offerType === 'Raise Offer') statusId = 1;

      const amount = this.state.offerAmount;

      this.props
        .loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount)
        .then(({ response }) => {
          if (response) {
            if (response.wsStatus === 'Success') {
              this.onOfferSuccess(response);
            } else {
              this.onOfferWarning(response);
            }
          } else {
            throw new Error('Counter Offer Error');
          }
        })
        .catch(error => this.onOfferError(error));
    });
  };

  onOfferSuccess(response) {
    const mpId = getConfig('marketplaceId');
    this.props.loadWatchlistObject(mpId);
    toastr.success(response.wsStatus, response.wsMessage);
    this.onClose();

    this.setState(
      {
        offerAmount: '',
        isSubmitting: false,
        agreed: !!getConfig('agreeToTermsAtLogin'),
        requiredAmount: null,
        sellerWants: null,
        validationMessage: '',
      },
      () => {
        setTimeout(() => {
          this.props.loadData();
          this.props.resetOffer();
        }, 1000);
      }
    );
  }

  onOfferWarning(response) {
    toastr.warning('Attention', response.wsMessage, { icon: 'warning' });

    this.setState(
      {
        offerAmount: '',
        isSubmitting: false,
        requiredAmount: null,
        sellerWants: null,
        validationMessage: '',
      },
      () => {
        setTimeout(() => {
          this.props.loadData();
          this.props.resetOffer();
        }, 1000);
      }
    );
  }

  onOfferError(error) {
    console.error(error);
    toastr.error('Error', error.message || 'An unknown error occured');
    this.onClose();

    this.setState(
      {
        offerAmount: '',
        isSubmitting: false,
        requiredAmount: null,
        sellerWants: null,
        validationMessage: '',
      },
      () => {
        setTimeout(() => {
          this.props.loadData();
          this.props.resetOffer();
        }, 1000);
      }
    );
  }

  onClose = () => {
    this.setState(
      {
        offerAmount: '',
        isSubmitting: false,
        agreed: !!getConfig('agreeToTermsAtLogin'),
        requiredAmount: null,
        sellerWants: null,
        validationMessage: '',
      },
      () => {
        this.props.onClose();
      }
    );
  };

  renderBuyNowPrice() {
    const buyNowPrice = this.props.item.outrightPrice || this.props.item.buyNow;

    return (
      <div style={{ margin: '15px 0' }}>
        <h4
          style={{
            color: buyNowPrice ? '#016b19' : 'transparent', // keeps the spacing
          }}
        >
          {`Buy Now Price: ${commafyCurrency(buyNowPrice)}`}
        </h4>
      </div>
    );
  }

  renderBasicInfo() {
    const { item } = this.props;

    const name = `${item.year || item.vehicleYear} ${item.make} ${item.model}`;
    const itemLocation =
      item.itemCity && item.itemState
        ? `${item.itemCity}, ${item.itemState}`
        : item.vehicleCity && item.vehicleState
        ? `${item.vehicleCity}, ${item.vehicleState}`
        : '';

    return (
      <div>
        <h4 style={{ margin: '5px 0' }}>{name}</h4>
        <div style={{ margin: '5px 0' }}>
          <IDHorizontal
            vin={item.vin || item.vIN}
            stockNumber={item.stockNumber}
          />
        </div>
        {itemLocation && (
          <div style={{ margin: '5px 0' }}>
            <strong style={{ marginRight: 4 }}>Location:</strong>
            <span>{`${itemLocation}`}</span>
          </div>
        )}
      </div>
    );
  }

  renderAmountInput() {
    const placeholder = this.makeAmountFieldPlaceHolderText();

    return (
      <div className="form-group">
        <label className="sr-only" htmlFor="offer-amount">
          Amount
        </label>
        <div className="input-group">
          <div className="input-group-addon">
            {localization[getConfig('localization')].currency}
          </div>
          <input
            type="text"
            id="offer-amount"
            className="form-control"
            placeholder={placeholder}
            value={commafy(this.state.offerAmount)}
            onChange={this.handleAmountChange}
          />
        </div>
        {this.state.agreed && this.state.validationMessage && (
          <div style={{ color: 'red', marginTop: 5 }}>
            {this.state.validationMessage}
          </div>
        )}
      </div>
    );
  }

  renderFees() {
    if (!this.state.hideFees && this.props.offerType === 'Offer') {
      return (
        <div>
          <div style={{ margin: '0 5px 15px 5px' }}>
            <strong>* Normal fees apply for simulcast auctions.</strong>
          </div>
          <Fees
            amount={this.state.offerAmount}
            type="1"
            itemId={this.props.item.itemId || this.props.item.id}
            userId={this.state.selectedAccount.userId}
          />
        </div>
      );
    }
  }

  renderPreviousOfferMessage = accountHasActiveOffer => {
    if (accountHasActiveOffer) {
      return (
        <div style={{ color: 'red' }}>
          {accountHasActiveOffer &&
            !this.state.isSubmitting &&
            `You have already made an offer on this item with this account`}
        </div>
      );
    }
  };

  renderAccountSelect() {
    // if no authorized accounts
    if (!this.state.accountList.length) {
      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Buyer Account</label>
          <div
            style={{
              ...styles.pseudoDropdown,
              color: this.state.loaded ? 'red' : 'transparent',
            }}
          >
            No Authorized Accounts
          </div>
        </div>
      );
    }

    const accountHasActiveOffer = this.state.accountsWithActiveOffers.includes(
      get(this.state.selectedAccount, 'userId')
    );

    // if single option don't use a dropdown
    if (this.state.accountList.length === 1) {
      const { accountName, city, state } = this.state.selectedAccount;

      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Buyer Account</label>
          <div style={styles.pseudoDropdown}>
            {`${accountName} – ${city}, ${state}`}
          </div>
          {this.renderPreviousOfferMessage(accountHasActiveOffer)}
        </div>
      );
    }

    // if multiple options show dropdown
    const options = (this.state.accountList || []).map((account, index) => {
      const text = `${account.accountName} – ${account.accountNumber} – ${account.city}, ${account.state}`;

      return (
        <option key={index} value={account.userId}>
          {text}
        </option>
      );
    });

    return (
      <div className="form-group">
        <label htmlFor="buyer-account">Buyer Account</label>
        <select
          id="buyer-account"
          className="form-control"
          onChange={this.handleAccountSelect}
          value={this.state.selectedAccount.userId}
        >
          {options}
        </select>
        {this.renderPreviousOfferMessage(accountHasActiveOffer)}
      </div>
    );
  }

  renderPaymentSelect() {
    if (
      getConfig('hideSinglePaymentOption') &&
      this.state.paymentTypes.length <= 2 // if hideSinglePaymentOption feature is enabled you must have more than just Pay Later
    ) {
      return null;
    }

    // if single option don't use a dropdown
    if (this.state.paymentTypes.length < 2) {
      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Payment Type</label>
          <div
            style={{
              ...styles.pseudoDropdown,
              color: this.state.loaded ? '#555' : 'transparent',
            }}
          >
            {this.state.selectedPayment || '-'}
          </div>
        </div>
      );
    }

    // if multiple options show dropdown
    const options = (this.state.paymentTypes || []).map((item, index) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });

    return (
      <div className="form-group">
        <label htmlFor="payment-types">Payment Type</label>
        <select
          id="payment"
          className="form-control"
          onChange={this.handlePaymentSelect}
          value={this.state.selectedPayment}
        >
          {options}
        </select>
      </div>
    );
  }

  renderIfSaleDisclaimer () {
    if (!this.state.isIfSaleListing) return null;
    return (
      <div>
        <div style={{ 
            margin: '0 10px 10px 15px',
            border: '1px groove',
            background: 'rgba(125,125,55,.10)',
          }}
        >
            <strong>{ifSalePurchaseDisclaimer.HEADING}</strong>
            <p style={{marginTop: 5}}>
              {ifSalePurchaseDisclaimer.DISCLAIMER} 
            </p>
        </div>
      </div>
    )
  }

  renderTermsAndConditions() {
    const { customerTermsLink } = this.props;

    return (
      <div className="form-group">
        <input
          type="checkbox"
          id="agree"
          checked={this.state.agreed}
          disabled={getConfig('agreeToTermsAtLogin')}
          onChange={e => {
            this.setState(prevState => ({ agreed: !prevState.agreed }));
          }}
        />
        {customerTermsLink ? (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the{' '}
            <a
              href={customerTermsLink}
              target="_blank"
              rel="noreferrer"
              style={styles.link}
            >
              {`${getConfig('shortName')} Terms and Conditions`}
            </a>
          </label>
        ) : (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the Terms and Conditions
          </label>
        )}
      </div>
    );
  }

  renderDivider() {
    return <hr className='marketplace-reusable__modal-divider' />;
  }

  renderHeader() {
    return (
      <div className='marketplace-reusable__modal-header'>
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>{this.props.offerType}</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderFooter() {
    const {
      accountsWithActiveOffers,
      agreed,
      isSubmitting,
      offerAmount,
      selectedAccount,
      validationMessage,
    } = this.state;
    const { offerType } = this.props;

    const isBuyer = !this.isSeller();

    const isDisabled = !!(
      isSubmitting ||
      !agreed ||
      !offerAmount ||
      offerAmount === '0' ||
      validationMessage ||
      (isBuyer && !selectedAccount.userId) ||
      (isBuyer &&
        accountsWithActiveOffers.includes(get(selectedAccount, 'userId')) &&
        !['Raise Offer', 'Buyer Counter'].includes(offerType))
    ); // buyers should not make a 2nd offer... should be a raise or counter.

    const buttonText = isSubmitting
      ? 'Submitting Offer...'
      : offerType === 'Raise Offer'
      ? 'Place New Offer'
      : offerType === 'Seller Counter' || offerType === 'Buyer Counter'
      ? 'Place Counter Offer'
      : 'Place Offer';

    return (
      <div className='marketplace-reusable__modal-footer'>
        <div />
        <div className='marketplace-reusable__modal-buttons'>
          <button
            className="btn btn-primary button-cancel"
            style={{
              opacity: isSubmitting ? 0.5 : 1,
              minWidth: 100,
              marginRight: 10,
            }}
            disabled={isSubmitting}
            type="button"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary button-action"
            style={{
              opacity: isDisabled ? 0.5 : 1,
              minWidth: 100,
            }}
            type="button"
            disabled={isDisabled}
            onClick={(e) => this.handleOfferButtonPress(e, offerType)}
            // onClick={
            //   offerType === 'Offer' ? this.handleOffer : this.handleCounterOffer
            // }
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.item || !this.props.userProfile.user) {
      return null;
    }

    return (
      <Modal
        className={'marketplace-reusable__modal-content'}
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Offer Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div className='marketplace-reusable__modal-container'>
          {this.renderHeader()}
          {this.renderDivider()}
          <div className='marketplace-reusable__modal-body' style={styles.body}>
            {this.renderBasicInfo()}
            {this.renderBuyNowPrice()}
            <form autoComplete="off">
              {this.renderAmountInput()}
              {this.renderFees()}
              {this.props.offerType === 'Offer' && this.renderAccountSelect()}
              {!this.isSeller() && this.renderPaymentSelect()}
              {this.renderIfSaleDisclaimer()}
              {this.renderTermsAndConditions()}
            </form>
          </div>
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

OfferModal.defaultProps = {
  loadData: () => null,
};

OfferModal.propTypes = {
  customerTermsLink: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  offerAmount: PropTypes.number.isRequired,
  offerId: PropTypes.number.isRequired,
  offerType: PropTypes.string.isRequired,
  requiredAmount: PropTypes.number.isRequired,
  userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    bidHistory,
    customerTermsLink,
    marketplaceFeatures,
    offer,
    userProfile,
  } = state.entities;
  return {
    bidHistory,
    customerTermsLink,
    marketplaceFeatures,
    offer,
    userProfile,
  };
};

export default connect(mapStateToProps, {
  loadItemOffer,
  loadItemUpdateOffer,
  loadWatchlistObject,
  resetBidHistory,
  resetOffer,
})(OfferModal);
