import { FETCH_API } from '../../middleware';

/* * * * SellerActivity -> 'Sold' * * * *
 --------  Arbitration Actions  --------
 * * GET  - comments
 * * POST - (add new) comment
 * 
 */

 /* * * * (GET) Arbitration Comments * * * */
export const SELLER_ARBITRATION_COMMENTS_REQUEST = 'SELLER_ARBITRATION_COMMENTS_REQUEST';
export const SELLER_ARBITRATION_COMMENTS_SUCCESS = 'SELLER_ARBITRATION_COMMENTS_SUCCESS';
export const SELLER_ARBITRATION_COMMENTS_FAILURE = 'SELLER_ARBITRATION_COMMENTS_FAILURE';

const fetchSellerArbitrationComments = ({itemId}) => {
  return {
    [FETCH_API]: {
      types: [SELLER_ARBITRATION_COMMENTS_REQUEST, SELLER_ARBITRATION_COMMENTS_SUCCESS, SELLER_ARBITRATION_COMMENTS_FAILURE],
      endpoint: `seller/sold/${itemId}/arbitration/comments?apiKey=`,
      method: 'GET',
    },
  };
};

export const loadSellerArbitrationComments = (itemId) => {
  return dispatch => {
    return dispatch(fetchSellerArbitrationComments({itemId}));
  };
};

/* * * * (POST) Add Arbitration Comment * * * */
export const SELLER_ARBITRATION_ADD_COMMENT_REQUEST = 'SELLER_ARBITRATION_ADD_COMMENT_REQUEST';
export const SELLER_ARBITRATION_ADD_COMMENT_SUCCESS = 'SELLER_ARBITRATION_ADD_COMMENT_SUCCESS';
export const SELLER_ARBITRATION_ADD_COMMENT_FAILURE = 'SELLER_ARBITRATION_ADD_COMMENT_FAILURE';

const fetchSellerArbitrationAddComment = ({itemId, comment, file}) => {
  const formData = new FormData();
  formData.append('comment', comment);
  if (file) {
      formData.append('file', file, file?.name)
  }
  return {
    [FETCH_API]: {
      types: [SELLER_ARBITRATION_ADD_COMMENT_REQUEST, SELLER_ARBITRATION_ADD_COMMENT_SUCCESS, SELLER_ARBITRATION_ADD_COMMENT_FAILURE],
      endpoint: `seller/sold/${itemId}/arbitration/comment?apiKey=`,
      method: 'POST',
      body: formData,
    },
  };
};

export const loadSellerArbitrationAddComment = (itemId, comment, file ) => {
  return dispatch => {
    return dispatch(fetchSellerArbitrationAddComment({itemId, comment, file}));
  };
};

/* * * * Reset Seller Arbitration State * * * */
export const RESET_SELLER_ARBITRATION = 'RESET_SELLER_ARBITRATION';
export const resetSellerArbitration = () => {
    return {
      type: RESET_SELLER_ARBITRATION,
    };
};

// - - - - - BELOW: ACTIONS NOT IN USE - - - - - -

/* * * * (POST) Request new Arbitration * * * */
export const SELLER_ARBITRATION_NEW_REQUEST = 'SELLER_ARBITRATION_NEW_REQUEST';
export const SELLER_ARBITRATION_NEW_SUCCESS = 'SELLER_ARBITRATION_NEW_SUCCESS';
export const SELLER_ARBITRATION_NEW_FAILURE = 'SELLER_ARBITRATION_NEW_FAILURE';

const fetchSellerArbitrationNewRequest = ({itemId}) => {
  return {
    [FETCH_API]: {
      types: [SELLER_ARBITRATION_COMMENTS_REQUEST, SELLER_ARBITRATION_COMMENTS_SUCCESS, SELLER_ARBITRATION_COMMENTS_FAILURE],
      endpoint: `seller/sold/${itemId}/arbitration/request?apiKey=`,
      method: 'POST',
      body: '{}',
    },
  };
};

export const loadSellerArbitrationNewRequest = (itemId ) => {
  return dispatch => {
    return dispatch(fetchSellerArbitrationNewRequest({itemId}));
  };
};

/* * * * (POST) Cancel Existing Arbitration * * * */
export const SELLER_ARBITRATION_CANCEL_REQUEST = 'SELLER_ARBITRATION_CANCEL_REQUEST';
export const SELLER_ARBITRATION_CANCEL_SUCCESS = 'SELLER_ARBITRATION_CANCEL_SUCCESS';
export const SELLER_ARBITRATION_CANCEL_FAILURE = 'SELLER_ARBITRATION_CANCEL_FAILURE';

const fetchSellerArbitrationCancel = ({itemId}) => {
  return {
    [FETCH_API]: {
      types: [SELLER_ARBITRATION_COMMENTS_REQUEST, SELLER_ARBITRATION_COMMENTS_SUCCESS, SELLER_ARBITRATION_COMMENTS_FAILURE],
      endpoint: `seller/sold/${itemId}/arbitration/cancel?apiKey=`,
      method: 'POST',
      body: '{}',
    },
  };
};

export const loadSellerArbitrationCancel = (itemId ) => {
  return dispatch => {
    return dispatch(fetchSellerArbitrationCancel({itemId}));
  };
};

