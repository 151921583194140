import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './root';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'photoswipe/dist/photoswipe.css';
import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import configureStore from './store';
import { persistStore } from 'redux-persist';
import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';

const renderApp = async () => {
    const store = await configureStore();
    const history = syncHistoryWithStore(createBrowserHistory(), store);
    const {mpConfig} = store.getState()
    
    const root = ReactDOM.createRoot(document.getElementById('app-container'));
    const persistor = persistStore(store, {}, () => {
    //   isHydrationComplete = true;
      root.render(
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <Root history={history} mpConfig={mpConfig}  />
              </PersistGate>
          </Provider>
      );
    });
}

renderApp();
