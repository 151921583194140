import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import OfferModal from '../OfferModal';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadItemUpdateOffer,
  loadSellerOffers,
  resetSellerOffers,
  loadExtOfferUpdate,
} from '../../actions';
import { compose, getConfig, getTemplate } from '../../utils/helpers';
import styles from './styles';
import $ from 'jquery';
import 'bootstrap';

import ExtOfferModal from '../ExtOfferModal';
import { SST_FIRST_OFFER } from '../../utils/constants';

class SellerOffers extends Component {
  state = {
    title: 'seller offers',
    offerType: '',
    offerId: 0,
    offerAmount: 0,
    requiredAmount: 0,
    offerStatus: 0,
    item: {},
    headers: [],
    searchStr: '',
    deeplink: null,
    isOfferModalOpen: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetSellerOffers();
    this.props.loadSellerOffers(getConfig('marketplaceId'));
  };

  determineModalId = itemId => {
    return `sellerOffers${itemId || this.state.item.itemId}`;
  };

  handleAccept = row => {
    confirm('Are you sure you want to accept this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const mpId = getConfig('marketplaceId');
        const { amount, offerId, itemId } = row;
        const statusId = 8; // seller accepted

        loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount).then(
          ({ response }) => {
            if (response) this.loadData();
          }
        );
      },
      () => null
    );
  };

  handleDecline = row => {
    confirm('Are you sure you want to decline this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const mpId = getConfig('marketplaceId');
        const { amount, offerId, itemId } = row;
        const statusId = 7; // seller rejected

        loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount).then(
          ({ response }) => {
            if (response) this.loadData();
          }
        );
      },
      () => null
    );
  };

  handleWithdraw = row => {
    confirm('Are you sure you want to withdraw this offer?').then(
      () => {
        const { loadItemUpdateOffer } = this.props;
        const mpId = getConfig('marketplaceId');
        const { amount, offerId, itemId } = row;
        const statusId = 13; // seller cancelled

        loadItemUpdateOffer(mpId, itemId, offerId, statusId, amount).then(
          ({ response }) => {
            if (response) this.loadData();
          }
        );
      },
      () => null
    );
  };

  handleCounter = row => {
    const { offerId, amount, requiredAmount } = row;

    this.setState(
      {
        offerType: 'Seller Counter',
        item: row,
        offerId,
        offerAmount: amount,
        requiredAmount,
      },
      () => {
        this.setState({ isOfferModalOpen: true });
      }
    );
  };

  getDeepLink = offerId => {
    const url =
      `${getConfig('apiRoot')}ext-offer-deeplink` +
      `?apiKey=${this.props.cookies.get('apiKey')}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&offerId=${offerId}`;

    fetch(url)
      .then(res => res.json())
      .then(result => {
        if (result.deepLink.deepLinkUri) {
          this.setState(
            { offerId: offerId, deeplink: result.deepLink.deepLinkUri },
            () => {
              $('#extOfferModal').modal('show');
            }
          );
        } else {
          toastr.error('Error', result.wsMessage);
        }
      });
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  handleExtOffersModalCancel = () => {
    const mpId = getConfig('marketplaceId');
    const { offerId } = this.state;

    this.props.loadExtOfferUpdate(mpId, offerId);
    this.setState({ offerId: 0, deeplink: null }, () => {
      $('#extOfferModal').modal('hide');
    });
  };

  makeActionButtons = data => {
    return {
      Header: 'Actions',
      headerStyle: { background: 'none' },
      id: 'buttons',
      sortable: false,
      filterable: false,
      Cell: row => {
        const showAcceptButton = ['Active', 'Buyer Countered'].includes(
          row.original.status
        );
        const showCounterButton = ['Active', 'Buyer Countered'].includes(
          row.original.status
        );
        const showDeclineButton = ['Active', 'Buyer Countered'].includes(
          row.original.status
        );
        const showCancelButton = ['Seller Countered'].includes(
          row.original.status
        );

        return (
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              onClick={() =>
                this.handleDetailsClick(row.original.itemId, data)
              }
            >
              View Details
            </Button>

            {row.original.type === SST_FIRST_OFFER ? (
              <Button
                style={styles.button}
                onClick={() => this.getDeepLink(row.original.offerId)}
              >
                View Offer
              </Button>
            ) : (
              <div style={styles.counterButtons}>
                {showAcceptButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.green'
                      ),
                    }}
                    onClick={() => this.handleAccept(row.original)}
                  >
                    Accept
                  </Button>
                )}

                {showCounterButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.orange'
                      ),
                    }}
                    onClick={() => this.handleCounter(row.original)}
                  >
                    Counter
                  </Button>
                )}

                {showDeclineButton && (
                  <Button
                    style={{
                      ...styles.button,
                      backgroundColor: getTemplate(
                        this.props.template,
                        'misc.red'
                      ),
                    }}
                    onClick={() => this.handleDecline(row.original)}
                  >
                    Decline
                  </Button>
                )}
              </div>
            )}

            {showCancelButton && row.original.type !== SST_FIRST_OFFER && (
              <Button
                style={{
                  ...styles.button,
                  backgroundColor: getTemplate(
                    this.props.template,
                    'misc.red'
                  ),
                }}
                onClick={() => this.handleWithdraw(row.original)}
              >
                Withdraw
              </Button>
            )}
          </div>
        );
      },
    }
  }

  render() {
    const loading = isEmpty(this.props.sellerOffers);
    const data = (this.props.sellerOffers.data || []).sort((a, b) =>
      a.dateCreated > b.dateCreated ? -1 : 1
    );
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      this.makeActionButtons(data),
      'vin',
      'year',
      'make',
      'model',
      'seller',
      'sellerWants',
      'bidder',
      'buyerOffer',
      'status',
      'buyNow',
      'offerDate',
      'daysInMarketplace',
      'lotNumber',
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Offers</h4>
        <div>
          <ExtOfferModal
            deeplink={this.state.deeplink}
            onModalCancel={this.handleExtOffersModalCancel}
          />
          <OfferModal
            modalId={this.determineModalId()}
            item={this.state.item}
            loadData={this.loadData}
            offerAmount={this.state.offerAmount}
            offerId={this.state.offerId}
            offerType={this.state.offerType}
            requiredAmount={this.state.requiredAmount}
            navigate={this.props.navigate}
            isOpen={this.state.isOfferModalOpen}
            onClose={() => this.setState({ isOfferModalOpen: false })}
            user={this.props.userProfile.user}
          />
          <DataTable
            loading={loading}
            title={this.state.title}
            data={data}
            columns={columns}
            showCSVButton={true}
            showPrintButton={true}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { offer, sellerOffers, userProfile, template } = state.entities;
  return { offer, sellerOffers, userProfile, template };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadItemUpdateOffer,
    loadSellerOffers,
    resetSellerOffers,
    loadExtOfferUpdate,
  })
)(SellerOffers);
