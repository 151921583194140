import { FETCH_API } from '../../middleware';

/* * * *  BuyerActivity -> 'Purchased' * * * *
 ---------  Arbitration Actions  ---------
 * * GET  - comments
 * * POST - (add new) arbitration request 
 *        - (add new) comment
 *        - cancel arbitration request
 */

 /* * * * (GET) Arbitration Comments * * * */
export const BUYER_ARBITRATION_COMMENTS_REQUEST = 'BUYER_ARBITRATION_COMMENTS_REQUEST';
export const BUYER_ARBITRATION_COMMENTS_SUCCESS = 'BUYER_ARBITRATION_COMMENTS_SUCCESS';
export const BUYER_ARBITRATION_COMMENTS_FAILURE = 'BUYER_ARBITRATION_COMMENTS_FAILURE';

export const fetchBuyerArbitrationComments = ({itemId}) => {
  return {
    [FETCH_API]: {
      types: [BUYER_ARBITRATION_COMMENTS_REQUEST, BUYER_ARBITRATION_COMMENTS_SUCCESS, BUYER_ARBITRATION_COMMENTS_FAILURE],
      endpoint: `buyer/purchased/${itemId}/arbitration/comments?apiKey=`,
      method: 'GET',
    },
  };
};

export const loadBuyerArbitrationComments = (itemId) => {
  return dispatch => {
    return dispatch(fetchBuyerArbitrationComments({itemId}));
  };
};

/* * * * (POST) Request new Arbitration * * * */
export const BUYER_ARBITRATION_NEW_REQUEST = 'BUYER_ARBITRATION_NEW_REQUEST';
export const BUYER_ARBITRATION_NEW_SUCCESS = 'BUYER_ARBITRATION_NEW_SUCCESS';
export const BUYER_ARBITRATION_NEW_FAILURE = 'BUYER_ARBITRATION_NEW_FAILURE';

export const fetchBuyerArbitrationNewRequest = ({itemId}) => {
  return {
    [FETCH_API]: {
      types: [BUYER_ARBITRATION_COMMENTS_REQUEST, BUYER_ARBITRATION_COMMENTS_SUCCESS, BUYER_ARBITRATION_COMMENTS_FAILURE],
      endpoint: `buyer/purchased/${itemId}/arbitration/request?apiKey=`,
      method: 'POST',
      body: '{}',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };
};

export const loadBuyerArbitrationNewRequest = (itemId ) => {
  return dispatch => {
    return dispatch(fetchBuyerArbitrationNewRequest({itemId}));
  };
};

/* * * * (POST) Cancel Existing Arbitration * * * */
export const BUYER_ARBITRATION_CANCEL_REQUEST = 'BUYER_ARBITRATION_CANCEL_REQUEST';
export const BUYER_ARBITRATION_CANCEL_SUCCESS = 'BUYER_ARBITRATION_CANCEL_SUCCESS';
export const BUYER_ARBITRATION_CANCEL_FAILURE = 'BUYER_ARBITRATION_CANCEL_FAILURE';

export const fetchBuyerArbitrationCancel = ({itemId}) => {
  return {
    [FETCH_API]: {
      types: [BUYER_ARBITRATION_COMMENTS_REQUEST, BUYER_ARBITRATION_COMMENTS_SUCCESS, BUYER_ARBITRATION_COMMENTS_FAILURE],
      endpoint: `buyer/purchased/${itemId}/arbitration/cancel?apiKey=`,
      method: 'POST',
      body: '{}',
    },
  };
};

export const loadBuyerArbitrationCancel = (itemId ) => {
  return dispatch => {
    return dispatch(fetchBuyerArbitrationCancel({itemId}));
  };
};

/* * * * (POST) Add Arbitration Comment * * * */
export const BUYER_ARBITRATION_ADD_COMMENT_REQUEST = 'BUYER_ARBITRATION_ADD_COMMENT_REQUEST';
export const BUYER_ARBITRATION_ADD_COMMENT_SUCCESS = 'BUYER_ARBITRATION_ADD_COMMENT_SUCCESS';
export const BUYER_ARBITRATION_ADD_COMMENT_FAILURE = 'BUYER_ARBITRATION_ADD_COMMENT_FAILURE';

const fetchBuyerArbitrationAddComment = ({itemId, comment, file}) => {
  const formData = new FormData();
  formData.append('comment', comment);
  if (file) {
      formData.append('file', file, file?.name)
  }
  return {
    [FETCH_API]: {
      types: [BUYER_ARBITRATION_ADD_COMMENT_REQUEST, BUYER_ARBITRATION_ADD_COMMENT_SUCCESS, BUYER_ARBITRATION_ADD_COMMENT_FAILURE],
      endpoint: `buyer/purchased/${itemId}/arbitration/comment?apiKey=`,
      method: 'POST',
    //   body: JSON.stringify({
    //     comment,
    //     file,
    //   }),
      body: formData,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    },
  };
};

export const loadBuyerArbitrationAddComment = (itemId, comment, file ) => {
  return dispatch => {
    return dispatch(fetchBuyerArbitrationAddComment({itemId, comment, file}));
  };
};

/* * * * Reset Buyer Arbitration State * * * */
export const RESET_BUYER_ARBITRATION = 'RESET_BUYER_ARBITRATION';
export const resetBuyerArbitration = () => {
    return {
      type: RESET_BUYER_ARBITRATION,
    };
};