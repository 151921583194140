import React from "react"

/** ArbitrationModalFooter - Contains actionable buttons for Arbitration Modal
 * 
 * @param {{
 *  cancelButtonLabel?: string,
 *  isSubmitDisabled: boolean,
 *  onCancel: ()=>void,
 *  onCancelArbitration: ()=>void,
 *  onSubmit: ()=>void,
 *  showCancelArbitrationButton: boolean,
 *  submitButtonLabel: string,
 *  submitFormId: string,
 * }} props 
 *  
 * @returns {React.JSX.Element}
 */
export function ArbitrationModalFooter ({
    cancelButtonLabel='Cancel', 
    isSubmitDisabled, 
    onCancel, 
    onCancelArbitration, 
    onSubmit, 
    showCancelArbitrationButton=false,
    submitButtonLabel='Submit', 
    submitFormId,
}) {
    return (
        <div id='arb-modal-footer' className='marketplace-reusable__modal-buttons' >
            {/* 'Cancel' button */}
            {showCancelArbitrationButton
                ? (<div >
                    <button
                        className="btn btn-primary button-warning"
                        style={{
                            minWidth: 100,
                        }}
                        type="button"
                        data-dismiss="modal"
                        onClick={onCancelArbitration}
                    >
                        {"Cancel Arbitration"}
                    </button>
                </div>)
                : null
            }
            <button
                className="btn btn-primary button-cancel"
                style={{
                    minWidth: 115,
                }}
                type="button"
                data-dismiss="modal"
                onClick={onCancel}
            >
                {cancelButtonLabel}
            </button>

            {/* 'Submit' button */}
            <button
                className="btn btn-primary button-action"
                style={{
                    opacity: isSubmitDisabled ? 0.25 : 1,
                    minWidth: 115,
                }}
                data-arb-form-id={submitFormId}
                disabled={isSubmitDisabled}
                type="button"
                onClick={(e) => onSubmit(e)}
            >
                {submitButtonLabel}
            </button>
        </div>
    )
}