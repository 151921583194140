import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeAssetLink } from '../../utils/helpers';

class TableRowImage extends Component {
  render() {
    if (!this.props.src) return null;

    return (
      <img
        src={this.props.src}
        style={{
          width: this.props.width,
          height: this.props.width ? this.props.width * 0.75 : 'auto',
        }}
        onError={e => {
          e.currentTarget.onerror = null;
          e.target.src = makeAssetLink('placeholder.png');
        }}
        alt="table"
      />
    );
  }
}

TableRowImage.defaultProps = {
  width: 120,
};

TableRowImage.propTypes = {
  src: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default TableRowImage;
