import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { TransporationModalMode } from './TransporationConstants';
import TransportationProviderSelection from './TransportationProviderSelection';
import AcertusContact from './AcertusContact';
import RoyaltyContact  from './RoyaltyContact';
import RunBuggy from './RunBuggy';

export default function TransportationModal({ mpId, itemData, user, mode, isOpen, onClose }) {

    const styles = {
        customStyles: {
            _deprecated__content: { /* Moved to 'assets/css/style.css' */
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                zIndex: 99,
                position: 'absolute',
                borderRadius: '8px',
                border: 'none',
                boxShadow:
                    '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                minWidth: '400px',
                width: '70%',
                // minHeight: 300,
                //   maxHeight: 750,
                overflowY: 'scroll',
            },
            overlay: {
                zIndex: 99,
                backgroundColor: 'rgba(0, 0, 0, .6)',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            alignContent: 'space-around',
            flexWrap: 'wrap',
            padding: '0 .15rem',
        },
        providerContainer: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 150,
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'center'
        },
        divider: {
            borderColor: '#E5E5E5',
            width: '100%',
            alignSelf: 'center',
            marginTop: 15,
            marginBottom: 10,
        }
    };

    const startingMode = mode || TransporationModalMode.SELECTION;
    const [isModalOpen, setModalOpen] = useState(isOpen);
    const [modalMode, setModalMode] = useState((startingMode));

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        setModalMode(startingMode);
    }, [startingMode]);

    function handleClose() {
        setModalMode(startingMode);
        setModalOpen(false);
        onClose();
    }

    function renderHeader() {
        return (
            <div style={{ ...styles.header, alignSelf: 'stretch' }}>
                {/* Form Title */}
                <div style={{ fontSize: 24, color: '#535768' }}>
                    <div>
                        <span className="fa fa-truck-moving" />
                        <span style={{ marginLeft: 10 }}>
                            Order Transportation
                        </span>
                    </div>
                </div>
                {/* Close Modal */}
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleClose()}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
        );
    }

    function renderDivider() {
        return (<hr style={styles.divider} />)
    }

    return (
        <Modal
            isOpen={isModalOpen}
            // style={styles.customStyles}
            className={'marketplace-reusable__modal-content'}
            overlayClassName={'marketplace-reusable__modal-overlay'}
        >
            <div style={styles.container}>
                {renderHeader()}
                {renderDivider()}
                {
                    {
                        [TransporationModalMode.SELECTION]:
                            <div style={styles.providerContainer}>
                                <TransportationProviderSelection
                                    itemData={itemData}
                                    onSelect={selection => {
                                        setModalMode(selection);
                                    }}
                                />
                            </div>,
                        [TransporationModalMode.ACERTUS]:
                            <AcertusContact
                                mpId={mpId}
                                itemData={itemData}
                                user={user}
                                onClose={handleClose}
                            />,
                        [TransporationModalMode.ROYALTY]:
                                <RoyaltyContact
                                    mpId={mpId}
                                    itemData={itemData}
                                    user={user}
                                    onClose={handleClose}
                                />,
                        [TransporationModalMode.RUNBUGGY]:
                            <RunBuggy
                                itemData={itemData}
                                user={user}
                            />
                    }[modalMode]
                }

            </div>
        </Modal>
    );
}


