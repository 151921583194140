/**
 * @description Stonly script element's innerHTML
 */
const stonlyInnerHTML = `\
!function(s,t,o,n,l,y,w,g,d,e){s.StonlyWidget||((d=s.StonlyWidget=function(){\
d._api?d._api.apply(d,arguments):d.queue.push(arguments)}).scriptPath=n,d.apiPath=l,d.sPath=y,d.queue=[],\
(g=t.createElement(o)).async=!0,(e=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),\
e.onreadystatechange=function(){4===e.readyState&&(g.src=n+"stonly-widget.js?v="+\
(200===e.status?e.responseText:Date.now()),(w=t.getElementsByTagName(o)[0]).parentNode.insertBefore(g,w))},e.send())}\
(window,document,"script","https://stonly.com/js/widget/v2/");`;

/**
 * @description Value set in `window.STONLY_WID` prior to script
 */
const stonlyWid = '5417a798-8c8d-11ed-871a-0a52ff1ec764'

export {stonlyInnerHTML, stonlyWid}