import React, { useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { loadSellerIfAccept } from "../../actions";
import { getConfig } from "../../utils/helpers";

const SellerIfAcceptHeader = ({onClose}) => {
    return (
    <div className='marketplace-reusable__modal-header'>
      <div style={{ fontSize: 24, color: '#535768' }}>
        <span className="fa fa-file" />
        <span style={{ marginLeft: 10 }}>Accept If Sale</span>
      </div>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  )
}

const SellerIfAcceptFooter = ({onProceed, onClose, isConfirmDisabled}) => {
    return (
        <div className='marketplace-reusable__modal-footer'>
          <div />
          <div className='marketplace-reusable__modal-buttons'>
            <button
              className="btn btn-primary button-cancel"
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              type="button"
              data-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
  
            <button
              className="btn btn-primary button-action"
              style={{
                opacity: isConfirmDisabled ? 0.5 : 1,
                minWidth: 100,
              }}
              type="button"
              disabled={isConfirmDisabled}
              onClick={onProceed}
            >
              Accept
            </button>
          </div>
        </div>
      )
}

/**
 * @description Confirmation of sale for co-signer listed vehicles
 * @param {{
 *   onConfirm: (accountId: number|string, comments?: string)=>void, 
 *   onClose: ()=>void, 
 *   isOpen: boolean} props 
 * @returns 
 */
export const SellerIfAcceptModal = (props) => {
  /* State */
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState({accountId: '0'});
  const [sellerComments, setSellerComments] = useState('');
  /* Redux state | Props */
  const dispatch = useDispatch();
  const {userProfile} = useSelector(({entities}) => entities);
  const accounts = userProfile?.user?.accountList ? [...userProfile.user.accountList] : [];
  /* Callbacks */
  const handleAccountSelection = (event) => {
    const accountId = Number(event.target.value);
    const selectedSellerAccount = accounts.find(acct => acct.accountId === accountId)
    if (selectedSellerAccount) {
      setIsDisabled(false);
      setSelectedAccount(selectedSellerAccount);
    }
  }
  const handleSubmitAccept = (event) => {
    event.preventDefault();
    const {accountId} = selectedAccount;
    setIsDisabled(true);
    props.onConfirm(accountId, sellerComments);
  }
  /* Data */
  const options = accounts.map(acct => {
    return {
      key: acct.accountId, 
      value: acct.accountId, 
      text: `${acct.accountName} – ${acct.accountNumber} – ${acct.city}, ${acct.state}`,
    }
  })
  options.unshift({key: '0', value: '0', text: 'Please select an account'})

  return (
      <ReactModal 
        isOpen={props.isOpen}
        className='marketplace-reusable__modal-content'
        // style={styles.customStyles}
        contentLabel="Seller Ifs Accept Sale"
      >
          {/* HEADER */}
          <SellerIfAcceptHeader onClose={props.onClose} />
          <hr className='marketplace-reusable__modal-divider' />
          {/* BODY */}
          <div>
            <textarea
              className="form-control marketplace-reusable__modal-textarea"
              cols="40"
              onChange={e=>{setSellerComments(e.target.value)}}
              placeholder="Seller comments ..."
              rows="5"
              style={{resize: 'none'}}
              value={sellerComments}
              
            />
            <label htmlFor="seller-account" />
            <select
              className="form-control"
              id="seller-account"
              value={selectedAccount.accountId}
              onChange={handleAccountSelection}
            >
              {options.map(({key, value, text}) => {
                return (<option disabled={value==='0'} key={key} value={value}>{text}</option>)  
              })}
            </select>

          </div>
          <hr className='marketplace-reusable__modal-divider' />
          {/* FOOTER */}
          <SellerIfAcceptFooter
              onClose={props.onClose}
              onProceed={handleSubmitAccept}
              isConfirmDisabled={isDisabled}
          />
      </ReactModal>
  )
}