import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import $ from 'jquery';
import {
  loadBuyNowCount,
  loadItemCount,
  loadSellingNowCount,
  loadServerTime,
  loadWatchlistUserItems,
} from '../actions';
import {
  getConfig,
  getParamFromUrl,
  getTemplate,
  hasMPFeature,
  makeAssetLink,
  makeLiveSimulcastLink,
} from '../utils/helpers';
import { mpHomePageSectionsMap } from '../utils/constants';
import HomeSection from './HomeSection';

window.$ = window.jQuery = $;
const awgHomeSectionsFallback = '13,4,1,8,9,3,6,7';
const minHeight = 224;
const styles = {
  mastheadContainer: {
    minHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    margin: 0,
    padding: 0,
    paddingTop: 56,
    zIndex: 10,
  },
  mastheadLeft: {
    minHeight,
    flex: '1 1 50%',
    height: '100%',
    paddingLeft: 20,
  },
  mastheadRight: {
    minHeight,
    flex: '1 1 50%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    color: '#fff',
    height: '100%',
  },
  statsSectionContainer: {
    display: 'flex',
    flex: '1 1',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingBottom: 20,
  },
  buttonsSectionContainer: {
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
  },
  dashboardLogo: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0px auto',
    height: 'auto',
    // width: 'auto',
    maxHeight: 200,
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#fff',
    margin: 14,
  },
  statsLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: -10,
    width: '100%',
  },
  statsVal: {
    fontSize: 36,
  },
  statsIcon: {
    marginRight: 15,
  },
  linkButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    transition: 'all 0.5s',
    padding: '12px 24px',
    margin: '20px 60px',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)',
    height: 60,
    width: 300,
    fontSize: 22,
  },
};

class Home extends Component {
  state = {
    mpHomePageSections: null,
  };

  componentDidMount() {
    this.loadInit();
    this.loadVAuto();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.mpHomePageSections &&
      this.props.marketplaces.marketplaceList
    ) {
      this.loadMarketplaceSettings(this.props.marketplaces);
    }
  }

  loadInit() {
    const mpId = getConfig('marketplaceId');
    localStorage.setItem('marketplaceId', mpId);

    const { listingStatusIds, unauthorizedEventIds } = this.props;

    if (
      !this.state.mpHomePageSections &&
      this.props.marketplaces.marketplaceList
    ) {
      this.loadMarketplaceSettings(this.props.marketplaces);
    }

    this.props.loadServerTime(mpId);
    this.props.loadWatchlistUserItems(mpId);
    this.props.loadSellingNowCount(listingStatusIds, unauthorizedEventIds);
    this.props.loadItemCount(listingStatusIds, unauthorizedEventIds);
  }

  loadVAuto() {
    if (getParamFromUrl('vauto', this.props.location.search) === 'true') {
      const mpId = getConfig('marketplaceId');
      const apiKey = this.props.cookies.get('apiKey');
      const userId = localStorage.getItem('userId');

      const url =
        `${getConfig('apiRoot')}` +
        `user-profile` +
        `?mpId=${mpId}` +
        `&apiKey=${apiKey}` +
        `&userId=${userId}`;
      fetch(url)
        .then(response => {
          if (response.status === 200) {
            window.opener.postMessage(apiKey, '*');
            window.open('', '_self', '');
            window.close();
          } else {
            this.props.navigate('/?vauto=true');
          }
        })
        .catch(() => {
          this.props.navigate('/login?vauto=true');
        });
    }
  }

  loadMarketplaceSettings = marketplaces => {
    const mpId = getConfig('marketplaceId');

    const parentMarketplace = find(
      marketplaces.marketplaceList,
      mp => mp.marketplaceId == mpId
    );

    if (parentMarketplace) {
      const awgHomeSections =
        parentMarketplace.mpHomepageSections ||
        parentMarketplace.mpHomePageSections;

      const mpHomePageSections = (
        awgHomeSections && awgHomeSections !== '0'
          ? awgHomeSections
          : awgHomeSectionsFallback
      )
        .split(',')
        .map(key => {
          const val = mpHomePageSectionsMap.get(key.trim());
          if (!val) {
            console.error(`mpHomePageSections does not contain key ${key}`);
          }
          return val;
        })
        .filter(val => val);

      this.setState({ mpHomePageSections });
    } else {
      this.setState({ mpHomePageSections: [] });
    }
  };

  renderLiveSimulcastButton() {
    const simulcastEnabled = hasMPFeature(
      this.props.marketplaceFeatures.features,
      410
    );
    const isEventRunning =
      get(this.props.simulcastStatus, 'simulcastStatus.eventsRunning') > 0;

    if (simulcastEnabled && isEventRunning) {
      return (
        <Link
          className='home__link-button'
          style={{
            // ...styles.linkButton, 8.28.2024 - moved to css class in 'assets/css/styles.css'
            backgroundColor: getTemplate(
              this.props.template,
              'joinLiveSimulcastButton.backgroundColor'
            ),
            color: getTemplate(
              this.props.template,
              'joinLiveSimulcastButton.color'
            ),
          }}
          to={makeLiveSimulcastLink(this.props.cookies)}
          target="_blank"
        >
          <span style={{ marginRight: 10 }}>Join Live Simulcast</span>
          <span className="fas fa-chevron-right" style={{ fontWeight: 600 }} />
          <span className="fas fa-chevron-right" style={{ fontWeight: 600 }} />
        </Link>
      );
    }
  }

  renderSearchInventoryButton() {
    const text = 'Search All Inventory';
    const link = '/search';

    return (
      <Link
        className='home__link-button'
        style={{
          // ...styles.linkButton, 7.10.2024 - replaced with css class
          backgroundColor: getTemplate(
            this.props.template,
            'searchAllInventoryButton.backgroundColor'
          ),
          color: getTemplate(
            this.props.template,
            'searchAllInventoryButton.color'
          ),
        }}
        to={link}
      >
        <div style={{ marginRight: 10 }}>{text}</div>
        <div className="fas fa-chevron-right" style={{ fontWeight: 600 }} />
        <div className="fas fa-chevron-right" style={{ fontWeight: 600 }} />
      </Link>
    );
  }

  renderButtonSection() {
    return (
      <div className='home__button-section-container'>
        {this.renderLiveSimulcastButton()}
        {this.renderSearchInventoryButton()}
      </div>
    );
  }

  renderItemCount() {
    const { count } = this.props.itemCount;
    if (!count) return null;

    return (
      <Link to="/search" style={styles.statsContainer}>
        <h2 style={styles.statsLabel}>
          <span className="fa fa-car" style={styles.statsIcon} />
          <span>Items</span>
        </h2>
        <h1 style={styles.statsVal}>
          <CountUp start={0} end={count} duration={0.33} separator="," />
        </h1>
      </Link>
    );
  }

  renderEventStats() {
    const { events, unauthorizedEventIds } = this.props;

    if (!events.eventList) return null;

    const count = events.eventList.filter(event => {
      const isAllowed = !unauthorizedEventIds.includes(event.eventId);
      const hasItemCount = event.itemCount > 0;
      return isAllowed && hasItemCount;
    }).length;

    return (
      <Link to="/events" style={styles.statsContainer}>
        <h2 style={styles.statsLabel}>
          <span
            className="fa fa-calendar"
            style={{ ...styles.statsIcon, fontSize: '0.9em' }}
          />
          <span>Events</span>
        </h2>
        <h1 style={styles.statsVal}>
          <CountUp start={0} end={count} duration={0.33} separator="," />
        </h1>
      </Link>
    );
  }

  renderStatsSection() {
    return (
      <div className='home__stats-section-container'>
        {this.renderItemCount()}
        {this.renderEventStats()}
      </div>
    );
  }

  renderMastheadContent() {
    const mastheadImage =
      makeAssetLink('masthead.png') || makeAssetLink('bg.jpg');
    const dashboardLogoImage = makeAssetLink('dashboardLogo.png');
    let opacity = getConfig('mastheadOpacity');
    if (opacity === undefined) opacity = 0.5;

    return (
      <div
        className='home__masthead-content'
        style={{backgroundImage: `url(${mastheadImage})`}}
      >
        <div
          style={{
            ...styles.mastheadContainer,
            backgroundColor: `rgba(0, 0, 0, ${opacity})`,
          }}
        >
          <div className='masthead_left'>
            {dashboardLogoImage && (
              <img
                src={dashboardLogoImage}
                alt="Logo"
                // style={styles.dashboardLogo}
                className='home__dashboard-logo'
              />
            )}
          </div>
          <div style={styles.mastheadRight}>
            {this.renderStatsSection()}
            {this.renderButtonSection()}
          </div>
        </div>
      </div>
    );
  }

  renderPlaceholderSection() {
    const { events } = this.props;

    if (!events.eventList) return null;

    if (!events.eventList.length) {
      return (
        <div className="row">
          <div className="col-md-12">
            <div
              className="full-screen-section"
              style={{
                backgroundColor: '#fff',
                padding: 15,
                marginTop: 15,
                borderRadius: 5,
              }}
            >
              <h3>
                We are working on getting vehicles loaded into our marketplace.
                Please check in later.
              </h3>
            </div>
          </div>
        </div>
      );
    }
  }

  renderSections() {
    const sections = (this.state.mpHomePageSections || []).map(
      (item, index) => {
        return (
          <HomeSection
            key={item.name}
            section={item}
            index={index}
            {...this.props}
          />
        );
      }
    );

    return sections;
  }

  renderMainContent() {
    if (!this.state.mpHomePageSections) return null;

    return (
      <div
        className="container-fluid user-dashboard step-2"
        style={{ paddingTop: '0px' }}
      >
        {this.renderPlaceholderSection()}
        {this.renderSections()}
      </div>
    );
  }

  render() {
    const title = getConfig('name') ? getConfig('name') + ' | Home' : 'Home';

    return (
      <DocumentTitle title={title}>
        <div>
          {this.renderMastheadContent()}
          {this.renderMainContent()}
        </div>
      </DocumentTitle>
    );
  }
}

Home.propTypes = {};

const mapStateToProps = state => {
  const {
    buyNowCount,
    events,
    itemCount,
    listingStatusIds,
    login,
    marketplaceFeatures,
    marketplaces,
    sellingNowCount,
    simulcastStatus,
    template,
    unauthorizedEventIds,
    userProfile,
  } = state.entities;
  return {
    buyNowCount,
    events,
    itemCount,
    listingStatusIds,
    login,
    marketplaceFeatures,
    marketplaces,
    sellingNowCount,
    simulcastStatus,
    template,
    unauthorizedEventIds,
    userProfile,
  };
};

export default connect(mapStateToProps, {
  loadBuyNowCount,
  loadItemCount,
  loadSellingNowCount,
  loadServerTime,
  loadWatchlistUserItems,
})(Home);
