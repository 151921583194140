import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import AddAlertModal from './AddAlertModal';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadUserAlerts,
  loadAlertUpdate,
  loadAlertRemove,
} from '../../actions';
import { getConfig, formatPhoneNumber } from '../../utils/helpers';
import { alertTypes, deviceTypes } from '../../utils/constants';

const customerDeviceTypes = getConfig('deviceTypes') || [1, 6];
const customerAlertTypes = getConfig('alertTypes') || [
  1004, 1005, 1006, 1007, 1008, 1016, 1028, 1029, 1040
];

class Alerts extends Component {
  state = {
    isAddAlertModalOpen: false,
  };

  componentDidMount() {
    const mpId = getConfig('marketplaceId');
    this.props.loadUserAlerts(mpId);
  }

  handleAddAlert = e => {
    e.preventDefault();
    this.setState({ isAddAlertModalOpen: true });
  };

  handleUpdate = (e, item, index) => {
    e.preventDefault();
    const mpId = getConfig('marketplaceId');
    const updatedStatus = item.statusId === 1 ? 2 : 1;
    this.props
      .loadAlertUpdate(
        mpId,
        item.deviceTypeId,
        item.deviceAddress,
        item.alertTypeId,
        item.alertId,
        updatedStatus
      )
      .then(({ response }) => {
        if (response && response.wsStatus === 'Success') {
          toastr.success('Success', response.wsMessage);
          this.setState({ isAddAlertModalOpen: false });
          this.props.loadUserAlerts(getConfig('marketplaceId'));
        } else {
          toastr.error('Error', response.wsMessage);
        }
      });
  };

  handleDelete = (e, item, rowId) => {
    const mpId = getConfig('marketplaceId');
    const { alertId } = item;

    e.preventDefault();
    confirm('Are you sure you want to delete this Alert?').then(
      () => {
        this.props.loadAlertRemove(mpId, alertId).then(({ response }) => {
          if (response && response.wsStatus === 'Success') {
            toastr.success('Success', response.wsMessage);
            this.props.loadUserAlerts(getConfig('marketplaceId'));
          } else {
            toastr.error('Error', response.wsMessage);
          }
        });
      },
      () => null
    );
  };

  handleDeleteAll = e => {
    const mpId = getConfig('marketplaceId');
    const alertIds = (this.props.userAlerts.items || []).map(
      item => item.alertId
    );

    e.preventDefault();
    confirm('Are you sure you want to delete all Alerts?').then(
      () => {
        this.props.loadAlertRemove(mpId, alertIds).then(({ response }) => {
          if (response && response.wsStatus === 'Success') {
            toastr.success('Success', response.wsMessage);
            this.props.loadUserAlerts(getConfig('marketplaceId'));
          } else {
            toastr.error('Error', response.wsMessage);
          }
        });
      },
      () => null
    );
  };

  getAlertType = alertTypeId => {
    const alertType = _.find(alertTypes, { id: alertTypeId });
    return alertType ? alertType.description : '';
  };

  getDeviceType = deviceTypeId => {
    const deviceType = _.find(deviceTypes, { id: deviceTypeId });
    return deviceType ? deviceType.description : '';
  };

  renderRows(rows) {
    return rows.map((item, index) => {
      const alert = this.getAlertType(item.alertTypeId);
      const alertMethod = this.getDeviceType(item.deviceTypeId);
      const address = isNaN(item.deviceAddress)
        ? item.deviceAddress
        : formatPhoneNumber(item.deviceAddress);
      const model =
        item.deviceTypeId === 7 &&
        item.deviceModel &&
        item.deviceModel !== 'null'
          ? item.deviceModel
          : '';
      const name =
        item.deviceTypeId === 7 && item.deviceName && item.deviceName !== 'null'
          ? item.deviceName
          : '';

      return (
        <tr key={index}>
          <td>{alert}</td>

          <td>
            {alertMethod}
            {model && <br />}
            {model}
          </td>
          <td>
            {address}
            {name && <br />}
            {name}
          </td>

          <td>
            <Toggle
              id={String(index)}
              checked={item.statusId === 1 ? true : false}
              onChange={e => this.handleUpdate(e, item, index)}
            />
            <Button
              data-tip={item.alertId}
              bsStyle="danger"
              className="pull-right"
              style={{ marginLeft: 10 }}
              onClick={e => this.handleDelete(e, item, index)}
            >
              <span className="far fa-trash-alt"> </span>
            </Button>
          </td>
        </tr>
      );
    });
  }

  render() {
    if (!this.props.userAlerts.items) {
      return null;
    }

    const rows = _.chain(this.props.userAlerts.items)
      .filter(
        item =>
          customerAlertTypes.includes(item.alertTypeId) &&
          customerDeviceTypes.includes(item.deviceTypeId)
      )
      .orderBy(['alertTypeId', 'deviceTypeId'])
      .value();

    return (
      <div>
        <ReactTooltip
          effect="solid"
          place="right"
          delayShow={5000}
          offset={{ top: 0, right: 10 }}
          backgroundColor="lightgray"
          textColor="black"
        />
        <AddAlertModal
          isOpen={this.state.isAddAlertModalOpen}
          onClose={() => this.setState({ isAddAlertModalOpen: false })}
          navigate={this.props.navigate}
        />
        <div className="container-fluid">
          <div className="name">
            <h3>
              Alerts
              <Link
                to="#"
                className="btn btn-primary pull-right"
                onClick={this.handleAddAlert}
              >
                Add Alert
              </Link>
            </h3>
            {rows.length ? (
              <table className="table" id="tblAlerts">
                <tbody>
                  <tr>
                    <th>Alert</th>
                    <th>Alert Method</th>
                    <th>Address</th>
                    <th>
                      <span>Status</span>
                      <span className="pull-right">Delete</span>
                    </th>
                  </tr>
                  {this.renderRows(rows)}
                </tbody>
              </table>
            ) : (
              <div style={{ marginTop: 20, marginLeft: 10 }}>
                You have no Alerts
              </div>
            )}
          </div>

          {rows.length > 0 && (
            <h3>
              <Link
                to="#"
                className="btn btn-primary pull-right"
                onClick={this.handleDeleteAll}
              >
                Delete All
              </Link>
            </h3>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { userAlerts } = state.entities;
  return { userAlerts };
};

export default connect(mapStateToProps, {
  loadUserAlerts,
  loadAlertUpdate,
  loadAlertRemove,
})(Alerts);
