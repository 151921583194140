import React, { useState } from "react";
import ReactModal from "react-modal";

const reasonOptions = [
  "Please select your Reason",
  "Out of Stock/Retail",
  "Other",
 ];

const SellerIfRejectHeader = ({onClose}) => {
    return (
    <div className='marketplace-reusable__modal-header'>
      <div style={{ fontSize: 24, color: '#535768' }}>
        <span className="fa fa-file" />
        <span style={{ marginLeft: 10 }}>Decline If Sale</span>
      </div>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  )
}

const SellerIfRejectFooter = ({onProceed, onClose, isConfirmDisabled=true}) => {
    return (
        <div className='marketplace-reusable__modal-footer'>
          <div />
          <div className='marketplace-reusable__modal-buttons'>
            <button
              className="btn btn-primary button-cancel"
              style={{
                minWidth: 100,
                marginRight: 10,
              }}
              type="button"
              data-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
  
            <button
              className="btn btn-primary button-action"
              style={{
                opacity: isConfirmDisabled ? 0.5 : 1,
                minWidth: 100,
              }}
              type="button"
              disabled={isConfirmDisabled}
              onClick={onProceed}
            >
              Reject
            </button>
          </div>
        </div>
      )
}

/**
 * @description Confirmation of sale for co-signer listed vehicles
 * @param {{
*   onConfirm: (comments?: string)=>void, 
*   onClose: ()=>void, 
*   isOpen: boolean} props 
* @returns 
*/
export const SellerIfRejectModal = (props) => {
 /* State */
 const [isDisabled, setIsDisabled] = useState(true);
 const [isCustomComment, setIsCustomComment] = useState(false);
 const [reasonCommentIndex, setReasonCommentIndex] = useState('0')
 const [sellerComments, setSellerComments] = useState('');
 /* Callbacks */
 const handleSubmitReject = (event) => {
   event.preventDefault();
   setIsDisabled(true);
   props.onConfirm(sellerComments);
 }
 const handleCommentsSelect = (event) => {
  event.preventDefault();
  setReasonCommentIndex(event.target.value);
  const reasonIndex = Number(event.target.value);
  switch (reasonIndex) {
    case 0:
      setSellerComments('');
      setIsCustomComment(false);
      if (!isDisabled) setIsDisabled(true);
      break;
    case 2:
      setSellerComments('');
      setIsCustomComment(true);
      if (!isDisabled) setIsDisabled(true);
      break;
    default:
      setIsCustomComment(false);
      setSellerComments(reasonOptions[reasonIndex]);
      setIsDisabled(false)
      break;
  }
 }
 const handleCustomCommentUpdate = (event) => {
  const commentValue = event.currentTarget.value;
  setSellerComments(commentValue);
  if (commentValue && isDisabled) {
    setIsDisabled(false);
  }
 }
 /* Data */

 const options = reasonOptions.map((text, i) => {
  return {
    text, 
    key: i, 
    value: String(i),
  }});

 return (
     <ReactModal 
       isOpen={props.isOpen}
       className='marketplace-reusable__modal-content'
       contentLabel="Seller Ifs - Reject Sale"
     >
         {/* HEADER */}
         <SellerIfRejectHeader onClose={props.onClose} />
         <hr className='marketplace-reusable__modal-divider' />
         {/* BODY */}
         <div>
           <label htmlFor="seller-reject-reason" >Reason</label>
           <select
             className="form-control"
             id="seller-reject-reason"
             value={reasonCommentIndex}
             onChange={handleCommentsSelect}
           >
             {options.map(({key, value, text}) => {
               return (<option disabled={key===0} key={key} value={value}>{text}</option>)  
             })}
           </select>
          {isCustomComment
            ? (
              <textarea
                className="form-control marketplace-reusable__modal-textarea"
                style={{resize: 'none', marginTop: 10}}
                cols="40"
                rows="5"
                placeholder="Seller comments ..."
                value={sellerComments}
                onChange={handleCustomCommentUpdate}
              />
            ) : null
          }
         </div>
         <hr className='marketplace-reusable__modal-divider' />
         {/* FOOTER */}
         <SellerIfRejectFooter
             onClose={props.onClose}
             onProceed={handleSubmitReject}
             isConfirmDisabled={isDisabled}
         />
     </ReactModal>
 )
}