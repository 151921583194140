import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hatchback4DrLeft extends Component {
  constructor(props) {
    super(props);
  }

  handlePress(value) {
    this.props.handleAreaPress(value);
  }

  splatColor(name) {
    if (this.props.selectedCondition.area[name]) {
      return '#c45f6a';
    }

    return '#83be97';
  }

  render() {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
        }}
      >
        <svg height="100%" width="100%" viewBox="0 0 842 595">
          <path
            fill="#FFFFFF"
            d="M666.5,152.4c-0.4,0.3-0.8,0.7-1.2,1.2l-23.5,19.1c-2.9,2.3-6.2,4.5-9.6,6.6l4.8,2.5
	c3.4-4.1,7.2-7.8,11-11.1l18.7-15.1l1.5-1l0.8-0.8l0.4-0.6v-0.1c0.1-0.4,0.1-0.8,0-1.1v-0.1h-0.1c-0.1-0.3-0.5-0.3-1-0.3l-0.7,0.1
	C667.2,152,666.8,152.1,666.5,152.4L666.5,152.4z M649.2,193.4l0.1,1.2c3.6,7.6,8.9,15.1,15.7,22.8c4.5,1.6,8.8,3.4,13.1,5.4
	l2.1-3.6l1.9-3.4l2.8-5.1c2.9-5.6,5.5-11.1,7.7-16.5c-6.9-1.5-13.9-2.9-21.3-4.4l-0.3,0.8l-1.1,3.4c-0.5,1.8-2.1,2.5-4.5,1.9l-5-0.8
	L649.2,193.4L649.2,193.4z"
          />
          <path
            fill="#FFFFFF"
            d="M649.3,194.6l-0.1-1.2l-18.8-2.6c-45.7-6.6-94.2-9.5-145.8-8.7l-12,0.3c-22.3,1.1-44.7,4.3-67,9.4l-19,4.3
	c-0.1,0.3-0.3,0.6-0.7,0.8c-0.5,0.6-1.2,1-2.3,1.4l-4.3,1.5c-19.3,6.9-37.6,14.4-55,22.6c-17.3,8.1-37.1,17.7-59.4,28.9l-0.3,0.1
	c-2.2,1-4.5,1.9-7,2.6l5.2,12.4c8-5.9,16.2-11.4,24.8-16.8c11.8-7,23.5-13.5,35.5-19.3c49.2-24.1,99-37.4,149.7-40l9.9-0.3
	c42.6-0.8,82.8,2.1,120.5,8.7c14.2,2.5,28.1,5.5,41.5,9.1l3.2,0.8c1.5,0.6,2.8,1.2,3.4,2.1c1.7,1.8,1.5,4.1-0.3,7
	c-3.9,6.2-8,11.6-12.2,16.4c-1.2,1.4-2.5,2.5-3.9,3.6l-2.3,1.6c-1.5,1-3.2,1.6-4.8,2.1c2.2,6.7,3.4,13.6,3.7,20.5l12.9-0.8
	l-13.3,1.6l-8.3,19.4c-0.8,2.2-1.8,4-2.8,5.2c7.3-2.2,15.1-3.3,23.5-3.3c17.2,0,32.2,4.7,45.1,14l8.5-15.8
	c-5.2-6.1-9.6-12.8-13.1-20.2c-2.1-1.4-3.9-3.2-5.2-5.6L663,232.8c-0.5-1.1-0.8-2.2-0.8-3.4c0-1.1,0.1-2.2,0.4-3.4l2.3-8.7
	C658.2,209.9,652.9,202.3,649.3,194.6L649.3,194.6z M668.8,286.1c-1.9-1.9-2.9-4.3-2.9-7c0-2.8,1-5.1,2.9-7c1.9-1.9,4.3-2.9,7-2.9
	c2.8,0,5.1,1,7,2.9c1.5,1.5,2.3,3.2,2.8,5.1c0.1,0.5,0.1,1.2,0.1,1.9c0,0.8-0.1,1.6-0.3,2.5c-0.4,1.8-1.4,3.3-2.6,4.5
	c-1.9,1.9-4.3,2.9-7,2.9C673.1,289.1,670.8,288.1,668.8,286.1L668.8,286.1z"
          />
          <path
            fill="#FFFFFF"
            d="M665.9,279.1c0,2.8,1,5.1,2.9,7c1.9,1.9,4.3,2.9,7,2.9c2.8,0,5.1-1,7-2.9c1.4-1.4,2.2-2.9,2.6-4.5h-0.1
	c-1.1,0-2.2-0.3-3-0.7s-1.2-1-1.2-1.5s0.4-1.1,1.2-1.5s1.8-0.7,3-0.7h0.1c-0.4-1.9-1.2-3.6-2.8-5.1c-1.9-1.9-4.3-2.9-7-2.9
	c-2.8,0-5.1,1-7,2.9C666.9,274,665.9,276.3,665.9,279.1L665.9,279.1z"
          />
          <path
            fill="#FFFFFF"
            d="M681.2,279.3c0,0.5,0.4,1.1,1.2,1.5c0.8,0.4,1.8,0.7,3,0.7h0.1c0.1-0.8,0.3-1.5,0.3-2.5c0-0.7,0-1.4-0.1-1.9
	h-0.1c-1.1,0-2.2,0.3-3,0.7C681.6,278.2,681.2,278.8,681.2,279.3L681.2,279.3z M649.2,192.5l-0.4-6.6l-11.1-1.8l-9.8-1.4l-8.8-0.8
	c-34.4-3.4-68.9-5.2-103.6-5.8c-14.4,0-28.8,0.4-42.9,1.2l-4.3,0.3c-20.9,1.4-49.5,5.9-85.8,13.9l3.6,3.7l19.7-4.5
	c22.3-5.1,44.7-8.3,67-9.4l12-0.3c51.6-0.8,100.1,2.1,145.8,8.7L649.2,192.5L649.2,192.5z"
          />
          <path
            fill="#FFFFFF"
            d="M648.8,185.9l0.4,6.6v0.8l11.1,1.8c2.1,0.3,3.4-0.4,3.9-2.1l1.2-3.3l0.3-1L648.8,185.9L648.8,185.9z"
          />
          <path
            fill="#656565"
            d="M649.2,193.4v-0.8l-18.8-2.6c-45.7-6.6-94.2-9.5-145.8-8.7l-12,0.3c-22.3,1.1-44.7,4.3-67,9.4l-19.7,4.5
	l0.5,0.6l19-4.3c22.3-5.1,44.7-8.3,67-9.4l12-0.3c51.6-0.8,100.1,2.1,145.8,8.7L649.2,193.4L649.2,193.4z"
          />
          <path
            fill="#FFFFFF"
            d="M670.9,190.8l-5.4-1l-1.2,3.3c-0.4,1.6-1.8,2.3-3.9,2.1l4.7,0.8c2.5,0.6,4.1,0,4.5-1.9L670.9,190.8
	L670.9,190.8z"
          />
          <path
            fill="#656565"
            d="M681.9,215.8l-1.9,3.4c10,4.8,20.1,10,29.8,15.5l-25-16.1C682.9,217.7,681.9,216.8,681.9,215.8L681.9,215.8z
	"
          />
          <path
            fill="#EBEBEB"
            d="M684.7,210.7l-2.8,5.1c0,1,1,1.9,2.9,2.9l25,16.1l0.7,0.4c6.2,3.6,12.2,7.3,18.3,11c2.3,1.5,4.8,2.9,7.3,4.3
	C721.2,236,704,222.7,684.7,210.7z"
          />
          <path
            fill="#FFFFFF"
            d="M687.3,227.2l0.3,0.1c6.3,3.3,12.2,6.7,17.7,10.5l0.4,0.3l4,2.9c7.4,5.4,14,11.1,20.1,17.5v9.4
	c0,1.4,0.1,2.8,0.3,4.1c0.1,1.2,0.4,2.5,0.8,3.7l1,3.2c1.9,5.6,4,10.9,6.3,15.7c1.9,3.6,4,6.6,6.2,9.4c-2.9-8.8-5.2-18.6-7-29.3
	l-0.3-6.1l-0.3-6.1c0-1.2-0.1-2.3-0.6-3.4c-0.3-1-0.7-1.8-1.1-2.6l-6.1-10c-6.1-3.9-12.2-7.6-18.3-11l-0.7-0.4
	c-9.8-5.6-19.8-10.9-29.9-15.5l-2.1,3.6C681.2,224.2,684.2,225.7,687.3,227.2L687.3,227.2z"
          />
          <path
            fill="#3296BA"
            d="M687.4,227.3l-0.3-0.1c-2.9-1.5-5.9-3-9.1-4.4c3.2,1.5,6.2,3,9.1,4.7l0.3,0.1c5.8,3.2,11.3,6.6,16.4,10.2
	l0.5,0.4l1.1-0.1l-0.4-0.3C699.6,234.1,693.7,230.5,687.4,227.3L687.4,227.3z"
          />
          <path
            fill="#D2D3D4"
            d="M687.4,227.6l-0.3-0.1c-3-1.6-6.1-3.3-9.1-4.7c-4.3-1.9-8.5-3.7-13.1-5.4l-2.3,8.7c-0.3,1.2-0.4,2.3-0.4,3.4
	c0,1.2,0.3,2.3,0.8,3.4l16,23.9c1.5,2.3,3.2,4.3,5.2,5.6c1,0.5,1.9,1.1,3,1.5c4.3,1.8,8.7,3.4,13.2,5.1c4.7,1.6,9.5,3.2,14.4,4.7
	c-4-1.2-7.3-2.5-10-3.4c-5.5-2.1-8.8-3.6-10.2-4.4c-1.8-1-3.2-2.8-4.3-5.4l-0.7-1.4l-0.8-1.2l-1.2-1.6l-1-1.2l-1.5-1.8l-1.1-1.2
	l-8.3-10c-1.7-2.3-0.8-3.9,2.3-4.5c4.8-1,9.9-1.8,15.5-2.2h0.5c1.2,0,2.2,0.3,3.2,0.8l4.1,2.6l2.6-0.4l-0.5-0.4
	C698.7,234.2,693.2,230.9,687.4,227.6L687.4,227.6z M691.4,233.5h-0.3c-7.7,0.7-14.9,1.6-21.2,2.9c-1.4,0.1-2.5-0.1-3.2-0.7
	l-1.1-1.5l-1-1.4l-0.1-0.1c-0.3-0.6-0.4-1.1-0.5-1.8c-0.1-0.6-0.1-1-0.1-1.5c0-1.1,0.1-2.2,0.4-3.3l1.2-4.7l0.3-1.1
	c3.6,1.4,7,2.9,10.5,4.4c2.1,1,4.1,1.9,6.2,2.9l2.6,1.2l3.3,1.8l2.5,1.2l2.6,1.5L691.4,233.5L691.4,233.5z"
          />
          <path
            fill="#D2D3D4"
            d="M691,233.5h0.3l2.1-0.1l-2.6-1.5l-2.2,0.1c-0.1,0-0.1,0-0.3,0.1L691,233.5L691,233.5z M682.5,227.5l-2.2,0.1
	h-0.1c-5.5,0.4-10.9,1-16.1,1.6c0,0.6,0.1,1,0.1,1.5c6.1-0.8,12.2-1.5,18.6-1.9h0.1l2.2-0.1L682.5,227.5L682.5,227.5z"
          />
          <path
            fill="#D2D3D4"
            d="M680.1,227.6l2.2-0.1l-6.2-2.9c-3.4-1.5-6.9-2.9-10.5-4.4l-0.3,1.1c3.3,1.2,6.6,2.6,9.8,4.1
	c1.7,0.7,3.2,1.5,4.8,2.2L680.1,227.6L680.1,227.6L680.1,227.6z M682.7,229h-0.1c0.4,0.1,0.8,0.4,1.2,0.6l1.9,1.1h0.3l2.2-0.1
	l-3.3-1.8L682.7,229L682.7,229z"
          />
          <path
            fill="#D2D3D4"
            d="M683.8,229.5c-0.4-0.3-0.8-0.4-1.2-0.6c-6.2,0.4-12.4,1.1-18.6,1.9c0.1,0.6,0.3,1.2,0.5,1.8l0.1,0.1
	c7-1,14-1.6,21.2-2.1L683.8,229.5L683.8,229.5z"
          />
          <path
            fill="#D2D3D4"
            d="M688.4,232c0.1,0,0.1-0.1,0.3-0.1l2.2-0.1l-2.5-1.2l-2.2,0.1h-0.3c-7.2,0.4-14.2,1.1-21.2,2.1l1,1.4
	C673.1,233.1,680.7,232.4,688.4,232L688.4,232z"
          />
          <path
            fill="#D2D3D4"
            d="M691,233.5l-2.8-1.5c-7.7,0.4-15.3,1.1-22.7,2.2l1.1,1.5c0.7,0.6,1.7,0.8,3.2,0.7
	C676.1,235.2,683.3,234.2,691,233.5L691,233.5z M664.3,226c-0.3,1.1-0.4,2.2-0.4,3.3c5.4-0.7,10.7-1.2,16.1-1.6
	c-1.5-0.8-3.2-1.5-4.8-2.2c-3.2-1.4-6.3-2.8-9.8-4.1L664.3,226L664.3,226z M682.6,240.3c-0.7,0.1-1.2,0.3-1.8,0.6
	c-0.7,0.4-0.8,1.1-0.3,1.8l7.7,9.1l2.6-0.1l-1.5,1.5l1.5,1.8l2.8-0.1l-1.5,1.5h-0.1l1.1,1.6l3.6-0.3l-1.5,1.4l-1.2,0.1l0.4,0.8
	c1.2,2.6,4.3,4.8,9.1,6.7c6.7,3.2,14.6,5.9,23.8,8.4l0.4-3v-0.1l-0.8-0.1c-4-0.8-7.7-1.9-11.1-3.2l-2.5-1.1l-0.8-0.3l-3.6-1.8
	l-5.5-3.4l-3.4-3l-2.2-2.1l-7.8-8.1c-1.5-1.6-3-3.4-4.4-5.2c-1.2-1.4-1.4-2.2-0.1-2.8l16.5-2.2c-0.8-0.4-1.8-0.6-2.9-0.6h-0.5
	C692.6,238.5,687.4,239.2,682.6,240.3L682.6,240.3z"
          />
          <path
            fill="#D2D3D4"
            d="M680.8,240.8c0.4-0.3,1.1-0.4,1.8-0.6c4.8-1,10-1.8,15.5-2.2h0.5c1.1,0,2.1,0.1,2.9,0.6h0.3l-4.1-2.6
	c-1-0.6-1.9-0.8-3.2-0.8H694c-5.5,0.4-10.7,1.2-15.5,2.2c-3.2,0.7-4,2.2-2.3,4.5l8.3,10l4-0.3l-7.7-9.1
	C680,241.8,680.1,241.2,680.8,240.8L680.8,240.8z"
          />
          <path
            fill="#EBEBEB"
            d="M685.1,240.8c-1.1,0.4-1.1,1.4,0.1,2.8c1.5,1.8,2.9,3.6,4.4,5.2l7.8,8.1l2.2,2.1h27.5v8.9
	c0,1.4,0.1,2.8,0.3,4v0.1c0.1,1.1,0.3,2.2,0.5,3.2l0.8,2.9l1.2,0.3h0.1l-0.8-2.8c-0.4-1.2-0.7-2.5-0.8-3.7c-0.1-1.4-0.3-2.8-0.3-4.1
	v-8.9l-1.9-2.1c-5.2-5.2-10.7-10.2-16.8-14.9l-5.1-3.7l-2.6,0.3h-0.3L685.1,240.8L685.1,240.8z M686.3,243.2c-0.3-0.7-0.1-1,0.4-1.1
	l14.2-1.9h0.1c1.5-0.1,2.8,0,3.7,0.6l0.3,0.1l0.7,0.6l0.1,0.1l2.9,2.1c4.7,3.4,9.1,7.2,13.2,11c0.8,1.1,0.5,1.8-0.7,2.1l-0.5,0.1
	h-16.2l-2.8-0.3c-2.1-0.4-4-1.2-5.6-2.3l-0.6-0.4l-2.1-2.2l-7-8.1L686.3,243.2L686.3,243.2z"
          />
          <path
            fill="#E6E7E7"
            d="M686.9,242.1c-0.5,0.1-0.8,0.4-0.4,1.1l0.3,0.3c0-0.3,0.3-0.6,0.5-0.7l13.5-1.8c0.4,0.1,0.7,0.3,1.1,0.6
	l3.7,2.8c2.9,2.2,5.6,4.5,8.3,6.9l3,2.8c0.8,1,0.8,1.5-0.3,1.9l-1.4,0.1l-13.1-0.1l-2.6-0.6c-1.1-0.4-2.1-1-3-1.5h-0.5l0.5,0.4
	c1.8,1.2,3.6,2.1,5.6,2.3l2.8,0.3h13.5l0.7-0.4c0.3-0.3,0.4-0.6,0.3-1l-0.3-0.6v-0.1l-4.4-4.1c-2.6-2.3-5.4-4.7-8.3-6.9l-3.7-2.8
	c-0.4-0.3-1-0.6-1.5-0.7l-0.3-0.1L686.9,242.1L686.9,242.1z"
          />
          <path
            fill="#E6E7E7"
            d="M687.3,242.8c-0.4,0.3-0.6,0.4-0.6,0.7l7,8.1l2.8,2.2c1,0.6,1.9,1.1,3,1.5l2.6,0.6l13.1,0.1l1.4-0.1
	c1-0.3,1.1-1,0.3-1.9l-3-2.8c-2.6-2.3-5.4-4.7-8.3-6.9l-3.7-2.8c-0.3-0.3-0.7-0.4-1.1-0.6L687.3,242.8L687.3,242.8z M700.8,241.8
	l4,3c2.9,2.2,5.6,4.5,8.3,6.9l1.5,1.4l1.5,1.4l0.1,0.1c0.1,0.3-0.3,0.4-1.2,0.6l-12.8-0.1c-1-0.1-1.8-0.3-2.6-0.6l-1.5-0.7l-0.3-0.1
	l-0.3-0.1l-0.8-0.6l-0.1-0.1h-0.1l-0.3-0.3l-2.9-3l-5-5.9L700.8,241.8L700.8,241.8z"
          />
          <path
            fill="#E6E7E7"
            d="M700.5,242.9l2.9,2.2c2.9,2.2,5.5,4.5,8.3,6.9l1.9,1.8h-13.9c-1.1-0.1-2.2-0.3-3-0.8l0.8,0.6l0.3,0.1
	l0.4,0.1c1.1,0.4,2.5,0.7,3.9,0.7h12.2c1,0,1.1-0.4,0.3-1.2l-1.5-1.4c-2.6-2.3-5.4-4.7-8.2-6.9l-4-3l-12.5,1.6l5,5.9l2.9,3l0.3,0.3
	h0.1l0.1,0.1c0.8,0.4,1.9,0.7,3,0.8h1.7l-1.8-0.3c-1.2-0.3-2.2-0.8-2.9-1.6l-0.3-0.3l-6.2-7.4L700.5,242.9L700.5,242.9z"
          />
          <path
            fill="#E6E7E7"
            d="M703.4,245l-2.9-2.2l-10.5,1.4l6.2,7.4l0.3,0.3c0.8,0.7,1.8,1.2,2.9,1.6l1.8,0.3h12.2l-1.9-1.8
	C708.9,249.5,706.2,247.2,703.4,245L703.4,245z M700.3,243.9l2.1,1.5l0.1,0.1c2.5,1.9,5.1,4,7.4,6.2l1.5,1.5h-9.2
	c-1.8,0-3.3-0.4-4.5-1.2l-0.3-0.4l-0.5-0.6l-0.1-0.3l-3.6-4.1l-1.2-1.6L700.3,243.9L700.3,243.9z"
          />
          <path
            fill="#E6E7E7"
            d="M702.4,245.4l-2.1-1.5l-8.5,1.2l1.2,1.7l1.5-0.1l6.9-1.1c2.5,2.1,5,4,7.3,6.2h-8.5c-1.7,0.1-2.9,0-3.4-0.6
	l0.7,0.4l0.3,0.3c1.2,0.8,2.8,1.2,4.5,1.2h9.2l-1.5-1.5C707.5,249.5,705,247.4,702.4,245.4L702.4,245.4z"
          />
          <path
            fill="#E6E7E7"
            d="M701.6,245.5l-6.9,1.1l2.9,3.3c1,1.1,2.2,1.6,3.8,1.8h7.4C706.5,249.5,704,247.4,701.6,245.5L701.6,245.5z"
          />
          <path
            fill="#E6E7E7"
            d="M700.3,251.6h1.1c-1.7,0-2.9-0.7-3.9-1.8l-2.9-3.3l-1.5,0.1l3.6,4.1l0.1,0.3
	C697.6,251.6,698.7,251.7,700.3,251.6L700.3,251.6z M716,254.6l-1.5-1.5c0.8,0.8,0.7,1.2-0.3,1.2H702c-1.5,0-2.8-0.3-3.9-0.7H698
	l1.5,0.7c0.8,0.3,1.7,0.6,2.6,0.6l12.8,0.1C715.9,255,716.3,254.9,716,254.6L716,254.6z"
          />
          <path fill="#3296BA" d="M697.9,253.8h0.1l-0.4-0.3" />
          <path
            fill="#EBEBEB"
            d="M701.4,240.3c0.5,0.1,1,0.3,1.5,0.7l3.7,2.8c2.9,2.2,5.6,4.5,8.3,6.9l4.4,4.1v0.1l0.3,0.6
	c0.1,0.4,0,0.8-0.3,1l-0.7,0.4h2.8l0.5-0.1c1.2-0.3,1.4-1,0.7-2.1c-4.1-3.9-8.5-7.6-13.2-11l-2.9-2.1l-0.1-0.1l-1.1-0.7
	C703.9,240.1,702.7,240,701.4,240.3l-0.3-0.1L701.4,240.3L701.4,240.3z"
          />
          <path
            fill="#3296BA"
            d="M705,240.8l1.1,0.6l-0.8-0.6 M718.2,256.8h0.1 M695.8,253.8h0.5l-2.6-2.2"
          />
          <path
            fill="#FFFFFF"
            d="M709.6,241l-4-2.9l-1.1,0.1l5.1,3.7c6.1,4.5,11.7,9.5,16.8,14.9l1.9,2.1v8.9c0,1.4,0.1,2.8,0.3,4.1
	c0.1,1.2,0.4,2.5,0.8,3.7l0.8,2.8l1.4,0.4l-1-3.2c-0.4-1.2-0.7-2.5-0.8-3.7c-0.1-1.4-0.3-2.8-0.3-4.1v-9.4
	C723.6,252.1,717,246.2,709.6,241L709.6,241z"
          />
          <path
            fill="#656565"
            d="M651.2,210.8c-0.8-0.8-1.9-1.5-3.4-2.1l-3.2-0.8c-13.5-3.6-27.4-6.5-41.5-9.1l3.3,5.5l6.7,1.2l3.7,0.7
	c11,2.2,21.9,4.8,32.6,7.7L651.2,210.8L651.2,210.8z"
          />
          <path
            fill="#FFFFFF"
            d="M651,217.9c1.8-2.9,1.9-5.2,0.3-7l-2.1,3.3l-2.6,4c-4.3,6.5-8,11.6-11.1,15l-2.1,2.2
	c-0.7,0.7-1.5,1.2-2.3,1.7c-1.4,0.8-3,1.5-4.8,1.9l1.7,2.8c1.7-0.4,3.2-1.1,4.8-2.1l2.3-1.6c1.2-1.1,2.6-2.2,3.9-3.6
	C642.8,229.5,647,224,651,217.9L651,217.9z"
          />
          <path
            fill="#656565"
            d="M646.5,218.1l2.6-4c-10.7-2.9-21.5-5.5-32.6-7.7l-3.7-0.7l18.2,31.1c0.8-0.4,1.7-1,2.3-1.7l2.1-2.2
	C638.6,229.5,642.3,224.6,646.5,218.1L646.5,218.1z M642.3,216.9c1,0.4,1.2,1.1,0.8,1.9c-3.2,4.7-6.6,8.9-10.2,12.7
	c-0.7,0.6-1.4,0.6-1.9-0.1L620.4,213c-0.3-0.8-0.1-1.1,0.8-1.1C628.3,213.5,635.4,215.1,642.3,216.9L642.3,216.9z"
          />
          <path
            fill="#EBEBEB"
            d="M643.2,219c0.4-1,0.1-1.6-0.8-1.9c-7-1.8-14-3.4-21.2-5c-0.8,0-1.1,0.3-0.8,1.1l10.6,18.3
	c0.7,0.7,1.4,0.8,1.9,0.1C636.6,227.8,639.9,223.6,643.2,219L643.2,219z M699.8,258.8l3.6,3l5.4,3.4h0.1l3.6,1.8l0.8,0.3v-8.5
	 M713.3,267.4l2.5,1.1c3.4,1.2,7.2,2.3,11.1,3.2l0.8,0.1c-0.1-1.4-0.3-2.8-0.3-4v-8.9h-14.2L713.3,267.4L713.3,267.4z"
          />
          <path
            fill="#D2D3D4"
            d="M693.6,254.6l-2.6,0.1l-4.1,0.3l1.1,1.2l4.1-0.3 M693.2,257.6l-1.1-1.7l-4.1,0.3l1.1,1.6"
          />
          <path
            fill="#D2D3D4"
            d="M696.8,257.5l-3.6,0.1l-4.1,0.3l0.8,1.2l4.1-0.3h1.2 M688.4,251.7l-4,0.3l1,1.2l4-0.3l1.5-1.4 M685.3,253.2
	l1.5,1.8l4.1-0.3l-1.7-1.8"
          />
          <path
            fill="#FFFFFF"
            d="M687.3,264.1c-1.1-0.4-2.2-1-3-1.5c3.4,7.4,7.8,14.2,13.1,20.2l3.2-13.5
	C696.1,267.5,691.7,265.7,687.3,264.1L687.3,264.1z"
          />
          <path
            fill="#D2D3D4"
            d="M694.4,259.8L694,259l-4.1,0.3l0.7,1.4c1.1,2.6,2.5,4.4,4.3,5.4c1.4,0.8,4.7,2.3,10.2,4.4
	c2.8,1,6.1,2.2,10,3.4l13.1,3.9c-0.4-0.8-0.7-1.8-0.8-2.8v-0.1c-9.2-2.5-17.2-5.2-23.8-8.4C698.5,264.6,695.7,262.4,694.4,259.8
	L694.4,259.8z"
          />
          <path
            fill="#FFFFFF"
            d="M715.1,273.8c-5-1.5-9.8-3-14.4-4.7l-3.2,13.5c9.9,11.4,23.1,20.4,39.6,27.1c8.1,3.4,16.6,5.9,25.6,7.4
	l-6.9-5.8c-3.8-1-7-2.8-9.5-5.5l-1.8-2.1c-2.2-2.8-4.3-5.8-6.2-9.4c-2.3-4.8-4.5-10-6.3-15.7l-1.4-0.4h-0.1l-1.2-0.3l-0.8-0.3
	L715.1,273.8L715.1,273.8z M740.9,261.6c0.1-1.9-0.3-3.9-1.2-5.6l-3.3-5.5c-2.6-1.2-5.1-2.8-7.3-4.3l6.1,10c0.5,0.8,0.8,1.6,1.1,2.6
	c0.3,1.1,0.5,2.2,0.5,3.4l0.3,6.1h3.6l0.1-4L740.9,261.6L740.9,261.6z"
          />
          <path fill="#FFFFFF" d="M741.9,264.5h-1.1l-0.1,3.9l-0.1,4.1h1" />
          <path
            fill="#3296BA"
            d="M727.3,274.8c0.3,1.1,0.5,1.9,0.8,2.9l0.8,0.3c-0.4-1-0.7-1.9-0.8-2.9c-0.3-1.1-0.4-2.2-0.5-3.2L727.3,274.8
	z"
          />
          <path fill="#FFFFFF" d="M740.4,274.4l0.1-1.9l0.1-4.1h-3.6l0.3,6.1" />
          <path fill="#3296BA" d="M763.8,340.4l-0.3-1.9v1.9" />
          <path
            fill="#FFFFFF"
            d="M764.4,342.9c-0.3-0.8-0.5-1.6-0.7-2.5l-0.1,0.1c-8.8,2.5-14.9,9.5-18.3,21c-0.1,0.5-0.4,1.2-0.7,1.8
	l0.4-0.1c1-1.1,1.8-2.2,2.5-3.4c5.4-8.5,11.3-13.2,17.9-14.2L764.4,342.9L764.4,342.9z"
          />
          <path
            fill="#FFFFFF"
            d="M763.5,340.4l-0.1-1.9l-1-21.5c-8.9-1.5-17.5-4.1-25.6-7.4c-16.4-6.6-29.6-15.7-39.6-27.1l-8.5,15.8
	c2.3,1.6,4.7,3.6,6.9,5.6c2.6,2.3,5,4.8,7,7.3c5.2,5.9,14.4,9.8,27.8,11.3l20.4,1.9c-5.2,1.4-11,2.5-17.1,3.3
	c-5.8,0.5-10.7,0.7-14.9,0.3c-7.7-1.1-14.4-4.7-20.4-10.9l-3.9-3.7c-3.2-2.9-6.6-5.4-10.2-7.7l-8.9,14.9c3.7,2.6,7.2,5.6,10.6,9.1
	c10.7,11.1,16.9,24.2,18.7,39.3v0.1l39.5-6.1c0.3-0.6,0.5-1.1,0.7-1.8C748.6,350.1,754.8,343,763.5,340.4L763.5,340.4z M741.9,337.1
	c2.2-0.3,3.2,0.6,2.8,2.8c-2.6,7.4-8.1,11.1-16.4,11.3h-6.7c-5.1-0.5-8-3.6-8.7-9.1c-0.3-1,0.1-1.5,1.2-1.6l25-2.9L741.9,337.1
	L741.9,337.1z"
          />
          <path
            fill="#FFFFFF"
            d="M738.9,337.4c0.5,0.4,0.8,1.2,0.5,2.3c-2.1,7.3-7,11-15,11.3h3.7c8.4,0,13.8-3.7,16.4-11.3
	c0.4-2.2-0.4-3-2.8-2.8L738.9,337.4z"
          />
          <path
            fill="#D2D3D4"
            d="M739.4,339.9c0.3-1.1,0-1.9-0.5-2.3l-25,2.9c-1.1,0.1-1.5,0.5-1.2,1.6c0.8,5.5,3.7,8.5,8.7,9.1h3
	C732.3,350.9,737.3,347,739.4,339.9L739.4,339.9z"
          />
          <path
            fill="#FFFFFF"
            d="M751,324.6l-20.4-1.9c-13.3-1.5-22.6-5.2-27.8-11.3c-2.1-2.5-4.4-5-7-7.3c-2.2-2.1-4.5-3.9-6.9-5.6l-4,7.4
	c3.6,2.2,6.9,4.8,10.2,7.7l3.8,3.7c5.8,6.2,12.7,9.8,20.2,10.9c4.3,0.4,9.2,0.3,14.9-0.3C740.1,327.1,745.7,326,751,324.6L751,324.6
	z"
          />
          <path
            fill="#656565"
            d="M747.5,359.8c-0.7,1.2-1.5,2.3-2.5,3.4l-1.5,1.7l-12.7,11.3l3.4-0.7c3.4-0.5,6.9-1,10.6-1.2l10.2-1.1l7.7-10
	c1-1,1.5-2.5,1.8-4.4l1-13.3C758.8,346.5,752.9,351.2,747.5,359.8L747.5,359.8z"
          />
          <path fill="#3296BA" d="M743.4,364.9l1.7-1.7h-0.4" />
          <path
            fill="#FFFFFF"
            d="M743.4,364.9l1.2-1.7l-39.5,6.1c0.3,2.6,0.5,5.4,0.5,8.1l0.1,3.6l25-4.8L743.4,364.9L743.4,364.9z
	 M684.8,305.8l4-7.4c-12.9-9.4-28.1-14-45.1-14c-8.4,0-16.2,1.1-23.5,3.3l-7.7,11.1c8.9-3.3,18.7-5.1,29.2-5.1
	C657.8,293.8,672.3,297.8,684.8,305.8z M675.8,320.6l-3.4,4.7c3.4,2.2,6.7,5,9.8,8.1c9.6,9.8,15.3,21.2,17.1,34.3l0.3,2.6l5.6-0.8
	v-0.1c-1.8-15.1-8.1-28.2-18.7-39.3C683,326.3,679.6,323.2,675.8,320.6L675.8,320.6z"
          />
          <path
            fill="#FFFFFF"
            d="M672.4,325.3l3.4-4.7c-10.3-7-21.9-10.6-34.9-10.6c-17.7,0-32.9,6.6-45.4,19.7
	c-12.7,13.2-19.4,29.3-20.1,48.4l-0.3,5.5l6.2-0.1l0.4-8.1c0.8-16.6,6.9-30.7,18.3-42.1s25-17.1,41.1-17.1
	C652.7,316.2,663.2,319.2,672.4,325.3z"
          />
          <path
            fill="#656565"
            d="M677.1,342.8c-9.9-9.9-22-15-36.2-15c-14,0-26.1,5-36,15c-1,1-1.9,2.1-2.8,3c-8.1,9.4-12.1,20.4-12.1,33
	c0,1.5,0.1,3,0.3,4.5c1,12.1,5.9,22.7,14.7,31.5l2.1,1.9c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15c1.5-1.5,2.8-2.9,4-4.4
	c6.9-8.4,10.5-18.2,11-29.2v-2.6C692.1,364.8,687.1,352.8,677.1,342.8L677.1,342.8z M641,341.4c3.8,0,7.4,0.5,10.9,1.5
	c5.8,1.7,11,4.8,15.7,9.5h0.1l1.7,1.8c2.3,2.5,4.1,5.2,5.6,8.1c2.5,5.1,3.9,10.6,3.9,16.8c0,6.1-1.2,11.7-3.9,16.8
	c-1.8,3.6-4.1,6.7-7.2,9.8c-1.1,1.1-2.2,2.2-3.4,3.2c-6.6,5.2-14.3,7.8-23.1,7.8s-16.5-2.6-23.1-7.8l-3.4-3.2
	c-3.3-3.3-5.8-6.7-7.6-10.6c-2.3-4.8-3.4-10.2-3.4-16s1.1-11.1,3.4-16c1.5-3.2,3.4-6.2,5.9-8.9l1.7-1.8c2.5-2.5,5.2-4.7,8.1-6.2
	c2.5-1.4,5-2.5,7.6-3.3C633.6,341.8,637.2,341.4,641,341.4L641,341.4z"
          />
          <path
            fill="#EBEBEB"
            d="M651.8,342.9c-3.4-1-7-1.5-10.9-1.5s-7.4,0.5-10.7,1.5c-2.6,0.8-5.2,1.8-7.6,3.3c-2.9,1.6-5.6,3.7-8.1,6.2
	l-1.7,1.8c-2.5,2.8-4.4,5.8-5.9,8.9c-2.3,4.8-3.4,10.2-3.4,16s1.1,11,3.4,16c1.8,3.9,4.4,7.4,7.6,10.6l3.4,3.2
	c6.6,5.2,14.3,7.8,23.1,7.8s16.5-2.6,23.1-7.8c1.2-1,2.3-2.1,3.4-3.2c3-3,5.5-6.3,7.2-9.8c2.5-5.1,3.9-10.6,3.9-16.8
	c0-6.1-1.2-11.7-3.9-16.8c-1.5-2.9-3.3-5.6-5.6-8.1l-1.7-1.8h-0.1C662.9,347.7,657.7,344.6,651.8,342.9L651.8,342.9z M641,342.5
	l5.5,0.4l3.4,0.7l2.3,0.7c1.8,0.5,3.6,1.4,5.4,2.2c0.7,0.3,1.4,0.7,2.1,1.1c2.5,1.5,4.8,3.4,7,5.5l0.8,1c1.9,2.1,3.6,4.1,4.8,6.3
	l1.1,2.1l1.2,2.5l1,2.8l0.7,2.2c0.7,2.9,1.1,5.9,1.1,9.1s-0.4,6.1-1.1,8.9l-0.7,2.5l-1,2.8l-1.2,2.6l-1.1,2.1
	c-1.5,2.5-3.4,4.8-5.6,7c-1.5,1.5-3,2.8-4.5,4l-2.6,1.8l-1.9,1.1c-1.7,0.8-3.4,1.6-5.2,2.2l-2.2,0.5c-2.9,0.7-5.9,1.1-9.1,1.1
	c-3,0-6.1-0.3-8.8-1l-2.3-0.7c-1.8-0.5-3.6-1.2-5.4-2.2l-4.5-2.9c-1.7-1.2-3.2-2.5-4.5-4c-2.2-2.2-4.1-4.7-5.6-7.3l-1-1.8
	c-0.5-1.2-1.2-2.5-1.7-3.7l-0.5-1.6l-0.5-2.2c-0.7-2.9-1.1-5.9-1.1-9.1s0.3-6.1,1-8.9l0.5-2.3l0.7-1.8c0.4-1.2,1-2.3,1.5-3.4
	l1.2-2.2c1.2-2.2,2.9-4.1,4.7-6.2l0.8-1c2.2-2.3,4.7-4.1,7.3-5.8h0.1c0.5-0.4,1.1-0.7,1.8-1c1.7-0.8,3.4-1.7,5.4-2.2l2.2-0.7h0.1
	l3.4-0.7L641,342.5L641,342.5z"
          />
          <path
            fill="#EBEBEB"
            d="M646.5,342.9l-5.5-0.4l-5.5,0.4l-3.4,0.7l1.9,1.5c2.2-0.4,4.5-0.7,7-0.7s4.7,0.3,6.9,0.7l2.1-1.4l-3,13.8
	l-3.7,13.6l-0.1,0.4l-0.5,0.7c0.7,0.1,1.2,0.3,1.8,0.7l5-8.7l6.2-10l3.3-5l1-1.5l0.4,2.3c1.8,1.1,3.4,2.5,5,4.1l2.3,2.5
	c1,1.1,1.8,2.2,2.6,3.4l2.3,0.4c-1.4-2.2-2.9-4.3-4.8-6.3l-0.8-1c-2.2-2.2-4.5-4.1-7-5.5c-0.7-0.4-1.4-0.8-2.1-1.1l-1,1.5l-5.1,8.8
	c-0.8-0.4-1.8-0.8-2.6-1.1l2.8-9.8l0.5-1.8l-2.3-0.7L646.5,342.9L646.5,342.9z"
          />
          <path
            fill="#EBEBEB"
            d="M660.2,350.2l-0.4-2.5l-1,1.5c-1.1,1.6-2.2,3.3-3.3,5l-6.2,10l-5,8.7l4.5-5l4.5-7.4l1.1-1.9L660.2,350.2
	L660.2,350.2z"
          />
          <path
            fill="#656565"
            d="M660,350.1l-5.5,8.5c1.2,1,2.6,1.9,3.7,3.2l3.2,3.9l8.5-5.4h0.1c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5
	C663.5,352.5,661.8,351.2,660,350.1L660,350.1z"
          />
          <path
            fill="#EBEBEB"
            d="M661.5,365.5l-0.5,0.3l-5.8,3.4l-2.5,1.5L652,371l-5.1,4.5c4.5-2.5,8.9-5,13.3-7.6l12-7.6l-2.3-0.4h-0.1
	L661.5,365.5L661.5,365.5z"
          />
          <path
            fill="#EBEBEB"
            d="M661,365.7l0.5-0.3l-3.2-3.9c-1.2-1.1-2.5-2.2-3.7-3.2l-1.1,1.9c1,0.7,1.8,1.4,2.8,2.2l0.8-1
	c1.1,0.8,1.9,1.6,2.8,2.5L661,365.7L661,365.7z M672,363.3l1.5-0.8l-1.1-2.1l-12,7.6c-4.4,2.6-8.8,5.1-13.3,7.6
	c0.3,0.6,0.5,1.1,0.7,1.6c0.3-0.3,0.5-0.4,0.8-0.5l0.3-0.1c4.4-1.4,8.9-2.6,13.6-3.7l2.1-0.5c3.2-0.8,6.6-1.5,9.9-2.2h0.1l1.7-0.3
	l-0.7-2.2l-1.8,0.5l-9.8,2.6l-1.1-2.6L672,363.3L672,363.3z"
          />
          <path
            fill="#EBEBEB"
            d="M673.7,362.4l-1.5,0.8c0.8,1.5,1.5,3.2,2.1,5l1.8-0.5l-1-2.8L673.7,362.4L673.7,362.4z M651.8,345.9
	c1.7,0.5,3.3,1.2,5,2.1l1-1.5c-1.8-1-3.4-1.7-5.4-2.2L651.8,345.9L651.8,345.9z"
          />
          <path
            fill="#656565"
            d="M656.7,348c-1.7-0.8-3.3-1.5-5-2.1l-2.8,9.8c1,0.3,1.8,0.7,2.6,1.1L656.7,348L656.7,348z M647.9,345
	c-2.2-0.4-4.5-0.7-6.9-0.7c-2.3,0-4.8,0.3-7,0.7h0.1c0.4,3,1,6.1,1.7,9.1l0.1,0.8c1.7-0.3,3.3-0.4,5.1-0.4l4.8,0.4l0.1-0.8
	C646.8,351.2,647.4,348.1,647.9,345L647.9,345z"
          />
          <path
            fill="#EBEBEB"
            d="M645.9,354.9l-4.8-0.4c-1.8,0-3.4,0.1-5.1,0.4l0.5,2.1l4.5-0.4l4.4,0.4L645.9,354.9L645.9,354.9z"
          />
          <path
            fill="#EBEBEB"
            d="M647.9,345c-0.5,3.2-1.1,6.2-1.8,9.1l-0.1,0.8l-0.5,2.1c-0.6,2.3-1.2,4.8-2.1,7L643,365l-0.1,0.5v3.2
	c0.4,0.4,0.5,1,0.5,1.5v0.4l3.7-13.6l3-13.8L647.9,345L647.9,345z"
          />
          <path
            fill="#EBEBEB"
            d="M645.3,357.1l-4.4-0.4l-4.5,0.4c0.7,2.3,1.4,4.8,2.2,7l0.4,1.1l0.1,0.5h3.6l0.1-0.5l0.4-1.1
	C644.1,361.7,644.8,359.4,645.3,357.1L645.3,357.1z M656.2,362.6c-0.8-0.8-1.8-1.5-2.8-2.2l-4.5,7.4l3.2,3.2l0.7-0.3v-1.5
	c0.7-2.1,1.5-3.7,2.2-5.1L656.2,362.6L656.2,362.6z"
          />
          <path
            fill="#EBEBEB"
            d="M656.9,361.6l-0.8,1l-1.1,1.5c-0.8,1.4-1.5,3.2-2.2,5.1h2.5l5.8-3.4l-1.4-1.6
	C658.9,363.1,658,362.3,656.9,361.6L656.9,361.6z"
          />
          <path
            fill="#EBEBEB"
            d="M652.9,369.2v1.5l2.3-1.5 M651,377h3.3l1.8-0.5l3.6-1.2l3.3-1l2.1-0.5c3.2-0.8,6.5-1.4,9.8-1.8h0.1v-0.1
	l1.5-1.9l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2l-2.1,0.6c-4.7,1.1-9.2,2.3-13.6,3.7h0.6C650,376.5,650.5,376.7,651,377L651,377z
	 M654.1,377h-3.3l0.3,0.1c0.4,0.4,0.5,1,0.5,1.6c0,0.5-0.1,1.1-0.5,1.5l-0.1,0.1h3.2L654.1,377L654.1,377z"
          />
          <path
            fill="#EBEBEB"
            d="M651.1,377.2l-0.3-0.1c-0.4-0.3-0.8-0.5-1.4-0.5h-0.6l-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.6
	c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.5v0.1c0.3,0.1,0.4,0.3,0.7,0.4l0.4,0.1h0.4c0.6,0,1.1-0.1,1.5-0.5l0.1-0.1
	c0.4-0.4,0.5-1,0.5-1.5C651.8,378.3,651.5,377.7,651.1,377.2L651.1,377.2z M650.7,378.8c0,0.3-0.1,0.7-0.4,0.8
	c-0.3,0.3-0.5,0.4-0.8,0.4c-0.3,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4
	c0.4,0,0.7,0.1,0.8,0.4C650.5,378.2,650.7,378.5,650.7,378.8L650.7,378.8z"
          />
          <path
            fill="#EBEBEB"
            d="M650.4,379.8c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-0.8-0.4s-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,1,0.4C649.9,380.2,650.1,380,650.4,379.8L650.4,379.8z M659.6,375.4
	l-3.6,1.2v4.7l4,1.2l0.6-0.5c0.7-0.8,1.1-1.8,1.1-2.9c0-1.1-0.3-2.1-1.1-2.9C660.3,375.8,659.9,375.5,659.6,375.4L659.6,375.4z"
          />
          <path
            fill="#EBEBEB"
            d="M656,376.5l-1.8,0.5v3.6l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3L656,376.5L656,376.5z M642.6,368.9
	l0.1,0.1v-3.3h-3.6l0.1,3.3l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7C641.6,368.2,642.1,368.5,642.6,368.9L642.6,368.9z"
          />
          <path
            fill="#EBEBEB"
            d="M642.7,369l-0.1-0.1c-0.4-0.4-1-0.7-1.7-0.7c-0.5,0-1.1,0.3-1.7,0.7l-0.1,0.1c-0.4,0.4-0.5,0.8-0.5,1.4v0.5
	l0.1,0.3l0.4,0.7c0.4,0.4,1,0.7,1.7,0.7s1.2-0.3,1.7-0.7l0.6-0.7l0.1-0.4v-0.4C643.2,370,643.1,369.4,642.7,369L642.7,369z
	 M641.9,369.6c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1s-0.5,0.4-0.8,0.4s-0.7-0.1-1-0.4s-0.4-0.5-0.4-1s0.1-0.7,0.4-0.8
	c0.3-0.3,0.5-0.4,1-0.4C641.3,369.3,641.7,369.3,641.9,369.6L641.9,369.6z"
          />
          <path
            fill="#EBEBEB"
            d="M642.3,370.5c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4s-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8
	s0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,0.8-0.4C642.1,371.1,642.3,370.8,642.3,370.5L642.3,370.5z M644.3,372.8
	c-0.6-0.3-1.1-0.5-1.8-0.7c-0.4,0.4-1,0.7-1.7,0.7c-0.5,0-1.1-0.3-1.7-0.7c-0.5,0.1-1.1,0.3-1.7,0.7c-0.5,0.3-1.1,0.7-1.5,1.1
	c-0.5,0.5-1,1.1-1.2,1.6c-0.3,0.5-0.5,1.1-0.7,1.6l0.1,0.1c0.4,0.4,0.5,1,0.5,1.6c0,0.5-0.1,1.1-0.5,1.6h-0.1
	c0.1,0.6,0.4,1.1,0.7,1.6c0.3,0.6,0.7,1.1,1.1,1.5c0.5,0.5,1.1,1,1.7,1.2c0.5,0.3,1.1,0.5,1.7,0.7l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7
	c0.5,0,1.1,0.3,1.7,0.7v0.1l1.7-0.7l1.5-1.2l1.2-1.6c0.3-0.5,0.5-1.1,0.5-1.8v-0.1c-0.4-0.4-0.7-1-0.7-1.5c0-0.7,0.3-1.2,0.7-1.6
	c-0.1-0.5-0.3-1.1-0.7-1.7c-0.3-0.5-0.7-1.1-1.2-1.5C645.4,373.4,644.9,373,644.3,372.8L644.3,372.8z"
          />
          <path
            fill="#EBEBEB"
            d="M646,374c0.5,0.5,0.8,1,1.2,1.5l5.1-4.5l-3.2-3.2l-4.5,5C644.9,373,645.4,373.4,646,374L646,374z
	 M647.4,382.4l-0.1-0.1l-1.2,1.6l-1.5,1.2l4.5,5l3.2-3.3"
          />
          <path
            fill="#EBEBEB"
            d="M647.2,382.2c4.5,2.5,8.9,5.1,13.2,7.7l1.8,1.1c2.9,1.8,5.8,3.6,8.5,5.5c0.5,0.3,1,0.5,1.5,1l-2.2,0.4
	c-1.2,1.9-2.8,3.7-4.5,5.5c-1.8,1.8-3.6,3.3-5.6,4.7l-0.3,2.3l2.6-1.8c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1l-1.5-1
	l-8.8-5.1l1.1-2.6l9.8,2.8l1.8,0.5l0.7-2.5c0.7-2.9,1.1-5.8,1.1-8.9s-0.3-6.2-1.1-9.1l-1.5,2.1c0.4,2.3,0.7,4.7,0.7,7.2
	c0,2.3-0.3,4.7-0.7,6.9l1.4,1.9l-1.5-0.3h-0.1l-12-2.6l-13.6-3.7l-0.4-0.1c-0.3-0.1-0.5-0.3-0.7-0.4
	C647.8,381,647.5,381.7,647.2,382.2L647.2,382.2z"
          />
          <path
            fill="#EBEBEB"
            d="M649.4,381.1H649l13.6,3.7l12,2.6h0.1l1.5,0.3l-1.4-1.9l-0.1-0.1h-0.1c-3.3-0.4-6.5-1.1-9.8-1.8l-2.1-0.5
	l-3-0.8l-4-1.2l-0.8-0.3l-0.4-0.1l-0.1-0.1l-0.4-0.1h-3.2C650.5,381,650,381.1,649.4,381.1L649.4,381.1z M644.5,385.1l-1.7,0.7
	l0.4,0.7l0.1,0.3c1.4,4.4,2.6,8.9,3.7,13.8l3,13.8l2.2-0.5l-0.5-1.8l-2.6-9.8l2.6-1.1l5.1,8.8l0.8,1.5l1.9-1.1l-7.6-12
	C649.4,394.1,647,389.7,644.5,385.1L644.5,385.1z"
          />
          <path
            fill="#EBEBEB"
            d="M642.7,385.7c-0.4-0.4-1-0.7-1.7-0.7s-1.2,0.3-1.7,0.7l-0.1,0.1c-0.1,0.1-0.3,0.4-0.4,0.7
	c-0.1,0.1-0.1,0.3-0.1,0.4v0.4c0,0.5,0.1,1.1,0.5,1.5l0.1,0.1c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.7-0.7l0.1-0.1
	c0.4-0.4,0.5-0.8,0.5-1.5v-0.5l-0.1-0.3L642.7,385.7L642.7,385.7z M640.2,386.4c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,0.8,0.4
	c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.5,0.4-0.8,0.4c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8
	C639.8,386.9,639.9,386.6,640.2,386.4L640.2,386.4z"
          />
          <path
            fill="#EBEBEB"
            d="M641,386c-0.4,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,0.8,0.4
	s0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1C641.7,386.1,641.5,386,641,386L641,386z M643,388.7l-0.1,0.1
	c-0.4,0.4-1,0.7-1.7,0.7c-0.7,0-1.2-0.3-1.7-0.7l-0.1-0.1v3.2h3.6L643,388.7L643,388.7z"
          />
          <path
            fill="#EBEBEB"
            d="M643.4,386.8v0.6c0,0.5-0.1,1.1-0.5,1.5v3.3l0.3,1c1,2.6,1.8,5.2,2.3,7.8l0.5,2.1c0.4,1.8,0.8,3.7,1.2,5.6
	l0.7,4.1l0.1,0.1l2.1,1.4l-3-13.8C646,395.7,644.8,391.2,643.4,386.8L643.4,386.8z M649,390.1l-4.5-5c2.5,4.5,5,8.9,7.6,13.3l7.6,12
	l0.3-2.3V408c-1.8-2.8-3.6-5.6-5.4-8.4l-1.1-1.8L649,390.1L649,390.1z"
          />
          <path
            fill="#EBEBEB"
            d="M652.9,387.2l-0.7-0.4l-3.2,3.2l4.4,7.6l2.9-2.3c-1.2-1.6-2.3-4-3.4-6.9V387.2L652.9,387.2z"
          />
          <path
            fill="#EBEBEB"
            d="M652.2,386.8l0.7,0.4l1.9,1.1l6.1,3.7l0.7,0.4l8.4,5.5h0.1l2.2-0.4c-0.4-0.3-1-0.7-1.5-1
	c-2.8-1.9-5.6-3.7-8.5-5.5l-1.8-1.1c-4.3-2.8-8.7-5.2-13.2-7.7L652.2,386.8L652.2,386.8z"
          />
          <path fill="#EBEBEB" d="M652.9,387.2v1.1h1.8" />
          <path
            fill="#EBEBEB"
            d="M654.7,388.3h-1.9c1.1,2.9,2.2,5.2,3.4,6.9l0.5,0.7c1.5-1.1,2.9-2.5,3.9-3.9L654.7,388.3L654.7,388.3z"
          />
          <path
            fill="#656565"
            d="M674.9,371.8l-0.1,0.1c-3.3,0.4-6.6,1.1-9.8,1.8c0.3,1.6,0.6,3.4,0.6,5.1s-0.1,3.3-0.5,4.8
	c3.2,0.8,6.5,1.4,9.8,1.8h0.1l0.1,0.1c0.4-2.2,0.7-4.5,0.7-6.9C675.6,376.5,675.4,374.1,674.9,371.8L674.9,371.8z"
          />
          <path
            fill="#EBEBEB"
            d="M665,373.9l-2.1,0.5c0.3,1.5,0.4,3,0.4,4.5s-0.1,2.9-0.4,4.3l2.1,0.5c0.3-1.5,0.5-3.2,0.5-4.8
	C665.5,377.2,665.4,375.5,665,373.9L665,373.9z"
          />
          <path
            fill="#656565"
            d="M663.2,368.3l1.1,2.6l9.8-2.6c-0.5-1.8-1.2-3.4-2.1-5L663.2,368.3L663.2,368.3z"
          />
          <path
            fill="#EBEBEB"
            d="M663.3,378.9c0-1.5-0.1-3.2-0.4-4.5l-3.3,1c0.3,0.1,0.7,0.4,1,0.8c0.7,0.8,1.1,1.8,1.1,2.9
	c0,1.1-0.3,2.2-1.1,2.9l-0.5,0.5l3,0.8C663.2,381.8,663.3,380.5,663.3,378.9z"
          />
          <path
            fill="#656565"
            d="M664.3,387.1l-1.1,2.6l8.8,5.1c0.8-1.5,1.5-3.2,2.1-5L664.3,387.1L664.3,387.1z"
          />
          <path
            fill="#EBEBEB"
            d="M675.7,390.2l-1.8-0.5c-0.5,1.6-1.2,3.3-2.1,5l1.5,1l1.2-2.6L675.7,390.2L675.7,390.2z"
          />
          <path
            fill="#656565"
            d="M670.1,397.9h-0.1l-8.4-5.5c-1,1.4-1.9,2.6-3.2,3.7c-1.2,1.2-2.5,2.2-3.9,3.2c1.8,2.9,3.6,5.6,5.4,8.4v0.1
	c1.9-1.2,3.9-2.9,5.6-4.7C667.2,401.6,668.7,399.9,670.1,397.9L670.1,397.9z"
          />
          <path
            fill="#EBEBEB"
            d="M661.4,392.4l-0.7-0.4c-1.1,1.4-2.3,2.8-3.9,3.9l-0.5-0.7l-2.9,2.3l1.1,1.8c1.2-1,2.6-1.9,3.9-3.2
	C659.5,395,660.6,393.8,661.4,392.4L661.4,392.4z"
          />
          <path
            fill="#656565"
            d="M646.1,402.9l-5.1,0.4l-4.8-0.4c-0.4,1.9-0.8,3.7-1.2,5.6l-0.7,4.1l-0.1,0.1c2.2,0.4,4.5,0.6,6.9,0.6
	c2.5,0,4.8-0.3,7.2-0.7l-0.1-0.1l-0.7-4.1C647,406.7,646.5,404.8,646.1,402.9L646.1,402.9z"
          />
          <path
            fill="#EBEBEB"
            d="M641,403.4l5.1-0.5l-0.5-2.1l-4.5,0.4l-4.4-0.3l-0.4,2.1"
          />
          <path
            fill="#EBEBEB"
            d="M643.2,393l-0.3-1h-3.6l-0.3,1c-1,2.6-1.8,5.2-2.5,7.8l4.3,0.4l4.5-0.4C644.9,398.2,644.1,395.6,643.2,393
	L643.2,393z"
          />
          <path
            fill="#EBEBEB"
            d="M648.1,412.9c-2.3,0.4-4.7,0.7-7.2,0.7c-2.3,0-4.7-0.1-6.9-0.5l-1.9,1.2l2.5-11.6l0.4-2.1
	c1.1-4.7,2.3-9.4,3.7-13.8c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0.1-0.4,0.4-0.7c-0.5-0.1-1.1-0.4-1.7-0.7c-2.5,4.5-5.1,8.9-7.8,13.3
	l-7.6,11.7l-0.3-2.2c-1.9-1.2-3.7-2.8-5.5-4.5c-1.8-1.8-3.3-3.7-4.7-5.6l-2.3-0.3c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4
	l4.5,2.9l1-1.5l5.1-8.8c0.8,0.4,1.7,0.8,2.5,1.1l-2.8,9.8l-0.5,1.8l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1L648.1,412.9
	L648.1,412.9z M651.8,412l0.5,1.8c1.8-0.6,3.6-1.2,5.2-2.2l-0.8-1.5C655.1,410.7,653.6,411.4,651.8,412L651.8,412z"
          />
          <path
            fill="#656565"
            d="M651.8,412c1.7-0.5,3.3-1.2,5-1.9l-5.1-8.8l-2.6,1.1L651.8,412L651.8,412z"
          />
          <path
            fill="#EBEBEB"
            d="M636,355l-0.1-0.8c-0.7-2.9-1.2-5.9-1.7-9.1H634l-1.9-1.5l2.9,13.9l1.8,6.7c0.7,2.3,1.2,4.7,1.9,7v-0.5
	c0-0.5,0.1-1,0.5-1.4l-0.1-3.3l-0.1-0.6l-0.4-1.1c-0.8-2.3-1.5-4.7-2.2-7L636,355L636,355z"
          />
          <path
            fill="#EBEBEB"
            d="M632.1,343.5l-2.3,0.7l0.5,1.8l2.6,9.8c-1,0.3-1.8,0.7-2.6,1.1l-5-8.8l-1-1.6c-0.5,0.3-1.2,0.5-1.8,1h-0.1
	c-2.6,1.5-5,3.4-7.3,5.8l-0.8,1c-1.8,1.9-3.4,4-4.7,6.2l2.3-0.3c1.2-1.9,2.8-3.7,4.5-5.4l0.4-0.4c1.7-1.6,3.4-3,5.2-4.3l0.4-2.3v0.1
	l7.4,11.8l2.8,4.7c1.7,2.9,3.3,5.8,4.8,8.7c0.5-0.3,1.1-0.5,1.7-0.7l-0.4-0.7l-0.1-0.3c-0.7-2.3-1.4-4.7-1.9-7l-1.8-6.7L632.1,343.5
	L632.1,343.5z"
          />
          <path
            fill="#EBEBEB"
            d="M622.6,347.6l-0.4,2.3v0.1l5.4,8.5l1.1,1.8l4.4,7.6l4.5,5c-1.5-2.9-3.2-5.8-4.8-8.7l-2.8-4.7L622.6,347.6
	L622.6,347.6z M629.8,344.1c-1.8,0.5-3.6,1.2-5.4,2.2l1,1.6c1.7-0.8,3.2-1.5,5-2.1L629.8,344.1L629.8,344.1z"
          />
          <path
            fill="#656565"
            d="M622.2,350.1c-1.8,1.1-3.6,2.5-5.2,4.1l-0.4,0.4c-1.7,1.6-3.2,3.4-4.5,5.4h0.1c2.8,1.8,5.5,3.7,8.4,5.5
	c0.5-0.8,1.2-1.6,1.9-2.5l1.1-1.2c1.2-1.2,2.5-2.2,3.9-3.2L622.2,350.1L622.2,350.1z"
          />
          <path
            fill="#EBEBEB"
            d="M622.6,362.8c-0.7,0.8-1.4,1.6-1.9,2.5l1.8,1.2c0.5-0.8,1.2-1.6,1.9-2.5l0.8-1c1.1-1.1,2.2-2.1,3.4-2.9
	l-1.1-1.8c-1.2,0.8-2.6,1.9-3.9,3.2L622.6,362.8L622.6,362.8z"
          />
          <path
            fill="#EBEBEB"
            d="M622.5,366.4l-1.8-1.2c-2.9-1.8-5.6-3.6-8.4-5.5h-0.1l-2.3,0.3l11.8,7.7c4.4,2.6,8.8,5.2,13.2,7.7l-5-4.5
	L622.5,366.4L622.5,366.4z"
          />
          <path
            fill="#EBEBEB"
            d="M624.4,364.1c-0.7,0.8-1.4,1.5-1.9,2.5l7.4,4.5l3.2-3.2l-4.4-7.7c-1.2,0.8-2.3,1.8-3.4,2.9L624.4,364.1
	L624.4,364.1z"
          />
          <path
            fill="#656565"
            d="M630.3,356.9c0.8-0.4,1.8-0.8,2.6-1.1l-2.6-9.8c-1.7,0.6-3.3,1.2-5,2.1L630.3,356.9L630.3,356.9z"
          />
          <path
            fill="#EBEBEB"
            d="M608.4,362.4c-0.5,1.1-1.1,2.3-1.5,3.4l-0.7,1.8l1.7,0.5c0.5-1.8,1.2-3.4,2.1-5L608.4,362.4L608.4,362.4z"
          />
          <path
            fill="#EBEBEB"
            d="M609.7,360.2l-1.2,2.2l1.5,0.8l8.8,5.1c-0.4,0.8-0.7,1.8-1.1,2.6l-9.9-2.8l-1.7-0.5l-0.5,2.3
	c-0.7,2.9-1,5.8-1,8.9s0.4,6.2,1.1,9.1l1.4-2.1l-0.1-1.1c-0.3-1.9-0.4-3.9-0.4-5.9c0-2.1,0.1-4,0.4-5.9l0.1-1l-1.4-2.1l13.8,3
	c4.7,1.1,9.2,2.3,13.8,3.7l0.4,0.1c0.3,0.1,0.4,0.1,0.7,0.4c0.1-0.5,0.4-1.2,0.7-1.6c-4.5-2.5-8.9-5.1-13.2-7.7L609.7,360.2
	L609.7,360.2z"
          />
          <path
            fill="#EBEBEB"
            d="M617,374c-0.3,1.6-0.4,3.2-0.4,5c0,1.8,0.1,3.4,0.4,5.1l2.1-0.5c-0.3-1.5-0.4-3-0.4-4.5s0.1-3,0.4-4.4
	L617,374L617,374z"
          />
          <path
            fill="#656565"
            d="M616.6,378.9c0-1.6,0.1-3.3,0.4-5c-3.2-0.8-6.5-1.4-9.8-1.8l-0.1-0.1l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9
	s0.1,4,0.4,5.9l0.1,1.1l0.1-0.1c3.3-0.4,6.6-1.1,9.9-1.8C616.7,382.4,616.6,380.7,616.6,378.9L616.6,378.9z"
          />
          <path
            fill="#EBEBEB"
            d="M617,374l2.1,0.5c2.6,0.7,5.2,1.5,7.8,2.5l0.4,0.1l0.5,0.1h3.2c0.4-0.4,0.8-0.5,1.5-0.5h0.4
	c-4.4-1.4-8.9-2.6-13.8-3.7l-13.8-3l1.4,2.1l0.1,0.1C610.5,372.6,613.8,373.3,617,374L617,374z"
          />
          <path
            fill="#EBEBEB"
            d="M619,374.5c-0.3,1.4-0.4,2.9-0.4,4.4c0,1.5,0.1,3,0.4,4.5c2.6-0.7,5.2-1.5,7.8-2.5l0.4-0.1l0.7-0.1v-3.6
	l-0.6-0.1l-0.4-0.1C624.4,376.1,621.8,375.2,619,374.5L619,374.5z"
          />
          <path
            fill="#656565"
            d="M617.8,371c0.3-1,0.7-1.8,1.1-2.6l-8.8-5.1c-0.8,1.5-1.5,3.2-2.1,5L617.8,371L617.8,371z"
          />
          <path
            fill="#EBEBEB"
            d="M605.7,388l0.5,2.2l1.8-0.5l9.8-2.6c0.3,0.8,0.7,1.8,1.1,2.6l-8.9,5l-1.5,0.8l1,1.8l1.5-1
	c2.9-1.9,5.8-3.7,8.7-5.4l1.8-1.1c4.4-2.6,8.8-5.2,13.3-7.6c-0.3-0.5-0.4-1.1-0.7-1.6l-0.7,0.6l-0.3,0.1c-4.4,1.4-9.1,2.6-13.8,3.7
	L605.7,388L605.7,388z"
          />
          <path
            fill="#EBEBEB"
            d="M606.4,390.2l0.5,1.6c0.4,1.2,1.1,2.5,1.7,3.7l1.5-0.8c-0.8-1.6-1.5-3.3-2.1-5L606.4,390.2L606.4,390.2z
	 M607.1,386l-1.4,2.1l13.8-3c4.7-1.1,9.4-2.5,13.8-3.7h-0.5c-0.5,0-1.1-0.1-1.4-0.5H628l-0.7,0.1l-0.4,0.1c-2.6,1-5.2,1.8-7.8,2.5
	L617,384C613.8,384.7,610.5,385.4,607.1,386L607.1,386z"
          />
          <path
            fill="#656565"
            d="M619,389.7c-0.4-0.8-0.8-1.8-1.1-2.6l-9.8,2.6c0.5,1.6,1.2,3.3,2.1,5L619,389.7L619,389.7z"
          />
          <path
            fill="#EBEBEB"
            d="M633.1,367.8l-3.2,3.2l5,4.5c0.3-0.6,0.7-1.1,1.2-1.6c0.5-0.4,1-0.8,1.5-1.1L633.1,367.8L633.1,367.8z
	 M633.1,376.7h-0.4c-0.5,0-1.1,0.1-1.5,0.5l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.6l0.1,0.1c0.4,0.3,0.8,0.5,1.4,0.5
	h0.5l0.3-0.1l0.7-0.6h0.1c0.4-0.4,0.5-1,0.5-1.6s-0.1-1.2-0.5-1.6l-0.1-0.1c-0.1-0.1-0.4-0.3-0.7-0.4L633.1,376.7L633.1,376.7z
	 M631.4,378.9c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4s0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1s-0.5,0.4-1,0.4
	c-0.3,0-0.7-0.1-0.8-0.4C631.6,379.6,631.4,379.2,631.4,378.9L631.4,378.9z"
          />
          <path
            fill="#EBEBEB"
            d="M631.8,378.1c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1s0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-1-0.4C632.2,377.7,632,377.8,631.8,378.1L631.8,378.1z M630.3,378.9c0-0.7,0.3-1.2,0.7-1.6
	l0.1-0.1H628v3.6h3.3l-0.1-0.1C630.6,380.2,630.3,379.6,630.3,378.9L630.3,378.9z M637.7,385.1c-0.5-0.3-1.1-0.8-1.7-1.2
	c-0.4-0.4-0.8-1-1.1-1.5l-5,4.5l3.3,3.2L637.7,385.1L637.7,385.1z"
          />
          <path
            fill="#EBEBEB"
            d="M637.7,385.1l-4.5,5l-4.5,7.6l-1.1,1.8l-5.5,8.4l0.1,0.3l0.3,2.2l7.6-11.7
	C632.7,394.1,635.3,389.7,637.7,385.1L637.7,385.1z M634.9,382.4c-4.5,2.3-9.1,5-13.3,7.6l-1.8,1.1l-8.7,5.4l-1.5,1l2.3,0.3h0.1
	c2.8-1.8,5.6-3.6,8.4-5.4l1.8-1.1c2.5-1.5,5.1-3,7.6-4.4L634.9,382.4L634.9,382.4z"
          />
          <path
            fill="#EBEBEB"
            d="M633.2,390.1l-3.3-3.2c-2.5,1.4-5.1,2.9-7.6,4.4c0.8,1.2,1.8,2.3,2.9,3.4s2.2,1.9,3.4,2.9L633.2,390.1
	L633.2,390.1z M639.4,388.8c-0.3-0.4-0.5-1-0.5-1.5v-0.4c-1.4,4.4-2.6,8.9-3.7,13.8c-0.1,0.7-0.3,1.4-0.4,2.1l-2.5,11.6l1.9-1.2
	l0.1-0.1l0.7-4.1c0.3-1.9,0.7-3.9,1.2-5.6l0.5-2.1c0.7-2.8,1.5-5.2,2.5-7.8l0.3-1v-3.4L639.4,388.8L639.4,388.8z"
          />
          <path
            fill="#656565"
            d="M633.1,402.2c-0.8-0.3-1.8-0.7-2.5-1.1l-5.1,8.8c1.7,0.8,3.2,1.5,5,1.9L633.1,402.2L633.1,402.2z
	 M627.4,399.3c-1.2-1-2.6-1.9-3.7-3.2c-1.2-1.2-2.2-2.5-3.2-3.9c-2.9,1.8-5.6,3.6-8.4,5.4H612c1.2,1.9,2.9,3.9,4.7,5.6
	c1.7,1.8,3.6,3.2,5.5,4.5l-0.1-0.3L627.4,399.3L627.4,399.3z"
          />
          <path
            fill="#EBEBEB"
            d="M623.7,396.3c1.1,1.1,2.5,2.2,3.7,3.2l1.1-1.8c-1.2-0.8-2.3-1.8-3.4-2.9c-1.1-1.1-2.1-2.2-2.9-3.4l-1.8,1.1
	C621.5,393.8,622.6,395,623.7,396.3L623.7,396.3z M624.4,411.4c1.8,0.8,3.4,1.6,5.4,2.2l0.5-1.8c-1.7-0.5-3.3-1.2-5-1.9L624.4,411.4
	L624.4,411.4z"
          />
          <path
            fill="#656565"
            d="M641,327.9c14.2,0,26.1,5,36.2,15c9.9,9.9,15,22,15,36.2v2.6l8.1-0.5c-0.1-1.9-0.3-4-0.3-6.2l-0.1-4.7
	l-0.3-2.6c-1.7-13.1-7.3-24.5-17.1-34.3c-3-3.2-6.3-5.8-9.8-8.1c-9.2-6.1-19.7-9.1-31.4-9.1c-16.1,0-29.7,5.6-41.1,17.1
	s-17.5,25.5-18.3,42.1l-0.4,8.1h8.8c-0.1-1.5-0.3-3-0.3-4.5c0-12.7,4-23.7,12.1-33c0.8-1.1,1.8-2.1,2.8-3
	C614.9,332.9,626.9,327.9,641,327.9L641,327.9z"
          />
          <path
            fill="#FFFFFF"
            d="M640.9,310c13.1,0,24.6,3.6,34.9,10.6l8.9-14.9c-12.5-8-27-12-43.2-12c-10.5,0-20.2,1.7-29.2,5.1
	c-16.8,24.8-33.8,47.6-51.3,68.4c-3.9,4.5-9.5,7-17.1,7.3l-74.1,1.9L298,381c-3.3,0.1-5.2-1-6.1-3.2c-0.4-1.4-0.7-3.2-0.8-5.2
	l-0.3-2.3c-3.6-0.1-5.5-1-6.1-2.6c-0.8-2.8,1-4.4,5.2-5.1c-1.8-16.5-3.3-33.6-4.5-50.9l-5.9,1l2.1,9.5l1.7,7.6
	c-5.6-4.8-10.3-8.9-14.3-12.7c-14.3-13.1-31.6-19.7-51.9-19.7c-15.4,0-29.2,3.9-41.3,11.6l11.3,11.1c8.8-4.7,18.4-7,29-7
	c17.7,0,32.9,6.6,45.4,19.7c12.5,13.2,19.3,29.3,20.1,48.4l0.5,10.2l292.9-7.7l0.3-5.5c0.8-19.1,7.6-35.2,20.1-48.4
	C608,316.6,623.2,310,640.9,310L640.9,310z M700.1,381h5.8l-0.1-3.6c0-2.8-0.3-5.5-0.5-8.1l-5.6,0.8l0.1,4.7
	C699.8,377,699.9,379.1,700.1,381L700.1,381z M382,192.8l3.9,4.1c0.4-0.3,0.5-0.6,0.7-0.8l-0.5-0.6l-3.6-3.7L382,192.8z"
          />
          <path
            fill="#656565"
            d="M385.9,196.9l-3.7-4.1l-6.9,2.8l4,4.3l4.3-1.5C384.5,197.9,385.3,197.5,385.9,196.9L385.9,196.9z
	 M321.8,236.8l1.1-6.2c-11.8,5.8-23.7,12.2-35.5,19.3c-8.5,5.2-16.8,10.9-24.8,16.8c-3.3,2.5-6.6,5-9.9,7.4l8.9-0.3l0.1-0.1
	c8.1-6.3,16.6-12.2,25.7-17.7c8.7-5.4,17.5-10.3,26.3-15l4-2.2L321.8,236.8L321.8,236.8z"
          />
          <path
            fill="#656565"
            d="M322.9,230.6l-1.1,6.2c1.6-0.8,3.2-1.6,4.8-2.3c47.5-23.2,95.5-36.2,144.2-39.1l10.9-0.3l0.7-4.8l-9.9,0.3
	C421.9,193.1,372.1,206.4,322.9,230.6L322.9,230.6z"
          />
          <path
            fill="#656565"
            d="M326.6,234.5c-1.6,0.8-3.2,1.5-4.8,2.3l-2.5,14.2l2.3-0.6h2.2L326.6,234.5L326.6,234.5z"
          />
          <path
            fill="#FFFFFF"
            d="M323.7,250.9l-1.8-0.3h-0.3l-2.3,0.6c-1.5,0.3-2.9,0.7-4.1,1.1c-1.4,0.6-2.8,1.1-4,1.8
	c-3.3,1.8-6.2,4.1-8.8,7c-0.7,0.7-0.8,1.6-0.4,2.8l1.5,5.4l1.6-0.1l5.6-0.8c0.4-1.4,1.1-2.6,1.9-3.6c1.1-1.2,2.8-2.1,4.7-2.3h0.1
	l15.4-2.2c-0.3-2.2-0.8-3.9-2.1-4.8c-1.6-1.6-3.6-2.9-5.9-3.9L323.7,250.9L323.7,250.9z"
          />
          <path fill="#3296BA" d="M321.9,250.6l1.8,0.3l0.1-0.3" />
          <path
            fill="#656565"
            d="M327.3,251.3c-1.1-0.4-2.2-0.7-3.4-0.7l-0.1,0.4l1.1,0.4c2.2,1,4.1,2.2,5.9,3.9c1.1,1,1.8,2.6,2.1,4.8v6.2
	c-0.1,0.8-0.3,1.5-0.4,2.2c-0.4,1.5-1.2,2.6-2.2,3.4c-0.8,0.7-1.9,1.1-3.2,1.4l1.6-0.1c1.6-0.3,2.9-0.7,4-1.5s1.8-1.9,2.2-3.4
	c0.3-1.1,0.5-2.3,0.4-3.9v-3.3c-0.1-2.8-0.8-4.7-2.1-5.8C331.5,253.5,329.5,252.2,327.3,251.3L327.3,251.3z"
          />
          <path
            fill="#EBEBEB"
            d="M323.8,250.6c1.2,0,2.3,0.3,3.4,0.7c2.2,1,4.1,2.2,5.8,3.9c1.2,1.1,1.9,3,2.1,5.8v3.3c0,1.5-0.1,2.8-0.4,3.9
	l123.8-12.5l12.1-60c-48.7,2.8-96.8,15.8-144.2,39.1L323.8,250.6L323.8,250.6z"
          />
          <path
            fill="#656565"
            d="M332.2,268.3c0.3-0.7,0.4-1.5,0.4-2.2l-16.5,1.4l-5.6,0.7l-5.6,0.8l-1.6,0.3l14.9,5.2
	c0.4-0.4,0.8-0.5,1.4-0.5l7.2-0.7c1.2-0.3,2.3-0.7,3.2-1.4C331.1,271,331.8,269.7,332.2,268.3L332.2,268.3z"
          />
          <path
            fill="#EBEBEB"
            d="M332.8,266v-6.2l-15.4,2.2h-0.1c-1.9,0.3-3.6,1.1-4.7,2.3c-0.8,1-1.5,2.1-1.9,3.6l5.6-0.7L332.8,266
	L332.8,266z M326.9,262.1c0.4,0.4,0.7,1,0.7,1.5c0,0.5-0.3,1.1-0.7,1.5c-0.4,0.4-1,0.7-1.5,0.7s-1.1-0.3-1.6-0.7
	c-0.4-0.4-0.5-1-0.5-1.5s0.1-1.1,0.5-1.5c0.4-0.4,1-0.7,1.6-0.7C325.9,261.5,326.3,261.7,326.9,262.1L326.9,262.1z"
          />
          <path
            fill="#EBEBEB"
            d="M327.5,263.7c0-0.5-0.3-1.1-0.7-1.5c-0.4-0.4-1-0.7-1.5-0.7s-1.1,0.3-1.6,0.7c-0.4,0.4-0.5,1-0.5,1.5
	s0.1,1.1,0.5,1.5s1,0.7,1.6,0.7c0.5,0,1.1-0.3,1.5-0.7C327.3,264.8,327.5,264.3,327.5,263.7L327.5,263.7z M326,263
	c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.8c-0.3,0.3-0.5,0.4-0.8,0.4c-0.3,0-0.5-0.1-0.8-0.4c-0.1-0.1-0.3-0.4-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.3,0.8-0.3C325.5,262.6,325.8,262.7,326,263L326,263z"
          />
          <path
            fill="#EBEBEB"
            d="M326.3,263.7c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3
	c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4s0.5-0.1,0.8-0.4C326.2,264.2,326.3,264.1,326.3,263.7
	L326.3,263.7z"
          />
          <path
            fill="#FFFFFF"
            d="M328.5,273l-1.6,0.1l-7.2,0.7c-0.5,0-1.1,0.1-1.4,0.5c-0.3,0.3-0.4,0.5-0.5,0.8l-0.7,1.9l-2.8,8
	c-1.7,4.8-4.7,7-9.4,6.3c-2.1-0.5-3.7-1.6-5-3.2s-1.6-3.2-1-5.2l2.2-6.5l0.7-2.1l-12,1.2l-5.5,24.1l128.1-15.1c-0.5-0.5-1-1.1-1-1.8
	c0-0.3,0-0.5,0.1-0.8c0.5-1.4,2.3-2.8,5.5-4.1l0.4-0.1c3.9-1.5,8.3-2.5,13.6-3c5.5-0.5,10-0.3,13.9,0.5c1.8,0.4,3.3,1,4.3,1.5
	l0.7,0.5c0.5,0.5,0.8,1.1,0.8,1.8v0.3l-0.1,0.4l-0.1,0.4l18.2-2.2l3.2-20.9l-139.5,14.3C331.4,272.2,330.2,272.7,328.5,273
	L328.5,273z"
          />
          <path
            fill="#FFFFFF"
            d="M334.7,268.1c-0.4,1.5-1.2,2.6-2.2,3.4l139.8-14.2l0.5-3.2l-14.2,1.4L334.7,268.1L334.7,268.1z"
          />
          <path
            fill="#EBEBEB"
            d="M324.1,222.4c17.5-8.3,35.8-15.7,55-22.6l-4-4.3c-41.8,16.5-82.3,36.3-121.1,59.4l3.3-0.8
	c2.5-0.7,4.8-1.6,7-2.6l0.3-0.1C287,240.3,306.8,230.5,324.1,222.4L324.1,222.4z"
          />
          <path
            fill="#FFFFFF"
            d="M411.9,282.2c0.3-0.4,0.8-0.8,1.8-1.2c1-0.8,2.3-1.5,4.1-2.3l1-0.3c0.5-0.3,1,0,1.1,0.7v1l19-2.3
	c0-0.7,0.1-1.2,0.4-1.6c0.1-0.4,0.5-0.5,1.1-0.5h1l4.5,0.7c1,0.3,1.9,0.5,2.6,0.8l1.2,0.3c-1-0.7-2.5-1.1-4.3-1.5
	c-3.9-0.8-8.5-1.1-13.9-0.5c-5.2,0.4-9.8,1.5-13.6,3l-0.4,0.1C414.3,279.5,412.4,280.9,411.9,282.2L411.9,282.2z"
          />
          <path
            fill="#FFFFFF"
            d="M413.8,281c-1,0.4-1.6,0.7-1.8,1.2c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.7,0.3,1.2,1,1.8l0.8,0.5h1l-0.8-0.4
	l0.8,0.4l5.9-0.5l18.8-1.9l1.9-0.1l-0.3-5.6l-1.9,0.3l-19,2.3l-2.6,0.3C415.7,280.4,414.6,280.7,413.8,281L413.8,281z"
          />
          <path
            fill="#FFFFFF"
            d="M419.8,279.1c-0.1-0.7-0.4-1-1.1-0.7l-1,0.3c-1.8,0.8-3.2,1.5-4.1,2.3c0.8-0.3,2.1-0.5,3.4-0.8l2.6-0.3v-0.8
	L419.8,279.1L419.8,279.1z M419.2,286.9l-1.4-0.3c-1.2-0.3-2.5-0.7-3.3-1.1h-1c1,0.5,2.3,1.1,4.1,1.5l0.3,0.1
	c3.9,0.8,8.4,0.8,13.6,0.4c5.5-0.5,10-1.5,13.9-3.2c2.9-1.1,4.7-2.3,5.4-3.6l-0.8,0.5l-0.8,0.4c-0.5,0.5-3,1.5-7.3,2.8l-0.1,0.1
	h-0.1c-0.7,0.1-1.2,0-1.6-0.5l-0.4-0.8l-18.8,1.9l0.1,0.7c0,0.3,0,0.5-0.1,0.8C420.1,286.8,419.7,286.9,419.2,286.9L419.2,286.9z"
          />
          <path
            fill="#FFFFFF"
            d="M417.8,286.6l1.4,0.3c0.5,0,1-0.1,1.2-0.4c0.1-0.1,0.1-0.4,0.1-0.8l-0.1-0.7l-5.9,0.5
	C415.3,285.9,416.4,286.2,417.8,286.6L417.8,286.6z"
          />
          <path
            fill="#FFFFFF"
            d="M417.8,287l-0.3-0.1c-1.8-0.4-3.2-1-4.1-1.5l-0.8-0.5L284.5,300l0.8,11.7c59.6-9.2,120.6-18.2,183.4-27
	l0.3-6.5l-18.2,2.2l-0.1,0.3c-0.8,1.2-2.6,2.5-5.4,3.6c-3.9,1.5-8.5,2.6-13.9,3.2C426.2,287.9,421.6,287.7,417.8,287L417.8,287z
	 M439.4,275.9c-0.3,0.4-0.4,1-0.4,1.6l1.9-0.3l0.4-1.9h-1C439.8,275.4,439.5,275.5,439.4,275.9L439.4,275.9z"
          />
          <path
            fill="#FFFFFF"
            d="M439.2,283.1l0.4,0.8c0.4,0.5,1,0.7,1.6,0.5h0.1l0.1-0.1l-0.5-1.5L439.2,283.1L439.2,283.1z M472.7,254
	l-0.4,3.2l152.1-15l3.3-0.7l-1.7-2.8l-2.1,0.6l-5.2,0.4l-132.3,12.9 M450.9,280.4l0.1-0.4l0.1-0.4v-0.3c0-0.7-0.3-1.2-0.8-1.8
	l-0.7-0.5l-1.2-0.3l-7.6,0.5l0.3,5.6l5.1-0.5c1.1-0.1,2.1-0.4,2.9-0.7l0.8-0.4l0.8-0.5L450.9,280.4L450.9,280.4z M444.2,278.1
	c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.7,0.5,1.1s-0.1,0.8-0.5,1.1l-0.5,0.4l-0.6,0.1h-0.3c-0.4-0.1-0.7-0.3-0.8-0.5
	c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.4,0.1-0.8,0.4-1.1C443.5,278.2,443.8,278.1,444.2,278.1L444.2,278.1z"
          />
          <path
            fill="#FFFFFF"
            d="M445.3,278.5c-0.3-0.3-0.7-0.4-1.1-0.4s-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1s0.1,0.8,0.4,1.1
	c0.3,0.3,0.5,0.4,0.8,0.5l0.8-0.1l0.5-0.4c0.3-0.3,0.5-0.7,0.5-1.1C445.8,279.2,445.7,278.8,445.3,278.5L445.3,278.5z"
          />
          <path fill="#3296BA" d="M443.9,281.3h0.3l0.7-0.1" />
          <path
            fill="#FFFFFF"
            d="M445.8,276l-4.5-0.7l-0.4,1.9l7.6-0.5C447.8,276.5,446.8,276.2,445.8,276L445.8,276z M446.3,282.4l-5.1,0.5
	l0.5,1.5c4.3-1.2,6.7-2.1,7.3-2.8C448.3,282,447.4,282.2,446.3,282.4L446.3,282.4z M469.1,278.2l71.8-8.4l34.5-3.4l0.1-0.7
	c0.5-1.5,2.3-2.9,5.6-4.1l0.4-0.1c3.7-1.5,8.3-2.5,13.6-3c5.5-0.5,10.2-0.4,13.9,0.4c1.8,0.4,3.3,1,4.3,1.5l0.7,0.5
	c0.5,0.5,0.8,1.1,0.8,1.8v0.5l16.5-1.1c-0.3-7-1.5-13.9-3.7-20.5l-3.3,0.6l-152.1,15.1L469.1,278.2L469.1,278.2z"
          />
          <path
            fill="#FFFFFF"
            d="M469.1,278.2l-0.3,6.5c36.3-5.1,73-10,110.3-15c-0.8-0.3-1.5-0.5-1.9-1c-1.1-0.7-1.7-1.5-1.7-2.3v-0.1
	l-34.5,3.4L469.1,278.2L469.1,278.2z"
          />
          <path fill="#656565" d="M458.6,255.4l14-1.4l8.9-58.9l-10.9,0.3" />
          <path
            fill="#FFFFFF"
            d="M619.3,179.9l-0.3,2.1l8.8,1l9.8,1.4l0.3-2.1l-9.8-1.5"
          />
          <path
            fill="#FFFFFF"
            d="M632.1,179.2c-1-0.3-1.9-0.4-3.2-0.4l-9.6,1.1l8.8,0.8l9.8,1.4l-0.8-0.4L632.1,179.2L632.1,179.2z"
          />
          <path
            fill="#656565"
            d="M482.4,190.2l-0.7,4.8c3.4-0.1,6.9-0.1,10.5-0.1c37.7-0.3,73.6,2.6,107.7,8.4l6.3,1.1l-3.3-5.5
	C565.1,192.3,524.9,189.4,482.4,190.2L482.4,190.2z"
          />
          <path
            fill="#656565"
            d="M492.1,195c-3.4,0-6.9,0-10.5,0.1l-8.9,59l13.8-1.4L492.1,195L492.1,195z M612.8,205.7l-6.7-1.2l19.9,34.4
	c1.8-0.4,3.4-1.1,4.8-1.9L612.8,205.7L612.8,205.7z"
          />
          <path
            fill="#656565"
            d="M624,239.3l2.1-0.6l-19.9-34.4l-6.3-1.1l19,36.5"
          />
          <path
            fill="#FFFFFF"
            d="M580.9,263.7l2.6-0.3v-1c0-0.7-0.4-0.8-1.1-0.5c-0.1,0-0.4,0.1-0.5,0.1l-0.4,0.1c-1.8,0.7-3.3,1.5-4.1,2.3
	C578.3,264.1,579.4,263.8,580.9,263.7L580.9,263.7z"
          />
          <path
            fill="#FFFFFF"
            d="M583.7,263.2l-2.6,0.3c-1.5,0.1-2.6,0.4-3.4,0.8c-1,0.3-1.7,0.7-1.8,1.2l-0.1,0.7v0.1c0,1,0.5,1.8,1.7,2.3
	l1.1,0.1l5.9-0.5l18.8-1.9l1.9-0.1l-0.3-5.5l-1.9,0.1L583.7,263.2L583.7,263.2z"
          />
          <path
            fill="#FFFFFF"
            d="M583.7,262.4v1l19-2.5c0-0.7,0.1-1.2,0.4-1.6c0.1-0.4,0.5-0.5,1.1-0.5h1c1.7,0.1,3.2,0.3,4.5,0.7
	c1,0.3,1.9,0.4,2.6,0.8l1.2,0.1c-1-0.5-2.5-1.1-4.3-1.5c-3.9-0.8-8.5-1-13.9-0.4c-5.2,0.4-9.8,1.5-13.6,3l-0.4,0.1
	c-3.2,1.2-5.1,2.8-5.6,4.1c0.3-0.5,0.8-0.8,1.8-1.2c1-0.8,2.3-1.6,4.1-2.3l0.4-0.1c0.1-0.1,0.3-0.1,0.5-0.1
	C583.3,261.5,583.5,261.7,583.7,262.4L583.7,262.4z M584.2,269c0,0.3,0,0.5-0.1,0.7c-0.3,0.3-0.5,0.4-1.2,0.4l-0.7-0.1l-2.1-0.5
	l-1,0.1l2.5,0.5c3.9,0.8,8.4,0.8,13.6,0.4c5.5-0.5,10.2-1.5,13.9-3.2c1.9-0.8,3.4-1.5,4.4-2.3c0.4-0.4,0.8-0.8,1.1-1.2l-0.8,0.5
	l-0.8,0.4l-0.5,0.4c-1.1,0.5-3.3,1.2-6.9,2.3l-0.1,0.1h-0.1c-0.7,0.1-1.2-0.1-1.7-0.5l-0.4-0.8l-18.8,1.9L584.2,269z"
          />
          <path
            fill="#FFFFFF"
            d="M584.1,269.9c0.1-0.1,0.3-0.4,0.1-0.7l-0.1-0.7l-5.9,0.5l1.9,0.7l2.1,0.5l0.7,0.1
	C583.4,270.3,583.8,270.1,584.1,269.9L584.1,269.9z"
          />
          <path
            fill="#3296BA"
            d="M577.1,268.9c0.5,0.4,1.2,0.7,1.9,1l1-0.1L578,269L577.1,268.9L577.1,268.9z"
          />
          <path
            fill="#FFFFFF"
            d="M595.2,270.8c-5.2,0.4-9.9,0.3-13.6-0.4l-2.5-0.5c-37.3,5-74.1,9.9-110.3,15c-1.1,21.9-1.5,43.9-1.1,65.9
	l87.8-5.9c-30,5.5-59.4,9.9-88.3,13.6l2.8,18.2l74.1-1.9c7.4-0.3,13.2-2.8,17.1-7.3c17.5-20.8,34.5-43.6,51.3-68.4
	c-8.8,3.3-16.8,8.1-24.1,14.7c-7.2,6.3-12.5,13.3-16.5,21c3.6-11.6,10.2-21.7,19.9-30.5c8.4-7.7,17.9-13.2,28.5-16.4
	c1-1.2,1.9-3,2.8-5.2l8.3-19.4l-17.7,2.1c-1,0.8-2.5,1.6-4.4,2.3C605.3,269.2,600.6,270.3,595.2,270.8L595.2,270.8z"
          />
          <path fill="#3296BA" d="M631.4,262.1L631,263l13.3-1.6" />
          <path
            fill="#FFFFFF"
            d="M631,263l0.4-1l-16.5,1.1v0.1l-0.3,0.7c-0.3,0.4-0.5,0.8-1.1,1.2L631,263L631,263z M609.5,259.4
	c-1.4-0.3-2.9-0.5-4.5-0.7l-0.4,1.9l7.6-0.5C611.5,259.9,610.6,259.7,609.5,259.4L609.5,259.4z"
          />
          <path
            fill="#FFFFFF"
            d="M603.1,259.3c-0.3,0.4-0.4,1-0.4,1.6l1.9-0.1l0.4-1.9h-1C603.6,258.7,603.2,258.8,603.1,259.3L603.1,259.3z
	 M602.9,266.4l0.4,0.8c0.4,0.5,1,0.7,1.7,0.5h0.1l0.1-0.1l-0.6-1.5L602.9,266.4L602.9,266.4z"
          />
          <path
            fill="#FFFFFF"
            d="M604.6,260.8l0.3,5.5l5.6-0.5l2.2-0.5l0.8-0.4l0.8-0.5l0.3-0.7v-0.7c0-0.7-0.3-1.2-0.8-1.8l-0.7-0.5
	l-1.2-0.1L604.6,260.8L604.6,260.8z"
          />
          <path
            fill="#FFFFFF"
            d="M604.9,266.3l0.5,1.5c3.6-1,5.8-1.8,6.9-2.3l0.5-0.4l-2.2,0.5L604.9,266.3L604.9,266.3z"
          />
          <path
            fill="#EBEBEB"
            d="M618.8,239.7l-19-36.5c-34.1-5.8-70-8.5-107.7-8.4l-5.6,57.6L618.8,239.7L618.8,239.7z"
          />
          <path
            fill="#FFFFFF"
            d="M145.5,287.9c3.3-1,6.5-1.6,9.8-2.1h0.4c3.4-0.5,6.7-0.8,10-0.8h0.4c5,0,9.8-0.3,14.7-1l28.8-4l43.1-5.9
	c3.2-2.5,6.5-5,9.9-7.4l-5.2-12.4l-3.3,0.8h-0.1c-7.6,1.5-14.9,2.9-22.1,4.5l-1.1,0.3c-45.7,9.5-86.3,22.1-121.7,38.1
	c-4.4,2.1-8.5,4.3-12.4,6.7l3.4,2.2c2.9-1.6,5.8-3.2,8.8-4.5c5.8-2.6,11.6-5.1,17.3-7.7C131.6,292.4,138,290.1,145.5,287.9
	L145.5,287.9z"
          />
          <path
            fill="#EBEBEB"
            d="M155.3,285.7c-3.3,0.5-6.5,1.2-9.8,2.1c-7.6,2.3-13.9,4.5-19.3,6.9c-5.9,2.5-11.7,5.1-17.3,7.7
	c-3,1.4-6.1,2.9-8.8,4.5l-5.4,3.3l-0.1,0.1c-1.1,1.4-1.4,3-0.8,5l1,2.9l0.6,0.8l1,0.5l2.3,0.1l19.1-4.4l0.3-0.1
	c11.6-2.9,23.5-6.2,36-10h0.1l13.5-4.3l2.6-1.1c-4.7,1.2-8.5,2.5-11.4,3.3l-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.4
	c-1.4,0.4-2.8,0.8-4.1,1.2c-12.2,3.6-24.1,6.7-35.4,9.5l-0.3,0.1l-10.3,2.2l-1.9,0.4l-3.6,0.8l-3.3,0.7c-1.1,0.3-1.9,0.1-2.2-0.4
	l-0.1-0.3l-1-2.9c-0.1-0.7-0.3-1.4-0.3-1.9c0-0.7,0.4-1.2,1.2-1.7l1.2-0.8c6.3-3.6,14.6-8,24.9-13.1
	C134.1,291.7,145,287.7,155.3,285.7L155.3,285.7z"
          />
          <path
            fill="#EBEBEB"
            d="M155.7,285.7h-0.4c-10.3,2.1-21.2,5.9-32.9,11.7c-10.3,5.1-18.6,9.5-24.9,13.1l-1.2,0.8
	c-0.8,0.4-1.2,1-1.2,1.6c0,0.5,0.1,1.2,0.3,1.9l1,2.9l0.1,0.3c0.4,0.5,1.1,0.7,2.2,0.4l3.3-0.7c-1.1,0.1-2.1-0.5-2.9-2.2l-0.6-1.5
	l-0.1-0.7c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.5,0.4-1.1,1.1-1.4c2.1-1.4,9.8-5.2,23.1-11.8c11.7-5.8,22.4-9.5,32-11.3
	c5.6-1,11-1.4,16.1-1.2c3.6,0,7.4-0.3,11.1-0.8l23.2-3l4.5-2.5l-28.8,4c-5,0.5-9.9,1-14.7,1h-0.4C162.5,284.8,159,285.1,155.7,285.7
	L155.7,285.7z"
          />
          <path
            fill="#EBEBEB"
            d="M181.9,285.4c-3.9,0.5-7.6,0.8-11.1,0.8c-5.1-0.1-10.5,0.3-16.1,1.2c-9.6,1.6-20.4,5.5-32.1,11.3
	c-13.2,6.5-20.9,10.5-23.1,11.8c-0.6,0.3-1,0.8-1.1,1.4c-0.1,0.4-0.1,0.8,0,1.2l0.1,0.7l0.6,1.5c0.8,1.6,1.8,2.3,2.9,2.2l3.6-0.8
	l2.3-2.5l24.6-5.9l22.7-6.2h1.2c0.3,0.1,0.4,0.3,0.7,0.4l0.3,0.3l0.4,0.5c0.1-0.1,0.3-0.1,0.4-0.1l0.4-0.1l5.5-6.7
	c1-1.1,2.2-1.8,3.7-2.2l18.7-2.2c6.2-3,12.4-6.2,18.4-9.5L181.9,285.4L181.9,285.4z M154.1,288.6c1.9-0.4,3.9-0.7,5.4-0.5
	c1,0,1.9,0.3,2.9,0.5c0.8,0.3,1.5,0.5,2.1,1l21.9-2.3l-4.1,3.7l-17.2,1.8l-2.6,2.8l-2.5,2.8c-1.4,1.5-3.3,2.8-5.6,3.7l-2.3,0.7
	l-5.1,1.5l-1,0.3l-12.9,3.4l-1.1,0.3l-8.7,2.2l-7.2,1.6l-8.3,1.9l-2.3,2.2l-2.6,0.4c-1.1,0.1-1.8-0.1-2.3-1.1l-1.1-2.2
	c-0.1-0.4-0.1-1,0-1.2c0.1-0.3,0.3-0.5,0.6-0.7c2.6-1.5,10.2-5.4,22.6-11.6C134,294.1,144.6,290.4,154.1,288.6L154.1,288.6z"
          />
          <path
            fill="#EBEBEB"
            d="M154.2,291.3v-0.5c1.1-1.4,2.9-2.2,5.2-2.8c-1.6-0.1-3.4,0.1-5.4,0.5c-9.6,1.8-20.1,5.5-31.6,11.1
	c-12.5,6.2-20.1,10-22.6,11.6c-0.3,0.1-0.4,0.4-0.6,0.7H116l7.2-1.6v-6.6h10.7l0.4-1l17.2-10.7h1.8L154.2,291.3L154.2,291.3z"
          />
          <path
            fill="#EBEBEB"
            d="M154.2,290.8v11.1c2.3-1,4.1-2.2,5.6-3.7l2.5-2.8v-6.9c-0.8-0.3-1.8-0.4-2.9-0.5
	C157.1,288.6,155.3,289.4,154.2,290.8L154.2,290.8z M153.4,292h-1.8l-4.7,12.1l5.1-1.5c-0.4-0.8-0.6-1.8-0.7-2.8v-2.6
	C151.3,295,152,293.2,153.4,292L153.4,292z"
          />
          <path
            fill="#EBEBEB"
            d="M154.2,291.3l-0.8,0.7c-1.4,1.2-2.1,3-2.1,5.2v2.6c0.1,1.1,0.3,1.9,0.7,2.8l2.3-0.7v-10.6L154.2,291.3
	L154.2,291.3z M164.4,289.5l0.6,3.2l17.2-1.8l4.1-3.7"
          />
          <path
            fill="#EBEBEB"
            d="M164.9,292.7l-0.6-3.2c-0.7-0.4-1.4-0.7-2.1-1v6.9L164.9,292.7L164.9,292.7z M145.8,304.4l0.3-0.5h-11.3
	l-1.8,4"
          />
          <path
            fill="#EBEBEB"
            d="M146.1,303.8l-0.3,0.5l1-0.3l4.8-12.1l-17.2,10.7l-0.6,1l-1.9,4.4l1-0.3h0.1l1.8-4l0.1-0.4l14.9-9.2"
          />
          <path
            fill="#EBEBEB"
            d="M134.9,303.4l-0.1,0.4h11.3l3.7-9.6 M131.9,308.1l1.9-4.4h-10.7v6.6 M105.4,316.1l2.3-2.2l8.3-1.9H99.3
	c-0.1,0.4-0.1,0.8,0,1.2l1.1,2.2c0.4,0.8,1.2,1.2,2.3,1.1L105.4,316.1L105.4,316.1z M186.7,292l-18.7,2.2c-1.5,0.4-2.8,1.1-3.7,2.2
	l-5.5,6.7c2.9-1,6.7-2.1,11.4-3.3l0.3-0.1C175.9,297.2,181.3,294.6,186.7,292L186.7,292z"
          />
          <path
            fill="#FFFFFF"
            d="M209.8,279.9l-4.5,2.5c-6.1,3.3-12.2,6.5-18.4,9.5c-5.4,2.6-10.9,5.2-16.2,7.7l-0.3,0.1l-2.6,1.1l-0.8,0.4
	l4.7,5.2c13.5-10,29.2-15.1,47.2-15.1c15.7,0,29.7,3.9,41.8,11.6l24.1-2.9l5.5-24.1l-12.2,1.2c-5.1,0.8-9.8,0.8-14,0.1
	c-1.8-0.3-3.4-0.7-5.1-1.1l-5.8-2.1L209.8,279.9L209.8,279.9z M230.8,259.8h-16.2c-34.8,6.1-67.3,15.5-97.1,28.5
	c-8,3.6-15.5,7.4-22.7,11.7c-1.1,0.7-2.2,1.4-3.2,2.2l5.2,2.5c3.9-2.5,8-4.7,12.4-6.7C144.4,282.1,185,269.3,230.8,259.8
	L230.8,259.8z"
          />
          <path
            fill="#656565"
            d="M317.9,238.8l-4,2.2c-8.8,4.7-17.5,9.6-26.3,15c-9.1,5.5-17.6,11.4-25.7,17.7l-0.1,0.1h0.1
	c1.8,0.4,3.6,0.5,5.5,0.7l10.5-0.5l13.5-1.4l11.8-1.2c0.3-0.7,0.4-1.5,0.3-2.2V269l-20.9,2.2c-4.5,0.4-7.6,0.3-8.9-0.3
	c5.2-4.4,10.6-8.3,16.1-11.6c6.7-4.1,13.3-8,20.1-11.7c1.9-0.8,2.8-0.3,2.3,1.6l-1,4.7c1.2-0.7,2.6-1.2,4-1.8L317.9,238.8
	L317.9,238.8z"
          />
          <path
            fill="#FFFFFF"
            d="M252.8,274l5.8,2.1c1.6,0.5,3.3,0.8,5.1,1.1c4.3,0.7,8.9,0.7,14-0.1l12.2-1.2v-0.4l1.1-2.9l-13.5,1.4
	l-10.5,0.5c-1.9-0.1-3.7-0.4-5.5-0.7h-0.1L252.8,274L252.8,274z"
          />
          <path
            fill="#EBEBEB"
            d="M311.2,253.9l1-4.7c0.3-1.8-0.5-2.3-2.3-1.6c-6.7,3.7-13.5,7.6-20.1,11.7c-5.5,3.4-10.9,7.3-16.1,11.6
	c1.4,0.5,4.4,0.7,8.9,0.3l20.9-2.2l-1.5-5.4c-0.4-1.1-0.1-1.9,0.4-2.8C305,258,307.9,255.7,311.2,253.9L311.2,253.9z"
          />
          <path
            fill="#656565"
            d="M315.2,252.2c1.2-0.4,2.8-0.8,4.1-1.1l2.5-14.2l-3.9,1.9L315.2,252.2L315.2,252.2z"
          />
          <path fill="#3296BA" d="M303.5,269.2l1.6-0.3l-1.6,0.1" />
          <path fill="#FFFFFF" d="M290,275.5v0.4l12-1.2l1.1-3.3l-11.8,1.2" />
          <path
            fill="#656565"
            d="M299.1,283.1c-0.5,2.1-0.3,3.7,1,5.2s2.9,2.6,5,3.2c4.7,0.5,7.7-1.5,9.4-6.3l2.8-8
	c-5.2,1.2-10.6,1.1-15.8-0.5L299.1,283.1L299.1,283.1z"
          />
          <path
            fill="#656565"
            d="M317.8,275.2c0.1-0.4,0.4-0.7,0.5-0.8l-14.9-5.2c0.1,0.7,0,1.5-0.3,2.2l-1.1,3.3l-0.7,2.1
	c5.2,1.6,10.6,1.8,15.8,0.5L317.8,275.2L317.8,275.2z"
          />
          <path
            fill="#FFFFFF"
            d="M285.3,311.7l-0.8-11.7l-24.1,2.9c1.8,1.1,3.6,2.3,5.4,3.7c4.1,2.3,8.5,4.4,13.5,6.1L285.3,311.7
	L285.3,311.7z"
          />
          <path
            fill="#FFFFFF"
            d="M283,329.7l-1.6-7.6c-2.9-3.9-6.3-7.6-10.2-11.1c-1.8-1.5-3.6-3-5.4-4.4c-2.1-1.1-3.9-2.3-5.6-3.7h0.3
	c-12.2-7.7-26.1-11.6-41.8-11.6c-18,0-33.7,5.1-47.2,15.1l4.1,2.3c12.1-7.7,25.9-11.6,41.3-11.6c20.2,0,37.6,6.6,51.9,19.7
	C272.7,320.6,277.5,324.9,283,329.7L283,329.7z"
          />
          <path
            fill="#FFFFFF"
            d="M279.4,312.6c-4.8-1.6-9.4-3.7-13.5-6.1c1.8,1.4,3.6,2.9,5.4,4.4c3.9,3.4,7.3,7.2,10.2,11.1L279.4,312.6
	L279.4,312.6z"
          />
          <path
            fill="#3296BA"
            d="M260.5,302.9h-0.3c1.8,1.4,3.7,2.6,5.6,3.7C264.1,305.2,262.3,304,260.5,302.9L260.5,302.9z"
          />
          <path
            fill="#656565"
            d="M252.4,342.8c1.1,1,1.9,2.1,2.9,3c8.1,9.4,12.1,20.4,12.1,33c0,3.6-0.3,7.2-1,10.5h9.2l-0.5-11
	c-0.8-16.6-6.9-30.5-18.3-42.1c-11.4-11.4-25-17.1-41.1-17.1c-8.8,0-17.1,1.8-24.5,5.2c-6.1,2.8-11.6,6.7-16.8,11.8
	c-9.6,9.6-15.3,21-17.1,34.3c-0.3,2.3-0.6,4.8-0.6,7.3c-0.1,4.4-0.4,8.4-1.1,11.7h10.5c-0.7-3.4-1.1-6.9-1.1-10.6
	c0-14.2,5-26.1,15-36.2c10-9.9,22-15,36.2-15C230.4,327.9,242.4,332.9,252.4,342.8L252.4,342.8z"
          />
          <path
            fill="#656565"
            d="M252.4,342.9c-10-10-22.1-15-36.2-15c-14.2,0-26.1,5-36.2,15c-9.9,9.9-15,22-15,36.2c0,3.7,0.4,7.2,1.1,10.6
	c1.5,7.7,4.8,14.9,9.9,21.2c1.2,1.5,2.5,2.9,4,4.4c10,9.9,22,15,36.2,15c13.2,0,24.5-4.4,34.1-13.1l2.1-1.9
	c7.4-7.4,12.1-16,13.9-25.7c0.7-3.4,1-6.9,1-10.5c0-12.7-4-23.7-12.1-33C254.5,344.8,253.5,343.9,252.4,342.9L252.4,342.9z
	 M242.9,352.4l1.6,1.8c2.5,2.8,4.4,5.8,5.9,8.9c2.2,4.8,3.4,10.2,3.4,16s-1.1,11-3.4,16c-1.8,3.9-4.4,7.4-7.6,10.6l-3.4,3.2
	c-6.6,5.2-14.3,7.8-23.1,7.8s-16.5-2.6-23.1-7.8c-1.2-1-2.3-2.1-3.6-3.2c-3-3-5.4-6.3-7.2-9.8c-2.5-5.1-3.9-10.6-3.9-16.8
	c0-6.1,1.2-11.7,3.9-16.8c1.4-2.9,3.3-5.6,5.6-8.1l1.5-1.8h0.1c4.5-4.5,9.9-7.7,15.7-9.5c3.4-1,7-1.5,10.7-1.5
	c3.9,0,7.4,0.5,10.7,1.5c2.6,0.8,5.2,1.8,7.6,3.3C237.6,347.7,240.3,349.8,242.9,352.4L242.9,352.4z"
          />
          <path
            fill="#EBEBEB"
            d="M244.4,354l-1.6-1.8c-2.5-2.5-5.2-4.7-8.3-6.2c-2.3-1.4-5-2.5-7.6-3.3c-3.4-1-7-1.5-10.7-1.5
	c-3.9,0-7.4,0.5-10.7,1.5c-5.8,1.6-11.1,4.8-15.7,9.5h-0.1l-1.5,1.8c-2.3,2.5-4.1,5.2-5.6,8.1c-2.5,5.1-3.9,10.6-3.9,16.8
	c0,6.1,1.2,11.7,3.9,16.8c1.8,3.6,4.1,6.7,7.2,9.8c1.1,1.1,2.3,2.2,3.6,3.2c6.6,5.2,14.3,7.8,23.1,7.8s16.5-2.6,23.1-7.8l3.4-3.2
	c3.2-3.3,5.8-6.7,7.6-10.6c2.2-4.8,3.4-10.2,3.4-16c0-5.8-1.1-11.1-3.4-16C249,359.8,247.1,356.8,244.4,354L244.4,354z M242.1,353.2
	l0.8,1c1.8,1.9,3.4,4,4.7,6.2l1.2,2.2c0.6,1.1,1.1,2.3,1.5,3.4l0.7,1.8l0.6,2.3c0.7,2.9,1,5.8,1,8.9s-0.3,6.2-1.1,9.1l-0.7,2.2
	l-0.6,1.6c-0.4,1.2-1.1,2.5-1.6,3.7l-1,1.8c-1.5,2.6-3.4,5-5.6,7.3c-1.5,1.5-3,2.8-4.5,4l-4.7,2.9c-1.6,0.8-3.4,1.6-5.4,2.2
	l-2.3,0.7c-2.9,0.7-5.8,1-8.8,1c-3.2,0-6.2-0.4-9.1-1.1l-2.2-0.5c-1.8-0.5-3.6-1.2-5.2-2.2l-1.9-1.1c-0.8-0.5-1.8-1.1-2.5-1.8
	c-1.6-1.2-3.2-2.5-4.5-4c-2.2-2.2-4.1-4.5-5.6-7l-1.1-2.1l-1.2-2.6l-1-2.8l-0.7-2.5c-0.7-2.9-1-5.8-1-8.9s0.3-6.2,1.1-9.1
	c0.1-0.7,0.4-1.4,0.6-2.2c0.3-1,0.7-1.9,1.1-2.8c0.4-0.8,0.7-1.7,1.2-2.5c0.3-0.7,0.7-1.4,1.1-2.1c1.4-2.2,2.9-4.3,4.8-6.3l0.8-1
	c2.2-2.2,4.5-4.1,7-5.5c0.7-0.4,1.4-0.8,2.1-1.1c1.8-1,3.4-1.7,5.4-2.2l2.3-0.7c1.1-0.3,2.2-0.5,3.4-0.7l5.5-0.4l5.5,0.4l3.4,0.7
	h0.1l2.2,0.7c1.8,0.5,3.6,1.2,5.4,2.2l1.8,1h0.1C237.4,349,239.8,350.9,242.1,353.2L242.1,353.2z"
          />
          <path
            fill="#EBEBEB"
            d="M242.9,354l-0.8-1c-2.3-2.3-4.7-4.1-7.3-5.8h-0.1l-1.8-1l-1,1.6l-5,8.8c-0.8-0.4-1.8-0.8-2.6-1.1l2.6-9.8
	l0.6-1.8l-2.2-0.7h-0.1l-2.9,13.9l-3.7,13.6l-0.1,0.3l-0.6,0.7c0.6,0.1,1.1,0.3,1.6,0.7c1.5-2.9,3.2-5.8,4.8-8.7l2.8-4.7l7.4-11.8
	l0.4,2.3c1.8,1.2,3.6,2.6,5.2,4.3l0.4,0.4c1.6,1.6,3.2,3.4,4.5,5.4l2.3,0.3C246.4,358,244.7,356,242.9,354L242.9,354z"
          />
          <path
            fill="#EBEBEB"
            d="M245.3,359.8H245l-8.4,5.5l-1.8,1.2l-7.4,4.5l-4.8,4.5c4.4-2.5,8.9-5.1,13.2-7.7l1.8-1.1
	c2.9-1.8,5.6-3.6,8.5-5.5c0.6-0.4,1-0.7,1.5-1L245.3,359.8L245.3,359.8z"
          />
          <path
            fill="#656565"
            d="M245,359.8h0.3c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4c-1.6-1.6-3.4-3-5.2-4.3v0.1l-2.6,4.1
	c-1,1.4-1.8,2.9-2.8,4.4c1.4,0.8,2.6,1.9,3.9,3.2l1.1,1.2c0.7,0.8,1.4,1.6,2.1,2.5L245,359.8z"
          />
          <path
            fill="#EBEBEB"
            d="M248.9,362.4l-1.2-2.2c-0.6,0.3-1,0.7-1.5,1c-2.9,1.9-5.6,3.7-8.5,5.5l-1.8,1.1c-4.4,2.6-8.8,5.2-13.2,7.7
	l0.7,1.6c0.3-0.1,0.4-0.3,0.7-0.4l0.4-0.1c4.4-1.4,8.9-2.6,13.8-3.7l13.6-3l-1.4,2.1l0.1,1c0.3,1.9,0.4,3.9,0.4,5.9
	c0,2.1-0.1,4-0.4,5.9l-0.1,1.1l1.4,2.1c0.7-2.9,1.1-5.9,1.1-9.1s-0.3-6.1-1-8.9l-0.6-2.3l-1.6,0.5l-9.9,2.8l-1.1-2.6l8.8-5.1
	L248.9,362.4L248.9,362.4z"
          />
          <path
            fill="#EBEBEB"
            d="M250.5,365.9c-0.4-1.2-1-2.3-1.5-3.4l-1.5,0.8c0.8,1.5,1.5,3.2,2.1,5l1.6-0.5L250.5,365.9L250.5,365.9z
	 M250.2,372.2l1.4-2.1l-13.6,3c-4.7,1.1-9.4,2.3-13.8,3.7h0.4c0.6,0,1.1,0.1,1.5,0.5h3.2l0.6-0.1l0.4-0.1c2.6-1,5.2-1.8,7.8-2.5
	l2.1-0.5C243.6,373.3,246.8,372.6,250.2,372.2L250.2,372.2z"
          />
          <path
            fill="#656565"
            d="M250.4,372.1c-3.4,0.5-6.7,1.2-9.9,1.9c0.3,1.6,0.4,3.2,0.4,5c0,1.8-0.1,3.4-0.6,5.1
	c3.2,0.8,6.5,1.4,9.9,1.8l0.1,0.1l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9c0-2.1-0.1-4-0.4-5.9L250.4,372.1L250.4,372.1z"
          />
          <path
            fill="#EBEBEB"
            d="M250.2,386c-3.4-0.5-6.7-1.2-9.9-1.9l-2.1-0.5c-2.8-0.7-5.2-1.5-7.8-2.5l-0.4-0.1l-0.7-0.1H226
	c-0.4,0.3-0.8,0.6-1.4,0.6h-0.6c4.4,1.4,9.1,2.6,13.8,3.7l13.8,3L250.2,386L250.2,386z"
          />
          <path
            fill="#EBEBEB"
            d="M251.1,390.2l0.7-2.2l-13.8-3c-4.8-1.1-9.4-2.5-13.8-3.7l-0.3-0.1l-0.7-0.5c-0.1,0.5-0.4,1.1-0.7,1.6
	c4.5,2.5,8.9,5,13.3,7.6l1.8,1.1c2.9,1.8,5.8,3.6,8.7,5.4l1.5,1l1-1.8l-1.5-0.8l-8.8-5l1.1-2.6l9.8,2.6L251.1,390.2L251.1,390.2z"
          />
          <path
            fill="#EBEBEB"
            d="M250.5,391.9l0.6-1.6l-1.8-0.5c-0.6,1.7-1.2,3.3-2.1,5l1.5,0.8C249.4,394.3,250,393.1,250.5,391.9
	L250.5,391.9z"
          />
          <path
            fill="#656565"
            d="M249.4,368.2c-0.6-1.8-1.2-3.4-2.1-5l-8.8,5.1l1.1,2.6L249.4,368.2L249.4,368.2z"
          />
          <path
            fill="#EBEBEB"
            d="M216.4,342.5l-5.5,0.4c-1.2,0.1-2.3,0.4-3.4,0.7l-2.3,0.7l0.6,1.8l2.8,9.8c-1,0.3-1.8,0.7-2.6,1.1l-5.1-8.8
	l-1-1.5c-0.7,0.3-1.4,0.7-2.1,1.1c-2.5,1.5-4.8,3.4-7,5.5l-0.8,1c-1.9,2.1-3.4,4.1-4.8,6.3l2.3-0.4c0.8-1.1,1.6-2.3,2.6-3.4l2.3-2.5
	c1.5-1.5,3.3-2.9,5-4.1l0.4-2.3l10.5,16.4c1.6,2.9,3.3,5.8,5,8.7c0.6-0.3,1.1-0.6,1.8-0.7c-0.1-0.3-0.4-0.4-0.4-0.7l-0.1-0.4
	l-4.4-16.9c-0.7-2.9-1.4-5.8-1.9-8.8l-0.3-1.8l2.1,1.4c2.2-0.4,4.5-0.7,6.9-0.7c2.5,0,4.8,0.3,7,0.7l1.9-1.5l-3.4-0.7L216.4,342.5
	L216.4,342.5z"
          />
          <path
            fill="#EBEBEB"
            d="M199.7,346.5l1,1.5c1.6-0.8,3.3-1.5,5-2.1l-0.6-1.8C203.2,344.8,201.4,345.5,199.7,346.5L199.7,346.5z"
          />
          <path
            fill="#656565"
            d="M197.3,350.2c-1.6,1.1-3.4,2.3-5,4l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4h0.1l8.5,5.4l1-1.4
	c0.7-0.8,1.4-1.6,2.2-2.5c1.1-1.1,2.5-2.2,3.7-3.2L197.3,350.2L197.3,350.2z"
          />
          <path
            fill="#EBEBEB"
            d="M197.3,350.1l5.5,8.5l1.2,1.9l4.4,7.4l4.5,5l-5-8.7l-10.5-16.4L197.3,350.1L197.3,350.1z M187.2,360.1
	l-2.3,0.4l12,7.6c4.4,2.6,8.8,5.1,13.3,7.6l-5-4.5c-0.3-0.1-0.4-0.3-0.7-0.3l-2.5-1.5l-5.8-3.4l-0.6-0.3L187.2,360.1z"
          />
          <path
            fill="#EBEBEB"
            d="M184.9,360.4c-0.4,0.7-0.8,1.4-1.1,2.1l1.5,0.8l8.8,5c-0.4,0.8-0.8,1.6-1.1,2.6l-9.8-2.6l-1.8-0.5
	c-0.3,0.7-0.4,1.4-0.6,2.2l1.7,0.3h0.1c3.4,0.7,6.7,1.4,9.9,2.2l2.1,0.6c4.7,1.1,9.2,2.3,13.6,3.7h0.1l0.3,0.1
	c0.3,0.1,0.6,0.3,0.8,0.5c0.1-0.5,0.3-1.1,0.7-1.6c-4.5-2.5-8.9-5-13.3-7.6L184.9,360.4L184.9,360.4z"
          />
          <path
            fill="#EBEBEB"
            d="M185.4,363.3l-1.5-0.8c-0.4,0.8-0.8,1.6-1.2,2.5c-0.4,1-0.7,1.9-1.1,2.8l1.8,0.5
	C183.8,366.6,184.5,364.9,185.4,363.3L185.4,363.3z M196.9,364.1l-1,1.4l0.6,0.3c0.4-0.5,0.8-1.1,1.4-1.6c0.8-0.8,1.6-1.8,2.8-2.5
	l0.8,1c0.8-0.8,1.8-1.5,2.8-2.2l-1.2-1.9c-1.2,1-2.6,1.9-3.7,3.2C198.2,362.4,197.5,363.3,196.9,364.1L196.9,364.1z"
          />
          <path
            fill="#EBEBEB"
            d="M197.7,364.1c-0.6,0.5-1,1.1-1.4,1.6l5.8,3.4h2.5c-0.7-2.1-1.5-3.7-2.2-5.1l-1.1-1.5l-0.8-1
	C199.5,362.3,198.5,363.1,197.7,364.1L197.7,364.1z M179.9,378.9c0,3.2,0.3,6.1,1,8.9l0.7,2.5l1.8-0.6l9.8-2.8
	c0.3,0.8,0.7,1.8,1.1,2.6l-8.8,5.1l-1.5,1l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7c1.5,1.5,3,2.8,4.5,4c0.8,0.5,1.6,1.2,2.5,1.8l-0.3-2.3
	c-1.9-1.2-3.9-2.9-5.6-4.7c-1.8-1.6-3.2-3.6-4.5-5.5l-2.3-0.4c0.6-0.3,1-0.7,1.5-1c2.8-1.9,5.6-3.7,8.5-5.5l1.8-1.1
	c4.3-2.8,8.7-5.2,13.2-7.7l0.1-0.1c-0.3-0.5-0.6-1.1-0.7-1.8l-1.1,0.6l-7,1.9l-8.8,2.2l-9.9,2.2h-0.1l-1.7,0.3l1.4-1.9
	c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2l-1.5-1.9C180.3,372.7,179.9,375.8,179.9,378.9L179.9,378.9z"
          />
          <path
            fill="#EBEBEB"
            d="M197.8,375.4l-3.3-1c-0.3,1.5-0.4,3-0.4,4.5s0.1,2.9,0.4,4.3l3-0.8l-0.6-0.5c-0.7-0.8-1.1-1.8-1.1-2.9
	s0.4-2.1,1.1-2.9C197.1,375.8,197.4,375.5,197.8,375.4L197.8,375.4z"
          />
          <path
            fill="#EBEBEB"
            d="M194.5,374.4l3.3,1l3.6,1.2l1.8,0.6h3.3c0.4-0.3,0.8-0.5,1.5-0.5h0.6c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.5
	c-3.3-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3l1.5,2.1v0.1h0.1c3.3,0.4,6.6,1.1,9.8,1.8L194.5,374.4L194.5,374.4z"
          />
          <path
            fill="#EBEBEB"
            d="M197.8,375.4c-0.3,0.1-0.7,0.4-1,0.8c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.2,1.1,2.9l0.6,0.5l4-1.2v-4.7
	L197.8,375.4L197.8,375.4z M192.3,373.9c-0.3,1.6-0.6,3.4-0.6,5.1s0.1,3.3,0.4,4.8l2.1-0.5c-0.3-1.4-0.4-2.8-0.4-4.3
	c0-1.5,0.1-3.2,0.4-4.5L192.3,373.9z"
          />
          <path
            fill="#656565"
            d="M191.9,378.9c0-1.8,0.1-3.4,0.6-5.1c-3.2-0.8-6.5-1.4-9.8-1.8h-0.1v-0.1c-0.4,2.3-0.7,4.7-0.7,7.2
	c0,2.3,0.3,4.7,0.7,6.9l0.1-0.1h0.1c3.3-0.4,6.5-1.1,9.6-1.8C192,382.2,191.9,380.6,191.9,378.9L191.9,378.9z M193.1,371
	c0.3-1,0.7-1.8,1.1-2.6l-8.8-5c-0.8,1.5-1.5,3.2-2.1,5L193.1,371L193.1,371z"
          />
          <path
            fill="#EBEBEB"
            d="M182.4,385.7l-0.1,0.1l-1.4,1.9l1.6-0.3h0.1l9.9-2.2l8.8-2.2l7-1.9H208c-0.6,0-1.1-0.1-1.5-0.5h-3.2
	l-0.4,0.1c0,0-0.1,0-0.1,0.1h-0.6l-0.8,0.3l-4,1.2l-3,0.8l-2.1,0.5c-3.2,0.8-6.5,1.4-9.6,1.8h-0.3L182.4,385.7L182.4,385.7z
	 M183.4,389.7l-1.8,0.5l1,2.8l1.2,2.6l1.5-1C184.6,393.1,183.9,391.5,183.4,389.7L183.4,389.7z M196.9,389.9l-1.8,1.1
	c-2.9,1.8-5.8,3.6-8.5,5.5c-0.4,0.3-1,0.5-1.5,1l2.3,0.4h0.1l8.4-5.5l0.7-0.4l1.1-0.7c1.6-1.1,3.3-2.1,5-3c0.7-0.4,1.2-0.8,1.9-1.1
	l0.7-0.4l5-4.5C205.5,384.7,201.1,387.3,196.9,389.9L196.9,389.9z"
          />
          <path
            fill="#656565"
            d="M194.2,389.7c-0.4-0.8-0.8-1.8-1.1-2.6l-9.8,2.8c0.6,1.6,1.2,3.3,2.1,5L194.2,389.7L194.2,389.7z"
          />
          <path
            fill="#EBEBEB"
            d="M232.1,348l1-1.6c-1.8-0.8-3.4-1.6-5.4-2.2l-0.6,1.8C228.8,346.5,230.4,347.2,232.1,348L232.1,348z
	 M232.6,354l2.6-4.1v-0.1l-0.4-2.3l-7.4,11.8l-2.8,4.7c-1.7,2.9-3.3,5.8-4.8,8.7l4.5-5l4.4-7.6l1.1-1.8
	C230.7,356.9,231.7,355.6,232.6,354L232.6,354z"
          />
          <path
            fill="#656565"
            d="M221.6,354c0.7-2.9,1.2-5.9,1.6-9.1h0.1c-2.2-0.4-4.5-0.7-7-0.7c-2.3,0-4.7,0.3-6.9,0.7l0.1,0.1
	c0.4,3,1,6.1,1.8,9.1l0.1,0.8l4.8-0.4c1.8,0,3.4,0.1,5.1,0.4L221.6,354L221.6,354z"
          />
          <path
            fill="#EBEBEB"
            d="M223.3,345.1c-0.4,3-1,6.1-1.6,9.1l-0.3,0.8l-0.6,2.1c-0.7,2.3-1.4,4.8-2.2,7l-0.6,1.8v3.3
	c0.4,0.4,0.6,0.8,0.6,1.4v0.5l3.7-13.6l2.9-13.9L223.3,345.1L223.3,345.1z M229.9,358.4l-1.1,1.8c1.2,0.8,2.3,1.8,3.4,2.9l1,1
	c0.7,0.8,1.4,1.5,1.9,2.5l1.8-1.2c-0.6-0.8-1.2-1.6-2.1-2.5l-1.1-1.2C232.3,360.4,231.1,359.4,229.9,358.4L229.9,358.4z"
          />
          <path
            fill="#656565"
            d="M224.5,355.8c0.8,0.3,1.8,0.7,2.6,1.1l5-8.8c-1.6-0.8-3.2-1.5-5-2.1L224.5,355.8z"
          />
          <path
            fill="#EBEBEB"
            d="M233,364.1l-1-1c-1.1-1.1-2.2-2.1-3.4-2.9l-4.4,7.6l3.2,3.2l7.4-4.5C234.4,365.6,233.7,364.8,233,364.1
	L233,364.1z M216.2,354.5l-4.8,0.4l0.6,2.1l4.4-0.4l4.5,0.4l0.6-2.1C219.8,354.6,218,354.5,216.2,354.5z"
          />
          <path
            fill="#EBEBEB"
            d="M211.4,354.9l-0.1-0.8c-0.7-2.9-1.2-5.9-1.8-9.1l-0.1-0.1l-2.1-1.4l0.3,1.8c0.6,3,1.2,5.9,1.9,8.8l4.4,16.9
	v-0.4c0-0.5,0.1-1.1,0.6-1.5v-3.3l-0.6-1.8c-0.8-2.3-1.5-4.7-2.1-7L211.4,354.9L211.4,354.9z M201.3,362.6l1.1,1.5
	c0.8,1.4,1.5,3.2,2.2,5.1v1.5c0.1,0.1,0.4,0.3,0.7,0.3l3.2-3.2l-4.4-7.4C203,361.1,202.1,361.7,201.3,362.6L201.3,362.6z"
          />
          <path
            fill="#656565"
            d="M205.7,356.8c0.8-0.4,1.8-0.8,2.6-1.1l-2.8-9.8c-1.8,0.5-3.4,1.2-5,2.1L205.7,356.8L205.7,356.8z"
          />
          <path
            fill="#EBEBEB"
            d="M218.7,364.1c0.8-2.3,1.5-4.7,2.2-7l-4.5-0.4l-4.4,0.4c0.6,2.3,1.2,4.8,2.1,7l0.6,1.8h3.6L218.7,364.1
	L218.7,364.1z"
          />
          <path
            fill="#EBEBEB"
            d="M218.2,365.7h-3.6v3.2l0.1-0.1c0.4-0.4,1-0.7,1.5-0.7c0.7,0,1.2,0.3,1.6,0.7l0.1,0.1L218.2,365.7
	L218.2,365.7z"
          />
          <path
            fill="#EBEBEB"
            d="M218.2,369l-0.1-0.1c-0.4-0.4-1-0.7-1.7-0.7s-1.1,0.3-1.5,0.7l-0.1,0.1c-0.4,0.4-0.6,1-0.6,1.5v0.4l0.1,0.4
	c0.1,0.3,0.3,0.5,0.4,0.7c0.4,0.4,1,0.7,1.5,0.7c0.7,0,1.2-0.3,1.7-0.7l0.6-0.7l0.1-0.3v-0.5C218.7,370,218.4,369.5,218.2,369
	L218.2,369z M216.4,369.3c0.3,0,0.6,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-0.8,0.4s-0.7-0.1-0.8-0.4
	c-0.3-0.3-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8C215.7,369.3,216,369.3,216.4,369.3L216.4,369.3z"
          />
          <path
            fill="#EBEBEB"
            d="M217.2,369.6c-0.3-0.3-0.6-0.4-0.8-0.4s-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1
	c0.3,0.3,0.6,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4s0.4-0.5,0.4-1C217.6,370.1,217.5,369.9,217.2,369.6L217.2,369.6z M218,372.1
	c-0.4,0.4-1,0.7-1.7,0.7s-1.1-0.3-1.5-0.7c-0.7,0.1-1.2,0.3-1.8,0.7c-0.6,0.3-1.1,0.7-1.6,1.2c-0.4,0.5-0.8,1-1.2,1.5
	c-0.3,0.5-0.6,1.1-0.7,1.6c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.5v0.1c0.1,0.5,0.3,1.1,0.7,1.8c0.3,0.5,0.7,1.1,1.2,1.6
	s1,0.8,1.5,1.2c0.6,0.3,1.1,0.5,1.6,0.7v-0.1c0.4-0.4,1-0.7,1.6-0.7c0.6,0,1.1,0.3,1.6,0.7l0.1,0.1c0.6-0.1,1.1-0.4,1.8-0.7
	c0.6-0.3,1.1-0.8,1.6-1.2c0.4-0.4,0.8-1,1.2-1.5c0.3-0.5,0.6-1.1,0.7-1.6h-0.1c-0.4-0.4-0.7-1-0.7-1.6s0.3-1.2,0.7-1.6l0.1-0.1
	l-0.7-1.6c-0.3-0.5-0.7-1.1-1.2-1.6c-0.4-0.4-1-0.8-1.5-1.1C219.1,372.5,218.6,372.2,218,372.1L218,372.1z"
          />
          <path
            fill="#EBEBEB"
            d="M211.4,374c0.6-0.5,1.1-0.8,1.6-1.2l-4.5-5l-3.2,3.2l5,4.5C210.5,375,210.9,374.4,211.4,374L211.4,374z
	 M202.1,369.2l2.5,1.5v-1.5 M203.2,377l-1.8-0.5v4.7l0.8-0.3l0.4-0.1l0.1-0.1l0.4-0.1V377L203.2,377z M208.4,376.6h-0.6
	c-0.6,0-1.1,0.1-1.5,0.6l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.5l0.1,0.1c0.4,0.4,1,0.5,1.5,0.5h0.4l1.1-0.5v-0.1
	c0.4-0.4,0.7-1,0.7-1.5c0-0.7-0.3-1.2-0.7-1.6c-0.3-0.3-0.6-0.4-0.8-0.5L208.4,376.6L208.4,376.6z M207,379.8
	c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,0.8-0.4c0.3,0,0.6,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1
	c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4C207.6,380.2,207.3,380,207,379.8L207,379.8z"
          />
          <path
            fill="#EBEBEB"
            d="M206.6,378.8c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4s0.4-0.5,0.4-0.8
	c0-0.4-0.1-0.7-0.4-1s-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-0.8,0.4C206.8,378.2,206.6,378.5,206.6,378.8L206.6,378.8z M206.3,377.2
	l0.1-0.1h-3.3v3.6h3.2l-0.1-0.1c-0.4-0.4-0.7-1-0.7-1.5C205.7,378.3,205.8,377.7,206.3,377.2L206.3,377.2z M210.2,382.2l-5,4.5
	l3.2,3.2l4.5-5c-0.6-0.3-1.1-0.7-1.5-1.2C210.9,383.3,210.5,382.8,210.2,382.2L210.2,382.2z M204.6,387.2c-0.6,0.4-1.2,0.7-1.9,1.1
	h1.9L204.6,387.2L204.6,387.2z"
          />
          <path
            fill="#EBEBEB"
            d="M205.1,386.8l-0.7,0.4v1.1c-1.1,2.9-2.2,5.2-3.4,6.9l2.9,2.3l4.4-7.6L205.1,386.8L205.1,386.8z"
          />
          <path
            fill="#EBEBEB"
            d="M204.6,388.3h-1.9c-1.6,1-3.3,1.9-4.9,3l-1.1,0.7c1.1,1.4,2.3,2.8,3.9,3.9l0.6-0.7
	C202.4,393.5,203.5,391.3,204.6,388.3L204.6,388.3z M219.7,385.1c2.5,4.5,5.1,8.9,7.7,13.3l1.1,1.8c1.8,2.9,3.6,5.6,5.5,8.4v0.1
	l1,1.4l0.4-2.2v-0.3l-5.5-8.4l-1.1-1.8l-4.5-7.6L219.7,385.1L219.7,385.1z"
          />
          <path
            fill="#EBEBEB"
            d="M219.7,385.1c-0.6,0.3-1.1,0.5-1.8,0.7c0.1,0.1,0.3,0.4,0.4,0.7l0.1,0.4c1.4,4.4,2.6,8.9,3.7,13.8l3,13.6
	l-1.9-1.2c-2.2,0.4-4.5,0.6-6.9,0.6c-2.5,0-4.8-0.3-7-0.7l-2.1,1.4c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.3,8.8-1l2.3-0.7l-0.4-1.8
	l-2.8-9.8c0.8-0.3,1.8-0.7,2.6-1.1l5.1,8.8l0.8,1.5l4.7-2.9c1.5-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3l-2.3,0.3
	c-1.2,1.9-2.9,3.9-4.5,5.6c-1.8,1.8-3.6,3.2-5.5,4.5l-0.4,2.2l-1-1.4v-0.1c-1.9-2.8-3.7-5.5-5.5-8.4l-1.1-1.8
	C224.6,394.1,222.2,389.7,219.7,385.1L219.7,385.1z"
          />
          <path
            fill="#EBEBEB"
            d="M217.9,385.8c-0.6-0.5-1.1-0.8-1.6-0.8c-0.7,0-1.2,0.3-1.6,0.7v0.1l-0.6,0.7l-0.1,0.3v0.5
	c0,0.6,0.1,1.1,0.6,1.5l0.1,0.1c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.1-0.3,1.6-0.7l0.1-0.1c0.4-0.4,0.6-1,0.6-1.5v-0.4l-0.1-0.4
	C218.2,386.2,218,386,217.9,385.8L217.9,385.8z M215.3,388.2c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4
	c0.3,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4C216,388.6,215.6,388.4,215.3,388.2
	L215.3,388.2z"
          />
          <path
            fill="#EBEBEB"
            d="M215,387.3c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,1,0.4c0.3,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8
	c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4C215.1,386.6,215,386.9,215,387.3L215,387.3z M214.6,385.8
	c-0.6-0.1-1.1-0.4-1.6-0.7c-2.5,4.5-5,8.9-7.6,13.3l-7.6,12l1.9,1.1l0.8-1.5l5-8.8c0.8,0.4,1.6,0.8,2.6,1.1l-2.6,9.8l-0.6,1.8
	l2.2,0.5l3-13.8c1.1-4.8,2.5-9.4,3.7-13.8l0.1-0.3L214.6,385.8L214.6,385.8z M214.6,389l-0.1-0.1v3.3h3.6V389l-0.1,0.1
	c-0.4,0.4-1,0.7-1.6,0.7C215.6,389.5,215,389.4,214.6,389L214.6,389z"
          />
          <path
            fill="#EBEBEB"
            d="M214.5,388.7c-0.3-0.4-0.6-0.8-0.6-1.5v-0.5c-1.4,4.4-2.6,8.9-3.7,13.8l-3,13.8l2.1-1.4l0.1-0.1l0.7-4.1
	l1.2-5.6l0.6-2.1c0.7-2.6,1.5-5.2,2.3-7.8l0.1-0.4l0.1-0.5L214.5,388.7L214.5,388.7z M218.4,387.3c0,0.5-0.1,1.1-0.6,1.5v3.2l0.3,1
	c1,2.6,1.8,5.2,2.5,7.8l0.6,2.1c0.4,1.9,0.8,3.7,1.1,5.6l0.7,4.1l0.1,0.1l1.9,1.2l-3-13.6c-1.1-4.7-2.3-9.4-3.7-13.8L218.4,387.3
	L218.4,387.3z M212.9,385.1l-4.5,5l-4.4,7.6c-0.4,0.5-0.8,1.2-1.1,1.8l-5.4,8.4l0.1,0.1l0.3,2.3l7.6-12
	C208,394.1,210.5,389.7,212.9,385.1L212.9,385.1z M238.7,378.9c0-1.5-0.1-3-0.4-4.4c-2.8,0.7-5.4,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1v3.6
	l0.7,0.1l0.4,0.1c2.6,1,5.2,1.8,7.8,2.5C238.5,382,238.7,380.5,238.7,378.9L238.7,378.9z"
          />
          <path
            fill="#EBEBEB"
            d="M238.3,374.5c0.3,1.4,0.4,2.9,0.4,4.4c0,1.5-0.1,3-0.4,4.5l2.1,0.5c0.3-1.6,0.6-3.3,0.6-5.1
	c0-1.6-0.1-3.3-0.4-5L238.3,374.5L238.3,374.5z M224.8,376.7h-0.4l-0.4,0.1c-0.3,0.1-0.4,0.1-0.7,0.4l-0.1,0.1
	c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.6h0.1l0.7,0.5l0.3,0.1h0.6c0.6,0,1-0.1,1.4-0.5l0.1-0.1c0.4-0.4,0.7-1,0.7-1.6
	s-0.3-1.2-0.7-1.6l-0.1-0.1C225.7,376.9,225.3,376.7,224.8,376.7L224.8,376.7z M225.6,378.1c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1
	c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4s-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,1-0.4
	C225.1,377.7,225.3,377.8,225.6,378.1L225.6,378.1z"
          />
          <path
            fill="#EBEBEB"
            d="M226,378.9c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8
	s0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4s0.7-0.1,0.8-0.4C225.9,379.6,226,379.2,226,378.9L226,378.9z M221.3,374
	c0.6,0.5,1,1.1,1.2,1.6l4.8-4.5l-3.2-3.2l-4.5,5C220.4,373,220.8,373.4,221.3,374z M229.5,377.3h-3.2l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6
	c0,0.5-0.3,1.1-0.7,1.6l-0.1,0.1h3.3L229.5,377.3L229.5,377.3z M222.4,382.4l5,4.5c2.5,1.4,5.1,2.9,7.6,4.4l1.8,1.1l8.4,5.4h0.1
	l2.3-0.3l-1.5-1c-2.9-1.9-5.8-3.7-8.7-5.4l-1.8-1.1C231.5,387.3,227,384.7,222.4,382.4L222.4,382.4z"
          />
          <path
            fill="#EBEBEB"
            d="M222.4,382.4c-0.3,0.5-0.7,1.1-1.2,1.5c-0.6,0.5-1.1,1-1.6,1.2l4.5,5l3.2-3.2L222.4,382.4L222.4,382.4z
	 M227.4,386.9l-3.2,3.2l4.5,7.6c1.2-0.8,2.3-1.8,3.3-2.9c1.1-1.1,2.1-2.2,2.9-3.4C232.5,389.8,230,388.3,227.4,386.9L227.4,386.9z"
          />
          <path
            fill="#656565"
            d="M239.5,387.1l-1.1,2.6l8.8,5c0.8-1.6,1.5-3.3,2.1-5L239.5,387.1L239.5,387.1z"
          />
          <path
            fill="#EBEBEB"
            d="M233.6,396.3c1.2-1.2,2.2-2.5,3.2-3.9l-1.8-1.1c-0.8,1.2-1.8,2.3-2.9,3.4s-2.2,1.9-3.3,2.9l1.1,1.8
	C231.2,398.5,232.5,397.4,233.6,396.3L233.6,396.3z"
          />
          <path
            fill="#656565"
            d="M236.7,392.4c-1,1.4-1.9,2.6-3.2,3.9c-1.1,1.1-2.3,2.2-3.7,3.2l5.5,8.4v0.3c1.9-1.2,3.7-2.8,5.5-4.5
	c1.8-1.8,3.3-3.7,4.5-5.6h-0.1L236.7,392.4L236.7,392.4z M227,401.1c-0.8,0.4-1.6,0.8-2.6,1.1l2.8,9.8c1.7-0.5,3.3-1.2,5-1.9
	L227,401.1L227,401.1z"
          />
          <path
            fill="#EBEBEB"
            d="M227.1,412l0.4,1.8c1.8-0.6,3.6-1.2,5.4-2.2l-0.8-1.5C230.4,410.7,228.8,411.4,227.1,412L227.1,412z"
          />
          <path
            fill="#656565"
            d="M223.1,412.9l-0.1-0.1l-0.7-4.1c-0.3-1.9-0.7-3.9-1.1-5.6l-5,0.4l-5.1-0.4l-1.2,5.6l-0.7,4.1l-0.1,0.1
	c2.2,0.4,4.5,0.7,7,0.7C218.7,413.6,221.1,413.3,223.1,412.9L223.1,412.9z"
          />
          <path
            fill="#EBEBEB"
            d="M217.9,392h-3.6l-0.1,0.5L214,393c-1,2.6-1.6,5.2-2.3,7.8l4.5,0.4l4.4-0.4c-0.7-2.8-1.5-5.2-2.5-7.8
	L217.9,392L217.9,392z"
          />
          <path
            fill="#EBEBEB"
            d="M211.7,400.8l-0.4,2.1l5,0.5l5-0.4l-0.6-2.1l-4.3,0.3 M202.8,399.4c0.4-0.7,0.7-1.2,1.1-1.8l-2.9-2.3
	l-0.6,0.7c-1.5-1.1-2.9-2.5-3.9-3.9l-0.7,0.4c0.8,1.4,1.9,2.6,3.2,3.7C200.3,397.5,201.5,398.5,202.8,399.4L202.8,399.4z"
          />
          <path
            fill="#656565"
            d="M208.1,402.2c-0.8-0.3-1.8-0.7-2.6-1.1l-5,8.8c1.5,0.8,3.2,1.5,5,1.9L208.1,402.2L208.1,402.2z"
          />
          <path
            fill="#EBEBEB"
            d="M205.1,413.6l0.6-1.8c-1.6-0.5-3.3-1.2-5-1.9l-0.8,1.5C201.4,412.4,203.2,413.1,205.1,413.6L205.1,413.6z"
          />
          <path
            fill="#656565"
            d="M199.1,396.3c-1.2-1.2-2.2-2.5-3.2-3.7l-8.4,5.5h-0.1c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.6,3.3,5.6,4.7
	l-0.1-0.1l5.4-8.4C201.5,398.5,200.3,397.5,199.1,396.3L199.1,396.3z"
          />
          <path fill="#EBEBEB" d="M197.7,410.3h0.1" />
          <path
            fill="#FFFFFF"
            d="M275.7,389.4l0.1,2.1l6.2-0.1l-0.5-10.2c-0.8-19.1-7.6-35.2-20.1-48.4C248.9,319.6,233.7,313,216,313
	c-10.6,0-20.2,2.3-29,7l4.4,4.4c7.4-3.4,15.7-5.2,24.5-5.2c16.1,0,29.7,5.8,41.1,17.1c11.4,11.4,17.5,25.4,18.3,42.1L275.7,389.4
	L275.7,389.4z M290.8,370.1l0.3,2.3c0.1,2.2,0.4,3.9,0.8,5.2c0.8,2.2,2.8,3.2,6.1,3.2l172-4.5l-2.8-18.2c-33.3,4.1-65.8,7-97.5,8.5
	L290.8,370.1L290.8,370.1z M284.8,367.7c0.4,1.6,2.5,2.5,6.1,2.6l-0.8-7.7C285.7,363.1,283.9,364.9,284.8,367.7z"
          />
          <path
            fill="#FFFFFF"
            d="M290,362.6l0.8,7.7l78.8-3.4c31.8-1.5,64.2-4.4,97.5-8.5l0.4-7.6L290,362.6L290,362.6z M285.3,311.7
	c1.2,17.3,2.8,34.3,4.5,50.9l177.9-12c-0.4-22,0-44,1.1-65.9C406.1,293.5,344.9,302.5,285.3,311.7L285.3,311.7z M171.5,306.4
	c-1.9,1.4-3.7,2.9-5.5,4.5c-12.4,11.1-19.5,24.2-21.7,39.3c-0.3,1.4-0.4,2.8-0.6,4.1c2.5-14.3,9.5-26.8,21.2-37.4
	c3.4-3.2,6.9-5.8,10.7-8.1L171.5,306.4L171.5,306.4z M166.7,301.2l0.8-0.4l-13.5,4.3h-0.1c-12.5,3.9-24.5,7.2-36,10l-0.3,0.1
	l-19.1,4.4l-2.3-0.1l-1-0.5l-0.6-0.8l-1-2.9c-0.6-1.9-0.3-3.6,0.8-5l0.1-0.1l5.4-3.3l-3.4-2.2c-0.7,0.4-1.4,0.8-1.9,1.4
	c-2.1,1.7-3.9,3.3-5.4,5.2c-1.1,1.5-1.5,3.2-0.8,5.2l1,2.9c1.2,2.6,4.3,3.6,8.9,2.9l19.8-4.4c15.5-3.9,30.7-8.9,45.4-15L166.7,301.2
	L166.7,301.2z"
          />
          <path
            fill="#FFFFFF"
            d="M166.7,301.2l-3.4,1.5c-14.6,6.1-29.7,11.1-45.4,15l-19.8,4.4c-4.7,0.8-7.6-0.1-8.9-2.9l-1-2.9
	c-0.6-2.1-0.3-3.9,0.8-5.2c1.7-1.8,3.4-3.6,5.4-5.2c0.7-0.4,1.2-1,1.9-1.4l-5.2-2.5c-1.4,1.1-2.8,2.2-3.9,3.6
	c-2.3,2.6-4.5,5.5-6.6,8.5c0.6,0.4,1.1,1.2,1.5,2.3l5.9,18.8c0.3,0.8,0.3,1.8,0.3,2.8l-0.1,1.2l-0.3,1.1v0.1l-2.3,5l-0.3,0.4
	c-1.2,2.1-2.8,3.6-4.8,4.5h-0.3v8l5.8-1.9c0.8-0.1,1.2,0.1,1,1.1l-1.2,6.7l-0.3,1.1l-0.6,3.4l-0.3,1.2l-1.5,9.5c1-0.7,1.9-1.1,3-1.2
	l1.9-0.3l42.6-6.3c2.9-0.4,5.5-0.7,7.8-0.5h11.7l-67.3,12.4H78v0.8c0,0.8,0.3,1.4,0.8,1.6c0.7,0.4,1.5,0.8,2.6,1.4l3.2,1.5
	c1.4,0.5,2.9,1,4.8,1.1h60.5c0.4-2.9,0.6-6.2,0.7-9.8c0-2.9,0.3-5.6,0.6-8.4c1.8-15.1,8.1-28.2,18.7-39.3
	c5.1-5.2,10.6-9.5,16.5-12.7l-11.3-11.1c-3.7,2.3-7.3,5.1-10.7,8.1c-11.7,10.7-18.7,23.1-21.2,37.4c0.1-1.4,0.3-2.8,0.6-4.1
	c2.2-15.1,9.4-28.2,21.7-39.3c1.8-1.7,3.6-3.2,5.5-4.5L166.7,301.2L166.7,301.2z M100.4,357.5c4.1-2.5,9.2-4.3,15.5-5.5l19.7-4.3
	c2.5-0.5,4.1-0.4,5.1,0.4c1.1,0.8,1.2,2.3,0.6,4.7c-1.2,5.2-3,10-5.5,14.7c-1,1.8-2.6,3-5,3.6l-35.2,5.1l-4.5,0.3l-0.3-0.1
	c-1.2-0.3-1.8-1-1.7-2.1l0.1-0.3c1.1-2.8,2.3-5.4,3.8-7.8l3.2-4.8C97.5,359.5,99,358.3,100.4,357.5L100.4,357.5z M94.8,354.7
	c4.5-3.4,12.7-6.5,24.3-9.2c10.2-2.3,18.2-3.2,23.7-2.3L94.8,354.7z"
          />
          <path
            fill="#656565"
            d="M100.4,357.5c-1.5,0.8-2.9,2.1-4.3,3.7L93,366c-1.5,2.5-2.8,5.1-3.9,7.8l-0.1,0.3c-0.1,1.1,0.4,1.8,1.7,2.1
	l0.3,0.1l4.5-0.3l35.2-5.1c2.3-0.5,4-1.8,5-3.6c2.3-4.7,4.3-9.5,5.5-14.7c0.7-2.2,0.4-3.9-0.6-4.7c-1-0.8-2.6-1-5.1-0.4L116,352
	C109.8,353.2,104.5,355.1,100.4,357.5L100.4,357.5z M97.1,362.3c1.2-1.5,2.5-2.6,3.9-3.4c4-2.3,9.1-4.1,15.1-5.4l21.6-4.5
	c0.8-0.1,1.5,0,2.1,0.4c0.6,0.5,0.6,1.5,0.1,3v0.1c-1.2,5.1-3,9.9-5.4,14.4c-0.7,1.5-2.1,2.3-3.9,2.8h-0.1l-35.4,5.1l-3.9,0.3h-0.1
	c-0.4-0.1-0.7-0.3-0.7-0.6c1.2-3,2.6-5.8,4.3-8.4L97.1,362.3L97.1,362.3z"
          />
          <path
            fill="#656565"
            d="M97.1,364.9l-0.6,0.8c-0.7,1-1.4,1.9-1.9,3l-0.1,0.1l-1.4,2.5c-0.7,1.1-1.2,2.3-1.8,3.6l3.9-0.3l35.4-5.1
	c-1.1,0-2.1-0.3-3-0.7c0,0.3-0.1,0.5-0.6,0.5h-0.7c-0.4,0-0.6-0.1-0.6-0.5v-13.3c0-0.3,0.1-0.5,0.6-0.5h0.7c0.3,0,0.6,0.1,0.6,0.5
	c0.5-0.7,1.2-1.4,2.1-2.1l-13.3,2.8c-6.1,1.2-11.1,3-15.1,5.2c-0.8,0.4-1.5,1-2.2,1.5C98.2,363.7,97.7,364.2,97.1,364.9L97.1,364.9z
	 M99.2,364.5c0.6-0.5,1-1.1,1.5-1.5l22.4-3v2.3l-24.3,3.3L99.2,364.5L99.2,364.5z M95.1,371.1c0.4-1,0.8-1.8,1.2-2.5l26.5-3.6v2.2
	L95.1,371.1L95.1,371.1z"
          />
          <path
            fill="#656565"
            d="M100.6,363l-1.5,1.5l-0.6,1l24.3-3.3v-2.3L100.6,363L100.6,363z M96.4,368.6c-0.4,0.8-0.8,1.5-1.2,2.5
	l27.8-3.7v-2.2L96.4,368.6L96.4,368.6z"
          />
          <path
            fill="#656565"
            d="M96.6,365.7l0.6-0.8c0.6-0.7,1.1-1.2,1.7-1.8c0.7-0.7,1.5-1.2,2.2-1.5c4-2.3,9.1-4.1,15.1-5.2l13.3-2.8
	l0.6-0.4c2.1-1.6,4.7-3,7.7-4.1l-21.6,4.5c-6.1,1.2-11.1,3-15.1,5.4c-1.4,0.7-2.6,1.8-3.8,3.3l-2.5,3.7c-1.7,2.6-3,5.5-4.3,8.4
	c0,0.3,0.3,0.4,0.7,0.5h0.1c0.6-1.2,1.1-2.3,1.8-3.6l1.4-2.5l0.1-0.1C95.2,367.7,95.9,366.7,96.6,365.7L96.6,365.7z"
          />
          <path
            fill="#656565"
            d="M139.8,349.2c-0.4-0.3-1.1-0.4-2.1-0.4c-3,1.1-5.5,2.5-7.7,4.1l-0.6,0.4l-2.1,2.1v13.3c1,0.4,1.9,0.5,3,0.7
	h0.1c1.9-0.4,3.2-1.2,3.9-2.8c2.3-4.5,4.1-9.4,5.4-14.4v-0.1C140.3,350.9,140.2,349.8,139.8,349.2L139.8,349.2z"
          />
          <path
            fill="#656565"
            d="M127,355h-0.7c-0.4,0-0.6,0.1-0.6,0.5v13.3c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5v-13.3
	C127.4,355.1,127.2,355,127,355L127,355z"
          />
          <path fill="#3296BA" d="M130.4,369.6h0.1v-0.1" />
          <path
            fill="#FFFFFF"
            d="M119.1,345.5c-11.7,2.8-19.8,5.8-24.3,9.2l48-11.6C137.3,342.3,129.4,343.2,119.1,345.5z M191.2,324.5
	l-4.4-4.4c-5.9,3.2-11.4,7.4-16.5,12.7c-10.7,11.1-16.9,24.2-18.7,39.3c-0.4,2.8-0.6,5.5-0.6,8.4c0,3.6-0.3,6.7-0.7,9.8h5.4l0.1-0.7
	c0.7-3.3,1.1-7.3,1.1-11.7c0.1-2.5,0.3-5,0.6-7.3c1.6-13.1,7.4-24.5,17.1-34.3C179.7,331.2,185.2,327.2,191.2,324.5L191.2,324.5z"
          />
          <path
            fill="#EBEBEB"
            d="M157.2,302.6c-0.3-0.1-0.4-0.3-0.7-0.4h-1.2l-22.7,6.2l-24.6,5.9l-2.3,2.5l1.9-0.4l1.5-1.5l14.2-3.3
	c9.6-2.3,19.4-5,29.4-7.8l3.9-1.1L157.2,302.6L157.2,302.6z"
          />
          <path
            fill="#EBEBEB"
            d="M157.5,302.9l-0.3-0.3l-0.8,0.1l-3.7,1.1c-10,2.9-19.9,5.5-29.4,7.8l-14.3,3.2l-1.5,1.5l10.3-2.2l0.3-0.1
	c11.3-2.8,23.1-5.9,35.4-9.5l4.1-1.2h0.4L157.5,302.9L157.5,302.9z"
          />
          <path
            fill="#FFFFFF"
            d="M84.6,327.4l0.4,1.4l1.1,3.3l0.4,1.2l0.8,2.5c0.1,0.8,0.3,1.6,0.3,2.5l-0.1,1.2l-0.1,0.7
	c-0.6,1.5-1.2,2.9-1.8,4.1l-0.4,0.5l-0.3,0.4l-0.1,0.3c-1,1.6-2.3,2.9-3.9,3.6v1.2h0.3c2.1-1,3.6-2.5,4.8-4.5l0.3-0.4l2.3-5v-0.1
	l0.3-1.1l0.1-1.2c0-1-0.1-1.9-0.3-2.8l-5.9-18.8c-0.4-1.1-0.8-1.8-1.5-2.3l-0.7,1c0.4,0.4,0.8,1,1,1.7l2.9,9.1L84.6,327.4
	L84.6,327.4z"
          />
          <path
            fill="#656565"
            d="M84.6,327.4l-0.4-1.2h-0.8c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4l0.4,0.1
	L84.6,327.4L84.6,327.4z M87.2,335.6l-0.8-2.5H85v5h2.5C87.5,337.3,87.4,336.4,87.2,335.6L87.2,335.6z"
          />
          <path
            fill="#656565"
            d="M86,332h-3.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.4,0.1,0.5l0.4,0.1h3.6
	C86.3,333.3,86,332,86,332z"
          />
          <path fill="#3296BA" d="M85,332h1l-1-3.3" />
          <path
            fill="#656565"
            d="M82.4,339.5h4.8l0.1-1.2h-5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4
	C82.1,339.3,82.3,339.5,82.4,339.5L82.4,339.5z M87.1,340.1l0.1-0.7h-2.3v4.8h0.4C86,343,86.5,341.7,87.1,340.1L87.1,340.1z"
          />
          <path fill="#3296BA" d="M85,344.3v0.5l0.3-0.5" />
          <path
            fill="#656565"
            d="M85,344.8v-0.5h-2.9c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.1,0.1,0.3,0.1,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1h2.5l0.1-0.3L85,344.8L85,344.8z"
          />
          <path
            fill="#FFFFFF"
            d="M87.5,357.6c0.3-1-0.1-1.2-1-1.1l-5.8,1.9v7.2H86l0.3-1.1h-5.1h5.1L87.5,357.6L87.5,357.6z M84.1,370.1
	c0.1,0.1,0.3,0.1,0.4,0.1h0.7l0.3-1.2h-0.8c-0.1,0-0.3,0-0.4,0.1s-0.1,0.3-0.1,0.4C84,369.7,83.9,370,84.1,370.1L84.1,370.1z
	 M88.6,378.2l-1.9,0.3c-1.1,0.1-2.1,0.5-3,1.2l-5.1,4h2.3l3.7-3.3C86,379.2,87.4,378.5,88.6,378.2L88.6,378.2z"
          />
          <path
            fill="#656565"
            d="M96,390.2h-4.3l10.7,3.3l44.2-3.3h-31.2l-13.6,1.8"
          />
          <path
            fill="#FFFFFF"
            d="M88.6,378.2c-1.2,0.3-2.6,1-4,2.2l-3.7,3.3h2.6l67.3-12.4h-11.7c-2.5-0.1-5.1,0.1-7.8,0.5L88.6,378.2z
	 M101.8,392l13.6-1.8H96 M571.7,334.5c3.9-7.7,9.4-14.7,16.5-21c7.3-6.5,15.4-11.4,24.1-14.7l7.7-11.1c-10.5,3.2-19.9,8.7-28.5,16.4
	C582,312.8,575.4,323.1,571.7,334.5L571.7,334.5z M467.7,350.6l-0.4,7.6c28.9-3.6,58.2-8.1,88.3-13.6L467.7,350.6z"
          />
          <path
            fill="none"
            d="M146.8,304.1l4.8-12.1 M162.3,295.4l2.6-2.8 M162.3,288.6v6.9 M154.2,301.9v-10.6 M123.1,310.3l-7.2,1.6
	 M116,311.9H99.3 M105.7,316.9l-3.6,0.8 M116,311.9l-8.3,1.9l-2.3,2.2l-2.6,0.4c-1.1,0.1-1.8-0.1-2.3-1.1l-1.1-2.2
	c-0.1-0.4-0.1-1,0-1.2 M105.7,316.9l1.9-0.5 M145.8,304.4l-12.8,3.4 M134.8,303.8h11.3 M133.9,303.7h-10.7v6.6 M123.1,310.3l8.8-2.2
	 M131.9,308.1l1.9-4.4 M133,307.8l1.8-4 M131.9,308.1l1-0.3h0.1 M134.8,303.8l0.1-0.4l14.9-9.2l-3.7,9.6 M151.6,292l-17.2,10.7
	l-0.6,1 M146.8,304.1l5.1-1.5 M145.8,304.4l1-0.3 M146.1,303.8l-0.3,0.5 M162.3,295.4l-2.5,2.8c-1.4,1.5-3.3,2.8-5.6,3.7
	 M107.6,316.4l1.5-1.5l14.2-3.3c9.6-2.3,19.4-5,29.4-7.8l3.9-1.1l0.8-0.1 M157.9,303.4h-0.4c-1.4,0.4-2.8,0.8-4.1,1.2
	c-12.2,3.6-24.1,6.7-35.4,9.5l-0.3,0.1l-10.3,2.2 M157.9,303.4l-0.4-0.5l-0.3-0.3"
          />
          <path
            fill="none"
            d="M157.2,302.6c-0.3-0.1-0.4-0.3-0.7-0.4h-1.2l-22.7,6.2l-24.6,5.9l-2.3,2.5 M158.8,303l-0.4,0.1
	c-0.1,0-0.3,0.1-0.4,0.1 M154.2,305.1l-0.1,0.1 M154.2,301.9l-2.3,0.7 M151.9,302.6c-0.4-0.8-0.6-1.8-0.7-2.8v-2.6
	c0-2.2,0.7-4,2.1-5.2 M158.8,303c2.9-1,6.7-2.1,11.4-3.3l0.3-0.1 M159.4,288c1,0,1.9,0.3,2.9,0.5"
          />
          <path
            fill="none"
            d="M186.7,292l-18.7,2.2c-1.5,0.4-2.8,1.1-3.7,2.2l-5.5,6.7"
          />
          <path
            fill="none"
            d="M102.1,317.7c-1.1,0.1-2.1-0.5-2.9-2.2l-0.6-1.5l-0.1-0.7c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.5,0.4-1.1,1.1-1.4
	c2.1-1.4,9.8-5.2,23.1-11.8c11.7-5.8,22.4-9.5,32.1-11.3c5.6-1,11-1.4,16.1-1.2c3.6,0,7.4-0.3,11.1-0.8l23.2-3"
          />
          <path
            fill="none"
            d="M164.4,289.5l21.9-2.3l-4.1,3.7l-17.2,1.8 M164.4,289.5c-0.7-0.4-1.4-0.7-2.1-1 M164.9,292.7l-0.6-3.2
	 M155.7,285.7h-0.4c-10.3,2.1-21.2,5.9-32.9,11.7c-10.3,5.1-18.6,9.5-24.9,13.1l-1.2,0.8c-0.8,0.4-1.2,1-1.2,1.6
	c0,0.5,0.1,1.2,0.3,1.9l1,2.9l0.1,0.3c0.4,0.5,1.1,0.7,2.2,0.4l3.3-0.7 M154.2,291.3l-0.8,0.7 M153.4,292h-1.8"
          />
          <path
            fill="none"
            d="M99.3,311.9c0.1-0.3,0.3-0.5,0.6-0.7c2.6-1.5,10.2-5.4,22.6-11.6c11.6-5.6,22-9.4,31.6-11.1
	c2.1-0.4,3.9-0.7,5.4-0.5"
          />
          <path
            fill="none"
            d="M159.4,288c-2.5,0.4-4.3,1.4-5.2,2.8v0.5 M690.7,231.7l2.6,1.5l-1.9,0.3H691 M680,227.6h0.1l2.3-0.1
	 M685.1,228.7l-2.3,0.3h-0.1 M682.6,229c0.4,0.1,0.8,0.4,1.2,0.6l1.9,1.1"
          />
          <path
            fill="none"
            d="M688.4,232c0.1,0,0.1-0.1,0.3-0.1l2.2-0.1 M688.2,230.5l-3.2-1.8 M685.9,230.6h0.1l2.2-0.1 M682.5,227.5
	l2.6,1.2 M688.4,251.7l-7.7-9.1c-0.5-0.7-0.4-1.4,0.3-1.8c0.4-0.3,1.1-0.4,1.8-0.6c4.8-1,10-1.8,15.5-2.2h0.5c1.1,0,2.1,0.1,2.9,0.6
	"
          />
          <path
            fill="none"
            d="M701.6,238.6l-16.5,2.2c-1.1,0.4-1.1,1.4,0.1,2.8c1.5,1.8,2.9,3.6,4.4,5.2l7.8,8.1l2.2,2.1"
          />
          <path
            fill="none"
            d="M686.6,243.4l-0.3-0.3c-0.3-0.7-0.1-1,0.4-1.1l14.2-1.9"
          />
          <path
            fill="none"
            d="M696.3,253.8c1,0.6,1.9,1.1,3,1.5l2.6,0.6l13.1,0.1l1.4-0.1c1-0.3,1.1-1,0.3-1.9l-3-2.8
	c-2.6-2.3-5.4-4.7-8.3-6.9l-3.7-2.8c-0.3-0.3-0.7-0.4-1.1-0.6l-13.5,1.8c-0.4,0.3-0.6,0.4-0.6,0.7"
          />
          <path
            fill="none"
            d="M684.4,252l-8.3-10c-1.7-2.3-0.8-3.9,2.3-4.5c4.8-1,9.9-1.8,15.5-2.2h0.5c1.2,0,2.2,0.3,3.2,0.8l4.1,2.6
	 M688.2,230.5l2.5,1.2 M691,233.5l-2.6-1.5 M701.8,238.6h-0.3 M704.5,238.2l-2.6,0.4"
          />
          <path
            fill="none"
            d="M693.2,246.6l-1.2-1.6l8.5-1.2l1.9,1.5l0.1,0.1c2.5,1.9,5.1,4,7.4,6.2l1.5,1.5h-9.2c-1.8,0-3.3-0.4-4.5-1.2
	l-0.3-0.3l-0.5-0.6"
          />
          <path
            fill="none"
            d="M701.3,253.8h12.2l-1.9-1.8c-2.6-2.3-5.4-4.7-8.3-6.9l-2.9-2.2l-10.5,1.4l6.2,7.4l0.3,0.3
	c0.8,0.7,1.8,1.2,2.9,1.6L701.3,253.8L701.3,253.8z M701,240.1l0.3,0.1c0.5,0.1,1,0.3,1.5,0.7l3.7,2.8c2.9,2.2,5.6,4.5,8.3,6.9
	l4.4,4.1v0.1l0.3,0.6c0.1,0.4,0,0.8-0.3,1l-0.7,0.4"
          />
          <path
            fill="none"
            d="M701.4,251.6h7.4c-2.3-2.1-4.8-4.1-7.3-6.2l-6.9,1.1 M701,240.1h0.1c1.5-0.1,2.8,0,3.7,0.6 M706.1,241.4
	l0.1,0.1l2.9,2.1c4.7,3.4,9.1,7.2,13.2,11c0.8,1.1,0.5,1.8-0.7,2.1l-0.5,0.1h-2.8 M705,240.8l1.1,0.6 M706.1,241.4l-0.8-0.6H705"
          />
          <path
            fill="none"
            d="M696.6,252.9l-0.3-0.1h-0.1l-0.3-0.3l-2.9-3l-5-5.9l12.5-1.6l4,3c2.9,2.2,5.6,4.5,8.3,6.9l1.5,1.4 M665.4,221.3
	l0.3-1.1c3.6,1.4,7,2.9,10.5,4.4l6.2,2.9 M664.7,232.7l-0.1-0.1c-0.3-0.6-0.4-1.1-0.5-1.8 M664,230.8c-0.1-0.6-0.1-1-0.1-1.5"
          />
          <path
            fill="none"
            d="M663.9,229.3c0-1.1,0.1-2.2,0.4-3.3l1.2-4.7 M691,233.5c-7.7,0.7-14.9,1.6-21.2,2.9c-1.4,0.1-2.5-0.1-3.2-0.7
	l-1.1-1.5 M665.7,234.1l-1-1.4"
          />
          <path
            fill="none"
            d="M680,227.6c-1.5-0.8-3.2-1.5-4.8-2.2c-3.2-1.4-6.3-2.8-9.8-4.1 M714.5,253.1l1.5,1.4l0.1,0.1
	c0.1,0.3-0.3,0.4-1.2,0.6l-12.8-0.1c-1-0.1-1.8-0.3-2.6-0.6l-1.5-0.7 M718.2,256.8h0.1 M718.4,256.8h-0.1"
          />
          <path
            fill="none"
            d="M714.5,253.1c0.8,0.8,0.7,1.2-0.3,1.2H702c-1.5,0-2.8-0.3-3.9-0.7 M718.2,256.8h-13.3l-2.8-0.3
	c-2.1-0.4-4-1.2-5.6-2.3l-0.5-0.4 M696.9,251c0.5,0.6,1.7,0.7,3.4,0.6h1.1 M699.8,258.8l3.6,3l5.4,3.4h0.1l3.6,1.8l0.8,0.3
	 M713.3,267.4l2.5,1.1c3.4,1.2,7.2,2.3,11.1,3.2l0.8,0.1 M713.3,267.4v-8.5 M713.3,258.8h-13.5 M696.9,251l-0.1-0.1l-3.6-4.3
	 M694.7,246.5l-1.5,0.1 M692.1,256l1.5-1.4l-2.6,0.1 M693.2,257.6l-1.1-1.7 M697.6,253.5h-0.1l-0.8-0.6 M698,253.8h-0.1
	 M697.9,253.8l-0.3-0.3 M697.6,253.5l0.4,0.3 M695.8,253.8h0.5"
          />
          <path
            fill="none"
            d="M701.3,253.8h-1.7c-1.1-0.1-2.2-0.3-3-0.8 M694,258.8h1.2l1.5-1.4l-3.6,0.1 M701.4,251.6
	c-1.7,0-2.9-0.7-3.9-1.8l-2.9-3.3 M696.3,253.8l-2.6-2.2 M695.8,253.8l-2.1-2.2 M688.4,251.7l-4,0.3 M686.9,255l1.1,1.2
	 M685.3,253.2l1.5,1.8 M684.4,252l1,1.2 M689.3,252.9l-4,0.3 M688,256.2l1.1,1.6 M727.3,274.8c-9.2-2.5-17.2-5.2-23.8-8.4
	c-4.8-1.8-7.8-4.1-9.1-6.7l-0.4-0.8"
          />
          <path
            fill="none"
            d="M689.9,259.1l0.7,1.4c1.1,2.6,2.5,4.4,4.3,5.4c1.4,0.8,4.7,2.3,10.2,4.4c2.8,1,6.1,2.2,10,3.4 M694,258.8
	l-4.1,0.3 M691,254.7l-1.7-1.8 M689.3,252.9l1.5-1.4l-2.5,0.1 M689.1,257.9l0.8,1.2 M686.9,255l4.1-0.3 M692.1,256l-4.1,0.3
	 M693.2,257.6l-4.1,0.3 M664,230.8c6.1-0.8,12.2-1.5,18.6-1.9 M685.9,230.6c-7.2,0.4-14.2,1.1-21.2,2.1 M663.9,229.3
	c5.4-0.7,10.7-1.2,16.1-1.6 M665.7,234.1c7.4-1.1,15-1.8,22.7-2.2 M693.7,251.6l-7.2-8.1 M713.3,258.8h14.2v8.9c0,1.4,0.1,2.8,0.3,4
	 M727.3,274.8c0.3,1.1,0.5,1.9,0.8,2.9 M727.6,271.9l-0.3,2.9 M727.6,271.8v0.1 M727.6,271.9c0.1,1.1,0.3,2.2,0.5,3.2l0.8,2.9
	 M763.8,340.4l-0.3-1.9 M765.4,345.5l-1-2.6c-0.3-0.8-0.5-1.6-0.7-2.5 M763.5,338.5v1.9 M763.8,340.4h-0.3 M763.5,338.5l-1-21.3
	 M762.5,317.2l-6.9-5.8c-3.8-1-7-2.8-9.5-5.5l-1.8-2.1 M724.4,351c7.8-0.3,12.9-4,15-11.3c0.3-1.1,0-1.9-0.5-2.3"
          />
          <path
            fill="none"
            d="M738.9,337.4l3-0.4c2.2-0.3,3.2,0.5,2.8,2.8c-2.6,7.4-8.1,11.1-16.4,11.3h-3.7 M684.8,305.8
	c3.6,2.2,6.9,4.8,10.2,7.7l3.9,3.7c5.8,6.2,12.7,9.8,20.2,10.9c4.3,0.4,9.2,0.3,14.9-0.3c6.1-0.8,11.7-1.8,16.9-3.3l-20.4-1.9
	c-13.3-1.5-22.6-5.2-27.8-11.3c-2.1-2.5-4.4-5-7-7.3c-2.2-2.1-4.5-3.9-6.9-5.6 M763.5,340.4c-8.8,2.5-14.9,9.5-18.3,21
	c-0.1,0.5-0.4,1.2-0.7,1.8"
          />
          <path
            fill="none"
            d="M745,363.3c1-1.1,1.8-2.2,2.5-3.4c5.4-8.5,11.3-13.2,17.9-14.2 M743.4,364.9l1.7-1.7 M745,363.3h-0.4
	 M743.4,364.9l1.2-1.7 M724.4,351h-3c-5.1-0.5-8-3.6-8.7-9.1c-0.3-1,0.1-1.5,1.2-1.6l25-2.9"
          />
          <path
            fill="none"
            d="M730.9,376.2l3.4-0.7c3.4-0.5,6.9-1,10.6-1.2l10.2-1.1l7.7-10c1-1,1.5-2.5,1.8-4.4l1-13.3 M730.9,376.2
	l12.5-11.3 M762.5,317.2c-8.9-1.5-17.5-4.1-25.6-7.4c-16.4-6.6-29.6-15.7-39.6-27.1 M688.9,298.3l-4.1,7.4 M632.1,343.5l3.4-0.6
	l5.5-0.4l5.5,0.4l3.4,0.5l2.3,0.7"
          />
          <path
            fill="none"
            d="M651.8,342.9c5.8,1.7,11,4.8,15.7,9.5h0.1l1.7,1.8c2.3,2.5,4.1,5.2,5.6,8.1c2.5,5.1,3.9,10.6,3.9,16.8
	c0,6.1-1.2,11.7-3.8,16.8c-1.8,3.6-4.1,6.7-7.2,9.8c-1.1,1.1-2.2,2.2-3.4,3.2c-6.6,5.2-14.3,7.8-23.1,7.8c-8.8,0-16.5-2.6-23.1-7.8
	l-3.4-3.2c-3.3-3.3-5.8-6.7-7.6-10.6c-2.3-4.8-3.4-10.2-3.4-16s1.1-11.1,3.4-16c1.5-3.2,3.4-6.2,5.9-8.9l1.7-1.8
	c2.5-2.5,5.2-4.7,8.1-6.2c2.5-1.4,5-2.5,7.6-3.3c3.4-1,7-1.5,10.7-1.5C644.9,341.4,648.5,341.8,651.8,342.9L651.8,342.9z
	 M672.4,325.3l3.4-4.7"
          />
          <path
            fill="none"
            d="M590.1,383.5c-0.1-1.5-0.3-3-0.3-4.5c0-12.7,4-23.7,12.1-33c0.8-1.1,1.8-2.1,2.8-3c9.9-9.9,22-15,36-15
	c14.2,0,26.1,5,36.2,15c9.9,9.9,15,22,15,36.2v2.6"
          />
          <path
            fill="none"
            d="M672.4,325.3c-9.2-6.1-19.7-9.1-31.4-9.1c-16.1,0-29.7,5.6-41.1,17.1s-17.5,25.5-18.3,42.1l-0.4,8.1"
          />
          <path
            fill="none"
            d="M575,383.6l0.3-5.5c0.8-19.1,7.6-35.2,20.1-48.4c12.5-13.1,27.6-19.7,45.4-19.7c13.1,0,24.6,3.6,34.9,10.6
	 M657.7,346.5c0.7,0.3,1.4,0.7,2.1,1.1c2.5,1.5,4.8,3.4,7,5.5l0.8,1c1.9,2.1,3.6,4.1,4.8,6.3 M660,350.1l0.1,0.1l-5.5,8.3
	 M670.1,360.1c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5c-1.7-1.5-3.3-2.9-5-4.1 M661,365.7l0.5-0.3 M672.5,360.4l-2.5-0.3 M673.7,362.4
	l-1.7,0.8 M673.7,362.4l1.1,2.5l1,2.8 M661.5,365.5l8.4-5.5l0.1,0.1 M672.5,360.4l1.1,2.1 M656.7,348c-1.7-0.8-3.3-1.5-5-2.1
	 M652.3,344.1c1.8,0.5,3.6,1.4,5.4,2.2 M652.3,344.1l-0.5,1.8 M656.7,348l1-1.5"
          />
          <path
            fill="none"
            d="M660,350.1l-0.4-2.3l-1,1.5c-1.1,1.6-2.2,3.3-3.3,5l-6.2,10l-5,8.7 M647.9,345c-2.2-0.4-4.5-0.7-6.9-0.7
	c-2.3,0-4.8,0.3-7,0.7 M636,355c1.7-0.3,3.3-0.4,5.1-0.4l4.8,0.4"
          />
          <path
            fill="none"
            d="M645.9,354.9l0.1-0.8c0.7-2.9,1.2-5.9,1.7-9.1l0.1-0.1 M645.3,357.1l-4.3-0.4l-4.5,0.4 M645.3,357.1
	c-0.5,2.3-1.2,4.8-2.1,7l-0.4,1.1l-0.1,0.5 M654.7,358.4l-1.2,1.9 M654.7,358.4c1.2,1,2.6,1.9,3.7,3.2l3.2,3.9 M656.2,362.6
	c-0.8-0.8-1.8-1.5-2.8-2.2"
          />
          <path
            fill="none"
            d="M661,365.7l-1.4-1.6c-0.8-0.8-1.7-1.8-2.8-2.5l-0.8,1"
          />
          <path
            fill="none"
            d="M656.2,362.6l-1.1,1.5c-0.8,1.4-1.5,3.2-2.2,5.1 M643.2,371l3.7-13.6l3-13.8l-2.1,1.4 M651.8,345.9l-2.8,9.8
	c1,0.3,1.8,0.7,2.6,1.1l5.1-8.8 M645.9,354.9l-0.5,2.2 M652.9,369.2v1.5 M652.2,371l0.7-0.3 M654.1,377H651 M651,377l0.3,0.1
	c0.4,0.4,0.5,1,0.5,1.6c0,0.5-0.1,1.1-0.5,1.5l-0.1,0.1 M650.7,378.8c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,0.8-0.4C650.5,379.5,650.7,379.2,650.7,378.8
	L650.7,378.8z M659.6,375.4l-3.6,1.1 M652.9,370.7l2.3-1.5 M655.2,369.2h-2.3 M656,376.5l-1.9,0.5 M642.7,369l-0.1-0.1
	c-0.4-0.4-1-0.7-1.7-0.7c-0.5,0-1.1,0.3-1.7,0.7l-0.1,0.1 M643.2,371v-0.4c0-0.5-0.1-1.1-0.5-1.5 M641.9,369.6
	c-0.3-0.3-0.5-0.4-0.8-0.4s-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-1
	C642.3,370.1,642.1,369.9,641.9,369.6L641.9,369.6z M644.3,372.8c-0.6-0.3-1.1-0.5-1.8-0.7 M642.6,372.1l0.5-0.7l0.1-0.4
	 M639.3,372.1c0.5,0.4,1.1,0.7,1.7,0.7c0.7,0,1.2-0.3,1.7-0.7 M648.9,376.6h0.5c0.5,0,1.1,0.1,1.4,0.6 M648.9,376.6l-0.3,0.1
	c-0.3,0.1-0.5,0.3-0.8,0.5 M644.3,372.8c0.5,0.3,1.1,0.7,1.7,1.2c0.5,0.5,0.8,1,1.2,1.5"
          />
          <path
            fill="none"
            d="M647.9,380.5c-0.4-0.5-0.7-1.1-0.7-1.6c0-0.7,0.3-1.2,0.7-1.6 M647.8,377.2c-0.1-0.5-0.4-1.1-0.7-1.6
	 M649,367.8l-4.7,5 M647.2,382.2l0.1,0.1 M647.9,380.5c-0.1,0.5-0.3,1.1-0.5,1.8 M649,381.1l-0.4-0.1c-0.3-0.1-0.5-0.3-0.7-0.4
	 M651,380.6c-0.4,0.4-1,0.6-1.5,0.6H649 M647.2,382.2l-1.2,1.6l-1.5,1.2 M644.5,385.1l-1.7,0.7 M642.8,385.8
	c-0.5-0.5-1.1-0.7-1.7-0.7c-0.7,0-1.2,0.3-1.7,0.7l-0.1,0.1 M639.4,388.8l0.1,0.1c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.7-0.7
	l0.1-0.1 M643,388.7c0.4-0.4,0.5-0.8,0.5-1.5v-0.5"
          />
          <path
            fill="none"
            d="M643.4,386.8l-0.1-0.3l-0.4-0.7 M640.2,386.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8
	c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1s-0.5-0.4-0.8-0.4
	S640.5,386.1,640.2,386.4L640.2,386.4z M649,390.1l-4.5-5 M654.1,380.6l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3 M651,380.6h3.2
	 M652.2,386.8l0.7,0.4 M652.9,388.3v-1.1 M654.7,388.3h-1.8 M652.9,387.2l1.8,1.1 M647.2,375.5l5-4.5 M652.2,386.8l-3.2,3.3
	 M647.4,382.4l4.8,4.4 M654.1,377v3.6 M656,381.1v-4.7 M652.2,371l-3.2-3.2 M676.4,369.9l-1.5,1.9 M674.9,371.8l-0.1,0.1
	c-3.3,0.4-6.6,1.1-9.8,1.8 M675.7,367.7l0.7,2.2 M676.4,369.9l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2l-2.1,0.6
	c-4.7,1.1-9.2,2.3-13.6,3.7 M674.9,371.8c0.4,2.3,0.7,4.7,0.7,7.2c0,2.3-0.3,4.7-0.7,6.9"
          />
          <path
            fill="none"
            d="M675.7,390.2l0.7-2.5c0.7-2.9,1.1-5.8,1.1-8.9s-0.3-6.2-1.1-9.1 M674.1,368.2l1.7-0.5 M665,373.9l-2.1,0.5
	 M672,363.3l-8.8,5.1l1.1,2.5l9.8-2.6 M665,383.8c0.3-1.5,0.5-3.2,0.5-4.8c0-1.8-0.1-3.4-0.5-5.1 M662.9,374.4
	c0.3,1.5,0.4,3,0.4,4.5s-0.1,2.9-0.4,4.3 M659.6,375.4c0.3,0.1,0.7,0.4,1,0.8c0.7,0.8,1.1,1.8,1.1,2.9c0,1.1-0.3,2.2-1.1,2.9
	l-0.5,0.5 M662.9,383.2l2.1,0.5 M660,382.4l2.9,0.8 M670.1,397.9l2.2-0.4c-0.4-0.3-1-0.7-1.5-1c-2.8-1.9-5.6-3.7-8.5-5.5l-1.8-1.1
	c-4.3-2.8-8.7-5.2-13.2-7.7 M674.1,389.7l-9.8-2.6l-1.1,2.6l8.8,5"
          />
          <path
            fill="none"
            d="M675,385.8l1.4,1.9l-1.7-0.3h-0.1l-12-2.8l-13.6-3.6 M665,383.8c3.2,0.8,6.5,1.4,9.8,1.8h0.1l0.1,0.1
	 M675.7,390.2l-1.7-0.5 M647.2,375.5c4.5-2.5,8.9-5,13.3-7.6l12-7.6 M662.9,374.4l-3.3,1 M642.7,369v-3.3 M656,381.1l4,1.2
	 M674.1,368.2c-0.5-1.8-1.2-3.4-2.1-5 M655.2,369.2l5.8-3.4 M653.4,360.4l-4.4,7.4 M700.1,381h5.8 M705.2,369.3v-0.1
	c-1.8-15.1-8.1-28.2-18.7-39.3c-3.3-3.4-6.9-6.5-10.6-9.1 M705.8,381l-0.1-3.6c0-2.8-0.3-5.5-0.5-8.1 M700.1,381
	c-0.1-1.9-0.3-4-0.3-6.2l-0.1-4.7 M692.1,381.6l8-0.5"
          />
          <path
            fill="none"
            d="M699.5,370.1l-0.3-2.6c-1.7-13.1-7.3-24.5-17.1-34.3c-3-3.2-6.3-5.8-9.8-8.1 M705.2,369.3l-5.6,0.8
	 M675.8,320.6l8.9-14.9 M661.4,392.4l8.4,5.6l0.3-0.1 M672,394.6l1.5,1 M673.5,395.6l1.2-2.6l1-2.8 M659.6,410.3l2.6-1.8
	c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1 M660.7,392l0.7,0.4 M659.9,408v-0.1c-1.8-2.8-3.6-5.6-5.4-8.4"
          />
          <path
            fill="none"
            d="M659.9,408c1.9-1.2,3.9-2.9,5.6-4.7c1.7-1.6,3.2-3.6,4.5-5.5 M654.5,399.4l-1.1-1.8 M653.4,397.6l2.9-2.3
	 M654.5,399.4c1.2-1,2.6-1.9,3.8-3.2c1.1-1.2,2.2-2.5,3.2-3.7 M660.7,392c-1.1,1.4-2.3,2.8-3.9,3.9l-0.5-0.7 M636.2,403l4.8,0.4
	l5.1-0.5 M643,392l0.3,1c1,2.6,1.8,5.2,2.3,7.8 M646.1,402.9l-0.5-2.1 M645.6,400.8l-4.5,0.4l-4.4-0.3 M648.1,412.9
	c-2.3,0.4-4.7,0.7-7.2,0.7c-2.3,0-4.7-0.1-6.9-0.5 M646.1,402.9c0.4,1.8,0.8,3.7,1.2,5.6l0.7,4.1l0.1,0.1 M651.8,412l0.5,1.7
	 M650.1,414.3l2.2-0.7 M657.6,411.4l1.9-1.1h0.1 M652.3,413.6c1.8-0.5,3.6-1.2,5.2-2.2 M657.6,411.4l-0.8-1.5 M651.8,412
	c1.7-0.5,3.3-1.2,5-1.9 M629.8,413.6l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1 M656.7,409.9l-5-8.8l-2.6,1.1l2.6,9.8
	 M650.1,414.3l-2.1-1.4 M643.4,386.8c1.4,4.4,2.6,8.9,3.7,13.8l3,13.8"
          />
          <path fill="none" d="M644.5,385.1c2.5,4.5,5,8.9,7.6,13.3l7.6,12" />
          <path
            fill="none"
            d="M590.1,383.5c1,12.1,5.9,22.7,14.7,31.5l2.1,1.9c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15
	c1.5-1.5,2.8-2.9,4-4.4c6.9-8.4,10.5-18.2,11-29.2 M659.6,410.3l0.3-2.3 M705.8,381l25-4.8 M744.6,363.3l-39.5,6.1 M643,388.7v3.3
	 M656.3,395.3c-1.2-1.6-2.3-4-3.4-6.9 M653.4,397.6l-4.4-7.6 M672,394.6c0.8-1.5,1.5-3.2,2.1-5 M654.7,388.3l6.1,3.7 M736.4,250.5
	c-15.1-14.4-32.3-27.8-51.6-39.8 M740.5,272.5l0.1-4.1 M737.1,268.3l0.3,6.1 M740.6,268.3h-3.6 M737.3,274.4h3l0.1-1.9 M744.4,303.7
	c-2.2-2.8-4.3-5.8-6.2-9.4c-2.3-4.8-4.5-10-6.3-15.7 M731.8,278.7l-1.5-0.4 M736.4,250.5c-2.6-1.2-5.1-2.8-7.3-4.3"
          />
          <path
            fill="none"
            d="M737.1,268.3l-0.3-6.1c0-1.2-0.1-2.3-0.5-3.4c-0.3-1-0.7-1.8-1.1-2.6l-6.1-10 M740.8,264.5l-0.1,3.9
	 M740.5,272.5h1l0.4-8h-1.1"
          />
          <path
            fill="none"
            d="M740.8,264.5l0.1-2.9c0.1-1.9-0.3-3.9-1.2-5.6l-3.3-5.5 M680.1,219.2l-2.1,3.6 M697.3,282.6l3.3-13.5
	 M730.3,278.4L729,278l-0.8-0.3l-13.1-3.9c-5-1.5-9.8-3-14.4-4.7 M684.2,262.4c3.4,7.4,7.8,14.2,13.1,20.2 M685.6,281.5
	c0.1-0.8,0.3-1.5,0.3-2.5c0-0.7,0-1.4-0.1-1.9"
          />
          <path
            fill="none"
            d="M685.6,277.1h-0.1c-1.1,0-2.2,0.3-3,0.7c-0.8,0.4-1.2,1-1.2,1.5s0.4,1.1,1.2,1.5c0.8,0.4,1.8,0.7,3,0.7h0.1
	 M700.6,269.2c-4.5-1.6-8.9-3.3-13.2-5.1c-1.1-0.4-2.2-1-3-1.5"
          />
          <path
            fill="none"
            d="M685.6,281.5c-0.4,1.8-1.4,3.3-2.6,4.5c-1.9,1.9-4.3,2.9-7,2.9s-5.1-1-7-2.9c-1.9-1.9-2.9-4.3-2.9-7
	c0-2.8,1-5.1,2.9-7s4.3-2.9,7-2.9s5.1,1,7,2.9c1.5,1.5,2.3,3.2,2.8,5.1 M678.1,222.8c-4.3-1.9-8.5-3.7-13.1-5.4"
          />
          <path
            fill="none"
            d="M665,217.4l-2.3,8.7c-0.3,1.2-0.4,2.3-0.4,3.4c0,1.2,0.3,2.3,0.8,3.4l16,23.9c1.5,2.3,3.2,4.3,5.2,5.6
	 M631,236.8c1-0.4,1.7-1,2.3-1.6l2.1-2.2c3.2-3.4,6.9-8.5,11.1-15l2.6-4 M649.2,214.1l2.1-3.3 M643.2,219
	c-3.2,4.7-6.6,8.9-10.2,12.7c-0.7,0.6-1.4,0.6-1.9-0.1L620.4,213c-0.3-0.8-0.1-1.1,0.8-1.1c7.2,1.5,14.2,3.2,21.2,5
	C643.2,217.3,643.7,218,643.2,219L643.2,219z"
          />
          <path
            fill="none"
            d="M627.7,241.5c1.7-0.4,3.2-1.1,4.8-2.1l2.3-1.6c1.2-1.1,2.6-2.2,3.9-3.6c4.3-4.8,8.4-10.3,12.2-16.4
	c1.8-2.9,1.9-5.2,0.3-7 M651.2,210.8c-0.8-0.8-1.9-1.5-3.4-2.1l-3.2-0.8c-13.5-3.6-27.4-6.5-41.5-9.1 M680.1,219.2
	c10,4.8,20.1,10,29.9,15.5 M729,246.2c-6.1-3.9-12.2-7.6-18.3-11l-0.7-0.4 M730.3,278.4l-0.8-2.8c-0.4-1.2-0.7-2.5-0.8-3.7
	c-0.1-1.4-0.3-2.8-0.3-4.1v-8.9l-1.9-2.1c-5.2-5.2-10.7-10.2-16.8-14.9l-5.1-3.7 M705.6,238.1l-1.1,0.1"
          />
          <path
            fill="none"
            d="M731.8,278.7l-1-3.2c-0.4-1.2-0.7-2.5-0.8-3.7c-0.1-1.4-0.3-2.8-0.3-4.1v-9.2c-6.1-6.3-12.7-12.1-20.1-17.5
	l-4-2.9 M678.1,222.8c3.2,1.5,6.2,3,9.1,4.7l0.3,0.1c5.8,3.2,11.3,6.6,16.4,10.2l0.5,0.4"
          />
          <path
            fill="none"
            d="M705.6,238.1l-0.4-0.3c-5.5-3.7-11.4-7.3-17.7-10.5l-0.3-0.1c-2.9-1.5-5.9-3-9.1-4.4 M710,234.8l-25-16.1
	c-1.9-1.1-2.9-2.1-2.9-2.9 M684.7,210.7l-2.8,5.1 M681.9,215.8l-1.8,3.4 M649.2,193.4l11.1,1.8 M660.3,195.2
	c2.1,0.3,3.4-0.4,3.9-2.1l1.2-3.3"
          />
          <path
            fill="none"
            d="M660.3,195.2l4.7,0.8c2.5,0.6,4.1,0,4.5-1.9l1.1-3.4 M684.7,210.7c2.9-5.6,5.5-11.1,7.7-16.5
	c-6.9-1.5-13.9-2.9-21.3-4.4l-0.3,0.8 M670.9,190.8l-5.5-1 M665.4,189.8l0.3-1l-16.9-2.9 M649.2,192.5v0.8 M648.8,185.9l0.4,6.6
	 M665,217.4c-6.9-7.6-12.1-15.1-15.7-22.8l-0.1-1.2 M636.9,181.7c3.4-4.1,7.2-7.8,11-11.1l18.7-15.1l1.5-1l0.8-0.8l0.4-0.6v-0.1
	c0.1-0.4,0.1-0.8,0-1.1v-0.1h-0.1c-0.1-0.3-0.5-0.3-1-0.3l-0.7,0.1c-0.4,0.1-0.7,0.4-1.1,0.7c-0.4,0.3-0.8,0.7-1.2,1.2l-23.5,19.1
	c-2.9,2.3-6.2,4.5-9.6,6.6 M744.4,303.7c-2.9-8.8-5.2-18.6-7-29.3 M688.9,298.3c-12.9-9.4-28.1-14-45.1-14c-8.4,0-16.2,1.1-23.5,3.3
	 M697.3,282.6l-8.4,15.7 M684.8,305.8c-12.5-8-27-12-43.2-12c-10.5,0-20.2,1.7-29.2,5.1 M619,181.9c-34.4-3.4-68.9-5.2-103.6-5.8
	c-14.4,0-28.8,0.4-42.9,1.2l-4.3,0.3c-20.9,1.4-49.5,5.9-86,13.9"
          />
          <path
            fill="none"
            d="M385.9,195.4l19.7-4.5c22.3-5.1,44.7-8.3,67-9.4l12-0.3c51.6-0.8,100.1,2.1,145.8,8.7l18.8,2.6"
          />
          <path
            fill="none"
            d="M649.2,193.4l-18.8-2.6c-45.7-6.6-94.2-9.5-145.8-8.7l-12,0.3c-22.3,1.1-44.7,4.3-67,9.4l-19,4.3 M382.3,191.7
	l-0.3,1.1 M386.4,196l-0.5-0.6 M385.9,195.4l-3.6-3.7 M385.9,196.9l-3.9-4.1 M386.4,196c-0.1,0.3-0.3,0.6-0.7,0.8 M382,192.8
	l-6.9,2.8 M379.1,199.8l4.3-1.5c1.1-0.4,1.9-0.8,2.3-1.4 M322.9,230.6l-1.1,6.2 M326.6,234.5c-1.6,0.8-3.2,1.5-4.8,2.3 M321.9,250.6
	l1.8,0.3 M323.8,250.6c1.2,0,2.3,0.3,3.4,0.7c2.2,1,4.1,2.2,5.8,3.9c1.2,1.1,1.9,3,2.1,5.8v3.3c0,1.5-0.1,2.8-0.4,3.9 M321.9,250.6
	h-0.3l-2.3,0.4 M323.8,250.6h-1.9 M323.7,250.9l0.1-0.3"
          />
          <path
            fill="none"
            d="M332.6,259.9c-0.3-2.2-0.8-3.9-2.1-4.8c-1.6-1.6-3.6-2.9-5.9-3.9l-1.1-0.4 M326,263c-0.3-0.3-0.5-0.3-0.8-0.3
	c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4s0.5-0.1,0.8-0.4
	c0.1-0.1,0.3-0.4,0.3-0.8C326.3,263.4,326.2,263.1,326,263L326,263z"
          />
          <path
            fill="none"
            d="M326.9,262.1c-0.4-0.4-1-0.7-1.5-0.7s-1.1,0.3-1.6,0.7c-0.4,0.4-0.5,1-0.5,1.5c0,0.5,0.1,1.1,0.5,1.5
	s1,0.7,1.6,0.7c0.5,0,1.1-0.3,1.5-0.7c0.4-0.4,0.7-1,0.7-1.5S327.3,262.6,326.9,262.1L326.9,262.1z M326.9,273.2
	c1.2-0.3,2.3-0.7,3.2-1.4c1.1-0.8,1.8-1.9,2.2-3.4c0.3-0.7,0.4-1.5,0.4-2.2 M332.8,266l-0.1-6.1"
          />
          <path
            fill="none"
            d="M332.5,271.5c-1.1,0.8-2.3,1.2-4,1.5l-1.6,0.1 M334.7,268.1c-0.4,1.5-1.2,2.6-2.2,3.4 M257.4,254.2
	c2.5-0.7,4.8-1.6,7-2.6l0.3-0.1c22.3-11.1,42.1-20.8,59.4-28.9c17.5-8.3,35.8-15.7,55-22.6 M326.6,234.5l-2.8,16.1 M413.8,281
	c-1,0.4-1.6,0.7-1.8,1.2 M419.8,279.9v-0.8c-0.1-0.7-0.4-1-1.1-0.7l-1,0.3c-1.8,0.8-3.2,1.5-4.1,2.3"
          />
          <path
            fill="none"
            d="M411.9,282.2c0.5-1.4,2.3-2.8,5.5-4.1l0.4-0.1c3.9-1.5,8.3-2.5,13.6-3c5.5-0.5,10-0.3,13.9,0.5
	c1.8,0.4,3.3,1,4.3,1.5"
          />
          <path
            fill="none"
            d="M419.8,279.9l-2.6,0.3c-1.5,0.3-2.6,0.4-3.4,0.8 M414.5,285.5c0.8,0.4,2.1,0.8,3.3,1.1l1.4,0.3
	c0.5,0,1-0.1,1.2-0.4c0.1-0.1,0.1-0.4,0.1-0.8l-0.1-0.7"
          />
          <path
            fill="none"
            d="M413.4,285.5c1,0.5,2.3,1.1,4.1,1.5l0.3,0.1c3.9,0.8,8.4,0.8,13.6,0.4c5.5-0.5,10-1.5,13.9-3.2
	c2.9-1.1,4.7-2.3,5.4-3.6 M411.9,282.2c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.7,0.3,1.2,1,1.8 M414.5,285.5l-0.8-0.4 M412.7,284.8l0.7,0.7
	 M413.4,285.5h1.1 M420.4,285l-5.9,0.5 M441.3,275.4h-1c-0.5,0-0.8,0.1-1.1,0.5c-0.3,0.4-0.4,1-0.4,1.6"
          />
          <path
            fill="none"
            d="M439.2,283.1l0.4,0.8c0.4,0.5,1,0.7,1.6,0.5h0.1l0.1-0.1 M438.8,277.6l-19,2.3 M420.4,285l18.8-1.9 M472.7,254
	l-0.4,3.2 M450.9,280.4l0.1-0.4l0.1-0.4v-0.3c0-0.7-0.3-1.2-0.8-1.8l-0.7-0.5 M450.8,280.7l0.1-0.3 M444.9,281.1l0.5-0.4
	c0.3-0.3,0.6-0.7,0.6-1.1c0-0.4-0.1-0.8-0.5-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4s-0.4,0.7-0.4,1.1
	c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.5,0.4,0.8,0.5"
          />
          <path
            fill="none"
            d="M443.9,281.3h0.3l0.7-0.1 M444.9,281.1l-1,0.1 M449.1,281.7l0.8-0.4l0.8-0.5 M449.7,277l-1.2-0.3 M448.5,276.7
	c-0.7-0.3-1.7-0.5-2.6-0.8l-4.5-0.7 M440.9,277.3l0.4-1.9 M448.5,276.7l-7.6,0.5 M449.1,281.7c-0.8,0.3-1.7,0.5-2.9,0.7l-5.1,0.5
	 M441.2,282.9l0.5,1.5 M441.7,284.4c4.3-1.2,6.7-2.1,7.3-2.8 M441.2,282.9l-0.3-5.6 M469.1,278.2l3.2-21 M469.1,278.2l-0.3,6.5
	 M472.7,254l-14,1.4 M450.9,280.4l18.2-2.2 M439.2,283.1l1.9-0.1 M438.8,277.6l2.1-0.3 M482.4,190.2l-9.9,0.3
	c-50.5,2.6-100.4,16-149.7,40 M470.7,195.4c-48.7,2.8-96.8,15.8-144.2,39.1 M458.6,255.4l12.1-60 M334.7,268.1l123.9-12.7
	 M375.1,195.6l4,4.3 M332.5,271.5l139.8-14.3 M619.3,179.9l-0.3,2.1 M632.1,179.2c-1-0.3-1.9-0.4-3.2-0.4l-9.6,1.1 M637.9,182.2
	l-1-0.6 M637.6,184.3l0.3-2.1 M637.6,184.3l-9.8-1.4l-8.8-1 M637.9,182.2l-9.8-1.5l-8.8-0.8 M636.9,181.7l-4.8-2.5 M482.4,190.2
	l-0.8,5 M492.1,195c-3.4,0-6.9,0-10.5,0.1 M602.8,198.9l3.3,5.5 M612.8,205.7l-6.7-1.4 M649.2,214.1c-10.7-2.9-21.5-5.5-32.6-7.7
	l-3.7-0.7 M626.1,238.8l-2.1,0.6l-5.2,0.4 M472.3,257.2l152.1-15l3.3-0.7 M627.7,241.5l-1.7-2.8 M626.1,238.8
	c1.8-0.4,3.4-1.1,4.8-1.9 M606.1,204.4l19.9,34.4 M631,236.8l-18.2-31.1 M583.7,263.2l-2.6,0.3c-1.5,0.1-2.6,0.4-3.4,0.8"
          />
          <path
            fill="none"
            d="M583.7,263.2v-1c0-0.7-0.4-0.8-1.1-0.5c-0.1,0-0.4,0.1-0.5,0.1l-0.4,0.1c-1.8,0.7-3.3,1.5-4.1,2.3"
          />
          <path
            fill="none"
            d="M613.4,260.4c-1-0.5-2.5-1.1-4.3-1.5c-3.9-0.8-8.5-1-13.9-0.4c-5.2,0.4-9.8,1.5-13.6,3l-0.4,0.1
	c-3.2,1.2-5.1,2.8-5.6,4.1 M580.1,269.6l2.1,0.5l0.7,0.1c0.5,0,1-0.1,1.2-0.4c0.1-0.1,0.3-0.4,0.1-0.7l-0.1-0.7 M575.7,265.6
	l-0.3,0.8 M577.5,264.5c-1,0.3-1.7,0.7-1.8,1.2 M577.1,268.9c0.5,0.4,1.2,0.7,1.9,1 M575.4,266.4c0,1,0.5,1.8,1.7,2.5 M578.2,268.9
	h-1.1"
          />
          <path
            fill="none"
            d="M579.1,269.7l2.5,0.5c3.8,0.8,8.4,0.8,13.6,0.4c5.5-0.5,10.2-1.5,13.9-3.2c1.9-0.8,3.4-1.5,4.4-2.3
	 M579.1,269.7l1-0.1 M578.2,268.9l1.9,0.7 M584.1,268.3l-5.9,0.5 M631,263l0.4-0.8 M612.2,260.2c-0.7-0.3-1.7-0.5-2.6-0.8
	c-1.4-0.3-2.9-0.5-4.5-0.7 M605,258.7h-1c-0.5,0-0.8,0.1-1.1,0.5s-0.4,1-0.4,1.6 M602.7,260.9l1.9-0.1"
          />
          <path
            fill="none"
            d="M602.9,266.4l0.4,0.8c0.4,0.5,1,0.7,1.7,0.5h0.1l0.1-0.1 M604.9,266.3l-1.9,0.1 M604.6,260.8l0.3,5.5
	 M604.9,266.3l0.5,1.5 M604.6,260.8l0.4-2.1 M614.5,264.1l0.4-0.7v-0.1"
          />
          <path
            fill="none"
            d="M614.9,263.2v-0.6c0-0.7-0.3-1.2-0.8-1.8l-0.7-0.5 M613.4,260.4l-1.2-0.1 M605.4,267.8c3.6-1,5.8-1.8,6.9-2.3
	l0.5-0.4 M612.8,265l0.8-0.4l0.8-0.5"
          />
          <path
            fill="none"
            d="M612.8,265l-2.3,0.7l-5.6,0.5 M614.5,264.1c-0.3,0.4-0.5,0.8-1.1,1.2 M612.2,260.2l-7.6,0.5 M620.1,287.7
	c1-1.2,1.9-3,2.8-5.2l8.3-19.4 M631.4,262.1l-16.5,1.1 M613.5,265.3L631,263 M631.4,262.1c-0.3-7-1.5-13.9-3.7-20.5 M606.1,204.4
	l-6.3-1.1 M602.7,260.9l-19,2.3 M584.1,268.3l18.8-1.9 M618.8,239.7l-19-36.5 M602.8,198.9c-37.7-6.6-77.9-9.5-120.5-8.7
	 M575.4,266.4l-34.5,3.4l-71.8,8.4 M599.8,203.3c-34.1-5.8-70-8.5-107.7-8.4 M492.1,195l-5.6,57.6 M486.4,252.7l132.3-12.9
	 M468.8,284.7c36.3-5.1,73-10,110.3-15 M481.6,195.2l-8.9,58.9 M486.4,252.7l-13.8,1.4 M481.6,195.2l-10.9,0.3 M155.3,285.7
	c-3.3,0.5-6.5,1.2-9.8,2.1c-7.6,2.3-13.9,4.5-19.3,6.9c-5.9,2.5-11.7,5.1-17.3,7.7c-3,1.4-6.1,2.9-8.8,4.5 M209.8,279.9l-28.8,4
	c-5,0.5-9.9,1-14.7,1h-0.4c-3.3,0-6.7,0.3-10,0.8 M254.1,255c-7.6,1.5-15,3-22.1,4.5l-1.1,0.3 M209.8,279.9l-4.5,2.5
	c-6.1,3.3-12.2,6.5-18.4,9.5c-5.4,2.6-10.9,5.2-16.2,7.7 M230.8,259.8h-16.2c-34.8,6.1-67.3,15.5-97.1,28.5
	c-8,3.6-15.5,7.4-22.7,11.7c-1.1,0.7-2.2,1.4-3.2,2.2 M317.9,238.8l-4,2.2c-8.8,4.7-17.5,9.6-26.3,15c-9.1,5.5-17.6,11.4-25.7,17.7
	l-0.1,0.1 M254.1,255l3.3-0.8 M290,275.9l-12.2,1.2c-5.1,0.8-9.8,0.8-14,0.1c-1.8-0.3-3.4-0.7-5.1-1.1l-5.8-2.1"
          />
          <path
            fill="none"
            d="M261.6,273.7h0.1c1.8,0.4,3.6,0.5,5.5,0.7l10.5-0.5l13.5-1.4 M262.7,266.5l-5.4-12.4 M252.8,274
	c3.2-2.5,6.5-5,9.9-7.4 M261.6,273.7l-8.8,0.3 M311.2,253.9l1-4.7c0.3-1.8-0.5-2.3-2.3-1.6c-6.7,3.7-13.5,7.6-20.1,11.7
	c-5.5,3.4-10.9,7.3-16.1,11.6c1.4,0.5,4.4,0.7,8.9,0.3l20.9-2.2 M315.2,252.2c1.2-0.4,2.8-0.8,4.1-1.1 M311.2,253.9
	c1.2-0.7,2.6-1.2,4-1.8"
          />
          <path
            fill="none"
            d="M303.5,269l-1.5-5.4c-0.4-1.1-0.1-1.9,0.4-2.8c2.5-2.9,5.5-5.2,8.8-7 M310.6,268.2l-5.5,0.7 M305.1,268.9
	l-1.6,0.3 M303.5,269.2V269 M305.1,268.9l-1.6,0.1 M332.6,259.9l-15.4,2.2h-0.1c-1.9,0.3-3.6,1.1-4.7,2.3c-0.8,1-1.5,2.1-1.9,3.6
	 M332.8,266l-16.5,1.5l-5.6,0.7 M291.2,272.6l-1.2,2.9v0.4 M301.3,276.7l-2.2,6.5c-0.5,2.1-0.3,3.7,1,5.2s2.9,2.6,5,3.2
	c4.7,0.5,7.7-1.5,9.4-6.3l2.8-8 M318.3,274.4c0.4-0.4,0.8-0.5,1.4-0.5l7.2-0.7 M317.1,277.1l0.7-1.9c0.1-0.4,0.4-0.7,0.5-0.8
	 M302,274.7l-0.7,2.1 M303.5,269.2c0.1,0.7,0,1.5-0.3,2.2 M303.1,271.4l-1.1,3.3 M317.1,277.1c-5.2,1.2-10.6,1.1-15.8-0.5
	 M290,275.9l12-1.2 M318.3,274.4l-14.9-5.2 M291.2,272.6l11.8-1.2 M322.9,230.6c-11.8,5.8-23.7,12.2-35.5,19.3
	c-8.5,5.2-16.8,10.9-24.8,16.8 M317.9,238.8l-2.8,13.5 M252.8,274l-43.1,5.9 M285.3,311.7l-0.8-11.7 M281.5,322.1l1.6,7.6
	c-5.6-4.8-10.3-8.9-14.3-12.7c-14.3-13.1-31.6-19.7-51.9-19.7c-15.4,0-29.2,3.9-41.3,11.6 M279.4,312.6c-4.8-1.6-9.4-3.7-13.5-6.1"
          />
          <path
            fill="none"
            d="M265.9,306.6c1.8,1.4,3.6,2.9,5.4,4.4c3.9,3.4,7.3,7.2,10.2,11.1 M260.5,302.9h-0.3c1.8,1.4,3.7,2.6,5.6,3.7
	 M266.5,389.4c0.7-3.4,1-6.9,1-10.5c0-12.7-4-23.7-12.1-33c-0.8-1.1-1.8-2.1-2.8-3c-10-9.9-22-15-36.2-15s-26.1,5-36.2,15
	c-9.9,9.9-15,22-15,36.2c0,3.7,0.4,7.2,1.1,10.6 M265.9,306.6c-1.8-1.4-3.6-2.6-5.4-3.7"
          />
          <path
            fill="none"
            d="M242.9,352.4c-2.5-2.5-5.2-4.7-8.3-6.2c-2.3-1.4-5-2.5-7.6-3.3c-3.4-1-7-1.5-10.7-1.5c-3.9,0-7.4,0.5-10.7,1.5
	c-5.8,1.7-11.1,4.8-15.7,9.5h-0.1l-1.5,1.8c-2.3,2.5-4.1,5.2-5.6,8.1c-2.5,5.1-3.9,10.6-3.9,16.8c0,6.1,1.2,11.7,3.9,16.8
	c1.8,3.6,4.1,6.7,7.2,9.8c1.1,1.1,2.3,2.2,3.6,3.2c6.6,5.2,14.3,7.8,23.1,7.8c8.8,0,16.5-2.6,23.1-7.8l3.4-3.2
	c3.2-3.3,5.8-6.7,7.6-10.6c2.2-4.8,3.4-10.2,3.4-16s-1.1-11.1-3.4-16c-1.5-3.2-3.4-6.2-5.9-8.9L242.9,352.4L242.9,352.4z"
          />
          <path
            fill="none"
            d="M247.6,360.2c-1.2-2.2-2.9-4.1-4.7-6.2l-0.8-1c-2.3-2.3-4.7-4.1-7.3-5.8h-0.1l-1.8-1 M236.7,365.3l8.3-5.5h0.3
	 M248.9,362.4l-1.2-2.2 M251.1,367.7l-0.7-1.8c-0.4-1.2-1-2.3-1.5-3.4 M248.9,362.4l-1.5,0.8 M247.6,360.2l-2.3-0.4 M222.6,375.6
	c4.4-2.5,8.9-5.1,13.2-7.7l1.8-1.1c2.9-1.8,5.6-3.6,8.5-5.5c0.6-0.4,1-0.7,1.5-1 M251.7,388c0.7-2.9,1.1-5.9,1.1-9.1s-0.3-6.1-1-8.9
	l-0.6-2.3 M250.4,372.1c-3.4,0.5-6.7,1.2-9.9,1.9"
          />
          <path
            fill="none"
            d="M250.2,386l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9c0-2.1-0.1-4-0.4-5.9l-0.1-1 M250.2,386c-3.4-0.5-6.7-1.2-9.9-1.9
	 M251.1,390.2l0.7-2.2"
          />
          <path
            fill="none"
            d="M250.4,372.1l1.4-2.1l-13.6,3c-4.7,1.1-9.4,2.3-13.8,3.7 M249.4,368.2l1.6-0.5 M248.7,395.6
	c0.7-1.2,1.2-2.5,1.6-3.7l0.6-1.6 M251.7,388l-1.5-2.1 M251.1,390.2l-1.8-0.4 M275.7,389.4l0.1,2.1l6.1-0.1 M266.5,389.4h9.2"
          />
          <path
            fill="none"
            d="M275.7,389.4l-0.5-11c-0.8-16.6-6.9-30.5-18.3-42.1c-11.4-11.4-25-17.1-41.1-17.1c-8.8,0-17.1,1.8-24.5,5.2
	 M247.3,363.3c0.8,1.5,1.5,3.2,2.1,5 M469.9,376.5L298,381c-3.3,0.1-5.2-1-6.1-3.2c-0.4-1.4-0.7-3.2-0.8-5.2l-0.3-2.3 M290.8,370.1
	c-3.6-0.1-5.5-1-6.1-2.6c-0.8-2.8,1-4.4,5.2-5.1"
          />
          <path
            fill="none"
            d="M186.8,320.1c8.8-4.7,18.4-7,29-7c17.7,0,32.9,6.6,45.4,19.7c12.5,13.2,19.3,29.3,20.1,48.4l0.5,10.2
	 M290,362.6l0.8,7.6 M260.5,302.9l23.9-2.9 M285.3,311.7l-5.9,1 M290,362.6c-1.8-16.5-3.3-33.6-4.5-50.9 M279.4,312.6l2.1,9.5"
          />
          <path
            fill="none"
            d="M225.3,343.5l-3.4-0.7l-5.5-0.4l-5.5,0.4c-1.2,0.1-2.3,0.4-3.4,0.7l-2.3,0.7 M175.5,308.9l-4-2.5h-0.1
	 M167.6,300.8l2.6-1.1 M166.7,301.2l0.8-0.4 M166.7,301.2l-3.4,1.5c-14.6,6.1-29.7,11.1-45.4,15l-19.8,4.4c-4.7,0.8-7.6-0.1-8.9-2.9
	l-1-2.9c-0.6-2.1-0.3-3.9,0.8-5.2c1.7-1.8,3.4-3.6,5.4-5.2c0.7-0.4,1.2-1,1.9-1.4 M171.4,306.4l-4.7-5.2"
          />
          <path
            fill="none"
            d="M175.5,308.9c-3.7,2.3-7.3,5.1-10.7,8.1c-11.7,10.7-18.7,23.1-21.2,37.4c0.1-1.4,0.3-2.8,0.6-4.1
	c2.2-15.1,9.4-28.2,21.7-39.3c1.8-1.6,3.6-3.2,5.5-4.5 M191.2,324.5l-4.4-4.4 M155.9,389.5c0.7-3.3,1.1-7.3,1.1-11.7
	c0.1-2.5,0.3-5,0.6-7.3c1.6-13.1,7.4-24.5,17.1-34.3c5.1-5.1,10.7-9.1,16.8-11.8 M186.8,320.1l-11.3-11.1"
          />
          <path
            fill="none"
            d="M150.4,390.2c0.4-2.9,0.6-6.2,0.7-9.8c0-2.9,0.3-5.6,0.6-8.4c1.8-15.1,8.1-28.2,18.7-39.3
	c5.1-5.2,10.6-9.5,16.5-12.7 M200.6,348l-1-1.5 M197.3,350.1l5.5,8.5 M212.9,372.8l-5-8.7l-10.5-16.4l-0.4,2.3 M197.3,350.1
	c-1.8,1.1-3.4,2.5-5,4.1l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4 M199.7,346.5c-0.7,0.3-1.4,0.7-2.1,1.1c-2.5,1.5-4.8,3.4-7,5.5l-0.8,1
	c-1.9,2.1-3.4,4.1-4.8,6.3 M187.2,360.1l-2.3,0.4 M184.9,360.4c-0.4,0.7-0.8,1.4-1.1,2.1 M185.4,363.3l-1.5-0.8 M183.8,362.4
	c-0.4,0.8-0.8,1.6-1.2,2.5c-0.4,1-0.7,1.9-1.1,2.8 M195.9,365.5l-8.5-5.4h-0.1 M202.8,358.4c-1.2,1-2.6,1.9-3.7,3.2
	c-0.8,0.8-1.5,1.6-2.2,2.5l-1,1.4 M196.3,365.7c0.4-0.5,0.8-1.1,1.4-1.6c0.8-0.8,1.6-1.8,2.8-2.5l0.8,1 M195.9,365.5l0.6,0.3
	 M181,369.9c-0.7,2.9-1.1,5.9-1.1,9.1s0.3,6.1,1,8.9l0.7,2.5 M194.5,374.4l3.3,1 M197.8,375.4c-0.3,0.1-0.7,0.4-1,0.8
	c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.2,1.1,2.9l0.6,0.5 M192.3,383.8c-0.3-1.5-0.4-3.2-0.4-4.8c0-1.8,0.1-3.4,0.6-5.1
	 M192.3,373.8l2.1,0.5 M194.5,374.4c-0.3,1.5-0.4,3-0.4,4.5s0.1,2.9,0.4,4.3 M183.4,368.2l9.8,2.6c0.3-1,0.7-1.8,1.1-2.6l-8.8-5
	 M208.4,376.6c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.5c-3.3-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3 M181.6,367.7l1.8,0.5 M182.4,371.8
	l0.1,0.1c3.3,0.4,6.6,1.1,9.8,1.8 M182.4,385.8c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2 M192.3,383.8
	c-3.2,0.8-6.5,1.4-9.6,1.8h-0.1l-0.1,0.1 M181.6,390.2l1.8-0.5 M208.4,381.1l-7,1.9l-8.8,2.2l-9.9,2.2h-0.1l-1.6,0.3l1.4-1.9
	 M194.4,383.2l-2.1,0.5 M197.4,382.4l-3,0.8 M187.4,397.9l-2.3-0.4c0.6-0.3,1-0.7,1.5-1c2.8-1.9,5.6-3.7,8.5-5.5l1.8-1.1
	c4.3-2.8,8.7-5.2,13.2-7.7 M183.4,389.7l9.8-2.8c0.3,0.8,0.7,1.8,1.1,2.6l-8.8,5.1 M181.6,367.7c-0.3,0.7-0.4,1.4-0.6,2.2
	 M185.4,363.3c-0.8,1.5-1.5,3.2-2.1,5 M181,369.9l1.5,2.1 M184.9,360.4l12,7.6c4.4,2.6,8.8,5.1,13.3,7.6 M233,346.3l-1,1.6
	 M235.2,349.9l-2.6,4.3c-1,1.4-1.8,2.9-2.8,4.4 M245.3,359.8c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4c-1.6-1.6-3.4-3-5.2-4.3
	 M235.2,349.9l-0.4-2.3l-7.4,11.8l-2.8,4.7c-1.7,2.9-3.3,5.8-4.8,8.7 M223.4,345c-0.6,3.2-1.1,6.1-1.8,9.1l-0.3,0.8 M227.7,344.1
	l-2.2-0.7h-0.1 M225.3,343.5l-1.9,1.5 M227.1,345.9l0.6-1.8 M229.9,358.4l-1.1,1.8 M232.1,348l-5,8.8c-0.8-0.4-1.8-0.8-2.6-1.1
	l2.6-9.8 M235,366.4l1.8-1.2 M236.7,365.3c-0.6-0.8-1.2-1.6-2.1-2.5l-1.1-1.2c-1.2-1.2-2.5-2.2-3.9-3.2 M235,366.4
	c-0.6-0.8-1.2-1.6-1.9-2.5l-1-1c-1.1-1.1-2.2-2.1-3.4-2.9 M233,346.3c-1.8-0.8-3.4-1.6-5.4-2.2 M232.1,348c-1.6-0.8-3.2-1.5-5-2.1
	 M218.7,371.1l3.7-13.6l2.9-13.9 M221.3,355c-1.6-0.3-3.3-0.4-5.1-0.4l-4.8,0.4 M211.4,354.9l-0.1-0.8c-0.7-2.9-1.2-5.9-1.8-9.1
	l-0.1-0.1 M205,344.1l0.6,1.8 M209.5,345l-2.1-1.4l0.3,1.8c0.6,3,1.2,5.9,1.9,8.8l4.4,16.9 M201.3,362.6l1.1,1.5
	c0.8,1.4,1.5,3.2,2.2,5.1 M201.3,362.6c0.8-0.8,1.8-1.5,2.8-2.2 M202.8,358.5l1.2,1.9 M200.6,348l5.1,8.8c0.8-0.4,1.8-0.8,2.6-1.1
	l-2.8-9.8 M218.2,365.7l0.6-1.8c0.8-2.3,1.5-4.7,2.2-7 M212,357.1c0.6,2.3,1.2,4.8,2.1,7l0.6,1.8 M220.9,357.1l-4.5-0.4l-4.4,0.4
	 M214.6,365.7h3.6 M223.4,345c-2.2-0.4-4.5-0.7-7-0.7c-2.3,0-4.7,0.3-6.9,0.7 M212,357.1l-0.6-2.1 M217.2,369.6
	c0.3,0.3,0.4,0.6,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-0.8,0.4s-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-1s0.1-0.7,0.4-0.8
	c0.3-0.3,0.6-0.4,0.8-0.4C216.7,369.3,216.9,369.3,217.2,369.6L217.2,369.6z M214.6,369l0.1-0.1c0.4-0.4,1-0.7,1.5-0.7
	c0.7,0,1.2,0.3,1.6,0.7l0.1,0.1 M218,372.1c-0.4,0.4-1,0.7-1.7,0.7s-1.1-0.3-1.5-0.7 M218,372.1l0.6-0.7l0.1-0.3 M218.7,371.1v-0.6
	c0-0.5-0.1-1-0.6-1.4 M219.7,372.8c-0.6-0.3-1.1-0.5-1.6-0.7 M214.6,369c-0.4,0.4-0.6,1-0.6,1.5v0.4 M214.2,371l0.1,0.4
	c0.1,0.3,0.3,0.6,0.4,0.7 M214.7,372.1c-0.7,0.1-1.2,0.3-1.8,0.7 M210.2,375.5c0.3-0.5,0.7-1.1,1.2-1.5c0.6-0.5,1.1-0.8,1.6-1.2
	 M202.1,369.2l2.5,1.5 M204.6,369.2h-2.5 M204.6,370.7v-1.5 M205.1,371c-0.3-0.1-0.4-0.3-0.7-0.3 M201.4,376.5l1.8,0.5 M206.5,377
	c0.4-0.3,0.8-0.5,1.5-0.5h0.6 M209.5,377.2c-0.3-0.3-0.6-0.4-0.8-0.5l-0.3-0.1h-0.1 M206.6,378.8c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.6-0.4,0.8-0.4c0.3,0,0.6,0.1,0.8,0.4s0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4
	c-0.4,0-0.7-0.1-0.8-0.4C206.8,379.5,206.6,379.2,206.6,378.8L206.6,378.8z M206.5,380.6l-0.1-0.1c-0.4-0.4-0.7-1-0.7-1.5
	c0-0.7,0.3-1.2,0.7-1.7l0.1-0.1 M209.5,377.2c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.5v0.1 M210.2,375.5
	c-0.3,0.6-0.6,1.1-0.7,1.6 M208.4,367.8l-3.2,3.2 M203.2,377h3.3 M205.1,371l5,4.5 M209.5,380.5l-1.1,0.6 M209.5,380.5
	c0.1,0.5,0.3,1.1,0.7,1.8 M206.5,380.6c0.4,0.4,1,0.5,1.5,0.5h0.4 M201.4,381.1l0.8-0.3l0.4-0.1l0.1-0.1l0.4-0.1 M204.5,387.2
	c-0.6,0.4-1.2,0.7-1.9,1.1 M205.1,386.8l-0.7,0.4 M204.5,388.3v-1.1 M204.5,388.3h-1.9 M203.2,380.6h3.2 M210.1,382.4l-5,4.5
	 M205.1,386.8l3.2,3.2 M219.7,385.1c-0.6,0.3-1.1,0.5-1.8,0.7 M214.6,385.8c0.6-0.5,1.1-0.7,1.6-0.7s1.1,0.3,1.6,0.7l0.1,0.1
	 M214.6,385.8c-0.6-0.1-1.1-0.4-1.6-0.7 M212.9,385.1c-0.6-0.3-1.1-0.7-1.5-1.2c-0.6-0.5-1-1.1-1.2-1.6 M217.9,388.9l-0.1,0.1
	c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.3-1.6-0.7l-0.1-0.1 M214.5,388.7c-0.3-0.4-0.6-0.8-0.6-1.5v-0.5 M213.9,386.8l0.1-0.3l0.6-0.7
	 M215,387.3c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8
	c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4C215.1,388,215,387.6,215,387.3L215,387.3z M218.4,386.9v0.4c0,0.6-0.1,1.1-0.6,1.5
	 M217.9,385.8c0.1,0.1,0.3,0.4,0.4,0.7l0.1,0.4 M203.2,377v3.6 M201.4,381.2v-4.7 M208.4,390.1l4.5-5 M212.9,372.8l-4.5-5
	 M238.3,374.5c0.3,1.4,0.4,2.9,0.4,4.4c0,1.5-0.1,3-0.4,4.5 M240.3,384c0.3-1.6,0.6-3.3,0.6-5.1c0-1.6-0.1-3.3-0.4-5 M240.3,374
	l-2.1,0.5 M247.3,363.3l-8.8,5.1l1.1,2.6l9.9-2.8 M226.1,377.3c-0.4-0.4-1-0.5-1.5-0.5h-0.4 M219.7,372.8c0.6,0.3,1.1,0.7,1.5,1.1
	c0.6,0.5,1,1.1,1.2,1.6 M223.1,377.3c-0.6,0.4-0.8,1-0.8,1.6c0,0.5,0.3,1.1,0.7,1.6h0.1 M226,378.9c0,0.3-0.1,0.7-0.4,1
	c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4s-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,0.8,0.4
	C225.9,378.3,226,378.5,226,378.9L226,378.9z M224.2,376.7l-0.4,0.1c-0.3,0.1-0.4,0.1-0.7,0.4 M223.1,377.3l-0.7-1.6 M238.3,374.5
	c-2.8,0.7-5.4,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1 M229.4,377.3h-3.2 M226.1,377.3l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.6
	l-0.1,0.1 M222.6,375.5l4.8-4.5 M227.4,371l-3.2-3.2 M229.4,380.9h-3.3 M238.3,383.5c-2.8-0.7-5.2-1.5-7.8-2.5l-0.4-0.1l-0.7-0.1
	 M226.1,380.7c-0.4,0.3-0.8,0.5-1.4,0.5h-0.6 M223.1,380.7l0.7,0.5l0.3,0.1 M222.4,382.4c0.3-0.5,0.6-1.1,0.7-1.6 M222.4,382.4
	c-0.3,0.5-0.7,1.1-1.2,1.5c-0.6,0.5-1.1,1-1.6,1.2 M227.4,386.9l-4.9-4.5 M224.2,390.1l3.2-3.2 M247.7,397.4l-1.5-1
	c-2.9-1.9-5.8-3.7-8.7-5.4l-1.8-1.1c-4.4-2.6-8.8-5.2-13.3-7.6 M249.3,389.8l-9.8-2.6l-1.1,2.6l8.8,5 M240.3,384l-2.1-0.5
	 M224.1,381.3c4.4,1.4,9.1,2.6,13.8,3.7l13.8,3 M229.4,380.9v-3.6 M235,391.3c-2.5-1.5-5.1-3-7.6-4.4 M227.4,371l7.4-4.5
	 M224.2,367.8l-4.5,5 M221.3,355l-0.6,2.1 M214.6,365.7v3.2 M218.2,369v-3.3 M219.7,385.1l4.5,5 M228.6,360.2l-4.4,7.6 M204,360.4
	l4.4,7.4 M205,344.1c-1.8,0.5-3.6,1.4-5.4,2.2 M205.5,345.9c-1.8,0.5-3.4,1.2-5,2.1 M197.4,382.4l4-1.2 M202.6,388.3
	c-1.6,1-3.3,1.9-4.9,3l-1.1,0.7 M197.8,375.4l3.6,1.2 M202.1,369.2l-5.8-3.4 M236.7,392.4c-1,1.4-1.9,2.6-3.2,3.9
	c-1.1,1.1-2.3,2.2-3.7,3.2 M219.7,385.1c2.5,4.5,5.1,8.9,7.7,13.3l1.1,1.8c1.8,2.9,3.6,5.6,5.5,8.4v0.1l1,1.4l0.4-2.2 M227.1,412
	l-2.8-9.8c0.8-0.3,1.8-0.7,2.6-1.1l5.1,8.8 M229.9,399.3l-1.1-1.8 M227.5,413.6l-0.4-1.8 M218.4,386.9c1.4,4.4,2.6,8.9,3.7,13.8
	l3,13.6l-1.9-1.2 M223.1,412.9l-0.1-0.1l-0.7-4.1c-0.3-1.9-0.7-3.9-1.1-5.6 M207.2,414.3c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.3,8.8-1
	l2.3-0.7 M231.9,409.9l0.8,1.5 M229.9,399.3l5.5,8.4v0.3 M232.9,411.4l4.7-2.9c1.5-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3
	 M235,391.3c-0.8,1.2-1.8,2.3-2.9,3.4c-1.1,1.1-2.2,1.9-3.3,2.9 M227.5,413.6c1.8-0.5,3.6-1.2,5.4-2.2 M231.9,409.9
	c-1.6,0.8-3.2,1.5-5,1.9 M235.4,408c1.9-1.2,3.7-2.8,5.5-4.5s3.3-3.7,4.5-5.6 M220.6,401c-0.7-2.8-1.5-5.2-2.5-7.8l-0.3-1
	 M211.7,400.8c0.7-2.6,1.5-5.2,2.3-7.8l0.1-0.4l0.1-0.5 M211.7,400.8l-0.6,2.1 M220.6,401l-4.4,0.4l-4.5-0.4 M214.5,392h3.6
	 M221.2,403l-5,0.4l-5.1-0.4 M202.8,399.4c0.4-0.7,0.7-1.2,1.1-1.8 M205.5,412l2.6-9.8c-0.8-0.3-1.8-0.7-2.6-1.1l-5,8.8
	 M205.1,413.6l2.2,0.5 M209.2,412.9l-2.1,1.4 M211.3,402.9l-1.2,5.6l-0.7,4.1l-0.1,0.1 M205.1,413.6l0.6-1.8 M209.2,412.9
	c2.2,0.4,4.5,0.7,7,0.7c2.3,0,4.7-0.1,6.9-0.5 M213.9,386.8c-1.4,4.4-2.6,8.9-3.7,13.8l-3,13.8 M221.2,403l-0.6-2.1 M202.8,399.4
	c-1.4-1-2.6-1.9-3.9-3.2c-1.2-1.2-2.2-2.5-3.2-3.7 M196.6,392c1.1,1.4,2.3,2.8,3.9,3.9l0.6-0.7 M196.6,392l-0.7,0.4 M183.8,395.6
	l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7c1.5,1.5,3,2.8,4.5,4c0.8,0.5,1.6,1.2,2.5,1.8 M195.9,392.4l-8.4,5.5h-0.1 M185.4,394.6l-1.5,1
	 M181.6,390.2l1.1,2.8l1.1,2.6 M197.4,408v-0.1l5.4-8.4 M197.7,410.3h0.1 M197.8,410.3l1.9,1.1 M197.8,410.3h-0.1 M200.7,409.9
	l-1,1.5 M197.7,410.3l-0.3-2.3"
          />
          <path
            fill="none"
            d="M187.4,397.9c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.6,3.3,5.6,4.7"
          />
          <path
            fill="none"
            d="M166.3,389.5c1.5,7.7,4.8,14.9,9.9,21.2c1.2,1.5,2.5,2.9,4,4.4c10,9.9,22,15,36.2,15c13.2,0,24.5-4.4,34.1-13.1
	l2.1-1.9c7.4-7.4,12.1-16,13.9-25.7 M199.7,411.4c1.6,0.8,3.4,1.6,5.2,2.2 M204,397.6l-2.9-2.3 M205.5,412c-1.6-0.5-3.3-1.2-5-1.9"
          />
          <path
            fill="none"
            d="M212.9,385.1c-2.5,4.5-5,8.9-7.6,13.3l-7.6,12 M247.8,397.4l1-1.8 M247.2,394.8l1.5,0.8 M245.4,397.8h-0.1
	l-8.5-5.4 M247.8,397.4l-2.3,0.4 M183.4,389.7c0.6,1.6,1.2,3.3,2.1,5 M201.1,395.3c1.2-1.6,2.3-4,3.4-6.9 M208.4,390.1l-4.4,7.6
	 M236.7,392.4l-1.8-1.1 M228.8,397.5l-4.5-7.4 M217.9,392v-3.2 M214.5,392v-3.3 M247.2,394.8c0.8-1.6,1.5-3.3,2.1-5 M154.1,305.2
	c-12.5,3.9-24.5,7.2-36,10l-0.3,0.1l-19.1,4.4l-2.3-0.1l-1-0.5l-0.6-0.8l-1-2.9c-0.6-1.9-0.3-3.6,0.8-5l0.1-0.1l5.4-3.3 M96.6,304.7
	l-5.1-2.5 M96.6,304.7h0.1l3.4,2.2 M91.5,302.2c-1.4,1.1-2.8,2.2-3.9,3.6c-2.3,2.6-4.5,5.5-6.6,8.5 M84.6,327.4l0.4,1.4 M84.6,327.4
	l-0.6-1.4 M84.1,326h-0.8c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4s0.1,0.3,0.1,0.4l0.4,0.1h1.2 M87.5,338.1
	c0-0.8-0.1-1.6-0.3-2.5l-0.8-2.5 M85,332h1 M86.4,333.3H85 M86.4,333.3L86,332 M85,332h-2.2c-0.1,0-0.3,0-0.4,0.1
	c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.4,0.1,0.5l0.4,0.1H85 M85,338.1h-2.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4
	s0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1H85 M87.4,339.5l0.1-1.4 M85.3,344.3c0.7-1.2,1.2-2.6,1.8-4.1l0.1-0.7"
          />
          <path
            fill="none"
            d="M80.6,350.3h0.3c2.1-1,3.6-2.5,4.8-4.5l0.3-0.4l2.3-5v-0.1l0.3-1.1l0.1-1.2c0-1-0.1-1.9-0.3-2.8l-5.9-18.8
	c-0.4-1.1-0.8-1.8-1.5-2.3 M85,333.3v4.8 M85,338.1h2.5 M87.4,339.5H85 M85,328.7v3.3 M86,332l-1-3.3 M230.8,259.8
	c-45.7,9.5-86.3,22.1-121.7,38.1c-4.4,2.1-8.5,4.3-12.4,6.7 M85,344.8v-0.5 M85,344.3h0.3 M85,344.8l0.3-0.5"
          />
          <path
            fill="none"
            d="M84.6,345.5l0.1-0.3l0.3-0.4 M85,344.3h-2.9c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.5
	c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h2.5 M86.1,364.4l1.2-6.7c0.3-1-0.1-1.2-1-1.1l-5.8,1.9 M86,365.5l0.1-1.1
	 M100.4,357.5c4.1-2.5,9.4-4.3,15.5-5.5l19.7-4.3c2.5-0.5,4.1-0.4,5.1,0.4c1.1,0.8,1.2,2.3,0.6,4.7c-1.2,5.2-3,10-5.5,14.7
	c-1,1.8-2.6,3-5,3.6l-35.2,5.1l-4.5,0.3l-0.3-0.1c-1.2-0.3-1.8-1-1.7-2.1l0.1-0.3c1.1-2.8,2.3-5.4,3.8-7.8l3.2-4.8
	C97.5,359.5,98.9,358.3,100.4,357.5L100.4,357.5z"
          />
          <path
            fill="none"
            d="M99.2,364.5l-0.6,1l24.3-3.3v-2.3l-22.4,3.2C100.1,363.4,99.6,363.8,99.2,364.5L99.2,364.5z"
          />
          <path
            fill="none"
            d="M91.2,375c0.6-1.2,1.1-2.3,1.8-3.6l1.4-2.5l0.1-0.1c0.6-1.1,1.2-2.1,1.9-3l0.6-0.8c0.6-0.7,1.1-1.2,1.7-1.8
	c0.7-0.7,1.5-1.2,2.2-1.5c4-2.3,9.1-4.1,15.1-5.2l13.3-2.8"
          />
          <path
            fill="none"
            d="M137.7,348.8l-21.6,4.5c-6.1,1.2-11.1,3-15.1,5.4c-1.4,0.7-2.6,1.8-3.8,3.3l-2.5,3.7c-1.7,2.6-3,5.5-4.3,8.4
	c0,0.3,0.3,0.4,0.7,0.5h0.1"
          />
          <path
            fill="none"
            d="M95.1,371.1l27.8-3.7v-2.2l-26.6,3.6C95.9,369.5,95.5,370.3,95.1,371.1L95.1,371.1z M91.2,375l3.9-0.3l35.4-5.1
	 M85.4,369h-0.8c-0.1,0-0.3,0-0.4,0.1s-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0.1,0.5c0.1,0.1,0.3,0.1,0.4,0.1h0.7 M85.2,370.3l0.3-1.2
	 M88.6,378.2l-1.9,0.3c-1.1,0.1-2.1,0.5-3,1.2 M85.2,370.3l-1.5,9.5 M78.6,383.8v0.8c0,0.8,0.3,1.4,0.8,1.6c0.7,0.4,1.5,0.8,2.6,1.4
	l3.2,1.5c1.4,0.5,2.9,1,4.8,1.1h1.8 M96,390.2h-4.3"
          />
          <path
            fill="none"
            d="M88.6,378.2c-1.2,0.3-2.6,1-4,2.2l-3.7,3.3 M115.4,390.2l-13.6,1.8l-5.8-1.8 M86,365.5l-0.6,3.6 M115.4,390.2
	H96 M94.8,354.7l48-11.6c-5.5-0.8-13.5,0-23.7,2.3C107.4,348.3,99.3,351.4,94.8,354.7z M130.5,369.6c1.9-0.4,3.2-1.2,3.9-2.8
	c2.3-4.5,4.1-9.4,5.4-14.4v-0.1c0.4-1.5,0.4-2.5-0.1-3c-0.4-0.3-1.1-0.4-2.1-0.4"
          />
          <path
            fill="none"
            d="M129.4,353.5l0.6-0.4c2.1-1.6,4.7-3,7.7-4.1 M127.4,355.6c0-0.3-0.1-0.5-0.6-0.5h-0.7c-0.4,0-0.6,0.1-0.6,0.5
	v13.3c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5 M129.4,353.5l-2.1,2.1 M130.4,369.6h0.1 M127.4,368.9c1,0.4,1.9,0.5,3,0.7
	 M130.5,369.6v-0.1l-0.1,0.1 M80.9,383.8h2.6l67.3-12.4h-11.7c-2.5-0.1-5.1,0.1-7.8,0.5l-42.6,6.3 M150.4,390.2h5.4l0.1-0.7
	 M146.6,390.2h3.7 M127.4,368.9v-13.3 M85,339.5v4.8 M146.6,390.2h-31.2 M80.9,314.3l-0.7,1c0.4,0.4,0.8,1,1,1.7l2.9,9.1
	 M80.6,358.4v7H86 M80.6,350.3v8.1 M84.6,345.5c-1,1.6-2.3,2.9-3.9,3.6v1.2 M78.6,383.8h2.3 M91.8,390.2l10.7,3.3l44.2-3.3
	 M83.6,379.8l-5.1,4 M86.1,364.4H81 M155.9,389.5h10.5 M167.6,300.8l-13.3,4.3 M620.1,287.7c-10.5,3.2-19.9,8.7-28.5,16.4
	c-9.6,8.8-16.2,19-19.9,30.5c3.9-7.7,9.4-14.7,16.5-21c7.3-6.5,15.4-11.4,24.1-14.7 M581.3,383.5h8.8 M575,383.6l6.3-0.1 M636,355
	l-0.1-0.8c-0.7-2.9-1.2-5.9-1.7-9.1H634 M632.1,343.5l-0.1,0.1l-2.2,0.5 M634,345l-1.9-1.5 M637.6,372.8c-1.5-2.9-3.2-5.8-4.8-8.7
	l-2.8-4.7l-7.4-11.8v-0.1l-0.4,2.3 M629.8,344.1c-1.8,0.5-3.6,1.2-5.4,2.2 M624.4,346.3l1,1.6"
          />
          <path
            fill="none"
            d="M624.4,346.3c-0.5,0.3-1.2,0.5-1.8,1h-0.1c-2.6,1.5-5,3.4-7.3,5.8l-0.8,1c-1.8,1.9-3.4,4-4.7,6.2 M622.2,349.9
	v0.1l5.4,8.4 M627.6,358.4c-1.2,0.8-2.6,1.9-3.9,3.2l-1.1,1.2c-0.7,0.8-1.4,1.6-1.9,2.5 M622.5,366.4l-1.8-1.1 M628.7,360.2
	c-1.2,0.8-2.3,1.8-3.4,2.9l-0.8,1c-0.7,0.8-1.4,1.5-1.9,2.5 M627.6,358.4l1.1,1.8 M639.1,365.7l-0.1-0.5l-0.4-1.1
	c-0.8-2.3-1.5-4.7-2.2-7 M638.7,371.1c-0.7-2.3-1.4-4.7-1.9-7l-1.8-6.7l-2.9-13.9 M625.4,348l5,8.8c0.8-0.4,1.8-0.8,2.6-1.1
	l-2.6-9.8 M636.5,357.1L636,355 M630.2,345.9l-0.4-1.8 M625.4,348c1.7-0.8,3.2-1.5,5-2.1 M622.2,349.9c-1.8,1.2-3.6,2.6-5.2,4.3
	l-0.4,0.4c-1.7,1.6-3.2,3.4-4.5,5.4 M608.4,362.4c-0.5,1.1-1.1,2.3-1.5,3.4l-0.7,1.8 M609.7,360.2l-1.2,2.2"
          />
          <path
            fill="none"
            d="M620.7,365.3c-2.9-1.8-5.6-3.6-8.4-5.5h-0.1 M612,359.8l-2.3,0.4 M610,363.3l-1.5-0.8 M617,384
	c-0.3-1.6-0.4-3.3-0.4-5.1c0-1.6,0.1-3.3,0.4-5 M617,374l2.1,0.5 M619,374.5c-0.3,1.4-0.4,2.9-0.4,4.4c0,1.5,0.1,3,0.4,4.5
	 M607.9,368.2l9.9,2.8c0.3-1,0.7-1.8,1.1-2.6l-8.8-5.1 M607.1,372.1l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9s0.1,4,0.4,5.9l0.1,1.1
	 M606.2,367.7l-0.5,2.3c-0.7,2.9-1,5.8-1,8.9s0.3,6.2,1.1,9.1 M606.2,367.7l1.7,0.5"
          />
          <path
            fill="none"
            d="M633.1,376.7c-4.4-1.4-8.9-2.6-13.8-3.7l-13.8-3l1.4,2.1"
          />
          <path
            fill="none"
            d="M617,374c-3.2-0.8-6.5-1.4-9.8-1.8l-0.1-0.1 M605.7,388l0.7,2.2 M606.4,390.2l0.5,1.6c0.4,1.2,1.1,2.5,1.7,3.7
	 M607.1,386l-1.4,2.1 M606.4,390.2l1.7-0.4 M607.1,386c3.4-0.5,6.7-1.2,9.9-1.9 M619,383.5L617,384 M608,389.8l9.8-2.6
	c0.3,0.8,0.7,1.8,1.1,2.6l-8.9,5 M633.2,381.3c-4.4,1.4-9.1,2.6-13.8,3.7l-13.8,3 M639.3,369c-0.4,0.4-0.6,0.8-0.6,1.4v0.5
	 M637.6,372.8c0.5-0.3,1.1-0.5,1.7-0.7 M638.7,371.1l0.1,0.3l0.4,0.7 M633.1,367.8l-3.2,3.2 M634.9,375.6v-0.1 M633.1,376.7h-0.4
	c-0.5,0-1.1,0.1-1.5,0.5 M631.4,378.9c0,0.3,0.1,0.7,0.4,1s0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-1-0.4c-0.3,0-0.7,0.1-0.8,0.4C631.6,378.3,631.4,378.5,631.4,378.9L631.4,378.9z
	 M631.1,377.3l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.6l0.1,0.1 M634.2,380.7c0.5-0.5,0.7-1.1,0.7-1.6
	c0-0.7-0.1-1.2-0.5-1.6l-0.1-0.1"
          />
          <path
            fill="none"
            d="M634.2,377.3c-0.1-0.1-0.4-0.3-0.7-0.4l-0.4-0.1 M634.2,377.3c0.1-0.5,0.4-1.2,0.7-1.6 M634.9,375.5
	c0.3-0.5,0.7-1.1,1.2-1.6c0.5-0.4,1-0.8,1.5-1.1 M633.1,367.8l4.5,5 M634.9,375.5l-5-4.5 M628,377.3l-0.7-0.1l-0.4-0.1
	c-2.6-1-5.2-1.8-7.8-2.5 M619,383.5c2.6-0.7,5.2-1.5,7.8-2.5l0.4-0.1l0.7-0.1 M637.7,385.1c-0.5-0.3-1.1-0.8-1.7-1.2
	c-0.4-0.4-0.8-1-1.1-1.5 M639.4,385.8c-0.5-0.1-1.1-0.4-1.7-0.7 M631.3,380.7c0.4,0.3,0.8,0.5,1.4,0.5h0.5 M634.9,382.4
	c-0.3-0.5-0.4-1.1-0.7-1.6"
          />
          <path
            fill="none"
            d="M633.2,381.3l0.3-0.1l0.7-0.4 M633.2,390.1l-3.3-3.2 M638.8,386.9v0.4c0,0.6,0.1,1.1,0.5,1.5 M639.4,385.8
	c-0.1,0.1-0.3,0.4-0.4,0.7c-0.1,0.1-0.1,0.3-0.1,0.4 M629.9,386.9l5-4.5 M637.7,385.1l-4.5,5 M609.7,360.2l11.8,7.7
	c4.4,2.6,8.8,5.2,13.2,7.7 M628,380.9l3.3-0.1 M631.1,377.3H628 M629.9,386.9c-2.5,1.4-5.1,2.9-7.6,4.4 M609.5,397.4l1.5-1
	c2.9-1.9,5.8-3.7,8.7-5.4l1.8-1.1c4.4-2.6,8.8-5.2,13.3-7.6 M628,380.9v-3.7 M639.1,365.7l0.1,3.3 M629.9,371l-7.4-4.5 M610,363.3
	c-0.8,1.5-1.5,3.2-2.1,5 M628.7,360.2l4.4,7.6 M612.4,298.9c-16.8,24.8-33.8,47.6-51.3,68.4c-3.9,4.5-9.5,7-17.1,7.3l-74.1,1.9
	 M636.6,401c0.7-2.8,1.5-5.2,2.5-7.8l0.3-1 M630.3,412l2.8-9.8c-0.8-0.3-1.8-0.7-2.5-1.1l-5.1,8.8 M636.2,403l0.4-2.1"
          />
          <path
            fill="none"
            d="M634.2,412.9l-1.9,1.2l2.5-11.6c0.1-0.7,0.3-1.4,0.4-2.1c1.1-4.7,2.3-9.4,3.7-13.8 M620.5,392.4
	c1,1.4,1.9,2.6,3.2,3.9c1.1,1.1,2.5,2.2,3.7,3.2 M628.7,397.5c-1.2-0.8-2.3-1.8-3.4-2.9c-1.1-1.1-2.1-2.2-2.9-3.4 M627.4,399.3
	l1.2-1.8 M624.4,411.4c1.8,0.8,3.4,1.6,5.4,2.2 M625.4,409.9l-1,1.5"
          />
          <path
            fill="none"
            d="M627.4,399.3l-5.5,8.4l0.1,0.3 M622.1,408l0.3,2.2l7.6-11.7c2.8-4.4,5.2-8.8,7.8-13.3 M634.2,412.9l0.1-0.1
	l0.7-4.1c0.3-1.9,0.7-3.9,1.2-5.6 M629.8,413.6l0.5-1.7 M625.4,409.9c1.7,0.8,3.2,1.5,5,1.9 M622.1,408c-1.9-1.2-3.7-2.8-5.5-4.5
	c-1.8-1.8-3.3-3.7-4.7-5.6 M620.5,392.4c-2.9,1.8-5.6,3.6-8.4,5.4H612 M608.6,395.6l1,1.8"
          />
          <path
            fill="none"
            d="M609.5,397.4c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4l4.5,2.9 M611.9,397.8l-2.3-0.4 M610.1,394.8l-1.5,0.8
	 M639.4,392v-3.2 M608,389.8c0.6,1.6,1.2,3.3,2.1,5 M622.3,391.3l-1.8,1.1 M628.7,397.5l4.5-7.4 M467.2,358.2l0.5-7.6 M469.9,376.5
	l-2.8-18.3 M290.8,370.1l78.8-3.4c31.8-1.5,64.2-4.4,97.5-8.5 M467.2,358.2c28.9-3.6,58.2-8.1,88.3-13.6l-87.8,6.1 M281.9,391.3
	l293.1-7.7 M375.1,195.6c-41.8,16.5-82.3,36.3-121.1,59.4 M467.7,350.6c-0.4-22,0-44,1.1-65.9 M612.4,298.9l7.7-11.1 M321.8,236.8
	l-3.9,1.9 M284.5,300l128.2-15.1 M468.8,284.7c-62.7,8.8-123.8,17.7-183.4,27 M284.5,300l5.5-24.1 M467.7,350.6l-177.7,12
	 M171.4,306.4c13.5-10,29.2-15.1,47.2-15.1c15.7,0,29.7,3.9,41.8,11.6 M319.3,251l2.5-14.2 M648.8,185.9l-11.1-1.6 M631,263
	l13.3-1.6l-12.9,0.8 M642.7,365.7h-3.6 M643,392h-3.6 M666.5,152.4c-0.4,0.3-0.8,0.7-1.2,1.2l-23.5,19.1c-2.9,2.3-6.2,4.5-9.6,6.6
	l4.8,2.5c3.4-4.1,7.2-7.8,11-11.1l18.7-15.1l1.5-1l0.8-0.8l0.4-0.6v-0.1c0.1-0.4,0.1-0.8,0-1.1v-0.1h-0.1c-0.1-0.3-0.5-0.3-1-0.3
	l-0.7,0.1C667.2,152,666.8,152.1,666.5,152.4L666.5,152.4z M649.2,193.4l0.1,1.2c3.6,7.6,8.9,15.1,15.7,22.8
	c4.5,1.6,8.8,3.4,13.1,5.4l2.1-3.6l1.9-3.4l2.8-5.1c2.9-5.6,5.5-11.1,7.7-16.5c-6.9-1.5-13.9-2.9-21.3-4.4l-0.3,0.8l-1.1,3.4
	c-0.5,1.8-2.1,2.5-4.5,1.9l-5-0.8L649.2,193.4L649.2,193.4z"
          />
          <path
            fill="none"
            d="M649.3,194.6l-0.1-1.2l-18.8-2.6c-45.7-6.6-94.2-9.5-145.8-8.7l-12,0.3c-22.3,1.1-44.7,4.3-67,9.4l-19,4.3
	c-0.1,0.3-0.3,0.6-0.7,0.8c-0.5,0.6-1.2,1-2.3,1.4l-4.3,1.5c-19.3,6.9-37.6,14.4-55,22.6c-17.3,8.1-37.1,17.7-59.4,28.9l-0.3,0.1
	c-2.2,1-4.5,1.9-7,2.6l5.2,12.4c8-5.9,16.2-11.4,24.8-16.8c11.8-7,23.5-13.5,35.5-19.3c49.2-24.1,99-37.4,149.7-40l9.9-0.3
	c42.6-0.8,82.8,2.1,120.5,8.7c14.2,2.5,28.1,5.5,41.5,9.1l3.2,0.8c1.5,0.6,2.8,1.2,3.4,2.1c1.7,1.8,1.5,4.1-0.3,7
	c-3.9,6.2-8,11.6-12.2,16.4c-1.2,1.4-2.5,2.5-3.9,3.6l-2.3,1.6c-1.5,1-3.2,1.6-4.8,2.1c2.2,6.7,3.4,13.6,3.7,20.5l12.9-0.8
	l-13.3,1.6l-8.3,19.4c-0.8,2.2-1.8,4-2.8,5.2c7.3-2.2,15.1-3.3,23.5-3.3c17.2,0,32.2,4.7,45.1,14l8.5-15.8
	c-5.2-6.1-9.6-12.8-13.1-20.2c-2.1-1.4-3.9-3.2-5.2-5.6L663,232.8c-0.5-1.1-0.8-2.2-0.8-3.4c0-1.1,0.1-2.2,0.4-3.4l2.3-8.7
	C658.2,209.9,652.9,202.3,649.3,194.6L649.3,194.6z M668.8,286.1c-1.9-1.9-2.9-4.3-2.9-7c0-2.8,1-5.1,2.9-7c1.9-1.9,4.3-2.9,7-2.9
	c2.8,0,5.1,1,7,2.9c1.5,1.5,2.3,3.2,2.8,5.1c0.1,0.5,0.1,1.2,0.1,1.9c0,0.8-0.1,1.6-0.3,2.5c-0.4,1.8-1.4,3.3-2.6,4.5
	c-1.9,1.9-4.3,2.9-7,2.9C673.1,289.1,670.8,288.1,668.8,286.1L668.8,286.1z"
          />
          <path
            fill="none"
            d="M665.9,279.1c0,2.8,1,5.1,2.9,7c1.9,1.9,4.3,2.9,7,2.9c2.8,0,5.1-1,7-2.9c1.4-1.4,2.2-2.9,2.6-4.5h-0.1
	c-1.1,0-2.2-0.3-3-0.7s-1.2-1-1.2-1.5s0.4-1.1,1.2-1.5s1.8-0.7,3-0.7h0.1c-0.4-1.9-1.2-3.6-2.8-5.1c-1.9-1.9-4.3-2.9-7-2.9
	c-2.8,0-5.1,1-7,2.9C666.9,274,665.9,276.3,665.9,279.1L665.9,279.1z"
          />
          <path
            fill="none"
            d="M681.2,279.3c0,0.5,0.4,1.1,1.2,1.5c0.8,0.4,1.8,0.7,3,0.7h0.1c0.1-0.8,0.3-1.5,0.3-2.5c0-0.7,0-1.4-0.1-1.9
	h-0.1c-1.1,0-2.2,0.3-3,0.7C681.6,278.2,681.2,278.8,681.2,279.3L681.2,279.3z M649.2,192.5l-0.4-6.6l-11.1-1.8l-9.8-1.4l-8.8-0.8
	c-34.4-3.4-68.9-5.2-103.6-5.8c-14.4,0-28.8,0.4-42.9,1.2l-4.3,0.3c-20.9,1.4-49.5,5.9-85.8,13.9l3.6,3.7l19.7-4.5
	c22.3-5.1,44.7-8.3,67-9.4l12-0.3c51.6-0.8,100.1,2.1,145.8,8.7L649.2,192.5L649.2,192.5z"
          />
          <path
            fill="none"
            d="M648.8,185.9l0.4,6.6v0.8l11.1,1.8c2.1,0.3,3.4-0.4,3.9-2.1l1.2-3.3l0.3-1L648.8,185.9L648.8,185.9z"
          />
          <path
            fill="none"
            d="M649.2,193.4v-0.8l-18.8-2.6c-45.7-6.6-94.2-9.5-145.8-8.7l-12,0.3c-22.3,1.1-44.7,4.3-67,9.4l-19.7,4.5
	l0.5,0.6l19-4.3c22.3-5.1,44.7-8.3,67-9.4l12-0.3c51.6-0.8,100.1,2.1,145.8,8.7L649.2,193.4L649.2,193.4z M670.9,190.8l-5.4-1
	l-1.2,3.3c-0.4,1.6-1.8,2.3-3.9,2.1l4.7,0.8c2.5,0.6,4.1,0,4.5-1.9L670.9,190.8L670.9,190.8z M681.9,215.8l-1.9,3.4
	c10,4.8,20.1,10,29.8,15.5l-25-16.1C682.9,217.7,681.9,216.8,681.9,215.8L681.9,215.8z"
          />
          <path
            fill="none"
            d="M684.7,210.7l-2.8,5.1c0,1,1,1.9,2.9,2.9l25,16.1l0.7,0.4c6.2,3.6,12.2,7.3,18.3,11c2.3,1.5,4.8,2.9,7.3,4.3
	C721.2,236,704,222.7,684.7,210.7z"
          />
          <path
            fill="none"
            d="M687.3,227.2l0.3,0.1c6.3,3.3,12.2,6.7,17.7,10.5l0.4,0.3l4,2.9c7.4,5.4,14,11.1,20.1,17.5v9.4
	c0,1.4,0.1,2.8,0.3,4.1c0.1,1.2,0.4,2.5,0.8,3.7l1,3.2c1.9,5.6,4,10.9,6.3,15.7c1.9,3.6,4,6.6,6.2,9.4c-2.9-8.8-5.2-18.6-7-29.3
	l-0.3-6.1l-0.3-6.1c0-1.2-0.1-2.3-0.6-3.4c-0.3-1-0.7-1.8-1.1-2.6l-6.1-10c-6.1-3.9-12.2-7.6-18.3-11l-0.7-0.4
	c-9.8-5.6-19.8-10.9-29.9-15.5l-2.1,3.6C681.2,224.2,684.2,225.7,687.3,227.2L687.3,227.2z"
          />
          <path
            fill="none"
            d="M687.4,227.3l-0.3-0.1c-2.9-1.5-5.9-3-9.1-4.4c3.2,1.5,6.2,3,9.1,4.7l0.3,0.1c5.8,3.2,11.3,6.6,16.4,10.2
	l0.5,0.4l1.1-0.1l-0.4-0.3C699.6,234.1,693.7,230.5,687.4,227.3L687.4,227.3z"
          />
          <path
            fill="none"
            d="M687.4,227.6l-0.3-0.1c-3-1.6-6.1-3.3-9.1-4.7c-4.3-1.9-8.5-3.7-13.1-5.4l-2.3,8.7c-0.3,1.2-0.4,2.3-0.4,3.4
	c0,1.2,0.3,2.3,0.8,3.4l16,23.9c1.5,2.3,3.2,4.3,5.2,5.6c1,0.5,1.9,1.1,3,1.5c4.3,1.8,8.7,3.4,13.2,5.1c4.7,1.6,9.5,3.2,14.4,4.7
	c-4-1.2-7.3-2.5-10-3.4c-5.5-2.1-8.8-3.6-10.2-4.4c-1.8-1-3.2-2.8-4.3-5.4l-0.7-1.4l-0.8-1.2l-1.2-1.6l-1-1.2l-1.5-1.8l-1.1-1.2
	l-8.3-10c-1.7-2.3-0.8-3.9,2.3-4.5c4.8-1,9.9-1.8,15.5-2.2h0.5c1.2,0,2.2,0.3,3.2,0.8l4.1,2.6l2.6-0.4l-0.5-0.4
	C698.7,234.2,693.2,230.9,687.4,227.6L687.4,227.6z M691.4,233.5h-0.3c-7.7,0.7-14.9,1.6-21.2,2.9c-1.4,0.1-2.5-0.1-3.2-0.7
	l-1.1-1.5l-1-1.4l-0.1-0.1c-0.3-0.6-0.4-1.1-0.5-1.8c-0.1-0.6-0.1-1-0.1-1.5c0-1.1,0.1-2.2,0.4-3.3l1.2-4.7l0.3-1.1
	c3.6,1.4,7,2.9,10.5,4.4c2.1,1,4.1,1.9,6.2,2.9l2.6,1.2l3.3,1.8l2.5,1.2l2.6,1.5L691.4,233.5L691.4,233.5z"
          />
          <path
            fill="none"
            d="M691,233.5h0.3l2.1-0.1l-2.6-1.5l-2.2,0.1c-0.1,0-0.1,0-0.3,0.1L691,233.5L691,233.5z M682.5,227.5l-2.2,0.1
	h-0.1c-5.5,0.4-10.9,1-16.1,1.6c0,0.6,0.1,1,0.1,1.5c6.1-0.8,12.2-1.5,18.6-1.9h0.1l2.2-0.1L682.5,227.5L682.5,227.5z"
          />
          <path
            fill="none"
            d="M680.1,227.6l2.2-0.1l-6.2-2.9c-3.4-1.5-6.9-2.9-10.5-4.4l-0.3,1.1c3.3,1.2,6.6,2.6,9.8,4.1
	c1.7,0.7,3.2,1.5,4.8,2.2L680.1,227.6L680.1,227.6L680.1,227.6z M682.7,229h-0.1c0.4,0.1,0.8,0.4,1.2,0.6l1.9,1.1h0.3l2.2-0.1
	l-3.3-1.8L682.7,229L682.7,229z"
          />
          <path
            fill="none"
            d="M683.8,229.5c-0.4-0.3-0.8-0.4-1.2-0.6c-6.2,0.4-12.4,1.1-18.6,1.9c0.1,0.6,0.3,1.2,0.5,1.8l0.1,0.1
	c7-1,14-1.6,21.2-2.1L683.8,229.5L683.8,229.5z"
          />
          <path
            fill="none"
            d="M688.4,232c0.1,0,0.1-0.1,0.3-0.1l2.2-0.1l-2.5-1.2l-2.2,0.1h-0.3c-7.2,0.4-14.2,1.1-21.2,2.1l1,1.4
	C673.1,233.1,680.7,232.4,688.4,232L688.4,232z"
          />
          <path
            fill="none"
            d="M691,233.5l-2.8-1.5c-7.7,0.4-15.3,1.1-22.7,2.2l1.1,1.5c0.7,0.6,1.7,0.8,3.2,0.7
	C676.1,235.2,683.3,234.2,691,233.5L691,233.5z M664.3,226c-0.3,1.1-0.4,2.2-0.4,3.3c5.4-0.7,10.7-1.2,16.1-1.6
	c-1.5-0.8-3.2-1.5-4.8-2.2c-3.2-1.4-6.3-2.8-9.8-4.1L664.3,226L664.3,226z M682.6,240.3c-0.7,0.1-1.2,0.3-1.8,0.6
	c-0.7,0.4-0.8,1.1-0.3,1.8l7.7,9.1l2.6-0.1l-1.5,1.5l1.5,1.8l2.8-0.1l-1.5,1.5h-0.1l1.1,1.6l3.6-0.3l-1.5,1.4l-1.2,0.1l0.4,0.8
	c1.2,2.6,4.3,4.8,9.1,6.7c6.7,3.2,14.6,5.9,23.8,8.4l0.4-3v-0.1l-0.8-0.1c-4-0.8-7.7-1.9-11.1-3.2l-2.5-1.1l-0.8-0.3l-3.6-1.8
	l-5.5-3.4l-3.4-3l-2.2-2.1l-7.8-8.1c-1.5-1.6-3-3.4-4.4-5.2c-1.2-1.4-1.4-2.2-0.1-2.8l16.5-2.2c-0.8-0.4-1.8-0.6-2.9-0.6h-0.5
	C692.6,238.5,687.4,239.2,682.6,240.3L682.6,240.3z"
          />
          <path
            fill="none"
            d="M680.8,240.8c0.4-0.3,1.1-0.4,1.8-0.6c4.8-1,10-1.8,15.5-2.2h0.5c1.1,0,2.1,0.1,2.9,0.6h0.3l-4.1-2.6
	c-1-0.6-1.9-0.8-3.2-0.8H694c-5.5,0.4-10.7,1.2-15.5,2.2c-3.2,0.7-4,2.2-2.3,4.5l8.3,10l4-0.3l-7.7-9.1
	C680,241.8,680.1,241.2,680.8,240.8L680.8,240.8z"
          />
          <path
            fill="none"
            d="M685.1,240.8c-1.1,0.4-1.1,1.4,0.1,2.8c1.5,1.8,2.9,3.6,4.4,5.2l7.8,8.1l2.2,2.1h27.5v8.9c0,1.4,0.1,2.8,0.3,4
	v0.1c0.1,1.1,0.3,2.2,0.5,3.2l0.8,2.9l1.2,0.3h0.1l-0.8-2.8c-0.4-1.2-0.7-2.5-0.8-3.7c-0.1-1.4-0.3-2.8-0.3-4.1v-8.9l-1.9-2.1
	c-5.2-5.2-10.7-10.2-16.8-14.9l-5.1-3.7l-2.6,0.3h-0.3L685.1,240.8L685.1,240.8z M686.3,243.2c-0.3-0.7-0.1-1,0.4-1.1l14.2-1.9h0.1
	c1.5-0.1,2.8,0,3.7,0.6l0.3,0.1l0.7,0.6l0.1,0.1l2.9,2.1c4.7,3.4,9.1,7.2,13.2,11c0.8,1.1,0.5,1.8-0.7,2.1l-0.5,0.1h-16.2l-2.8-0.3
	c-2.1-0.4-4-1.2-5.6-2.3l-0.6-0.4l-2.1-2.2l-7-8.1L686.3,243.2L686.3,243.2z"
          />
          <path
            fill="none"
            d="M686.9,242.1c-0.5,0.1-0.8,0.4-0.4,1.1l0.3,0.3c0-0.3,0.3-0.6,0.5-0.7l13.5-1.8c0.4,0.1,0.7,0.3,1.1,0.6
	l3.7,2.8c2.9,2.2,5.6,4.5,8.3,6.9l3,2.8c0.8,1,0.8,1.5-0.3,1.9l-1.4,0.1l-13.1-0.1l-2.6-0.6c-1.1-0.4-2.1-1-3-1.5h-0.5l0.5,0.4
	c1.8,1.2,3.6,2.1,5.6,2.3l2.8,0.3h13.5l0.7-0.4c0.3-0.3,0.4-0.6,0.3-1l-0.3-0.6v-0.1l-4.4-4.1c-2.6-2.3-5.4-4.7-8.3-6.9l-3.7-2.8
	c-0.4-0.3-1-0.6-1.5-0.7l-0.3-0.1L686.9,242.1L686.9,242.1z"
          />
          <path
            fill="none"
            d="M687.3,242.8c-0.4,0.3-0.6,0.4-0.6,0.7l7,8.1l2.8,2.2c1,0.6,1.9,1.1,3,1.5l2.6,0.6l13.1,0.1l1.4-0.1
	c1-0.3,1.1-1,0.3-1.9l-3-2.8c-2.6-2.3-5.4-4.7-8.3-6.9l-3.7-2.8c-0.3-0.3-0.7-0.4-1.1-0.6L687.3,242.8L687.3,242.8z M700.8,241.8
	l4,3c2.9,2.2,5.6,4.5,8.3,6.9l1.5,1.4l1.5,1.4l0.1,0.1c0.1,0.3-0.3,0.4-1.2,0.6l-12.8-0.1c-1-0.1-1.8-0.3-2.6-0.6l-1.5-0.7l-0.3-0.1
	l-0.3-0.1l-0.8-0.6l-0.1-0.1h-0.1l-0.3-0.3l-2.9-3l-5-5.9L700.8,241.8L700.8,241.8z"
          />
          <path
            fill="none"
            d="M700.5,242.9l2.9,2.2c2.9,2.2,5.5,4.5,8.3,6.9l1.9,1.8h-13.9c-1.1-0.1-2.2-0.3-3-0.8l0.8,0.6l0.3,0.1l0.4,0.1
	c1.1,0.4,2.5,0.7,3.9,0.7h12.2c1,0,1.1-0.4,0.3-1.2l-1.5-1.4c-2.6-2.3-5.4-4.7-8.2-6.9l-4-3l-12.5,1.6l5,5.9l2.9,3l0.3,0.3h0.1
	l0.1,0.1c0.8,0.4,1.9,0.7,3,0.8h1.7l-1.8-0.3c-1.2-0.3-2.2-0.8-2.9-1.6l-0.3-0.3l-6.2-7.4L700.5,242.9L700.5,242.9z"
          />
          <path
            fill="none"
            d="M703.4,245l-2.9-2.2l-10.5,1.4l6.2,7.4l0.3,0.3c0.8,0.7,1.8,1.2,2.9,1.6l1.8,0.3h12.2l-1.9-1.8
	C708.9,249.5,706.2,247.2,703.4,245L703.4,245z M700.3,243.9l2.1,1.5l0.1,0.1c2.5,1.9,5.1,4,7.4,6.2l1.5,1.5h-9.2
	c-1.8,0-3.3-0.4-4.5-1.2l-0.3-0.4l-0.5-0.6l-0.1-0.3l-3.6-4.1l-1.2-1.6L700.3,243.9L700.3,243.9z"
          />
          <path
            fill="none"
            d="M702.4,245.4l-2.1-1.5l-8.5,1.2l1.2,1.7l1.5-0.1l6.9-1.1c2.5,2.1,5,4,7.3,6.2h-8.5c-1.7,0.1-2.9,0-3.4-0.6
	l0.7,0.4l0.3,0.3c1.2,0.8,2.8,1.2,4.5,1.2h9.2l-1.5-1.5C707.5,249.5,705,247.4,702.4,245.4L702.4,245.4z"
          />
          <path
            fill="none"
            d="M701.6,245.5l-6.9,1.1l2.9,3.3c1,1.1,2.2,1.6,3.8,1.8h7.4C706.5,249.5,704,247.4,701.6,245.5L701.6,245.5z"
          />
          <path
            fill="none"
            d="M700.3,251.6h1.1c-1.7,0-2.9-0.7-3.9-1.8l-2.9-3.3l-1.5,0.1l3.6,4.1l0.1,0.3
	C697.6,251.6,698.7,251.7,700.3,251.6L700.3,251.6z M716,254.6l-1.5-1.5c0.8,0.8,0.7,1.2-0.3,1.2H702c-1.5,0-2.8-0.3-3.9-0.7H698
	l1.5,0.7c0.8,0.3,1.7,0.6,2.6,0.6l12.8,0.1C715.9,255,716.3,254.9,716,254.6L716,254.6z M697.9,253.8h0.1l-0.4-0.3 M701.4,240.3
	c0.5,0.1,1,0.3,1.5,0.7l3.7,2.8c2.9,2.2,5.6,4.5,8.3,6.9l4.4,4.1v0.1l0.3,0.6c0.1,0.4,0,0.8-0.3,1l-0.7,0.4h2.8l0.5-0.1
	c1.2-0.3,1.4-1,0.7-2.1c-4.1-3.9-8.5-7.6-13.2-11l-2.9-2.1l-0.1-0.1l-1.1-0.7C703.9,240.1,702.7,240,701.4,240.3l-0.3-0.1
	L701.4,240.3L701.4,240.3z"
          />
          <path
            fill="none"
            d="M705,240.8l1.1,0.6l-0.8-0.6 M718.2,256.8h0.1 M695.8,253.8h0.5l-2.6-2.2 M709.6,241l-4-2.9l-1.1,0.1l5.1,3.7
	c6.1,4.5,11.7,9.5,16.8,14.9l1.9,2.1v8.9c0,1.4,0.1,2.8,0.3,4.1c0.1,1.2,0.4,2.5,0.8,3.7l0.8,2.8l1.4,0.4l-1-3.2
	c-0.4-1.2-0.7-2.5-0.8-3.7c-0.1-1.4-0.3-2.8-0.3-4.1v-9.4C723.6,252.1,717,246.2,709.6,241L709.6,241z M651.2,210.8
	c-0.8-0.8-1.9-1.5-3.4-2.1l-3.2-0.8c-13.5-3.6-27.4-6.5-41.5-9.1l3.3,5.5l6.7,1.2l3.7,0.7c11,2.2,21.9,4.8,32.6,7.7L651.2,210.8
	L651.2,210.8z"
          />
          <path
            fill="none"
            d="M651,217.9c1.8-2.9,1.9-5.2,0.3-7l-2.1,3.3l-2.6,4c-4.3,6.5-8,11.6-11.1,15l-2.1,2.2c-0.7,0.7-1.5,1.2-2.3,1.7
	c-1.4,0.8-3,1.5-4.8,1.9l1.7,2.8c1.7-0.4,3.2-1.1,4.8-2.1l2.3-1.6c1.2-1.1,2.6-2.2,3.9-3.6C642.8,229.5,647,224,651,217.9L651,217.9
	z"
          />
          <path
            fill="none"
            d="M646.5,218.1l2.6-4c-10.7-2.9-21.5-5.5-32.6-7.7l-3.7-0.7l18.2,31.1c0.8-0.4,1.7-1,2.3-1.7l2.1-2.2
	C638.6,229.5,642.3,224.6,646.5,218.1L646.5,218.1z M642.3,216.9c1,0.4,1.2,1.1,0.8,1.9c-3.2,4.7-6.6,8.9-10.2,12.7
	c-0.7,0.6-1.4,0.6-1.9-0.1L620.4,213c-0.3-0.8-0.1-1.1,0.8-1.1C628.3,213.5,635.4,215.1,642.3,216.9L642.3,216.9z"
          />
          <path
            fill="none"
            d="M643.2,219c0.4-1,0.1-1.6-0.8-1.9c-7-1.8-14-3.4-21.2-5c-0.8,0-1.1,0.3-0.8,1.1l10.6,18.3
	c0.7,0.7,1.4,0.8,1.9,0.1C636.6,227.8,639.9,223.6,643.2,219L643.2,219z M699.8,258.8l3.6,3l5.4,3.4h0.1l3.6,1.8l0.8,0.3v-8.5
	 M713.3,267.4l2.5,1.1c3.4,1.2,7.2,2.3,11.1,3.2l0.8,0.1c-0.1-1.4-0.3-2.8-0.3-4v-8.9h-14.2L713.3,267.4L713.3,267.4z M693.6,254.6
	l-2.6,0.1l-4.1,0.3l1.1,1.2l4.1-0.3 M693.2,257.6l-1.1-1.7l-4.1,0.3l1.1,1.6"
          />
          <path
            fill="none"
            d="M696.8,257.5l-3.6,0.1l-4.1,0.3l0.8,1.2l4.1-0.3h1.2 M688.4,251.7l-4,0.3l1,1.2l4-0.3l1.5-1.4 M685.3,253.2
	l1.5,1.8l4.1-0.3l-1.7-1.8 M687.3,264.1c-1.1-0.4-2.2-1-3-1.5c3.4,7.4,7.8,14.2,13.1,20.2l3.2-13.5
	C696.1,267.5,691.7,265.7,687.3,264.1L687.3,264.1z M694.4,259.8L694,259l-4.1,0.3l0.7,1.4c1.1,2.6,2.5,4.4,4.3,5.4
	c1.4,0.8,4.7,2.3,10.2,4.4c2.8,1,6.1,2.2,10,3.4l13.1,3.9c-0.4-0.8-0.7-1.8-0.8-2.8v-0.1c-9.2-2.5-17.2-5.2-23.8-8.4
	C698.5,264.6,695.7,262.4,694.4,259.8L694.4,259.8z"
          />
          <path
            fill="none"
            d="M715.1,273.8c-5-1.5-9.8-3-14.4-4.7l-3.2,13.5c9.9,11.4,23.1,20.4,39.6,27.1c8.1,3.4,16.6,5.9,25.6,7.4
	l-6.9-5.8c-3.8-1-7-2.8-9.5-5.5l-1.8-2.1c-2.2-2.8-4.3-5.8-6.2-9.4c-2.3-4.8-4.5-10-6.3-15.7l-1.4-0.4h-0.1l-1.2-0.3l-0.8-0.3
	L715.1,273.8L715.1,273.8z M740.9,261.6c0.1-1.9-0.3-3.9-1.2-5.6l-3.3-5.5c-2.6-1.2-5.1-2.8-7.3-4.3l6.1,10c0.5,0.8,0.8,1.6,1.1,2.6
	c0.3,1.1,0.5,2.2,0.5,3.4l0.3,6.1h3.6l0.1-4L740.9,261.6L740.9,261.6z"
          />
          <path
            fill="none"
            d="M741.9,264.5h-1.1l-0.1,3.9l-0.1,4.1h1 M727.3,274.8c0.3,1.1,0.5,1.9,0.8,2.9l0.8,0.3c-0.4-1-0.7-1.9-0.8-2.9
	c-0.3-1.1-0.4-2.2-0.5-3.2L727.3,274.8z"
          />
          <path
            fill="none"
            d="M740.4,274.4l0.1-1.9l0.1-4.1h-3.6l0.3,6.1 M763.8,340.4l-0.3-1.9v1.9 M764.4,342.9c-0.3-0.8-0.5-1.6-0.7-2.5
	l-0.1,0.1c-8.8,2.5-14.9,9.5-18.3,21c-0.1,0.5-0.4,1.2-0.7,1.8l0.4-0.1c1-1.1,1.8-2.2,2.5-3.4c5.4-8.5,11.3-13.2,17.9-14.2
	L764.4,342.9L764.4,342.9z"
          />
          <path
            fill="none"
            d="M763.5,340.4l-0.1-1.9l-1-21.5c-8.9-1.5-17.5-4.1-25.6-7.4c-16.4-6.6-29.6-15.7-39.6-27.1l-8.5,15.8
	c2.3,1.6,4.7,3.6,6.9,5.6c2.6,2.3,5,4.8,7,7.3c5.2,5.9,14.4,9.8,27.8,11.3l20.4,1.9c-5.2,1.4-11,2.5-17.1,3.3
	c-5.8,0.5-10.7,0.7-14.9,0.3c-7.7-1.1-14.4-4.7-20.4-10.9l-3.9-3.7c-3.2-2.9-6.6-5.4-10.2-7.7l-8.9,14.9c3.7,2.6,7.2,5.6,10.6,9.1
	c10.7,11.1,16.9,24.2,18.7,39.3v0.1l39.5-6.1c0.3-0.6,0.5-1.1,0.7-1.8C748.6,350.1,754.8,343,763.5,340.4L763.5,340.4z M741.9,337.1
	c2.2-0.3,3.2,0.6,2.8,2.8c-2.6,7.4-8.1,11.1-16.4,11.3h-6.7c-5.1-0.5-8-3.6-8.7-9.1c-0.3-1,0.1-1.5,1.2-1.6l25-2.9L741.9,337.1
	L741.9,337.1z"
          />
          <path
            fill="none"
            d="M738.9,337.4c0.5,0.4,0.8,1.2,0.5,2.3c-2.1,7.3-7,11-15,11.3h3.7c8.4,0,13.8-3.7,16.4-11.3
	c0.4-2.2-0.4-3-2.8-2.8L738.9,337.4z"
          />
          <path
            fill="none"
            d="M739.4,339.9c0.3-1.1,0-1.9-0.5-2.3l-25,2.9c-1.1,0.1-1.5,0.5-1.2,1.6c0.8,5.5,3.7,8.5,8.7,9.1h3
	C732.3,350.9,737.3,347,739.4,339.9L739.4,339.9z M751,324.6l-20.4-1.9c-13.3-1.5-22.6-5.2-27.8-11.3c-2.1-2.5-4.4-5-7-7.3
	c-2.2-2.1-4.5-3.9-6.9-5.6l-4,7.4c3.6,2.2,6.9,4.8,10.2,7.7l3.8,3.7c5.8,6.2,12.7,9.8,20.2,10.9c4.3,0.4,9.2,0.3,14.9-0.3
	C740.1,327.1,745.7,326,751,324.6L751,324.6z M747.5,359.8c-0.7,1.2-1.5,2.3-2.5,3.4l-1.5,1.7l-12.7,11.3l3.4-0.7
	c3.4-0.5,6.9-1,10.6-1.2l10.2-1.1l7.7-10c1-1,1.5-2.5,1.8-4.4l1-13.3C758.8,346.5,752.9,351.2,747.5,359.8L747.5,359.8z"
          />
          <path fill="none" d="M743.4,364.9l1.7-1.7h-0.4" />
          <path
            fill="none"
            d="M743.4,364.9l1.2-1.7l-39.5,6.1c0.3,2.6,0.5,5.4,0.5,8.1l0.1,3.6l25-4.8L743.4,364.9L743.4,364.9z M684.8,305.8
	l4-7.4c-12.9-9.4-28.1-14-45.1-14c-8.4,0-16.2,1.1-23.5,3.3l-7.7,11.1c8.9-3.3,18.7-5.1,29.2-5.1
	C657.8,293.8,672.3,297.8,684.8,305.8z M675.8,320.6l-3.4,4.7c3.4,2.2,6.7,5,9.8,8.1c9.6,9.8,15.3,21.2,17.1,34.3l0.3,2.6l5.6-0.8
	v-0.1c-1.8-15.1-8.1-28.2-18.7-39.3C683,326.3,679.6,323.2,675.8,320.6L675.8,320.6z"
          />
          <path
            fill="none"
            d="M672.4,325.3l3.4-4.7c-10.3-7-21.9-10.6-34.9-10.6c-17.7,0-32.9,6.6-45.4,19.7c-12.7,13.2-19.4,29.3-20.1,48.4
	l-0.3,5.5l6.2-0.1l0.4-8.1c0.8-16.6,6.9-30.7,18.3-42.1s25-17.1,41.1-17.1C652.7,316.2,663.2,319.2,672.4,325.3z"
          />
          <path
            fill="none"
            d="M677.1,342.8c-9.9-9.9-22-15-36.2-15c-14,0-26.1,5-36,15c-1,1-1.9,2.1-2.8,3c-8.1,9.4-12.1,20.4-12.1,33
	c0,1.5,0.1,3,0.3,4.5c1,12.1,5.9,22.7,14.7,31.5l2.1,1.9c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15c1.5-1.5,2.8-2.9,4-4.4
	c6.9-8.4,10.5-18.2,11-29.2v-2.6C692.1,364.8,687.1,352.8,677.1,342.8L677.1,342.8z M641,341.4c3.8,0,7.4,0.5,10.9,1.5
	c5.8,1.7,11,4.8,15.7,9.5h0.1l1.7,1.8c2.3,2.5,4.1,5.2,5.6,8.1c2.5,5.1,3.9,10.6,3.9,16.8c0,6.1-1.2,11.7-3.9,16.8
	c-1.8,3.6-4.1,6.7-7.2,9.8c-1.1,1.1-2.2,2.2-3.4,3.2c-6.6,5.2-14.3,7.8-23.1,7.8s-16.5-2.6-23.1-7.8l-3.4-3.2
	c-3.3-3.3-5.8-6.7-7.6-10.6c-2.3-4.8-3.4-10.2-3.4-16s1.1-11.1,3.4-16c1.5-3.2,3.4-6.2,5.9-8.9l1.7-1.8c2.5-2.5,5.2-4.7,8.1-6.2
	c2.5-1.4,5-2.5,7.6-3.3C633.6,341.8,637.2,341.4,641,341.4L641,341.4z"
          />
          <path
            fill="none"
            d="M651.8,342.9c-3.4-1-7-1.5-10.9-1.5s-7.4,0.5-10.7,1.5c-2.6,0.8-5.2,1.8-7.6,3.3c-2.9,1.6-5.6,3.7-8.1,6.2
	l-1.7,1.8c-2.5,2.8-4.4,5.8-5.9,8.9c-2.3,4.8-3.4,10.2-3.4,16s1.1,11,3.4,16c1.8,3.9,4.4,7.4,7.6,10.6l3.4,3.2
	c6.6,5.2,14.3,7.8,23.1,7.8s16.5-2.6,23.1-7.8c1.2-1,2.3-2.1,3.4-3.2c3-3,5.5-6.3,7.2-9.8c2.5-5.1,3.9-10.6,3.9-16.8
	c0-6.1-1.2-11.7-3.9-16.8c-1.5-2.9-3.3-5.6-5.6-8.1l-1.7-1.8h-0.1C662.9,347.7,657.7,344.6,651.8,342.9L651.8,342.9z M641,342.5
	l5.5,0.4l3.4,0.7l2.3,0.7c1.8,0.5,3.6,1.4,5.4,2.2c0.7,0.3,1.4,0.7,2.1,1.1c2.5,1.5,4.8,3.4,7,5.5l0.8,1c1.9,2.1,3.6,4.1,4.8,6.3
	l1.1,2.1l1.2,2.5l1,2.8l0.7,2.2c0.7,2.9,1.1,5.9,1.1,9.1s-0.4,6.1-1.1,8.9l-0.7,2.5l-1,2.8l-1.2,2.6l-1.1,2.1
	c-1.5,2.5-3.4,4.8-5.6,7c-1.5,1.5-3,2.8-4.5,4l-2.6,1.8l-1.9,1.1c-1.7,0.8-3.4,1.6-5.2,2.2l-2.2,0.5c-2.9,0.7-5.9,1.1-9.1,1.1
	c-3,0-6.1-0.3-8.8-1l-2.3-0.7c-1.8-0.5-3.6-1.2-5.4-2.2l-4.5-2.9c-1.7-1.2-3.2-2.5-4.5-4c-2.2-2.2-4.1-4.7-5.6-7.3l-1-1.8
	c-0.5-1.2-1.2-2.5-1.7-3.7l-0.5-1.6l-0.5-2.2c-0.7-2.9-1.1-5.9-1.1-9.1s0.3-6.1,1-8.9l0.5-2.3l0.7-1.8c0.4-1.2,1-2.3,1.5-3.4
	l1.2-2.2c1.2-2.2,2.9-4.1,4.7-6.2l0.8-1c2.2-2.3,4.7-4.1,7.3-5.8h0.1c0.5-0.4,1.1-0.7,1.8-1c1.7-0.8,3.4-1.7,5.4-2.2l2.2-0.7h0.1
	l3.4-0.7L641,342.5L641,342.5z"
          />
          <path
            fill="none"
            d="M646.5,342.9l-5.5-0.4l-5.5,0.4l-3.4,0.7l1.9,1.5c2.2-0.4,4.5-0.7,7-0.7s4.7,0.3,6.9,0.7l2.1-1.4l-3,13.8
	l-3.7,13.6l-0.1,0.4l-0.5,0.7c0.7,0.1,1.2,0.3,1.8,0.7l5-8.7l6.2-10l3.3-5l1-1.5l0.4,2.3c1.8,1.1,3.4,2.5,5,4.1l2.3,2.5
	c1,1.1,1.8,2.2,2.6,3.4l2.3,0.4c-1.4-2.2-2.9-4.3-4.8-6.3l-0.8-1c-2.2-2.2-4.5-4.1-7-5.5c-0.7-0.4-1.4-0.8-2.1-1.1l-1,1.5l-5.1,8.8
	c-0.8-0.4-1.8-0.8-2.6-1.1l2.8-9.8l0.5-1.8l-2.3-0.7L646.5,342.9L646.5,342.9z"
          />
          <path
            fill="none"
            d="M660.2,350.2l-0.4-2.5l-1,1.5c-1.1,1.6-2.2,3.3-3.3,5l-6.2,10l-5,8.7l4.5-5l4.5-7.4l1.1-1.9L660.2,350.2
	L660.2,350.2z"
          />
          <path
            fill="none"
            d="M660,350.1l-5.5,8.5c1.2,1,2.6,1.9,3.7,3.2l3.2,3.9l8.5-5.4h0.1c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5
	C663.5,352.5,661.8,351.2,660,350.1L660,350.1z"
          />
          <path
            fill="none"
            d="M661.5,365.5l-0.5,0.3l-5.8,3.4l-2.5,1.5L652,371l-5.1,4.5c4.5-2.5,8.9-5,13.3-7.6l12-7.6l-2.3-0.4h-0.1
	L661.5,365.5L661.5,365.5z"
          />
          <path
            fill="none"
            d="M661,365.7l0.5-0.3l-3.2-3.9c-1.2-1.1-2.5-2.2-3.7-3.2l-1.1,1.9c1,0.7,1.8,1.4,2.8,2.2l0.8-1
	c1.1,0.8,1.9,1.6,2.8,2.5L661,365.7L661,365.7z M672,363.3l1.5-0.8l-1.1-2.1l-12,7.6c-4.4,2.6-8.8,5.1-13.3,7.6
	c0.3,0.6,0.5,1.1,0.7,1.6c0.3-0.3,0.5-0.4,0.8-0.5l0.3-0.1c4.4-1.4,8.9-2.6,13.6-3.7l2.1-0.5c3.2-0.8,6.6-1.5,9.9-2.2h0.1l1.7-0.3
	l-0.7-2.2l-1.8,0.5l-9.8,2.6l-1.1-2.6L672,363.3L672,363.3z"
          />
          <path
            fill="none"
            d="M673.7,362.4l-1.5,0.8c0.8,1.5,1.5,3.2,2.1,5l1.8-0.5l-1-2.8L673.7,362.4L673.7,362.4z M651.8,345.9
	c1.7,0.5,3.3,1.2,5,2.1l1-1.5c-1.8-1-3.4-1.7-5.4-2.2L651.8,345.9L651.8,345.9z"
          />
          <path
            fill="none"
            d="M656.7,348c-1.7-0.8-3.3-1.5-5-2.1l-2.8,9.8c1,0.3,1.8,0.7,2.6,1.1L656.7,348L656.7,348z M647.9,345
	c-2.2-0.4-4.5-0.7-6.9-0.7c-2.3,0-4.8,0.3-7,0.7h0.1c0.4,3,1,6.1,1.7,9.1l0.1,0.8c1.7-0.3,3.3-0.4,5.1-0.4l4.8,0.4l0.1-0.8
	C646.8,351.2,647.4,348.1,647.9,345L647.9,345z"
          />
          <path
            fill="none"
            d="M645.9,354.9l-4.8-0.4c-1.8,0-3.4,0.1-5.1,0.4l0.5,2.1l4.5-0.4l4.4,0.4L645.9,354.9L645.9,354.9z"
          />
          <path
            fill="none"
            d="M647.9,345c-0.5,3.2-1.1,6.2-1.8,9.1l-0.1,0.8l-0.5,2.1c-0.6,2.3-1.2,4.8-2.1,7L643,365l-0.1,0.5v3.2
	c0.4,0.4,0.5,1,0.5,1.5v0.4l3.7-13.6l3-13.8L647.9,345L647.9,345z"
          />
          <path
            fill="none"
            d="M645.3,357.1l-4.4-0.4l-4.5,0.4c0.7,2.3,1.4,4.8,2.2,7l0.4,1.1l0.1,0.5h3.6l0.1-0.5l0.4-1.1
	C644.1,361.7,644.8,359.4,645.3,357.1L645.3,357.1z M656.2,362.6c-0.8-0.8-1.8-1.5-2.8-2.2l-4.5,7.4l3.2,3.2l0.7-0.3v-1.5
	c0.7-2.1,1.5-3.7,2.2-5.1L656.2,362.6L656.2,362.6z"
          />
          <path
            fill="none"
            d="M656.9,361.6l-0.8,1l-1.1,1.5c-0.8,1.4-1.5,3.2-2.2,5.1h2.5l5.8-3.4l-1.4-1.6
	C658.9,363.1,658,362.3,656.9,361.6L656.9,361.6z"
          />
          <path
            fill="none"
            d="M652.9,369.2v1.5l2.3-1.5 M651,377h3.3l1.8-0.5l3.6-1.2l3.3-1l2.1-0.5c3.2-0.8,6.5-1.4,9.8-1.8h0.1v-0.1
	l1.5-1.9l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2l-2.1,0.6c-4.7,1.1-9.2,2.3-13.6,3.7h0.6C650,376.5,650.5,376.7,651,377L651,377z
	 M654.1,377h-3.3l0.3,0.1c0.4,0.4,0.5,1,0.5,1.6c0,0.5-0.1,1.1-0.5,1.5l-0.1,0.1h3.2L654.1,377L654.1,377z"
          />
          <path
            fill="none"
            d="M651.1,377.2l-0.3-0.1c-0.4-0.3-0.8-0.5-1.4-0.5h-0.6l-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.6
	c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.5v0.1c0.3,0.1,0.4,0.3,0.7,0.4l0.4,0.1h0.4c0.6,0,1.1-0.1,1.5-0.5l0.1-0.1
	c0.4-0.4,0.5-1,0.5-1.5C651.8,378.3,651.5,377.7,651.1,377.2L651.1,377.2z M650.7,378.8c0,0.3-0.1,0.7-0.4,0.8
	c-0.3,0.3-0.5,0.4-0.8,0.4c-0.3,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.5-0.4,1-0.4
	c0.4,0,0.7,0.1,0.8,0.4C650.5,378.2,650.7,378.5,650.7,378.8L650.7,378.8z"
          />
          <path
            fill="none"
            d="M650.4,379.8c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.5-0.4-0.8-0.4s-0.7,0.1-1,0.4
	c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,1,0.4C649.9,380.2,650.1,380,650.4,379.8L650.4,379.8z M659.6,375.4
	l-3.6,1.2v4.7l4,1.2l0.6-0.5c0.7-0.8,1.1-1.8,1.1-2.9c0-1.1-0.3-2.1-1.1-2.9C660.3,375.8,659.9,375.5,659.6,375.4L659.6,375.4z"
          />
          <path
            fill="none"
            d="M656,376.5l-1.8,0.5v3.6l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3L656,376.5L656,376.5z M642.6,368.9l0.1,0.1
	v-3.3h-3.6l0.1,3.3l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7C641.6,368.2,642.1,368.5,642.6,368.9L642.6,368.9z"
          />
          <path
            fill="none"
            d="M642.7,369l-0.1-0.1c-0.4-0.4-1-0.7-1.7-0.7c-0.5,0-1.1,0.3-1.7,0.7l-0.1,0.1c-0.4,0.4-0.5,0.8-0.5,1.4v0.5
	l0.1,0.3l0.4,0.7c0.4,0.4,1,0.7,1.7,0.7s1.2-0.3,1.7-0.7l0.6-0.7l0.1-0.4v-0.4C643.2,370,643.1,369.4,642.7,369L642.7,369z
	 M641.9,369.6c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1s-0.5,0.4-0.8,0.4s-0.7-0.1-1-0.4s-0.4-0.5-0.4-1s0.1-0.7,0.4-0.8
	c0.3-0.3,0.5-0.4,1-0.4C641.3,369.3,641.7,369.3,641.9,369.6L641.9,369.6z"
          />
          <path
            fill="none"
            d="M642.3,370.5c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-0.8-0.4s-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1
	s0.5,0.4,1,0.4s0.7-0.1,0.8-0.4C642.1,371.1,642.3,370.8,642.3,370.5L642.3,370.5z M644.3,372.8c-0.6-0.3-1.1-0.5-1.8-0.7
	c-0.4,0.4-1,0.7-1.7,0.7c-0.5,0-1.1-0.3-1.7-0.7c-0.5,0.1-1.1,0.3-1.7,0.7c-0.5,0.3-1.1,0.7-1.5,1.1c-0.5,0.5-1,1.1-1.2,1.6
	c-0.3,0.5-0.5,1.1-0.7,1.6l0.1,0.1c0.4,0.4,0.5,1,0.5,1.6c0,0.5-0.1,1.1-0.5,1.6h-0.1c0.1,0.6,0.4,1.1,0.7,1.6
	c0.3,0.6,0.7,1.1,1.1,1.5c0.5,0.5,1.1,1,1.7,1.2c0.5,0.3,1.1,0.5,1.7,0.7l0.1-0.1c0.4-0.4,1-0.7,1.7-0.7c0.5,0,1.1,0.3,1.7,0.7v0.1
	l1.7-0.7l1.5-1.2l1.2-1.6c0.3-0.5,0.5-1.1,0.5-1.8v-0.1c-0.4-0.4-0.7-1-0.7-1.5c0-0.7,0.3-1.2,0.7-1.6c-0.1-0.5-0.3-1.1-0.7-1.7
	c-0.3-0.5-0.7-1.1-1.2-1.5C645.4,373.4,644.9,373,644.3,372.8L644.3,372.8z"
          />
          <path
            fill="none"
            d="M646,374c0.5,0.5,0.8,1,1.2,1.5l5.1-4.5l-3.2-3.2l-4.5,5C644.9,373,645.4,373.4,646,374L646,374z M647.4,382.4
	l-0.1-0.1l-1.2,1.6l-1.5,1.2l4.5,5l3.2-3.3"
          />
          <path
            fill="none"
            d="M647.2,382.2c4.5,2.5,8.9,5.1,13.2,7.7l1.8,1.1c2.9,1.8,5.8,3.6,8.5,5.5c0.5,0.3,1,0.5,1.5,1l-2.2,0.4
	c-1.2,1.9-2.8,3.7-4.5,5.5c-1.8,1.8-3.6,3.3-5.6,4.7l-0.3,2.3l2.6-1.8c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1l-1.5-1
	l-8.8-5.1l1.1-2.6l9.8,2.8l1.8,0.5l0.7-2.5c0.7-2.9,1.1-5.8,1.1-8.9s-0.3-6.2-1.1-9.1l-1.5,2.1c0.4,2.3,0.7,4.7,0.7,7.2
	c0,2.3-0.3,4.7-0.7,6.9l1.4,1.9l-1.5-0.3h-0.1l-12-2.6l-13.6-3.7l-0.4-0.1c-0.3-0.1-0.5-0.3-0.7-0.4
	C647.8,381,647.5,381.7,647.2,382.2L647.2,382.2z"
          />
          <path
            fill="none"
            d="M649.4,381.1H649l13.6,3.7l12,2.6h0.1l1.5,0.3l-1.4-1.9l-0.1-0.1h-0.1c-3.3-0.4-6.5-1.1-9.8-1.8l-2.1-0.5
	l-3-0.8l-4-1.2l-0.8-0.3l-0.4-0.1l-0.1-0.1l-0.4-0.1h-3.2C650.5,381,650,381.1,649.4,381.1L649.4,381.1z M644.5,385.1l-1.7,0.7
	l0.4,0.7l0.1,0.3c1.4,4.4,2.6,8.9,3.7,13.8l3,13.8l2.2-0.5l-0.5-1.8l-2.6-9.8l2.6-1.1l5.1,8.8l0.8,1.5l1.9-1.1l-7.6-12
	C649.4,394.1,647,389.7,644.5,385.1L644.5,385.1z"
          />
          <path
            fill="none"
            d="M642.7,385.7c-0.4-0.4-1-0.7-1.7-0.7s-1.2,0.3-1.7,0.7l-0.1,0.1c-0.1,0.1-0.3,0.4-0.4,0.7
	c-0.1,0.1-0.1,0.3-0.1,0.4v0.4c0,0.5,0.1,1.1,0.5,1.5l0.1,0.1c0.4,0.4,1,0.7,1.7,0.7c0.5,0,1.1-0.3,1.7-0.7l0.1-0.1
	c0.4-0.4,0.5-0.8,0.5-1.5v-0.5l-0.1-0.3L642.7,385.7L642.7,385.7z M640.2,386.4c0.3-0.3,0.5-0.4,0.8-0.4c0.4,0,0.7,0.1,0.8,0.4
	c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.5,0.4-0.8,0.4c-0.4,0-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-0.8
	C639.8,386.9,639.9,386.6,640.2,386.4L640.2,386.4z"
          />
          <path
            fill="none"
            d="M641,386c-0.4,0-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,0.8,0.4
	s0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1C641.7,386.1,641.5,386,641,386L641,386z M643,388.7l-0.1,0.1
	c-0.4,0.4-1,0.7-1.7,0.7c-0.7,0-1.2-0.3-1.7-0.7l-0.1-0.1v3.2h3.6L643,388.7L643,388.7z"
          />
          <path
            fill="none"
            d="M643.4,386.8v0.6c0,0.5-0.1,1.1-0.5,1.5v3.3l0.3,1c1,2.6,1.8,5.2,2.3,7.8l0.5,2.1c0.4,1.8,0.8,3.7,1.2,5.6
	l0.7,4.1l0.1,0.1l2.1,1.4l-3-13.8C646,395.7,644.8,391.2,643.4,386.8L643.4,386.8z M649,390.1l-4.5-5c2.5,4.5,5,8.9,7.6,13.3l7.6,12
	l0.3-2.3V408c-1.8-2.8-3.6-5.6-5.4-8.4l-1.1-1.8L649,390.1L649,390.1z"
          />
          <path
            fill="none"
            d="M652.9,387.2l-0.7-0.4l-3.2,3.2l4.4,7.6l2.9-2.3c-1.2-1.6-2.3-4-3.4-6.9V387.2L652.9,387.2z"
          />
          <path
            fill="none"
            d="M652.2,386.8l0.7,0.4l1.9,1.1l6.1,3.7l0.7,0.4l8.4,5.5h0.1l2.2-0.4c-0.4-0.3-1-0.7-1.5-1
	c-2.8-1.9-5.6-3.7-8.5-5.5l-1.8-1.1c-4.3-2.8-8.7-5.2-13.2-7.7L652.2,386.8L652.2,386.8z"
          />
          <path fill="none" d="M652.9,387.2v1.1h1.8" />
          <path
            fill="none"
            d="M654.7,388.3h-1.9c1.1,2.9,2.2,5.2,3.4,6.9l0.5,0.7c1.5-1.1,2.9-2.5,3.9-3.9L654.7,388.3L654.7,388.3z
	 M674.9,371.8l-0.1,0.1c-3.3,0.4-6.6,1.1-9.8,1.8c0.3,1.6,0.6,3.4,0.6,5.1s-0.1,3.3-0.5,4.8c3.2,0.8,6.5,1.4,9.8,1.8h0.1l0.1,0.1
	c0.4-2.2,0.7-4.5,0.7-6.9C675.6,376.5,675.4,374.1,674.9,371.8L674.9,371.8z"
          />
          <path
            fill="none"
            d="M665,373.9l-2.1,0.5c0.3,1.5,0.4,3,0.4,4.5s-0.1,2.9-0.4,4.3l2.1,0.5c0.3-1.5,0.5-3.2,0.5-4.8
	C665.5,377.2,665.4,375.5,665,373.9L665,373.9z M663.2,368.3l1.1,2.6l9.8-2.6c-0.5-1.8-1.2-3.4-2.1-5L663.2,368.3L663.2,368.3z"
          />
          <path
            fill="none"
            d="M663.3,378.9c0-1.5-0.1-3.2-0.4-4.5l-3.3,1c0.3,0.1,0.7,0.4,1,0.8c0.7,0.8,1.1,1.8,1.1,2.9
	c0,1.1-0.3,2.2-1.1,2.9l-0.5,0.5l3,0.8C663.2,381.8,663.3,380.5,663.3,378.9z M664.3,387.1l-1.1,2.6l8.8,5.1c0.8-1.5,1.5-3.2,2.1-5
	L664.3,387.1L664.3,387.1z"
          />
          <path
            fill="none"
            d="M675.7,390.2l-1.8-0.5c-0.5,1.6-1.2,3.3-2.1,5l1.5,1l1.2-2.6L675.7,390.2L675.7,390.2z M670.1,397.9h-0.1
	l-8.4-5.5c-1,1.4-1.9,2.6-3.2,3.7c-1.2,1.2-2.5,2.2-3.9,3.2c1.8,2.9,3.6,5.6,5.4,8.4v0.1c1.9-1.2,3.9-2.9,5.6-4.7
	C667.2,401.6,668.7,399.9,670.1,397.9L670.1,397.9z"
          />
          <path
            fill="none"
            d="M661.4,392.4l-0.7-0.4c-1.1,1.4-2.3,2.8-3.9,3.9l-0.5-0.7l-2.9,2.3l1.1,1.8c1.2-1,2.6-1.9,3.9-3.2
	C659.5,395,660.6,393.8,661.4,392.4L661.4,392.4z M646.1,402.9l-5.1,0.4l-4.8-0.4c-0.4,1.9-0.8,3.7-1.2,5.6l-0.7,4.1l-0.1,0.1
	c2.2,0.4,4.5,0.6,6.9,0.6c2.5,0,4.8-0.3,7.2-0.7l-0.1-0.1l-0.7-4.1C647,406.7,646.5,404.8,646.1,402.9L646.1,402.9z"
          />
          <path
            fill="none"
            d="M641,403.4l5.1-0.5l-0.5-2.1l-4.5,0.4l-4.4-0.3l-0.4,2.1"
          />
          <path
            fill="none"
            d="M643.2,393l-0.3-1h-3.6l-0.3,1c-1,2.6-1.8,5.2-2.5,7.8l4.3,0.4l4.5-0.4C644.9,398.2,644.1,395.6,643.2,393
	L643.2,393z"
          />
          <path
            fill="none"
            d="M648.1,412.9c-2.3,0.4-4.7,0.7-7.2,0.7c-2.3,0-4.7-0.1-6.9-0.5l-1.9,1.2l2.5-11.6l0.4-2.1
	c1.1-4.7,2.3-9.4,3.7-13.8c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0.1-0.4,0.4-0.7c-0.5-0.1-1.1-0.4-1.7-0.7c-2.5,4.5-5.1,8.9-7.8,13.3
	l-7.6,11.7l-0.3-2.2c-1.9-1.2-3.7-2.8-5.5-4.5c-1.8-1.8-3.3-3.7-4.7-5.6l-2.3-0.3c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4
	l4.5,2.9l1-1.5l5.1-8.8c0.8,0.4,1.7,0.8,2.5,1.1l-2.8,9.8l-0.5,1.8l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1L648.1,412.9
	L648.1,412.9z M651.8,412l0.5,1.8c1.8-0.6,3.6-1.2,5.2-2.2l-0.8-1.5C655.1,410.7,653.6,411.4,651.8,412L651.8,412z"
          />
          <path
            fill="none"
            d="M651.8,412c1.7-0.5,3.3-1.2,5-1.9l-5.1-8.8l-2.6,1.1L651.8,412L651.8,412z M636,355l-0.1-0.8
	c-0.7-2.9-1.2-5.9-1.7-9.1H634l-1.9-1.5l2.9,13.9l1.8,6.7c0.7,2.3,1.2,4.7,1.9,7v-0.5c0-0.5,0.1-1,0.5-1.4l-0.1-3.3l-0.1-0.6
	l-0.4-1.1c-0.8-2.3-1.5-4.7-2.2-7L636,355L636,355z"
          />
          <path
            fill="none"
            d="M632.1,343.5l-2.3,0.7l0.5,1.8l2.6,9.8c-1,0.3-1.8,0.7-2.6,1.1l-5-8.8l-1-1.6c-0.5,0.3-1.2,0.5-1.8,1h-0.1
	c-2.6,1.5-5,3.4-7.3,5.8l-0.8,1c-1.8,1.9-3.4,4-4.7,6.2l2.3-0.3c1.2-1.9,2.8-3.7,4.5-5.4l0.4-0.4c1.7-1.6,3.4-3,5.2-4.3l0.4-2.3v0.1
	l7.4,11.8l2.8,4.7c1.7,2.9,3.3,5.8,4.8,8.7c0.5-0.3,1.1-0.5,1.7-0.7l-0.4-0.7l-0.1-0.3c-0.7-2.3-1.4-4.7-1.9-7l-1.8-6.7L632.1,343.5
	L632.1,343.5z"
          />
          <path
            fill="none"
            d="M622.6,347.6l-0.4,2.3v0.1l5.4,8.5l1.1,1.8l4.4,7.6l4.5,5c-1.5-2.9-3.2-5.8-4.8-8.7l-2.8-4.7L622.6,347.6
	L622.6,347.6z M629.8,344.1c-1.8,0.5-3.6,1.2-5.4,2.2l1,1.6c1.7-0.8,3.2-1.5,5-2.1L629.8,344.1L629.8,344.1z"
          />
          <path
            fill="none"
            d="M622.2,350.1c-1.8,1.1-3.6,2.5-5.2,4.1l-0.4,0.4c-1.7,1.6-3.2,3.4-4.5,5.4h0.1c2.8,1.8,5.5,3.7,8.4,5.5
	c0.5-0.8,1.2-1.6,1.9-2.5l1.1-1.2c1.2-1.2,2.5-2.2,3.9-3.2L622.2,350.1L622.2,350.1z"
          />
          <path
            fill="none"
            d="M622.6,362.8c-0.7,0.8-1.4,1.6-1.9,2.5l1.8,1.2c0.5-0.8,1.2-1.6,1.9-2.5l0.8-1c1.1-1.1,2.2-2.1,3.4-2.9
	l-1.1-1.8c-1.2,0.8-2.6,1.9-3.9,3.2L622.6,362.8L622.6,362.8z"
          />
          <path
            fill="none"
            d="M622.5,366.4l-1.8-1.2c-2.9-1.8-5.6-3.6-8.4-5.5h-0.1l-2.3,0.3l11.8,7.7c4.4,2.6,8.8,5.2,13.2,7.7l-5-4.5
	L622.5,366.4L622.5,366.4z"
          />
          <path
            fill="none"
            d="M624.4,364.1c-0.7,0.8-1.4,1.5-1.9,2.5l7.4,4.5l3.2-3.2l-4.4-7.7c-1.2,0.8-2.3,1.8-3.4,2.9L624.4,364.1
	L624.4,364.1z M630.3,356.9c0.8-0.4,1.8-0.8,2.6-1.1l-2.6-9.8c-1.7,0.6-3.3,1.2-5,2.1L630.3,356.9L630.3,356.9z M608.4,362.4
	c-0.5,1.1-1.1,2.3-1.5,3.4l-0.7,1.8l1.7,0.5c0.5-1.8,1.2-3.4,2.1-5L608.4,362.4L608.4,362.4z"
          />
          <path
            fill="none"
            d="M609.7,360.2l-1.2,2.2l1.5,0.8l8.8,5.1c-0.4,0.8-0.7,1.8-1.1,2.6l-9.9-2.8l-1.7-0.5l-0.5,2.3
	c-0.7,2.9-1,5.8-1,8.9s0.4,6.2,1.1,9.1l1.4-2.1l-0.1-1.1c-0.3-1.9-0.4-3.9-0.4-5.9c0-2.1,0.1-4,0.4-5.9l0.1-1l-1.4-2.1l13.8,3
	c4.7,1.1,9.2,2.3,13.8,3.7l0.4,0.1c0.3,0.1,0.4,0.1,0.7,0.4c0.1-0.5,0.4-1.2,0.7-1.6c-4.5-2.5-8.9-5.1-13.2-7.7L609.7,360.2
	L609.7,360.2z"
          />
          <path
            fill="none"
            d="M617,374c-0.3,1.6-0.4,3.2-0.4,5c0,1.8,0.1,3.4,0.4,5.1l2.1-0.5c-0.3-1.5-0.4-3-0.4-4.5s0.1-3,0.4-4.4L617,374
	L617,374z"
          />
          <path
            fill="none"
            d="M616.6,378.9c0-1.6,0.1-3.3,0.4-5c-3.2-0.8-6.5-1.4-9.8-1.8l-0.1-0.1l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9
	s0.1,4,0.4,5.9l0.1,1.1l0.1-0.1c3.3-0.4,6.6-1.1,9.9-1.8C616.7,382.4,616.6,380.7,616.6,378.9L616.6,378.9z"
          />
          <path
            fill="none"
            d="M617,374l2.1,0.5c2.6,0.7,5.2,1.5,7.8,2.5l0.4,0.1l0.5,0.1h3.2c0.4-0.4,0.8-0.5,1.5-0.5h0.4
	c-4.4-1.4-8.9-2.6-13.8-3.7l-13.8-3l1.4,2.1l0.1,0.1C610.5,372.6,613.8,373.3,617,374L617,374z"
          />
          <path
            fill="none"
            d="M619,374.5c-0.3,1.4-0.4,2.9-0.4,4.4c0,1.5,0.1,3,0.4,4.5c2.6-0.7,5.2-1.5,7.8-2.5l0.4-0.1l0.7-0.1v-3.6
	l-0.6-0.1l-0.4-0.1C624.4,376.1,621.8,375.2,619,374.5L619,374.5z M617.8,371c0.3-1,0.7-1.8,1.1-2.6l-8.8-5.1
	c-0.8,1.5-1.5,3.2-2.1,5L617.8,371L617.8,371z M605.7,388l0.5,2.2l1.8-0.5l9.8-2.6c0.3,0.8,0.7,1.8,1.1,2.6l-8.9,5l-1.5,0.8l1,1.8
	l1.5-1c2.9-1.9,5.8-3.7,8.7-5.4l1.8-1.1c4.4-2.6,8.8-5.2,13.3-7.6c-0.3-0.5-0.4-1.1-0.7-1.6l-0.7,0.6l-0.3,0.1
	c-4.4,1.4-9.1,2.6-13.8,3.7L605.7,388L605.7,388z"
          />
          <path
            fill="none"
            d="M606.4,390.2l0.5,1.6c0.4,1.2,1.1,2.5,1.7,3.7l1.5-0.8c-0.8-1.6-1.5-3.3-2.1-5L606.4,390.2L606.4,390.2z
	 M607.1,386l-1.4,2.1l13.8-3c4.7-1.1,9.4-2.5,13.8-3.7h-0.5c-0.5,0-1.1-0.1-1.4-0.5H628l-0.7,0.1l-0.4,0.1c-2.6,1-5.2,1.8-7.8,2.5
	L617,384C613.8,384.7,610.5,385.4,607.1,386L607.1,386z M619,389.7c-0.4-0.8-0.8-1.8-1.1-2.6l-9.8,2.6c0.5,1.6,1.2,3.3,2.1,5
	L619,389.7L619,389.7z M633.1,367.8l-3.2,3.2l5,4.5c0.3-0.6,0.7-1.1,1.2-1.6c0.5-0.4,1-0.8,1.5-1.1L633.1,367.8L633.1,367.8z"
          />
          <path
            fill="none"
            d="M633.1,376.7h-0.4c-0.5,0-1.1,0.1-1.5,0.5l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.6l0.1,0.1
	c0.4,0.3,0.8,0.5,1.4,0.5h0.5l0.3-0.1l0.7-0.6h0.1c0.4-0.4,0.5-1,0.5-1.6s-0.1-1.2-0.5-1.6l-0.1-0.1c-0.1-0.1-0.4-0.3-0.7-0.4
	L633.1,376.7L633.1,376.7z M631.4,378.9c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,0.8-0.4c0.4,0,0.7,0.1,1,0.4s0.4,0.5,0.4,0.8
	s-0.1,0.7-0.4,1s-0.5,0.4-1,0.4c-0.3,0-0.7-0.1-0.8-0.4C631.6,379.6,631.4,379.2,631.4,378.9L631.4,378.9z"
          />
          <path
            fill="none"
            d="M631.8,378.1c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1s0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1
	c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-1-0.4C632.2,377.7,632,377.8,631.8,378.1L631.8,378.1z M630.3,378.9c0-0.7,0.3-1.2,0.7-1.6
	l0.1-0.1H628v3.6h3.3l-0.1-0.1C630.6,380.2,630.3,379.6,630.3,378.9L630.3,378.9z M637.7,385.1c-0.5-0.3-1.1-0.8-1.7-1.2
	c-0.4-0.4-0.8-1-1.1-1.5l-5,4.5l3.3,3.2L637.7,385.1L637.7,385.1z"
          />
          <path
            fill="none"
            d="M637.7,385.1l-4.5,5l-4.5,7.6l-1.1,1.8l-5.5,8.4l0.1,0.3l0.3,2.2l7.6-11.7C632.7,394.1,635.3,389.7,637.7,385.1
	L637.7,385.1z M634.9,382.4c-4.5,2.3-9.1,5-13.3,7.6l-1.8,1.1l-8.7,5.4l-1.5,1l2.3,0.3h0.1c2.8-1.8,5.6-3.6,8.4-5.4l1.8-1.1
	c2.5-1.5,5.1-3,7.6-4.4L634.9,382.4L634.9,382.4z"
          />
          <path
            fill="none"
            d="M633.2,390.1l-3.3-3.2c-2.5,1.4-5.1,2.9-7.6,4.4c0.8,1.2,1.8,2.3,2.9,3.4s2.2,1.9,3.4,2.9L633.2,390.1
	L633.2,390.1z M639.4,388.8c-0.3-0.4-0.5-1-0.5-1.5v-0.4c-1.4,4.4-2.6,8.9-3.7,13.8c-0.1,0.7-0.3,1.4-0.4,2.1l-2.5,11.6l1.9-1.2
	l0.1-0.1l0.7-4.1c0.3-1.9,0.7-3.9,1.2-5.6l0.5-2.1c0.7-2.8,1.5-5.2,2.5-7.8l0.3-1v-3.4L639.4,388.8L639.4,388.8z M633.1,402.2
	c-0.8-0.3-1.8-0.7-2.5-1.1l-5.1,8.8c1.7,0.8,3.2,1.5,5,1.9L633.1,402.2L633.1,402.2z M627.4,399.3c-1.2-1-2.6-1.9-3.7-3.2
	c-1.2-1.2-2.2-2.5-3.2-3.9c-2.9,1.8-5.6,3.6-8.4,5.4H612c1.2,1.9,2.9,3.9,4.7,5.6c1.7,1.8,3.6,3.2,5.5,4.5l-0.1-0.3L627.4,399.3
	L627.4,399.3z"
          />
          <path
            fill="none"
            d="M623.7,396.3c1.1,1.1,2.5,2.2,3.7,3.2l1.1-1.8c-1.2-0.8-2.3-1.8-3.4-2.9c-1.1-1.1-2.1-2.2-2.9-3.4l-1.8,1.1
	C621.5,393.8,622.6,395,623.7,396.3L623.7,396.3z M624.4,411.4c1.8,0.8,3.4,1.6,5.4,2.2l0.5-1.8c-1.7-0.5-3.3-1.2-5-1.9L624.4,411.4
	L624.4,411.4z M641,327.9c14.2,0,26.1,5,36.2,15c9.9,9.9,15,22,15,36.2v2.6l8.1-0.5c-0.1-1.9-0.3-4-0.3-6.2l-0.1-4.7l-0.3-2.6
	c-1.7-13.1-7.3-24.5-17.1-34.3c-3-3.2-6.3-5.8-9.8-8.1c-9.2-6.1-19.7-9.1-31.4-9.1c-16.1,0-29.7,5.6-41.1,17.1s-17.5,25.5-18.3,42.1
	l-0.4,8.1h8.8c-0.1-1.5-0.3-3-0.3-4.5c0-12.7,4-23.7,12.1-33c0.8-1.1,1.8-2.1,2.8-3C614.9,332.9,626.9,327.9,641,327.9L641,327.9z"
          />
          <path
            fill="none"
            d="M640.9,310c13.1,0,24.6,3.6,34.9,10.6l8.9-14.9c-12.5-8-27-12-43.2-12c-10.5,0-20.2,1.7-29.2,5.1
	c-16.8,24.8-33.8,47.6-51.3,68.4c-3.9,4.5-9.5,7-17.1,7.3l-74.1,1.9L298,381c-3.3,0.1-5.2-1-6.1-3.2c-0.4-1.4-0.7-3.2-0.8-5.2
	l-0.3-2.3c-3.6-0.1-5.5-1-6.1-2.6c-0.8-2.8,1-4.4,5.2-5.1c-1.8-16.5-3.3-33.6-4.5-50.9l-5.9,1l2.1,9.5l1.7,7.6
	c-5.6-4.8-10.3-8.9-14.3-12.7c-14.3-13.1-31.6-19.7-51.9-19.7c-15.4,0-29.2,3.9-41.3,11.6l11.3,11.1c8.8-4.7,18.4-7,29-7
	c17.7,0,32.9,6.6,45.4,19.7c12.5,13.2,19.3,29.3,20.1,48.4l0.5,10.2l292.9-7.7l0.3-5.5c0.8-19.1,7.6-35.2,20.1-48.4
	C608,316.6,623.2,310,640.9,310L640.9,310z M700.1,381h5.8l-0.1-3.6c0-2.8-0.3-5.5-0.5-8.1l-5.6,0.8l0.1,4.7
	C699.8,377,699.9,379.1,700.1,381L700.1,381z M382,192.8l3.9,4.1c0.4-0.3,0.5-0.6,0.7-0.8l-0.5-0.6l-3.6-3.7L382,192.8z"
          />
          <path
            fill="none"
            d="M385.9,196.9l-3.7-4.1l-6.9,2.8l4,4.3l4.3-1.5C384.5,197.9,385.3,197.5,385.9,196.9L385.9,196.9z M321.8,236.8
	l1.1-6.2c-11.8,5.8-23.7,12.2-35.5,19.3c-8.5,5.2-16.8,10.9-24.8,16.8c-3.3,2.5-6.6,5-9.9,7.4l8.9-0.3l0.1-0.1
	c8.1-6.3,16.6-12.2,25.7-17.7c8.7-5.4,17.5-10.3,26.3-15l4-2.2L321.8,236.8L321.8,236.8z"
          />
          <path
            fill="none"
            d="M322.9,230.6l-1.1,6.2c1.6-0.8,3.2-1.6,4.8-2.3c47.5-23.2,95.5-36.2,144.2-39.1l10.9-0.3l0.7-4.8l-9.9,0.3
	C421.9,193.1,372.1,206.4,322.9,230.6L322.9,230.6z"
          />
          <path
            fill="none"
            d="M326.6,234.5c-1.6,0.8-3.2,1.5-4.8,2.3l-2.5,14.2l2.3-0.6h2.2L326.6,234.5L326.6,234.5z"
          />
          <path
            fill="none"
            d="M323.7,250.9l-1.8-0.3h-0.3l-2.3,0.6c-1.5,0.3-2.9,0.7-4.1,1.1c-1.4,0.6-2.8,1.1-4,1.8c-3.3,1.8-6.2,4.1-8.8,7
	c-0.7,0.7-0.8,1.6-0.4,2.8l1.5,5.4l1.6-0.1l5.6-0.8c0.4-1.4,1.1-2.6,1.9-3.6c1.1-1.2,2.8-2.1,4.7-2.3h0.1l15.4-2.2
	c-0.3-2.2-0.8-3.9-2.1-4.8c-1.6-1.6-3.6-2.9-5.9-3.9L323.7,250.9L323.7,250.9z"
          />
          <path fill="none" d="M321.9,250.6l1.8,0.3l0.1-0.3" />
          <path
            fill="none"
            d="M327.3,251.3c-1.1-0.4-2.2-0.7-3.4-0.7l-0.1,0.4l1.1,0.4c2.2,1,4.1,2.2,5.9,3.9c1.1,1,1.8,2.6,2.1,4.8v6.2
	c-0.1,0.8-0.3,1.5-0.4,2.2c-0.4,1.5-1.2,2.6-2.2,3.4c-0.8,0.7-1.9,1.1-3.2,1.4l1.6-0.1c1.6-0.3,2.9-0.7,4-1.5s1.8-1.9,2.2-3.4
	c0.3-1.1,0.5-2.3,0.4-3.9v-3.3c-0.1-2.8-0.8-4.7-2.1-5.8C331.5,253.5,329.5,252.2,327.3,251.3L327.3,251.3z"
          />
          <path
            fill="none"
            d="M323.8,250.6c1.2,0,2.3,0.3,3.4,0.7c2.2,1,4.1,2.2,5.8,3.9c1.2,1.1,1.9,3,2.1,5.8v3.3c0,1.5-0.1,2.8-0.4,3.9
	l123.8-12.5l12.1-60c-48.7,2.8-96.8,15.8-144.2,39.1L323.8,250.6L323.8,250.6z M332.2,268.3c0.3-0.7,0.4-1.5,0.4-2.2l-16.5,1.4
	l-5.6,0.7l-5.6,0.8l-1.6,0.3l14.9,5.2c0.4-0.4,0.8-0.5,1.4-0.5l7.2-0.7c1.2-0.3,2.3-0.7,3.2-1.4C331.1,271,331.8,269.7,332.2,268.3
	L332.2,268.3z"
          />
          <path
            fill="none"
            d="M332.8,266v-6.2l-15.4,2.2h-0.1c-1.9,0.3-3.6,1.1-4.7,2.3c-0.8,1-1.5,2.1-1.9,3.6l5.6-0.7L332.8,266L332.8,266z
	 M326.9,262.1c0.4,0.4,0.7,1,0.7,1.5c0,0.5-0.3,1.1-0.7,1.5c-0.4,0.4-1,0.7-1.5,0.7s-1.1-0.3-1.6-0.7c-0.4-0.4-0.5-1-0.5-1.5
	s0.1-1.1,0.5-1.5c0.4-0.4,1-0.7,1.6-0.7C325.9,261.5,326.3,261.7,326.9,262.1L326.9,262.1z"
          />
          <path
            fill="none"
            d="M327.5,263.7c0-0.5-0.3-1.1-0.7-1.5c-0.4-0.4-1-0.7-1.5-0.7s-1.1,0.3-1.6,0.7c-0.4,0.4-0.5,1-0.5,1.5
	s0.1,1.1,0.5,1.5s1,0.7,1.6,0.7c0.5,0,1.1-0.3,1.5-0.7C327.3,264.8,327.5,264.3,327.5,263.7L327.5,263.7z M326,263
	c0.1,0.1,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.8c-0.3,0.3-0.5,0.4-0.8,0.4c-0.3,0-0.5-0.1-0.8-0.4c-0.1-0.1-0.3-0.4-0.3-0.8
	c0-0.3,0.1-0.5,0.3-0.7c0.3-0.3,0.5-0.3,0.8-0.3C325.5,262.6,325.8,262.7,326,263L326,263z"
          />
          <path
            fill="none"
            d="M326.3,263.7c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.1-0.3,0.4-0.3,0.7
	s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4s0.5-0.1,0.8-0.4C326.2,264.2,326.3,264.1,326.3,263.7L326.3,263.7z M328.5,273l-1.6,0.1
	l-7.2,0.7c-0.5,0-1.1,0.1-1.4,0.5c-0.3,0.3-0.4,0.5-0.5,0.8l-0.7,1.9l-2.8,8c-1.7,4.8-4.7,7-9.4,6.3c-2.1-0.5-3.7-1.6-5-3.2
	s-1.6-3.2-1-5.2l2.2-6.5l0.7-2.1l-12,1.2l-5.5,24.1l128.1-15.1c-0.5-0.5-1-1.1-1-1.8c0-0.3,0-0.5,0.1-0.8c0.5-1.4,2.3-2.8,5.5-4.1
	l0.4-0.1c3.9-1.5,8.3-2.5,13.6-3c5.5-0.5,10-0.3,13.9,0.5c1.8,0.4,3.3,1,4.3,1.5l0.7,0.5c0.5,0.5,0.8,1.1,0.8,1.8v0.3l-0.1,0.4
	l-0.1,0.4l18.2-2.2l3.2-20.9l-139.5,14.3C331.4,272.2,330.2,272.7,328.5,273L328.5,273z"
          />
          <path
            fill="none"
            d="M334.7,268.1c-0.4,1.5-1.2,2.6-2.2,3.4l139.8-14.2l0.5-3.2l-14.2,1.4L334.7,268.1L334.7,268.1z M324.1,222.4
	c17.5-8.3,35.8-15.7,55-22.6l-4-4.3c-41.8,16.5-82.3,36.3-121.1,59.4l3.3-0.8c2.5-0.7,4.8-1.6,7-2.6l0.3-0.1
	C287,240.3,306.8,230.5,324.1,222.4L324.1,222.4z M411.9,282.2c0.3-0.4,0.8-0.8,1.8-1.2c1-0.8,2.3-1.5,4.1-2.3l1-0.3
	c0.5-0.3,1,0,1.1,0.7v1l19-2.3c0-0.7,0.1-1.2,0.4-1.6c0.1-0.4,0.5-0.5,1.1-0.5h1l4.5,0.7c1,0.3,1.9,0.5,2.6,0.8l1.2,0.3
	c-1-0.7-2.5-1.1-4.3-1.5c-3.9-0.8-8.5-1.1-13.9-0.5c-5.2,0.4-9.8,1.5-13.6,3l-0.4,0.1C414.3,279.5,412.4,280.9,411.9,282.2
	L411.9,282.2z"
          />
          <path
            fill="none"
            d="M413.8,281c-1,0.4-1.6,0.7-1.8,1.2c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.7,0.3,1.2,1,1.8l0.8,0.5h1l-0.8-0.4l0.8,0.4
	l5.9-0.5l18.8-1.9l1.9-0.1l-0.3-5.6l-1.9,0.3l-19,2.3l-2.6,0.3C415.7,280.4,414.6,280.7,413.8,281L413.8,281z"
          />
          <path
            fill="none"
            d="M419.8,279.1c-0.1-0.7-0.4-1-1.1-0.7l-1,0.3c-1.8,0.8-3.2,1.5-4.1,2.3c0.8-0.3,2.1-0.5,3.4-0.8l2.6-0.3v-0.8
	L419.8,279.1L419.8,279.1z M419.2,286.9l-1.4-0.3c-1.2-0.3-2.5-0.7-3.3-1.1h-1c1,0.5,2.3,1.1,4.1,1.5l0.3,0.1
	c3.9,0.8,8.4,0.8,13.6,0.4c5.5-0.5,10-1.5,13.9-3.2c2.9-1.1,4.7-2.3,5.4-3.6l-0.8,0.5l-0.8,0.4c-0.5,0.5-3,1.5-7.3,2.8l-0.1,0.1
	h-0.1c-0.7,0.1-1.2,0-1.6-0.5l-0.4-0.8l-18.8,1.9l0.1,0.7c0,0.3,0,0.5-0.1,0.8C420.1,286.8,419.7,286.9,419.2,286.9L419.2,286.9z"
          />
          <path
            fill="none"
            d="M417.8,286.6l1.4,0.3c0.5,0,1-0.1,1.2-0.4c0.1-0.1,0.1-0.4,0.1-0.8l-0.1-0.7l-5.9,0.5
	C415.3,285.9,416.4,286.2,417.8,286.6L417.8,286.6z"
          />
          <path
            fill="none"
            d="M417.8,287l-0.3-0.1c-1.8-0.4-3.2-1-4.1-1.5l-0.8-0.5L284.5,300l0.8,11.7c59.6-9.2,120.6-18.2,183.4-27l0.3-6.5
	l-18.2,2.2l-0.1,0.3c-0.8,1.2-2.6,2.5-5.4,3.6c-3.9,1.5-8.5,2.6-13.9,3.2C426.2,287.9,421.6,287.7,417.8,287L417.8,287z
	 M439.4,275.9c-0.3,0.4-0.4,1-0.4,1.6l1.9-0.3l0.4-1.9h-1C439.8,275.4,439.5,275.5,439.4,275.9L439.4,275.9z"
          />
          <path
            fill="none"
            d="M439.2,283.1l0.4,0.8c0.4,0.5,1,0.7,1.6,0.5h0.1l0.1-0.1l-0.5-1.5L439.2,283.1L439.2,283.1z M472.7,254
	l-0.4,3.2l152.1-15l3.3-0.7l-1.7-2.8l-2.1,0.6l-5.2,0.4l-132.3,12.9 M450.9,280.4l0.1-0.4l0.1-0.4v-0.3c0-0.7-0.3-1.2-0.8-1.8
	l-0.7-0.5l-1.2-0.3l-7.6,0.5l0.3,5.6l5.1-0.5c1.1-0.1,2.1-0.4,2.9-0.7l0.8-0.4l0.8-0.5L450.9,280.4L450.9,280.4z M444.2,278.1
	c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.7,0.5,1.1s-0.1,0.8-0.5,1.1l-0.5,0.4l-0.6,0.1h-0.3c-0.4-0.1-0.7-0.3-0.8-0.5
	c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.4,0.1-0.8,0.4-1.1C443.5,278.2,443.8,278.1,444.2,278.1L444.2,278.1z"
          />
          <path
            fill="none"
            d="M445.3,278.5c-0.3-0.3-0.7-0.4-1.1-0.4s-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1s0.1,0.8,0.4,1.1
	c0.3,0.3,0.5,0.4,0.8,0.5l0.8-0.1l0.5-0.4c0.3-0.3,0.5-0.7,0.5-1.1C445.8,279.2,445.7,278.8,445.3,278.5L445.3,278.5z M443.9,281.3
	h0.3l0.7-0.1 M445.8,276l-4.5-0.7l-0.4,1.9l7.6-0.5C447.8,276.5,446.8,276.2,445.8,276L445.8,276z M446.3,282.4l-5.1,0.5l0.5,1.5
	c4.3-1.2,6.7-2.1,7.3-2.8C448.3,282,447.4,282.2,446.3,282.4L446.3,282.4z M469.1,278.2l71.8-8.4l34.5-3.4l0.1-0.7
	c0.5-1.5,2.3-2.9,5.6-4.1l0.4-0.1c3.7-1.5,8.3-2.5,13.6-3c5.5-0.5,10.2-0.4,13.9,0.4c1.8,0.4,3.3,1,4.3,1.5l0.7,0.5
	c0.5,0.5,0.8,1.1,0.8,1.8v0.5l16.5-1.1c-0.3-7-1.5-13.9-3.7-20.5l-3.3,0.6l-152.1,15.1L469.1,278.2L469.1,278.2z"
          />
          <path
            fill="none"
            d="M469.1,278.2l-0.3,6.5c36.3-5.1,73-10,110.3-15c-0.8-0.3-1.5-0.5-1.9-1c-1.1-0.7-1.7-1.5-1.7-2.3v-0.1
	l-34.5,3.4L469.1,278.2L469.1,278.2z M458.6,255.4l14-1.4l8.9-58.9l-10.9,0.3 M619.3,179.9l-0.3,2.1l8.8,1l9.8,1.4l0.3-2.1l-9.8-1.5
	"
          />
          <path
            fill="none"
            d="M632.1,179.2c-1-0.3-1.9-0.4-3.2-0.4l-9.6,1.1l8.8,0.8l9.8,1.4l-0.8-0.4L632.1,179.2L632.1,179.2z M482.4,190.2
	l-0.7,4.8c3.4-0.1,6.9-0.1,10.5-0.1c37.7-0.3,73.6,2.6,107.7,8.4l6.3,1.1l-3.3-5.5C565.1,192.3,524.9,189.4,482.4,190.2L482.4,190.2
	z"
          />
          <path
            fill="none"
            d="M492.1,195c-3.4,0-6.9,0-10.5,0.1l-8.9,59l13.8-1.4L492.1,195L492.1,195z M612.8,205.7l-6.7-1.2l19.9,34.4
	c1.8-0.4,3.4-1.1,4.8-1.9L612.8,205.7L612.8,205.7z"
          />
          <path
            fill="none"
            d="M624,239.3l2.1-0.6l-19.9-34.4l-6.3-1.1l19,36.5 M580.9,263.7l2.6-0.3v-1c0-0.7-0.4-0.8-1.1-0.5
	c-0.1,0-0.4,0.1-0.5,0.1l-0.4,0.1c-1.8,0.7-3.3,1.5-4.1,2.3C578.3,264.1,579.4,263.8,580.9,263.7L580.9,263.7z"
          />
          <path
            fill="none"
            d="M583.7,263.2l-2.6,0.3c-1.5,0.1-2.6,0.4-3.4,0.8c-1,0.3-1.7,0.7-1.8,1.2l-0.1,0.7v0.1c0,1,0.5,1.8,1.7,2.3
	l1.1,0.1l5.9-0.5l18.8-1.9l1.9-0.1l-0.3-5.5l-1.9,0.1L583.7,263.2L583.7,263.2z"
          />
          <path
            fill="none"
            d="M583.7,262.4v1l19-2.5c0-0.7,0.1-1.2,0.4-1.6c0.1-0.4,0.5-0.5,1.1-0.5h1c1.7,0.1,3.2,0.3,4.5,0.7
	c1,0.3,1.9,0.4,2.6,0.8l1.2,0.1c-1-0.5-2.5-1.1-4.3-1.5c-3.9-0.8-8.5-1-13.9-0.4c-5.2,0.4-9.8,1.5-13.6,3l-0.4,0.1
	c-3.2,1.2-5.1,2.8-5.6,4.1c0.3-0.5,0.8-0.8,1.8-1.2c1-0.8,2.3-1.6,4.1-2.3l0.4-0.1c0.1-0.1,0.3-0.1,0.5-0.1
	C583.3,261.5,583.5,261.7,583.7,262.4L583.7,262.4z M584.2,269c0,0.3,0,0.5-0.1,0.7c-0.3,0.3-0.5,0.4-1.2,0.4l-0.7-0.1l-2.1-0.5
	l-1,0.1l2.5,0.5c3.9,0.8,8.4,0.8,13.6,0.4c5.5-0.5,10.2-1.5,13.9-3.2c1.9-0.8,3.4-1.5,4.4-2.3c0.4-0.4,0.8-0.8,1.1-1.2l-0.8,0.5
	l-0.8,0.4l-0.5,0.4c-1.1,0.5-3.3,1.2-6.9,2.3l-0.1,0.1h-0.1c-0.7,0.1-1.2-0.1-1.7-0.5l-0.4-0.8l-18.8,1.9L584.2,269z"
          />
          <path
            fill="none"
            d="M584.1,269.9c0.1-0.1,0.3-0.4,0.1-0.7l-0.1-0.7l-5.9,0.5l1.9,0.7l2.1,0.5l0.7,0.1
	C583.4,270.3,583.8,270.1,584.1,269.9L584.1,269.9z M577.1,268.9c0.5,0.4,1.2,0.7,1.9,1l1-0.1L578,269L577.1,268.9L577.1,268.9z"
          />
          <path
            fill="none"
            d="M595.2,270.8c-5.2,0.4-9.9,0.3-13.6-0.4l-2.5-0.5c-37.3,5-74.1,9.9-110.3,15c-1.1,21.9-1.5,43.9-1.1,65.9
	l87.8-5.9c-30,5.5-59.4,9.9-88.3,13.6l2.8,18.2l74.1-1.9c7.4-0.3,13.2-2.8,17.1-7.3c17.5-20.8,34.5-43.6,51.3-68.4
	c-8.8,3.3-16.8,8.1-24.1,14.7c-7.2,6.3-12.5,13.3-16.5,21c3.6-11.6,10.2-21.7,19.9-30.5c8.4-7.7,17.9-13.2,28.5-16.4
	c1-1.2,1.9-3,2.8-5.2l8.3-19.4l-17.7,2.1c-1,0.8-2.5,1.6-4.4,2.3C605.3,269.2,600.6,270.3,595.2,270.8L595.2,270.8z M631.4,262.1
	L631,263l13.3-1.6 M631,263l0.4-1l-16.5,1.1v0.1l-0.3,0.7c-0.3,0.4-0.5,0.8-1.1,1.2L631,263L631,263z M609.5,259.4
	c-1.4-0.3-2.9-0.5-4.5-0.7l-0.4,1.9l7.6-0.5C611.5,259.9,610.6,259.7,609.5,259.4L609.5,259.4z"
          />
          <path
            fill="none"
            d="M603.1,259.3c-0.3,0.4-0.4,1-0.4,1.6l1.9-0.1l0.4-1.9h-1C603.6,258.7,603.2,258.8,603.1,259.3L603.1,259.3z
	 M602.9,266.4l0.4,0.8c0.4,0.5,1,0.7,1.7,0.5h0.1l0.1-0.1l-0.6-1.5L602.9,266.4L602.9,266.4z"
          />
          <path
            fill="none"
            d="M604.6,260.8l0.3,5.5l5.6-0.5l2.2-0.5l0.8-0.4l0.8-0.5l0.3-0.7v-0.7c0-0.7-0.3-1.2-0.8-1.8l-0.7-0.5l-1.2-0.1
	L604.6,260.8L604.6,260.8z"
          />
          <path
            fill="none"
            d="M604.9,266.3l0.5,1.5c3.6-1,5.8-1.8,6.9-2.3l0.5-0.4l-2.2,0.5L604.9,266.3L604.9,266.3z M618.8,239.7l-19-36.5
	c-34.1-5.8-70-8.5-107.7-8.4l-5.6,57.6L618.8,239.7L618.8,239.7z M145.5,287.9c3.3-1,6.5-1.6,9.8-2.1h0.4c3.4-0.5,6.7-0.8,10-0.8
	h0.4c5,0,9.8-0.3,14.7-1l28.8-4l43.1-5.9c3.2-2.5,6.5-5,9.9-7.4l-5.2-12.4l-3.3,0.8h-0.1c-7.6,1.5-14.9,2.9-22.1,4.5l-1.1,0.3
	c-45.7,9.5-86.3,22.1-121.7,38.1c-4.4,2.1-8.5,4.3-12.4,6.7l3.4,2.2c2.9-1.6,5.8-3.2,8.8-4.5c5.8-2.6,11.6-5.1,17.3-7.7
	C131.6,292.4,138,290.1,145.5,287.9L145.5,287.9z"
          />
          <path
            fill="none"
            d="M155.3,285.7c-3.3,0.5-6.5,1.2-9.8,2.1c-7.6,2.3-13.9,4.5-19.3,6.9c-5.9,2.5-11.7,5.1-17.3,7.7
	c-3,1.4-6.1,2.9-8.8,4.5l-5.4,3.3l-0.1,0.1c-1.1,1.4-1.4,3-0.8,5l1,2.9l0.6,0.8l1,0.5l2.3,0.1l19.1-4.4l0.3-0.1
	c11.6-2.9,23.5-6.2,36-10h0.1l13.5-4.3l2.6-1.1c-4.7,1.2-8.5,2.5-11.4,3.3l-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.4
	c-1.4,0.4-2.8,0.8-4.1,1.2c-12.2,3.6-24.1,6.7-35.4,9.5l-0.3,0.1l-10.3,2.2l-1.9,0.4l-3.6,0.8l-3.3,0.7c-1.1,0.3-1.9,0.1-2.2-0.4
	l-0.1-0.3l-1-2.9c-0.1-0.7-0.3-1.4-0.3-1.9c0-0.7,0.4-1.2,1.2-1.7l1.2-0.8c6.3-3.6,14.6-8,24.9-13.1
	C134.1,291.7,145,287.7,155.3,285.7L155.3,285.7z"
          />
          <path
            fill="none"
            d="M155.7,285.7h-0.4c-10.3,2.1-21.2,5.9-32.9,11.7c-10.3,5.1-18.6,9.5-24.9,13.1l-1.2,0.8c-0.8,0.4-1.2,1-1.2,1.6
	c0,0.5,0.1,1.2,0.3,1.9l1,2.9l0.1,0.3c0.4,0.5,1.1,0.7,2.2,0.4l3.3-0.7c-1.1,0.1-2.1-0.5-2.9-2.2l-0.6-1.5l-0.1-0.7
	c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.5,0.4-1.1,1.1-1.4c2.1-1.4,9.8-5.2,23.1-11.8c11.7-5.8,22.4-9.5,32-11.3c5.6-1,11-1.4,16.1-1.2
	c3.6,0,7.4-0.3,11.1-0.8l23.2-3l4.5-2.5l-28.8,4c-5,0.5-9.9,1-14.7,1h-0.4C162.5,284.8,159,285.1,155.7,285.7L155.7,285.7z"
          />
          <path
            fill="none"
            d="M181.9,285.4c-3.9,0.5-7.6,0.8-11.1,0.8c-5.1-0.1-10.5,0.3-16.1,1.2c-9.6,1.6-20.4,5.5-32.1,11.3
	c-13.2,6.5-20.9,10.5-23.1,11.8c-0.6,0.3-1,0.8-1.1,1.4c-0.1,0.4-0.1,0.8,0,1.2l0.1,0.7l0.6,1.5c0.8,1.6,1.8,2.3,2.9,2.2l3.6-0.8
	l2.3-2.5l24.6-5.9l22.7-6.2h1.2c0.3,0.1,0.4,0.3,0.7,0.4l0.3,0.3l0.4,0.5c0.1-0.1,0.3-0.1,0.4-0.1l0.4-0.1l5.5-6.7
	c1-1.1,2.2-1.8,3.7-2.2l18.7-2.2c6.2-3,12.4-6.2,18.4-9.5L181.9,285.4L181.9,285.4z M154.1,288.6c1.9-0.4,3.9-0.7,5.4-0.5
	c1,0,1.9,0.3,2.9,0.5c0.8,0.3,1.5,0.5,2.1,1l21.9-2.3l-4.1,3.7l-17.2,1.8l-2.6,2.8l-2.5,2.8c-1.4,1.5-3.3,2.8-5.6,3.7l-2.3,0.7
	l-5.1,1.5l-1,0.3l-12.9,3.4l-1.1,0.3l-8.7,2.2l-7.2,1.6l-8.3,1.9l-2.3,2.2l-2.6,0.4c-1.1,0.1-1.8-0.1-2.3-1.1l-1.1-2.2
	c-0.1-0.4-0.1-1,0-1.2c0.1-0.3,0.3-0.5,0.6-0.7c2.6-1.5,10.2-5.4,22.6-11.6C134,294.1,144.6,290.4,154.1,288.6L154.1,288.6z"
          />
          <path
            fill="none"
            d="M154.2,291.3v-0.5c1.1-1.4,2.9-2.2,5.2-2.8c-1.6-0.1-3.4,0.1-5.4,0.5c-9.6,1.8-20.1,5.5-31.6,11.1
	c-12.5,6.2-20.1,10-22.6,11.6c-0.3,0.1-0.4,0.4-0.6,0.7H116l7.2-1.6v-6.6h10.7l0.4-1l17.2-10.7h1.8L154.2,291.3L154.2,291.3z"
          />
          <path
            fill="none"
            d="M154.2,290.8v11.1c2.3-1,4.1-2.2,5.6-3.7l2.5-2.8v-6.9c-0.8-0.3-1.8-0.4-2.9-0.5
	C157.1,288.6,155.3,289.4,154.2,290.8L154.2,290.8z M153.4,292h-1.8l-4.7,12.1l5.1-1.5c-0.4-0.8-0.6-1.8-0.7-2.8v-2.6
	C151.3,295,152,293.2,153.4,292L153.4,292z"
          />
          <path
            fill="none"
            d="M154.2,291.3l-0.8,0.7c-1.4,1.2-2.1,3-2.1,5.2v2.6c0.1,1.1,0.3,1.9,0.7,2.8l2.3-0.7v-10.6L154.2,291.3
	L154.2,291.3z M164.4,289.5l0.6,3.2l17.2-1.8l4.1-3.7"
          />
          <path
            fill="none"
            d="M164.9,292.7l-0.6-3.2c-0.7-0.4-1.4-0.7-2.1-1v6.9L164.9,292.7L164.9,292.7z M145.8,304.4l0.3-0.5h-11.3l-1.8,4
	"
          />
          <path
            fill="none"
            d="M146.1,303.8l-0.3,0.5l1-0.3l4.8-12.1l-17.2,10.7l-0.6,1l-1.9,4.4l1-0.3h0.1l1.8-4l0.1-0.4l14.9-9.2"
          />
          <path
            fill="none"
            d="M134.9,303.4l-0.1,0.4h11.3l3.7-9.6 M131.9,308.1l1.9-4.4h-10.7v6.6 M105.4,316.1l2.3-2.2l8.3-1.9H99.3
	c-0.1,0.4-0.1,0.8,0,1.2l1.1,2.2c0.4,0.8,1.2,1.2,2.3,1.1L105.4,316.1L105.4,316.1z M186.7,292l-18.7,2.2c-1.5,0.4-2.8,1.1-3.7,2.2
	l-5.5,6.7c2.9-1,6.7-2.1,11.4-3.3l0.3-0.1C175.9,297.2,181.3,294.6,186.7,292L186.7,292z"
          />
          <path
            fill="none"
            d="M209.8,279.9l-4.5,2.5c-6.1,3.3-12.2,6.5-18.4,9.5c-5.4,2.6-10.9,5.2-16.2,7.7l-0.3,0.1l-2.6,1.1l-0.8,0.4
	l4.7,5.2c13.5-10,29.2-15.1,47.2-15.1c15.7,0,29.7,3.9,41.8,11.6l24.1-2.9l5.5-24.1l-12.2,1.2c-5.1,0.8-9.8,0.8-14,0.1
	c-1.8-0.3-3.4-0.7-5.1-1.1l-5.8-2.1L209.8,279.9L209.8,279.9z M230.8,259.8h-16.2c-34.8,6.1-67.3,15.5-97.1,28.5
	c-8,3.6-15.5,7.4-22.7,11.7c-1.1,0.7-2.2,1.4-3.2,2.2l5.2,2.5c3.9-2.5,8-4.7,12.4-6.7C144.4,282.1,185,269.3,230.8,259.8
	L230.8,259.8z M317.9,238.8l-4,2.2c-8.8,4.7-17.5,9.6-26.3,15c-9.1,5.5-17.6,11.4-25.7,17.7l-0.1,0.1h0.1c1.8,0.4,3.6,0.5,5.5,0.7
	l10.5-0.5l13.5-1.4l11.8-1.2c0.3-0.7,0.4-1.5,0.3-2.2V269l-20.9,2.2c-4.5,0.4-7.6,0.3-8.9-0.3c5.2-4.4,10.6-8.3,16.1-11.6
	c6.7-4.1,13.3-8,20.1-11.7c1.9-0.8,2.8-0.3,2.3,1.6l-1,4.7c1.2-0.7,2.6-1.2,4-1.8L317.9,238.8L317.9,238.8z"
          />
          <path
            fill="none"
            d="M252.8,274l5.8,2.1c1.6,0.5,3.3,0.8,5.1,1.1c4.3,0.7,8.9,0.7,14-0.1l12.2-1.2v-0.4l1.1-2.9l-13.5,1.4l-10.5,0.5
	c-1.9-0.1-3.7-0.4-5.5-0.7h-0.1L252.8,274L252.8,274z M311.2,253.9l1-4.7c0.3-1.8-0.5-2.3-2.3-1.6c-6.7,3.7-13.5,7.6-20.1,11.7
	c-5.5,3.4-10.9,7.3-16.1,11.6c1.4,0.5,4.4,0.7,8.9,0.3l20.9-2.2l-1.5-5.4c-0.4-1.1-0.1-1.9,0.4-2.8C305,258,307.9,255.7,311.2,253.9
	L311.2,253.9z M315.2,252.2c1.2-0.4,2.8-0.8,4.1-1.1l2.5-14.2l-3.9,1.9L315.2,252.2L315.2,252.2z M303.5,269.2l1.6-0.3l-1.6,0.1"
          />
          <path
            fill="none"
            d="M290,275.5v0.4l12-1.2l1.1-3.3l-11.8,1.2 M299.1,283.1c-0.5,2.1-0.3,3.7,1,5.2s2.9,2.6,5,3.2
	c4.7,0.5,7.7-1.5,9.4-6.3l2.8-8c-5.2,1.2-10.6,1.1-15.8-0.5L299.1,283.1L299.1,283.1z"
          />
          <path
            fill="none"
            d="M317.8,275.2c0.1-0.4,0.4-0.7,0.5-0.8l-14.9-5.2c0.1,0.7,0,1.5-0.3,2.2l-1.1,3.3l-0.7,2.1
	c5.2,1.6,10.6,1.8,15.8,0.5L317.8,275.2L317.8,275.2z M285.3,311.7l-0.8-11.7l-24.1,2.9c1.8,1.1,3.6,2.3,5.4,3.7
	c4.1,2.3,8.5,4.4,13.5,6.1L285.3,311.7L285.3,311.7z"
          />
          <path
            fill="none"
            d="M283,329.7l-1.6-7.6c-2.9-3.9-6.3-7.6-10.2-11.1c-1.8-1.5-3.6-3-5.4-4.4c-2.1-1.1-3.9-2.3-5.6-3.7h0.3
	c-12.2-7.7-26.1-11.6-41.8-11.6c-18,0-33.7,5.1-47.2,15.1l4.1,2.3c12.1-7.7,25.9-11.6,41.3-11.6c20.2,0,37.6,6.6,51.9,19.7
	C272.7,320.6,277.5,324.9,283,329.7L283,329.7z"
          />
          <path
            fill="none"
            d="M279.4,312.6c-4.8-1.6-9.4-3.7-13.5-6.1c1.8,1.4,3.6,2.9,5.4,4.4c3.9,3.4,7.3,7.2,10.2,11.1L279.4,312.6
	L279.4,312.6z M260.5,302.9h-0.3c1.8,1.4,3.7,2.6,5.6,3.7C264.1,305.2,262.3,304,260.5,302.9L260.5,302.9z M252.4,342.8
	c1.1,1,1.9,2.1,2.9,3c8.1,9.4,12.1,20.4,12.1,33c0,3.6-0.3,7.2-1,10.5h9.2l-0.5-11c-0.8-16.6-6.9-30.5-18.3-42.1
	c-11.4-11.4-25-17.1-41.1-17.1c-8.8,0-17.1,1.8-24.5,5.2c-6.1,2.8-11.6,6.7-16.8,11.8c-9.6,9.6-15.3,21-17.1,34.3
	c-0.3,2.3-0.6,4.8-0.6,7.3c-0.1,4.4-0.4,8.4-1.1,11.7h10.5c-0.7-3.4-1.1-6.9-1.1-10.6c0-14.2,5-26.1,15-36.2c10-9.9,22-15,36.2-15
	C230.4,327.9,242.4,332.9,252.4,342.8L252.4,342.8z"
          />
          <path
            fill="none"
            d="M252.4,342.9c-10-10-22.1-15-36.2-15c-14.2,0-26.1,5-36.2,15c-9.9,9.9-15,22-15,36.2c0,3.7,0.4,7.2,1.1,10.6
	c1.5,7.7,4.8,14.9,9.9,21.2c1.2,1.5,2.5,2.9,4,4.4c10,9.9,22,15,36.2,15c13.2,0,24.5-4.4,34.1-13.1l2.1-1.9
	c7.4-7.4,12.1-16,13.9-25.7c0.7-3.4,1-6.9,1-10.5c0-12.7-4-23.7-12.1-33C254.5,344.8,253.5,343.9,252.4,342.9L252.4,342.9z
	 M242.9,352.4l1.6,1.8c2.5,2.8,4.4,5.8,5.9,8.9c2.2,4.8,3.4,10.2,3.4,16s-1.1,11-3.4,16c-1.8,3.9-4.4,7.4-7.6,10.6l-3.4,3.2
	c-6.6,5.2-14.3,7.8-23.1,7.8s-16.5-2.6-23.1-7.8c-1.2-1-2.3-2.1-3.6-3.2c-3-3-5.4-6.3-7.2-9.8c-2.5-5.1-3.9-10.6-3.9-16.8
	c0-6.1,1.2-11.7,3.9-16.8c1.4-2.9,3.3-5.6,5.6-8.1l1.5-1.8h0.1c4.5-4.5,9.9-7.7,15.7-9.5c3.4-1,7-1.5,10.7-1.5
	c3.9,0,7.4,0.5,10.7,1.5c2.6,0.8,5.2,1.8,7.6,3.3C237.6,347.7,240.3,349.8,242.9,352.4L242.9,352.4z"
          />
          <path
            fill="none"
            d="M244.4,354l-1.6-1.8c-2.5-2.5-5.2-4.7-8.3-6.2c-2.3-1.4-5-2.5-7.6-3.3c-3.4-1-7-1.5-10.7-1.5
	c-3.9,0-7.4,0.5-10.7,1.5c-5.8,1.6-11.1,4.8-15.7,9.5h-0.1l-1.5,1.8c-2.3,2.5-4.1,5.2-5.6,8.1c-2.5,5.1-3.9,10.6-3.9,16.8
	c0,6.1,1.2,11.7,3.9,16.8c1.8,3.6,4.1,6.7,7.2,9.8c1.1,1.1,2.3,2.2,3.6,3.2c6.6,5.2,14.3,7.8,23.1,7.8s16.5-2.6,23.1-7.8l3.4-3.2
	c3.2-3.3,5.8-6.7,7.6-10.6c2.2-4.8,3.4-10.2,3.4-16c0-5.8-1.1-11.1-3.4-16C249,359.8,247.1,356.8,244.4,354L244.4,354z M242.1,353.2
	l0.8,1c1.8,1.9,3.4,4,4.7,6.2l1.2,2.2c0.6,1.1,1.1,2.3,1.5,3.4l0.7,1.8l0.6,2.3c0.7,2.9,1,5.8,1,8.9s-0.3,6.2-1.1,9.1l-0.7,2.2
	l-0.6,1.6c-0.4,1.2-1.1,2.5-1.6,3.7l-1,1.8c-1.5,2.6-3.4,5-5.6,7.3c-1.5,1.5-3,2.8-4.5,4l-4.7,2.9c-1.6,0.8-3.4,1.6-5.4,2.2
	l-2.3,0.7c-2.9,0.7-5.8,1-8.8,1c-3.2,0-6.2-0.4-9.1-1.1l-2.2-0.5c-1.8-0.5-3.6-1.2-5.2-2.2l-1.9-1.1c-0.8-0.5-1.8-1.1-2.5-1.8
	c-1.6-1.2-3.2-2.5-4.5-4c-2.2-2.2-4.1-4.5-5.6-7l-1.1-2.1l-1.2-2.6l-1-2.8l-0.7-2.5c-0.7-2.9-1-5.8-1-8.9s0.3-6.2,1.1-9.1
	c0.1-0.7,0.4-1.4,0.6-2.2c0.3-1,0.7-1.9,1.1-2.8c0.4-0.8,0.7-1.7,1.2-2.5c0.3-0.7,0.7-1.4,1.1-2.1c1.4-2.2,2.9-4.3,4.8-6.3l0.8-1
	c2.2-2.2,4.5-4.1,7-5.5c0.7-0.4,1.4-0.8,2.1-1.1c1.8-1,3.4-1.7,5.4-2.2l2.3-0.7c1.1-0.3,2.2-0.5,3.4-0.7l5.5-0.4l5.5,0.4l3.4,0.7
	h0.1l2.2,0.7c1.8,0.5,3.6,1.2,5.4,2.2l1.8,1h0.1C237.4,349,239.8,350.9,242.1,353.2L242.1,353.2z"
          />
          <path
            fill="none"
            d="M242.9,354l-0.8-1c-2.3-2.3-4.7-4.1-7.3-5.8h-0.1l-1.8-1l-1,1.6l-5,8.8c-0.8-0.4-1.8-0.8-2.6-1.1l2.6-9.8
	l0.6-1.8l-2.2-0.7h-0.1l-2.9,13.9l-3.7,13.6l-0.1,0.3l-0.6,0.7c0.6,0.1,1.1,0.3,1.6,0.7c1.5-2.9,3.2-5.8,4.8-8.7l2.8-4.7l7.4-11.8
	l0.4,2.3c1.8,1.2,3.6,2.6,5.2,4.3l0.4,0.4c1.6,1.6,3.2,3.4,4.5,5.4l2.3,0.3C246.4,358,244.7,356,242.9,354L242.9,354z"
          />
          <path
            fill="none"
            d="M245.3,359.8H245l-8.4,5.5l-1.8,1.2l-7.4,4.5l-4.8,4.5c4.4-2.5,8.9-5.1,13.2-7.7l1.8-1.1
	c2.9-1.8,5.6-3.6,8.5-5.5c0.6-0.4,1-0.7,1.5-1L245.3,359.8L245.3,359.8z"
          />
          <path
            fill="none"
            d="M245,359.8h0.3c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4c-1.6-1.6-3.4-3-5.2-4.3v0.1l-2.6,4.1c-1,1.4-1.8,2.9-2.8,4.4
	c1.4,0.8,2.6,1.9,3.9,3.2l1.1,1.2c0.7,0.8,1.4,1.6,2.1,2.5L245,359.8z M248.9,362.4l-1.2-2.2c-0.6,0.3-1,0.7-1.5,1
	c-2.9,1.9-5.6,3.7-8.5,5.5l-1.8,1.1c-4.4,2.6-8.8,5.2-13.2,7.7l0.7,1.6c0.3-0.1,0.4-0.3,0.7-0.4l0.4-0.1c4.4-1.4,8.9-2.6,13.8-3.7
	l13.6-3l-1.4,2.1l0.1,1c0.3,1.9,0.4,3.9,0.4,5.9c0,2.1-0.1,4-0.4,5.9l-0.1,1.1l1.4,2.1c0.7-2.9,1.1-5.9,1.1-9.1s-0.3-6.1-1-8.9
	l-0.6-2.3l-1.6,0.5l-9.9,2.8l-1.1-2.6l8.8-5.1L248.9,362.4L248.9,362.4z"
          />
          <path
            fill="none"
            d="M250.5,365.9c-0.4-1.2-1-2.3-1.5-3.4l-1.5,0.8c0.8,1.5,1.5,3.2,2.1,5l1.6-0.5L250.5,365.9L250.5,365.9z
	 M250.2,372.2l1.4-2.1l-13.6,3c-4.7,1.1-9.4,2.3-13.8,3.7h0.4c0.6,0,1.1,0.1,1.5,0.5h3.2l0.6-0.1l0.4-0.1c2.6-1,5.2-1.8,7.8-2.5
	l2.1-0.5C243.6,373.3,246.8,372.6,250.2,372.2L250.2,372.2z"
          />
          <path
            fill="none"
            d="M250.4,372.1c-3.4,0.5-6.7,1.2-9.9,1.9c0.3,1.6,0.4,3.2,0.4,5c0,1.8-0.1,3.4-0.6,5.1c3.2,0.8,6.5,1.4,9.9,1.8
	l0.1,0.1l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9c0-2.1-0.1-4-0.4-5.9L250.4,372.1L250.4,372.1z"
          />
          <path
            fill="none"
            d="M250.2,386c-3.4-0.5-6.7-1.2-9.9-1.9l-2.1-0.5c-2.8-0.7-5.2-1.5-7.8-2.5l-0.4-0.1l-0.7-0.1H226
	c-0.4,0.3-0.8,0.6-1.4,0.6h-0.6c4.4,1.4,9.1,2.6,13.8,3.7l13.8,3L250.2,386L250.2,386z"
          />
          <path
            fill="none"
            d="M251.1,390.2l0.7-2.2l-13.8-3c-4.8-1.1-9.4-2.5-13.8-3.7l-0.3-0.1l-0.7-0.5c-0.1,0.5-0.4,1.1-0.7,1.6
	c4.5,2.5,8.9,5,13.3,7.6l1.8,1.1c2.9,1.8,5.8,3.6,8.7,5.4l1.5,1l1-1.8l-1.5-0.8l-8.8-5l1.1-2.6l9.8,2.6L251.1,390.2L251.1,390.2z"
          />
          <path
            fill="none"
            d="M250.5,391.9l0.6-1.6l-1.8-0.5c-0.6,1.7-1.2,3.3-2.1,5l1.5,0.8C249.4,394.3,250,393.1,250.5,391.9L250.5,391.9z
	 M249.4,368.2c-0.6-1.8-1.2-3.4-2.1-5l-8.8,5.1l1.1,2.6L249.4,368.2L249.4,368.2z M216.4,342.5l-5.5,0.4c-1.2,0.1-2.3,0.4-3.4,0.7
	l-2.3,0.7l0.6,1.8l2.8,9.8c-1,0.3-1.8,0.7-2.6,1.1l-5.1-8.8l-1-1.5c-0.7,0.3-1.4,0.7-2.1,1.1c-2.5,1.5-4.8,3.4-7,5.5l-0.8,1
	c-1.9,2.1-3.4,4.1-4.8,6.3l2.3-0.4c0.8-1.1,1.6-2.3,2.6-3.4l2.3-2.5c1.5-1.5,3.3-2.9,5-4.1l0.4-2.3l10.5,16.4c1.6,2.9,3.3,5.8,5,8.7
	c0.6-0.3,1.1-0.6,1.8-0.7c-0.1-0.3-0.4-0.4-0.4-0.7l-0.1-0.4l-4.4-16.9c-0.7-2.9-1.4-5.8-1.9-8.8l-0.3-1.8l2.1,1.4
	c2.2-0.4,4.5-0.7,6.9-0.7c2.5,0,4.8,0.3,7,0.7l1.9-1.5l-3.4-0.7L216.4,342.5L216.4,342.5z"
          />
          <path
            fill="none"
            d="M199.7,346.5l1,1.5c1.6-0.8,3.3-1.5,5-2.1l-0.6-1.8C203.2,344.8,201.4,345.5,199.7,346.5L199.7,346.5z
	 M197.3,350.2c-1.6,1.1-3.4,2.3-5,4l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4h0.1l8.5,5.4l1-1.4c0.7-0.8,1.4-1.6,2.2-2.5
	c1.1-1.1,2.5-2.2,3.7-3.2L197.3,350.2L197.3,350.2z"
          />
          <path
            fill="none"
            d="M197.3,350.1l5.5,8.5l1.2,1.9l4.4,7.4l4.5,5l-5-8.7l-10.5-16.4L197.3,350.1L197.3,350.1z M187.2,360.1l-2.3,0.4
	l12,7.6c4.4,2.6,8.8,5.1,13.3,7.6l-5-4.5c-0.3-0.1-0.4-0.3-0.7-0.3l-2.5-1.5l-5.8-3.4l-0.6-0.3L187.2,360.1z"
          />
          <path
            fill="none"
            d="M184.9,360.4c-0.4,0.7-0.8,1.4-1.1,2.1l1.5,0.8l8.8,5c-0.4,0.8-0.8,1.6-1.1,2.6l-9.8-2.6l-1.8-0.5
	c-0.3,0.7-0.4,1.4-0.6,2.2l1.7,0.3h0.1c3.4,0.7,6.7,1.4,9.9,2.2l2.1,0.6c4.7,1.1,9.2,2.3,13.6,3.7h0.1l0.3,0.1
	c0.3,0.1,0.6,0.3,0.8,0.5c0.1-0.5,0.3-1.1,0.7-1.6c-4.5-2.5-8.9-5-13.3-7.6L184.9,360.4L184.9,360.4z"
          />
          <path
            fill="none"
            d="M185.4,363.3l-1.5-0.8c-0.4,0.8-0.8,1.6-1.2,2.5c-0.4,1-0.7,1.9-1.1,2.8l1.8,0.5
	C183.8,366.6,184.5,364.9,185.4,363.3L185.4,363.3z M196.9,364.1l-1,1.4l0.6,0.3c0.4-0.5,0.8-1.1,1.4-1.6c0.8-0.8,1.6-1.8,2.8-2.5
	l0.8,1c0.8-0.8,1.8-1.5,2.8-2.2l-1.2-1.9c-1.2,1-2.6,1.9-3.7,3.2C198.2,362.4,197.5,363.3,196.9,364.1L196.9,364.1z"
          />
          <path
            fill="none"
            d="M197.7,364.1c-0.6,0.5-1,1.1-1.4,1.6l5.8,3.4h2.5c-0.7-2.1-1.5-3.7-2.2-5.1l-1.1-1.5l-0.8-1
	C199.5,362.3,198.5,363.1,197.7,364.1L197.7,364.1z M179.9,378.9c0,3.2,0.3,6.1,1,8.9l0.7,2.5l1.8-0.6l9.8-2.8
	c0.3,0.8,0.7,1.8,1.1,2.6l-8.8,5.1l-1.5,1l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7c1.5,1.5,3,2.8,4.5,4c0.8,0.5,1.6,1.2,2.5,1.8l-0.3-2.3
	c-1.9-1.2-3.9-2.9-5.6-4.7c-1.8-1.6-3.2-3.6-4.5-5.5l-2.3-0.4c0.6-0.3,1-0.7,1.5-1c2.8-1.9,5.6-3.7,8.5-5.5l1.8-1.1
	c4.3-2.8,8.7-5.2,13.2-7.7l0.1-0.1c-0.3-0.5-0.6-1.1-0.7-1.8l-1.1,0.6l-7,1.9l-8.8,2.2l-9.9,2.2h-0.1l-1.7,0.3l1.4-1.9
	c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2l-1.5-1.9C180.3,372.7,179.9,375.8,179.9,378.9L179.9,378.9z"
          />
          <path
            fill="none"
            d="M197.8,375.4l-3.3-1c-0.3,1.5-0.4,3-0.4,4.5s0.1,2.9,0.4,4.3l3-0.8l-0.6-0.5c-0.7-0.8-1.1-1.8-1.1-2.9
	s0.4-2.1,1.1-2.9C197.1,375.8,197.4,375.5,197.8,375.4L197.8,375.4z"
          />
          <path
            fill="none"
            d="M194.5,374.4l3.3,1l3.6,1.2l1.8,0.6h3.3c0.4-0.3,0.8-0.5,1.5-0.5h0.6c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.5
	c-3.3-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3l1.5,2.1v0.1h0.1c3.3,0.4,6.6,1.1,9.8,1.8L194.5,374.4L194.5,374.4z"
          />
          <path
            fill="none"
            d="M197.8,375.4c-0.3,0.1-0.7,0.4-1,0.8c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.2,1.1,2.9l0.6,0.5l4-1.2v-4.7
	L197.8,375.4L197.8,375.4z M192.3,373.9c-0.3,1.6-0.6,3.4-0.6,5.1s0.1,3.3,0.4,4.8l2.1-0.5c-0.3-1.4-0.4-2.8-0.4-4.3
	c0-1.5,0.1-3.2,0.4-4.5L192.3,373.9z"
          />
          <path
            fill="none"
            d="M191.9,378.9c0-1.8,0.1-3.4,0.6-5.1c-3.2-0.8-6.5-1.4-9.8-1.8h-0.1v-0.1c-0.4,2.3-0.7,4.7-0.7,7.2
	c0,2.3,0.3,4.7,0.7,6.9l0.1-0.1h0.1c3.3-0.4,6.5-1.1,9.6-1.8C192,382.2,191.9,380.6,191.9,378.9L191.9,378.9z M193.1,371
	c0.3-1,0.7-1.8,1.1-2.6l-8.8-5c-0.8,1.5-1.5,3.2-2.1,5L193.1,371L193.1,371z"
          />
          <path
            fill="none"
            d="M182.4,385.7l-0.1,0.1l-1.4,1.9l1.6-0.3h0.1l9.9-2.2l8.8-2.2l7-1.9H208c-0.6,0-1.1-0.1-1.5-0.5h-3.2l-0.4,0.1
	c0,0-0.1,0-0.1,0.1h-0.6l-0.8,0.3l-4,1.2l-3,0.8l-2.1,0.5c-3.2,0.8-6.5,1.4-9.6,1.8h-0.3L182.4,385.7L182.4,385.7z M183.4,389.7
	l-1.8,0.5l1,2.8l1.2,2.6l1.5-1C184.6,393.1,183.9,391.5,183.4,389.7L183.4,389.7z M196.9,389.9l-1.8,1.1c-2.9,1.8-5.8,3.6-8.5,5.5
	c-0.4,0.3-1,0.5-1.5,1l2.3,0.4h0.1l8.4-5.5l0.7-0.4l1.1-0.7c1.6-1.1,3.3-2.1,5-3c0.7-0.4,1.2-0.8,1.9-1.1l0.7-0.4l5-4.5
	C205.5,384.7,201.1,387.3,196.9,389.9L196.9,389.9z"
          />
          <path
            fill="none"
            d="M194.2,389.7c-0.4-0.8-0.8-1.8-1.1-2.6l-9.8,2.8c0.6,1.6,1.2,3.3,2.1,5L194.2,389.7L194.2,389.7z M232.1,348
	l1-1.6c-1.8-0.8-3.4-1.6-5.4-2.2l-0.6,1.8C228.8,346.5,230.4,347.2,232.1,348L232.1,348z M232.6,354l2.6-4.1v-0.1l-0.4-2.3
	l-7.4,11.8l-2.8,4.7c-1.7,2.9-3.3,5.8-4.8,8.7l4.5-5l4.4-7.6l1.1-1.8C230.7,356.9,231.7,355.6,232.6,354L232.6,354z M221.6,354
	c0.7-2.9,1.2-5.9,1.6-9.1h0.1c-2.2-0.4-4.5-0.7-7-0.7c-2.3,0-4.7,0.3-6.9,0.7l0.1,0.1c0.4,3,1,6.1,1.8,9.1l0.1,0.8l4.8-0.4
	c1.8,0,3.4,0.1,5.1,0.4L221.6,354L221.6,354z"
          />
          <path
            fill="none"
            d="M223.3,345.1c-0.4,3-1,6.1-1.6,9.1l-0.3,0.8l-0.6,2.1c-0.7,2.3-1.4,4.8-2.2,7l-0.6,1.8v3.3
	c0.4,0.4,0.6,0.8,0.6,1.4v0.5l3.7-13.6l2.9-13.9L223.3,345.1L223.3,345.1z M229.9,358.4l-1.1,1.8c1.2,0.8,2.3,1.8,3.4,2.9l1,1
	c0.7,0.8,1.4,1.5,1.9,2.5l1.8-1.2c-0.6-0.8-1.2-1.6-2.1-2.5l-1.1-1.2C232.3,360.4,231.1,359.4,229.9,358.4L229.9,358.4z
	 M224.5,355.8c0.8,0.3,1.8,0.7,2.6,1.1l5-8.8c-1.6-0.8-3.2-1.5-5-2.1L224.5,355.8z"
          />
          <path
            fill="none"
            d="M233,364.1l-1-1c-1.1-1.1-2.2-2.1-3.4-2.9l-4.4,7.6l3.2,3.2l7.4-4.5C234.4,365.6,233.7,364.8,233,364.1
	L233,364.1z M216.2,354.5l-4.8,0.4l0.6,2.1l4.4-0.4l4.5,0.4l0.6-2.1C219.8,354.6,218,354.5,216.2,354.5z"
          />
          <path
            fill="none"
            d="M211.4,354.9l-0.1-0.8c-0.7-2.9-1.2-5.9-1.8-9.1l-0.1-0.1l-2.1-1.4l0.3,1.8c0.6,3,1.2,5.9,1.9,8.8l4.4,16.9
	v-0.4c0-0.5,0.1-1.1,0.6-1.5v-3.3l-0.6-1.8c-0.8-2.3-1.5-4.7-2.1-7L211.4,354.9L211.4,354.9z M201.3,362.6l1.1,1.5
	c0.8,1.4,1.5,3.2,2.2,5.1v1.5c0.1,0.1,0.4,0.3,0.7,0.3l3.2-3.2l-4.4-7.4C203,361.1,202.1,361.7,201.3,362.6L201.3,362.6z
	 M205.7,356.8c0.8-0.4,1.8-0.8,2.6-1.1l-2.8-9.8c-1.8,0.5-3.4,1.2-5,2.1L205.7,356.8L205.7,356.8z"
          />
          <path
            fill="none"
            d="M218.7,364.1c0.8-2.3,1.5-4.7,2.2-7l-4.5-0.4l-4.4,0.4c0.6,2.3,1.2,4.8,2.1,7l0.6,1.8h3.6L218.7,364.1
	L218.7,364.1z"
          />
          <path
            fill="none"
            d="M218.2,365.7h-3.6v3.2l0.1-0.1c0.4-0.4,1-0.7,1.5-0.7c0.7,0,1.2,0.3,1.6,0.7l0.1,0.1L218.2,365.7L218.2,365.7z"
          />
          <path
            fill="none"
            d="M218.2,369l-0.1-0.1c-0.4-0.4-1-0.7-1.7-0.7s-1.1,0.3-1.5,0.7l-0.1,0.1c-0.4,0.4-0.6,1-0.6,1.5v0.4l0.1,0.4
	c0.1,0.3,0.3,0.5,0.4,0.7c0.4,0.4,1,0.7,1.5,0.7c0.7,0,1.2-0.3,1.7-0.7l0.6-0.7l0.1-0.3v-0.5C218.7,370,218.4,369.5,218.2,369
	L218.2,369z M216.4,369.3c0.3,0,0.6,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-0.8,0.4s-0.7-0.1-0.8-0.4
	c-0.3-0.3-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8C215.7,369.3,216,369.3,216.4,369.3L216.4,369.3z"
          />
          <path
            fill="none"
            d="M217.2,369.6c-0.3-0.3-0.6-0.4-0.8-0.4s-0.7,0.1-0.8,0.4c-0.3,0.3-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1
	c0.3,0.3,0.6,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4s0.4-0.5,0.4-1C217.6,370.1,217.5,369.9,217.2,369.6L217.2,369.6z M218,372.1
	c-0.4,0.4-1,0.7-1.7,0.7s-1.1-0.3-1.5-0.7c-0.7,0.1-1.2,0.3-1.8,0.7c-0.6,0.3-1.1,0.7-1.6,1.2c-0.4,0.5-0.8,1-1.2,1.5
	c-0.3,0.5-0.6,1.1-0.7,1.6c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.5v0.1c0.1,0.5,0.3,1.1,0.7,1.8c0.3,0.5,0.7,1.1,1.2,1.6
	s1,0.8,1.5,1.2c0.6,0.3,1.1,0.5,1.6,0.7v-0.1c0.4-0.4,1-0.7,1.6-0.7c0.6,0,1.1,0.3,1.6,0.7l0.1,0.1c0.6-0.1,1.1-0.4,1.8-0.7
	c0.6-0.3,1.1-0.8,1.6-1.2c0.4-0.4,0.8-1,1.2-1.5c0.3-0.5,0.6-1.1,0.7-1.6h-0.1c-0.4-0.4-0.7-1-0.7-1.6s0.3-1.2,0.7-1.6l0.1-0.1
	l-0.7-1.6c-0.3-0.5-0.7-1.1-1.2-1.6c-0.4-0.4-1-0.8-1.5-1.1C219.1,372.5,218.6,372.2,218,372.1L218,372.1z"
          />
          <path
            fill="none"
            d="M211.4,374c0.6-0.5,1.1-0.8,1.6-1.2l-4.5-5l-3.2,3.2l5,4.5C210.5,375,210.9,374.4,211.4,374L211.4,374z
	 M202.1,369.2l2.5,1.5v-1.5 M203.2,377l-1.8-0.5v4.7l0.8-0.3l0.4-0.1l0.1-0.1l0.4-0.1V377L203.2,377z M208.4,376.6h-0.6
	c-0.6,0-1.1,0.1-1.5,0.6l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.5l0.1,0.1c0.4,0.4,1,0.5,1.5,0.5h0.4l1.1-0.5v-0.1
	c0.4-0.4,0.7-1,0.7-1.5c0-0.7-0.3-1.2-0.7-1.6c-0.3-0.3-0.6-0.4-0.8-0.5L208.4,376.6L208.4,376.6z M207,379.8
	c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,0.8-0.4c0.3,0,0.6,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1
	c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4C207.6,380.2,207.3,380,207,379.8L207,379.8z"
          />
          <path
            fill="none"
            d="M206.6,378.8c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4s0.4-0.5,0.4-0.8
	c0-0.4-0.1-0.7-0.4-1s-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-0.8,0.4C206.8,378.2,206.6,378.5,206.6,378.8L206.6,378.8z M206.3,377.2
	l0.1-0.1h-3.3v3.6h3.2l-0.1-0.1c-0.4-0.4-0.7-1-0.7-1.5C205.7,378.3,205.8,377.7,206.3,377.2L206.3,377.2z M210.2,382.2l-5,4.5
	l3.2,3.2l4.5-5c-0.6-0.3-1.1-0.7-1.5-1.2C210.9,383.3,210.5,382.8,210.2,382.2L210.2,382.2z M204.6,387.2c-0.6,0.4-1.2,0.7-1.9,1.1
	h1.9L204.6,387.2L204.6,387.2z"
          />
          <path
            fill="none"
            d="M205.1,386.8l-0.7,0.4v1.1c-1.1,2.9-2.2,5.2-3.4,6.9l2.9,2.3l4.4-7.6L205.1,386.8L205.1,386.8z"
          />
          <path
            fill="none"
            d="M204.6,388.3h-1.9c-1.6,1-3.3,1.9-4.9,3l-1.1,0.7c1.1,1.4,2.3,2.8,3.9,3.9l0.6-0.7
	C202.4,393.5,203.5,391.3,204.6,388.3L204.6,388.3z M219.7,385.1c2.5,4.5,5.1,8.9,7.7,13.3l1.1,1.8c1.8,2.9,3.6,5.6,5.5,8.4v0.1
	l1,1.4l0.4-2.2v-0.3l-5.5-8.4l-1.1-1.8l-4.5-7.6L219.7,385.1L219.7,385.1z"
          />
          <path
            fill="none"
            d="M219.7,385.1c-0.6,0.3-1.1,0.5-1.8,0.7c0.1,0.1,0.3,0.4,0.4,0.7l0.1,0.4c1.4,4.4,2.6,8.9,3.7,13.8l3,13.6
	l-1.9-1.2c-2.2,0.4-4.5,0.6-6.9,0.6c-2.5,0-4.8-0.3-7-0.7l-2.1,1.4c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.3,8.8-1l2.3-0.7l-0.4-1.8
	l-2.8-9.8c0.8-0.3,1.8-0.7,2.6-1.1l5.1,8.8l0.8,1.5l4.7-2.9c1.5-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3l-2.3,0.3
	c-1.2,1.9-2.9,3.9-4.5,5.6c-1.8,1.8-3.6,3.2-5.5,4.5l-0.4,2.2l-1-1.4v-0.1c-1.9-2.8-3.7-5.5-5.5-8.4l-1.1-1.8
	C224.6,394.1,222.2,389.7,219.7,385.1L219.7,385.1z"
          />
          <path
            fill="none"
            d="M217.9,385.8c-0.6-0.5-1.1-0.8-1.6-0.8c-0.7,0-1.2,0.3-1.6,0.7v0.1l-0.6,0.7l-0.1,0.3v0.5
	c0,0.6,0.1,1.1,0.6,1.5l0.1,0.1c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.1-0.3,1.6-0.7l0.1-0.1c0.4-0.4,0.6-1,0.6-1.5v-0.4l-0.1-0.4
	C218.2,386.2,218,386,217.9,385.8L217.9,385.8z M215.3,388.2c-0.3-0.3-0.4-0.5-0.4-0.8c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4
	c0.3,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4C216,388.6,215.6,388.4,215.3,388.2
	L215.3,388.2z"
          />
          <path
            fill="none"
            d="M215,387.3c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.6,0.4,1,0.4c0.3,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8
	c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4C215.1,386.6,215,386.9,215,387.3L215,387.3z M214.6,385.8
	c-0.6-0.1-1.1-0.4-1.6-0.7c-2.5,4.5-5,8.9-7.6,13.3l-7.6,12l1.9,1.1l0.8-1.5l5-8.8c0.8,0.4,1.6,0.8,2.6,1.1l-2.6,9.8l-0.6,1.8
	l2.2,0.5l3-13.8c1.1-4.8,2.5-9.4,3.7-13.8l0.1-0.3L214.6,385.8L214.6,385.8z M214.6,389l-0.1-0.1v3.3h3.6V389l-0.1,0.1
	c-0.4,0.4-1,0.7-1.6,0.7C215.6,389.5,215,389.4,214.6,389L214.6,389z"
          />
          <path
            fill="none"
            d="M214.5,388.7c-0.3-0.4-0.6-0.8-0.6-1.5v-0.5c-1.4,4.4-2.6,8.9-3.7,13.8l-3,13.8l2.1-1.4l0.1-0.1l0.7-4.1
	l1.2-5.6l0.6-2.1c0.7-2.6,1.5-5.2,2.3-7.8l0.1-0.4l0.1-0.5L214.5,388.7L214.5,388.7z M218.4,387.3c0,0.5-0.1,1.1-0.6,1.5v3.2l0.3,1
	c1,2.6,1.8,5.2,2.5,7.8l0.6,2.1c0.4,1.9,0.8,3.7,1.1,5.6l0.7,4.1l0.1,0.1l1.9,1.2l-3-13.6c-1.1-4.7-2.3-9.4-3.7-13.8L218.4,387.3
	L218.4,387.3z M212.9,385.1l-4.5,5l-4.4,7.6c-0.4,0.5-0.8,1.2-1.1,1.8l-5.4,8.4l0.1,0.1l0.3,2.3l7.6-12
	C208,394.1,210.5,389.7,212.9,385.1L212.9,385.1z M238.7,378.9c0-1.5-0.1-3-0.4-4.4c-2.8,0.7-5.4,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1v3.6
	l0.7,0.1l0.4,0.1c2.6,1,5.2,1.8,7.8,2.5C238.5,382,238.7,380.5,238.7,378.9L238.7,378.9z"
          />
          <path
            fill="none"
            d="M238.3,374.5c0.3,1.4,0.4,2.9,0.4,4.4c0,1.5-0.1,3-0.4,4.5l2.1,0.5c0.3-1.6,0.6-3.3,0.6-5.1
	c0-1.6-0.1-3.3-0.4-5L238.3,374.5L238.3,374.5z M224.8,376.7h-0.4l-0.4,0.1c-0.3,0.1-0.4,0.1-0.7,0.4l-0.1,0.1
	c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.6h0.1l0.7,0.5l0.3,0.1h0.6c0.6,0,1-0.1,1.4-0.5l0.1-0.1c0.4-0.4,0.7-1,0.7-1.6
	s-0.3-1.2-0.7-1.6l-0.1-0.1C225.7,376.9,225.3,376.7,224.8,376.7L224.8,376.7z M225.6,378.1c0.3,0.3,0.4,0.5,0.4,0.8s-0.1,0.7-0.4,1
	c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4s-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,1-0.4
	C225.1,377.7,225.3,377.8,225.6,378.1L225.6,378.1z"
          />
          <path
            fill="none"
            d="M226,378.9c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.6-0.4-0.8-0.4c-0.4,0-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8
	s0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4s0.7-0.1,0.8-0.4C225.9,379.6,226,379.2,226,378.9L226,378.9z M221.3,374
	c0.6,0.5,1,1.1,1.2,1.6l4.8-4.5l-3.2-3.2l-4.5,5C220.4,373,220.8,373.4,221.3,374z M229.5,377.3h-3.2l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6
	c0,0.5-0.3,1.1-0.7,1.6l-0.1,0.1h3.3L229.5,377.3L229.5,377.3z M222.4,382.4l5,4.5c2.5,1.4,5.1,2.9,7.6,4.4l1.8,1.1l8.4,5.4h0.1
	l2.3-0.3l-1.5-1c-2.9-1.9-5.8-3.7-8.7-5.4l-1.8-1.1C231.5,387.3,227,384.7,222.4,382.4L222.4,382.4z"
          />
          <path
            fill="none"
            d="M222.4,382.4c-0.3,0.5-0.7,1.1-1.2,1.5c-0.6,0.5-1.1,1-1.6,1.2l4.5,5l3.2-3.2L222.4,382.4L222.4,382.4z
	 M227.4,386.9l-3.2,3.2l4.5,7.6c1.2-0.8,2.3-1.8,3.3-2.9c1.1-1.1,2.1-2.2,2.9-3.4C232.5,389.8,230,388.3,227.4,386.9L227.4,386.9z
	 M239.5,387.1l-1.1,2.6l8.8,5c0.8-1.6,1.5-3.3,2.1-5L239.5,387.1L239.5,387.1z"
          />
          <path
            fill="none"
            d="M233.6,396.3c1.2-1.2,2.2-2.5,3.2-3.9l-1.8-1.1c-0.8,1.2-1.8,2.3-2.9,3.4s-2.2,1.9-3.3,2.9l1.1,1.8
	C231.2,398.5,232.5,397.4,233.6,396.3L233.6,396.3z"
          />
          <path
            fill="none"
            d="M236.7,392.4c-1,1.4-1.9,2.6-3.2,3.9c-1.1,1.1-2.3,2.2-3.7,3.2l5.5,8.4v0.3c1.9-1.2,3.7-2.8,5.5-4.5
	c1.8-1.8,3.3-3.7,4.5-5.6h-0.1L236.7,392.4L236.7,392.4z M227,401.1c-0.8,0.4-1.6,0.8-2.6,1.1l2.8,9.8c1.7-0.5,3.3-1.2,5-1.9
	L227,401.1L227,401.1z"
          />
          <path
            fill="none"
            d="M227.1,412l0.4,1.8c1.8-0.6,3.6-1.2,5.4-2.2l-0.8-1.5C230.4,410.7,228.8,411.4,227.1,412L227.1,412z
	 M223.1,412.9l-0.1-0.1l-0.7-4.1c-0.3-1.9-0.7-3.9-1.1-5.6l-5,0.4l-5.1-0.4l-1.2,5.6l-0.7,4.1l-0.1,0.1c2.2,0.4,4.5,0.7,7,0.7
	C218.7,413.6,221.1,413.3,223.1,412.9L223.1,412.9z M217.9,392h-3.6l-0.1,0.5L214,393c-1,2.6-1.6,5.2-2.3,7.8l4.5,0.4l4.4-0.4
	c-0.7-2.8-1.5-5.2-2.5-7.8L217.9,392L217.9,392z"
          />
          <path
            fill="none"
            d="M211.7,400.8l-0.4,2.1l5,0.5l5-0.4l-0.6-2.1l-4.3,0.3 M202.8,399.4c0.4-0.7,0.7-1.2,1.1-1.8l-2.9-2.3l-0.6,0.7
	c-1.5-1.1-2.9-2.5-3.9-3.9l-0.7,0.4c0.8,1.4,1.9,2.6,3.2,3.7C200.3,397.5,201.5,398.5,202.8,399.4L202.8,399.4z M208.1,402.2
	c-0.8-0.3-1.8-0.7-2.6-1.1l-5,8.8c1.5,0.8,3.2,1.5,5,1.9L208.1,402.2L208.1,402.2z"
          />
          <path
            fill="none"
            d="M205.1,413.6l0.6-1.8c-1.6-0.5-3.3-1.2-5-1.9l-0.8,1.5C201.4,412.4,203.2,413.1,205.1,413.6L205.1,413.6z
	 M199.1,396.3c-1.2-1.2-2.2-2.5-3.2-3.7l-8.4,5.5h-0.1c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.6,3.3,5.6,4.7l-0.1-0.1l5.4-8.4
	C201.5,398.5,200.3,397.5,199.1,396.3L199.1,396.3z M197.7,410.3h0.1 M275.7,389.4l0.1,2.1l6.2-0.1l-0.5-10.2
	c-0.8-19.1-7.6-35.2-20.1-48.4C248.9,319.6,233.7,313,216,313c-10.6,0-20.2,2.3-29,7l4.4,4.4c7.4-3.4,15.7-5.2,24.5-5.2
	c16.1,0,29.7,5.8,41.1,17.1c11.4,11.4,17.5,25.4,18.3,42.1L275.7,389.4L275.7,389.4z M290.8,370.1l0.3,2.3c0.1,2.2,0.4,3.9,0.8,5.2
	c0.8,2.2,2.8,3.2,6.1,3.2l172-4.5l-2.8-18.2c-33.3,4.1-65.8,7-97.5,8.5L290.8,370.1L290.8,370.1z M284.8,367.7
	c0.4,1.6,2.5,2.5,6.1,2.6l-0.8-7.7C285.7,363.1,283.9,364.9,284.8,367.7z"
          />
          <path
            fill="none"
            d="M290,362.6l0.8,7.7l78.8-3.4c31.8-1.5,64.2-4.4,97.5-8.5l0.4-7.6L290,362.6L290,362.6z M285.3,311.7
	c1.2,17.3,2.8,34.3,4.5,50.9l177.9-12c-0.4-22,0-44,1.1-65.9C406.1,293.5,344.9,302.5,285.3,311.7L285.3,311.7z M171.5,306.4
	c-1.9,1.4-3.7,2.9-5.5,4.5c-12.4,11.1-19.5,24.2-21.7,39.3c-0.3,1.4-0.4,2.8-0.6,4.1c2.5-14.3,9.5-26.8,21.2-37.4
	c3.4-3.2,6.9-5.8,10.7-8.1L171.5,306.4L171.5,306.4z M166.7,301.2l0.8-0.4l-13.5,4.3h-0.1c-12.5,3.9-24.5,7.2-36,10l-0.3,0.1
	l-19.1,4.4l-2.3-0.1l-1-0.5l-0.6-0.8l-1-2.9c-0.6-1.9-0.3-3.6,0.8-5l0.1-0.1l5.4-3.3l-3.4-2.2c-0.7,0.4-1.4,0.8-1.9,1.4
	c-2.1,1.7-3.9,3.3-5.4,5.2c-1.1,1.5-1.5,3.2-0.8,5.2l1,2.9c1.2,2.6,4.3,3.6,8.9,2.9l19.8-4.4c15.5-3.9,30.7-8.9,45.4-15L166.7,301.2
	L166.7,301.2z"
          />
          <path
            fill="none"
            d="M166.7,301.2l-3.4,1.5c-14.6,6.1-29.7,11.1-45.4,15l-19.8,4.4c-4.7,0.8-7.6-0.1-8.9-2.9l-1-2.9
	c-0.6-2.1-0.3-3.9,0.8-5.2c1.7-1.8,3.4-3.6,5.4-5.2c0.7-0.4,1.2-1,1.9-1.4l-5.2-2.5c-1.4,1.1-2.8,2.2-3.9,3.6
	c-2.3,2.6-4.5,5.5-6.6,8.5c0.6,0.4,1.1,1.2,1.5,2.3l5.9,18.8c0.3,0.8,0.3,1.8,0.3,2.8l-0.1,1.2l-0.3,1.1v0.1l-2.3,5l-0.3,0.4
	c-1.2,2.1-2.8,3.6-4.8,4.5h-0.3v8l5.8-1.9c0.8-0.1,1.2,0.1,1,1.1l-1.2,6.7l-0.3,1.1l-0.6,3.4l-0.3,1.2l-1.5,9.5c1-0.7,1.9-1.1,3-1.2
	l1.9-0.3l42.6-6.3c2.9-0.4,5.5-0.7,7.8-0.5h11.7l-67.3,12.4H78v0.8c0,0.8,0.3,1.4,0.8,1.6c0.7,0.4,1.5,0.8,2.6,1.4l3.2,1.5
	c1.4,0.5,2.9,1,4.8,1.1h60.5c0.4-2.9,0.6-6.2,0.7-9.8c0-2.9,0.3-5.6,0.6-8.4c1.8-15.1,8.1-28.2,18.7-39.3
	c5.1-5.2,10.6-9.5,16.5-12.7l-11.3-11.1c-3.7,2.3-7.3,5.1-10.7,8.1c-11.7,10.7-18.7,23.1-21.2,37.4c0.1-1.4,0.3-2.8,0.6-4.1
	c2.2-15.1,9.4-28.2,21.7-39.3c1.8-1.7,3.6-3.2,5.5-4.5L166.7,301.2L166.7,301.2z M100.4,357.5c4.1-2.5,9.2-4.3,15.5-5.5l19.7-4.3
	c2.5-0.5,4.1-0.4,5.1,0.4c1.1,0.8,1.2,2.3,0.6,4.7c-1.2,5.2-3,10-5.5,14.7c-1,1.8-2.6,3-5,3.6l-35.2,5.1l-4.5,0.3l-0.3-0.1
	c-1.2-0.3-1.8-1-1.7-2.1l0.1-0.3c1.1-2.8,2.3-5.4,3.8-7.8l3.2-4.8C97.5,359.5,99,358.3,100.4,357.5L100.4,357.5z M94.8,354.7
	c4.5-3.4,12.7-6.5,24.3-9.2c10.2-2.3,18.2-3.2,23.7-2.3L94.8,354.7z"
          />
          <path
            fill="none"
            d="M100.4,357.5c-1.5,0.8-2.9,2.1-4.3,3.7L93,366c-1.5,2.5-2.8,5.1-3.9,7.8l-0.1,0.3c-0.1,1.1,0.4,1.8,1.7,2.1
	l0.3,0.1l4.5-0.3l35.2-5.1c2.3-0.5,4-1.8,5-3.6c2.3-4.7,4.3-9.5,5.5-14.7c0.7-2.2,0.4-3.9-0.6-4.7c-1-0.8-2.6-1-5.1-0.4L116,352
	C109.8,353.2,104.5,355.1,100.4,357.5L100.4,357.5z M97.1,362.3c1.2-1.5,2.5-2.6,3.9-3.4c4-2.3,9.1-4.1,15.1-5.4l21.6-4.5
	c0.8-0.1,1.5,0,2.1,0.4c0.6,0.5,0.6,1.5,0.1,3v0.1c-1.2,5.1-3,9.9-5.4,14.4c-0.7,1.5-2.1,2.3-3.9,2.8h-0.1l-35.4,5.1l-3.9,0.3h-0.1
	c-0.4-0.1-0.7-0.3-0.7-0.6c1.2-3,2.6-5.8,4.3-8.4L97.1,362.3L97.1,362.3z"
          />
          <path
            fill="none"
            d="M97.1,364.9l-0.6,0.8c-0.7,1-1.4,1.9-1.9,3l-0.1,0.1l-1.4,2.5c-0.7,1.1-1.2,2.3-1.8,3.6l3.9-0.3l35.4-5.1
	c-1.1,0-2.1-0.3-3-0.7c0,0.3-0.1,0.5-0.6,0.5h-0.7c-0.4,0-0.6-0.1-0.6-0.5v-13.3c0-0.3,0.1-0.5,0.6-0.5h0.7c0.3,0,0.6,0.1,0.6,0.5
	c0.5-0.7,1.2-1.4,2.1-2.1l-13.3,2.8c-6.1,1.2-11.1,3-15.1,5.2c-0.8,0.4-1.5,1-2.2,1.5C98.2,363.7,97.7,364.2,97.1,364.9L97.1,364.9z
	 M99.2,364.5c0.6-0.5,1-1.1,1.5-1.5l22.4-3v2.3l-24.3,3.3L99.2,364.5L99.2,364.5z M95.1,371.1c0.4-1,0.8-1.8,1.2-2.5l26.5-3.6v2.2
	L95.1,371.1L95.1,371.1z"
          />
          <path
            fill="none"
            d="M100.6,363l-1.5,1.5l-0.6,1l24.3-3.3v-2.3L100.6,363L100.6,363z M96.4,368.6c-0.4,0.8-0.8,1.5-1.2,2.5l27.8-3.7
	v-2.2L96.4,368.6L96.4,368.6z"
          />
          <path
            fill="none"
            d="M96.6,365.7l0.6-0.8c0.6-0.7,1.1-1.2,1.7-1.8c0.7-0.7,1.5-1.2,2.2-1.5c4-2.3,9.1-4.1,15.1-5.2l13.3-2.8l0.6-0.4
	c2.1-1.6,4.7-3,7.7-4.1l-21.6,4.5c-6.1,1.2-11.1,3-15.1,5.4c-1.4,0.7-2.6,1.8-3.8,3.3l-2.5,3.7c-1.7,2.6-3,5.5-4.3,8.4
	c0,0.3,0.3,0.4,0.7,0.5h0.1c0.6-1.2,1.1-2.3,1.8-3.6l1.4-2.5l0.1-0.1C95.2,367.7,95.9,366.7,96.6,365.7L96.6,365.7z"
          />
          <path
            fill="none"
            d="M139.8,349.2c-0.4-0.3-1.1-0.4-2.1-0.4c-3,1.1-5.5,2.5-7.7,4.1l-0.6,0.4l-2.1,2.1v13.3c1,0.4,1.9,0.5,3,0.7h0.1
	c1.9-0.4,3.2-1.2,3.9-2.8c2.3-4.5,4.1-9.4,5.4-14.4v-0.1C140.3,350.9,140.2,349.8,139.8,349.2L139.8,349.2z"
          />
          <path
            fill="none"
            d="M127,355h-0.7c-0.4,0-0.6,0.1-0.6,0.5v13.3c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5v-13.3
	C127.4,355.1,127.2,355,127,355L127,355z M130.4,369.6h0.1v-0.1 M119.1,345.5c-11.7,2.8-19.8,5.8-24.3,9.2l48-11.6
	C137.3,342.3,129.4,343.2,119.1,345.5z M191.2,324.5l-4.4-4.4c-5.9,3.2-11.4,7.4-16.5,12.7c-10.7,11.1-16.9,24.2-18.7,39.3
	c-0.4,2.8-0.6,5.5-0.6,8.4c0,3.6-0.3,6.7-0.7,9.8h5.4l0.1-0.7c0.7-3.3,1.1-7.3,1.1-11.7c0.1-2.5,0.3-5,0.6-7.3
	c1.6-13.1,7.4-24.5,17.1-34.3C179.7,331.2,185.2,327.2,191.2,324.5L191.2,324.5z M157.2,302.6c-0.3-0.1-0.4-0.3-0.7-0.4h-1.2
	l-22.7,6.2l-24.6,5.9l-2.3,2.5l1.9-0.4l1.5-1.5l14.2-3.3c9.6-2.3,19.4-5,29.4-7.8l3.9-1.1L157.2,302.6L157.2,302.6z"
          />
          <path
            fill="none"
            d="M157.5,302.9l-0.3-0.3l-0.8,0.1l-3.7,1.1c-10,2.9-19.9,5.5-29.4,7.8l-14.3,3.2l-1.5,1.5l10.3-2.2l0.3-0.1
	c11.3-2.8,23.1-5.9,35.4-9.5l4.1-1.2h0.4L157.5,302.9L157.5,302.9z M84.6,327.4l0.4,1.4l1.1,3.3l0.4,1.2l0.8,2.5
	c0.1,0.8,0.3,1.6,0.3,2.5l-0.1,1.2l-0.1,0.7c-0.6,1.5-1.2,2.9-1.8,4.1l-0.4,0.5l-0.3,0.4l-0.1,0.3c-1,1.6-2.3,2.9-3.9,3.6v1.2h0.3
	c2.1-1,3.6-2.5,4.8-4.5l0.3-0.4l2.3-5v-0.1l0.3-1.1l0.1-1.2c0-1-0.1-1.9-0.3-2.8l-5.9-18.8c-0.4-1.1-0.8-1.8-1.5-2.3l-0.7,1
	c0.4,0.4,0.8,1,1,1.7l2.9,9.1L84.6,327.4L84.6,327.4z"
          />
          <path
            fill="none"
            d="M84.6,327.4l-0.4-1.2h-0.8c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4l0.4,0.1
	L84.6,327.4L84.6,327.4z M87.2,335.6l-0.8-2.5H85v5h2.5C87.5,337.3,87.4,336.4,87.2,335.6L87.2,335.6z"
          />
          <path
            fill="none"
            d="M86,332h-3.3c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.4,0.1,0.5l0.4,0.1h3.6
	C86.3,333.3,86,332,86,332z"
          />
          <path
            fill="none"
            d="M85,332h1l-1-3.3 M82.4,339.5h4.8l0.1-1.2h-5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4
	c0,0.1,0.1,0.3,0.1,0.4C82.1,339.3,82.3,339.5,82.4,339.5L82.4,339.5z M87.1,340.1l0.1-0.7h-2.3v4.8h0.4
	C86,343,86.5,341.7,87.1,340.1L87.1,340.1z"
          />
          <path
            fill="none"
            d="M85,344.3v0.5l0.3-0.5 M85,344.8v-0.5h-2.9c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.5
	c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h2.5l0.1-0.3L85,344.8L85,344.8z M87.5,357.6c0.3-1-0.1-1.2-1-1.1l-5.8,1.9v7.2H86
	l0.3-1.1h-5.1h5.1L87.5,357.6L87.5,357.6z M84.1,370.1c0.1,0.1,0.3,0.1,0.4,0.1h0.7l0.3-1.2h-0.8c-0.1,0-0.3,0-0.4,0.1
	s-0.1,0.3-0.1,0.4C84,369.7,83.9,370,84.1,370.1L84.1,370.1z M88.6,378.2l-1.9,0.3c-1.1,0.1-2.1,0.5-3,1.2l-5.1,4h2.3l3.7-3.3
	C86,379.2,87.4,378.5,88.6,378.2L88.6,378.2z M96,390.2h-4.3l10.7,3.3l44.2-3.3h-31.2l-13.6,1.8"
          />
          <path
            fill="none"
            d="M88.6,378.2c-1.2,0.3-2.6,1-4,2.2l-3.7,3.3h2.6l67.3-12.4h-11.7c-2.5-0.1-5.1,0.1-7.8,0.5L88.6,378.2z
	 M101.8,392l13.6-1.8H96 M571.7,334.5c3.9-7.7,9.4-14.7,16.5-21c7.3-6.5,15.4-11.4,24.1-14.7l7.7-11.1c-10.5,3.2-19.9,8.7-28.5,16.4
	C582,312.8,575.4,323.1,571.7,334.5L571.7,334.5z M467.7,350.6l-0.4,7.6c28.9-3.6,58.2-8.1,88.3-13.6L467.7,350.6z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M146.8,304.1l4.8-12.1 M162.3,295.4l2.6-2.8 M162.3,288.6v6.9
	 M154.2,301.9v-10.6 M123.1,310.3l-7.2,1.6 M116,311.9H99.3 M105.7,316.9l-3.6,0.8 M116,311.9l-8.3,1.9l-2.3,2.2l-2.6,0.4
	c-1.1,0.1-1.8-0.1-2.3-1.1l-1.1-2.2c-0.1-0.4-0.1-1,0-1.2 M105.7,316.9l1.9-0.5 M145.8,304.4l-12.8,3.4 M134.8,303.8h11.3
	 M133.9,303.7h-10.7v6.6 M123.1,310.3l8.8-2.2 M131.9,308.1l1.9-4.4 M133,307.8l1.8-4 M131.9,308.1l1-0.3h0.1 M134.8,303.8l0.1-0.4
	l14.9-9.2l-3.7,9.6 M151.6,292l-17.2,10.7l-0.6,1 M146.8,304.1l5.1-1.5 M145.8,304.4l1-0.3 M146.1,303.8l-0.3,0.5 M162.3,295.4
	l-2.5,2.8c-1.4,1.5-3.3,2.8-5.6,3.7 M107.6,316.4l1.5-1.5l14.2-3.3c9.6-2.3,19.4-5,29.4-7.8l3.9-1.1l0.8-0.1 M157.9,303.4h-0.4
	c-1.4,0.4-2.8,0.8-4.1,1.2c-12.2,3.6-24.1,6.7-35.4,9.5l-0.3,0.1l-10.3,2.2 M157.9,303.4l-0.4-0.5l-0.3-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M157.2,302.6c-0.3-0.1-0.4-0.3-0.7-0.4h-1.2l-22.7,6.2l-24.6,5.9l-2.3,2.5
	 M158.8,303l-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1 M154.2,305.1l-0.1,0.1 M154.2,301.9l-2.3,0.7 M151.9,302.6c-0.4-0.8-0.6-1.8-0.7-2.8
	v-2.6c0-2.2,0.7-4,2.1-5.2 M158.8,303c2.9-1,6.7-2.1,11.4-3.3l0.3-0.1 M159.4,288c1,0,1.9,0.3,2.9,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M186.7,292l-18.7,2.2c-1.5,0.4-2.8,1.1-3.7,2.2l-5.5,6.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M102.1,317.7c-1.1,0.1-2.1-0.5-2.9-2.2l-0.6-1.5l-0.1-0.7
	c-0.1-0.4-0.1-0.8,0-1.2c0.1-0.5,0.4-1.1,1.1-1.4c2.1-1.4,9.8-5.2,23.1-11.8c11.7-5.8,22.4-9.5,32.1-11.3c5.6-1,11-1.4,16.1-1.2
	c3.6,0,7.4-0.3,11.1-0.8l23.2-3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M164.4,289.5l21.9-2.3l-4.1,3.7l-17.2,1.8 M164.4,289.5
	c-0.7-0.4-1.4-0.7-2.1-1 M164.9,292.7l-0.6-3.2 M155.7,285.7h-0.4c-10.3,2.1-21.2,5.9-32.9,11.7c-10.3,5.1-18.6,9.5-24.9,13.1
	l-1.2,0.8c-0.8,0.4-1.2,1-1.2,1.6c0,0.5,0.1,1.2,0.3,1.9l1,2.9l0.1,0.3c0.4,0.5,1.1,0.7,2.2,0.4l3.3-0.7 M154.2,291.3l-0.8,0.7
	 M153.4,292h-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M99.3,311.9c0.1-0.3,0.3-0.5,0.6-0.7c2.6-1.5,10.2-5.4,22.6-11.6
	c11.6-5.6,22-9.4,31.6-11.1c2.1-0.4,3.9-0.7,5.4-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M159.4,288c-2.5,0.4-4.3,1.4-5.2,2.8v0.5 M690.7,231.7l2.6,1.5l-1.9,0.3
	H691 M680,227.6h0.1l2.3-0.1 M685.1,228.7l-2.3,0.3h-0.1 M682.6,229c0.4,0.1,0.8,0.4,1.2,0.6l1.9,1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M688.4,232c0.1,0,0.1-0.1,0.3-0.1l2.2-0.1 M688.2,230.5l-3.2-1.8
	 M685.9,230.6h0.1l2.2-0.1 M682.5,227.5l2.6,1.2 M688.4,251.7l-7.7-9.1c-0.5-0.7-0.4-1.4,0.3-1.8c0.4-0.3,1.1-0.4,1.8-0.6
	c4.8-1,10-1.8,15.5-2.2h0.5c1.1,0,2.1,0.1,2.9,0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M701.6,238.6l-16.5,2.2c-1.1,0.4-1.1,1.4,0.1,2.8c1.5,1.8,2.9,3.6,4.4,5.2
	l7.8,8.1l2.2,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M686.6,243.4l-0.3-0.3c-0.3-0.7-0.1-1,0.4-1.1l14.2-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M696.3,253.8c1,0.6,1.9,1.1,3,1.5l2.6,0.6l13.1,0.1l1.4-0.1
	c1-0.3,1.1-1,0.3-1.9l-3-2.8c-2.6-2.3-5.4-4.7-8.3-6.9l-3.7-2.8c-0.3-0.3-0.7-0.4-1.1-0.6l-13.5,1.8c-0.4,0.3-0.6,0.4-0.6,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M684.4,252l-8.3-10c-1.7-2.3-0.8-3.9,2.3-4.5c4.8-1,9.9-1.8,15.5-2.2h0.5
	c1.2,0,2.2,0.3,3.2,0.8l4.1,2.6 M688.2,230.5l2.5,1.2 M691,233.5l-2.6-1.5 M701.8,238.6h-0.3 M704.5,238.2l-2.6,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M693.2,246.6l-1.2-1.6l8.5-1.2l1.9,1.5l0.1,0.1c2.5,1.9,5.1,4,7.4,6.2
	l1.5,1.5h-9.2c-1.8,0-3.3-0.4-4.5-1.2l-0.3-0.3l-0.5-0.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M701.3,253.8h12.2l-1.9-1.8c-2.6-2.3-5.4-4.7-8.3-6.9l-2.9-2.2l-10.5,1.4
	l6.2,7.4l0.3,0.3c0.8,0.7,1.8,1.2,2.9,1.6L701.3,253.8L701.3,253.8z M701,240.1l0.3,0.1c0.5,0.1,1,0.3,1.5,0.7l3.7,2.8
	c2.9,2.2,5.6,4.5,8.3,6.9l4.4,4.1v0.1l0.3,0.6c0.1,0.4,0,0.8-0.3,1l-0.7,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M701.4,251.6h7.4c-2.3-2.1-4.8-4.1-7.3-6.2l-6.9,1.1 M701,240.1h0.1
	c1.5-0.1,2.8,0,3.7,0.6 M706.1,241.4l0.1,0.1l2.9,2.1c4.7,3.4,9.1,7.2,13.2,11c0.8,1.1,0.5,1.8-0.7,2.1l-0.5,0.1h-2.8 M705,240.8
	l1.1,0.6 M706.1,241.4l-0.8-0.6H705"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M696.6,252.9l-0.3-0.1h-0.1l-0.3-0.3l-2.9-3l-5-5.9l12.5-1.6l4,3
	c2.9,2.2,5.6,4.5,8.3,6.9l1.5,1.4 M665.4,221.3l0.3-1.1c3.6,1.4,7,2.9,10.5,4.4l6.2,2.9 M664.7,232.7l-0.1-0.1
	c-0.3-0.6-0.4-1.1-0.5-1.8 M664,230.8c-0.1-0.6-0.1-1-0.1-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M663.9,229.3c0-1.1,0.1-2.2,0.4-3.3l1.2-4.7 M691,233.5
	c-7.7,0.7-14.9,1.6-21.2,2.9c-1.4,0.1-2.5-0.1-3.2-0.7l-1.1-1.5 M665.7,234.1l-1-1.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M680,227.6c-1.5-0.8-3.2-1.5-4.8-2.2c-3.2-1.4-6.3-2.8-9.8-4.1
	 M714.5,253.1l1.5,1.4l0.1,0.1c0.1,0.3-0.3,0.4-1.2,0.6l-12.8-0.1c-1-0.1-1.8-0.3-2.6-0.6l-1.5-0.7 M718.2,256.8h0.1 M718.4,256.8
	h-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M714.5,253.1c0.8,0.8,0.7,1.2-0.3,1.2H702c-1.5,0-2.8-0.3-3.9-0.7
	 M718.2,256.8h-13.3l-2.8-0.3c-2.1-0.4-4-1.2-5.6-2.3l-0.5-0.4 M696.9,251c0.5,0.6,1.7,0.7,3.4,0.6h1.1 M699.8,258.8l3.6,3l5.4,3.4
	h0.1l3.6,1.8l0.8,0.3 M713.3,267.4l2.5,1.1c3.4,1.2,7.2,2.3,11.1,3.2l0.8,0.1 M713.3,267.4v-8.5 M713.3,258.8h-13.5 M696.9,251
	l-0.1-0.1l-3.6-4.3 M694.7,246.5l-1.5,0.1 M692.1,256l1.5-1.4l-2.6,0.1 M693.2,257.6l-1.1-1.7 M697.6,253.5h-0.1l-0.8-0.6
	 M698,253.8h-0.1 M697.9,253.8l-0.3-0.3 M697.6,253.5l0.4,0.3 M695.8,253.8h0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M701.3,253.8h-1.7c-1.1-0.1-2.2-0.3-3-0.8 M694,258.8h1.2l1.5-1.4
	l-3.6,0.1 M701.4,251.6c-1.7,0-2.9-0.7-3.9-1.8l-2.9-3.3 M696.3,253.8l-2.6-2.2 M695.8,253.8l-2.1-2.2 M688.4,251.7l-4,0.3
	 M686.9,255l1.1,1.2 M685.3,253.2l1.5,1.8 M684.4,252l1,1.2 M689.3,252.9l-4,0.3 M688,256.2l1.1,1.6 M727.3,274.8
	c-9.2-2.5-17.2-5.2-23.8-8.4c-4.8-1.8-7.8-4.1-9.1-6.7l-0.4-0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M689.9,259.1l0.7,1.4c1.1,2.6,2.5,4.4,4.3,5.4c1.4,0.8,4.7,2.3,10.2,4.4
	c2.8,1,6.1,2.2,10,3.4 M694,258.8l-4.1,0.3 M691,254.7l-1.7-1.8 M689.3,252.9l1.5-1.4l-2.5,0.1 M689.1,257.9l0.8,1.2 M686.9,255
	l4.1-0.3 M692.1,256l-4.1,0.3 M693.2,257.6l-4.1,0.3 M664,230.8c6.1-0.8,12.2-1.5,18.6-1.9 M685.9,230.6c-7.2,0.4-14.2,1.1-21.2,2.1
	 M663.9,229.3c5.4-0.7,10.7-1.2,16.1-1.6 M665.7,234.1c7.4-1.1,15-1.8,22.7-2.2 M693.7,251.6l-7.2-8.1 M713.3,258.8h14.2v8.9
	c0,1.4,0.1,2.8,0.3,4 M727.3,274.8c0.3,1.1,0.5,1.9,0.8,2.9 M727.6,271.9l-0.3,2.9 M727.6,271.8v0.1 M727.6,271.9
	c0.1,1.1,0.3,2.2,0.5,3.2l0.8,2.9 M763.8,340.4l-0.3-1.9 M765.4,345.5l-1-2.6c-0.3-0.8-0.5-1.6-0.7-2.5 M763.5,338.5v1.9
	 M763.8,340.4h-0.3 M763.5,338.5l-1-21.3 M762.5,317.2l-6.9-5.8c-3.8-1-7-2.8-9.5-5.5l-1.8-2.1 M724.4,351c7.8-0.3,12.9-4,15-11.3
	c0.3-1.1,0-1.9-0.5-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M738.9,337.4l3-0.4c2.2-0.3,3.2,0.5,2.8,2.8c-2.6,7.4-8.1,11.1-16.4,11.3
	h-3.7 M684.8,305.8c3.6,2.2,6.9,4.8,10.2,7.7l3.9,3.7c5.8,6.2,12.7,9.8,20.2,10.9c4.3,0.4,9.2,0.3,14.9-0.3
	c6.1-0.8,11.7-1.8,16.9-3.3l-20.4-1.9c-13.3-1.5-22.6-5.2-27.8-11.3c-2.1-2.5-4.4-5-7-7.3c-2.2-2.1-4.5-3.9-6.9-5.6 M763.5,340.4
	c-8.8,2.5-14.9,9.5-18.3,21c-0.1,0.5-0.4,1.2-0.7,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M745,363.3c1-1.1,1.8-2.2,2.5-3.4c5.4-8.5,11.3-13.2,17.9-14.2
	 M743.4,364.9l1.7-1.7 M745,363.3h-0.4 M743.4,364.9l1.2-1.7 M724.4,351h-3c-5.1-0.5-8-3.6-8.7-9.1c-0.3-1,0.1-1.5,1.2-1.6l25-2.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M730.9,376.2l3.4-0.7c3.4-0.5,6.9-1,10.6-1.2l10.2-1.1l7.7-10
	c1-1,1.5-2.5,1.8-4.4l1-13.3 M730.9,376.2l12.5-11.3 M762.5,317.2c-8.9-1.5-17.5-4.1-25.6-7.4c-16.4-6.6-29.6-15.7-39.6-27.1
	 M688.9,298.3l-4.1,7.4 M632.1,343.5l3.4-0.6l5.5-0.4l5.5,0.4l3.4,0.5l2.3,0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M651.8,342.9c5.8,1.7,11,4.8,15.7,9.5h0.1l1.7,1.8
	c2.3,2.5,4.1,5.2,5.6,8.1c2.5,5.1,3.9,10.6,3.9,16.8c0,6.1-1.2,11.7-3.8,16.8c-1.8,3.6-4.1,6.7-7.2,9.8c-1.1,1.1-2.2,2.2-3.4,3.2
	c-6.6,5.2-14.3,7.8-23.1,7.8c-8.8,0-16.5-2.6-23.1-7.8l-3.4-3.2c-3.3-3.3-5.8-6.7-7.6-10.6c-2.3-4.8-3.4-10.2-3.4-16
	s1.1-11.1,3.4-16c1.5-3.2,3.4-6.2,5.9-8.9l1.7-1.8c2.5-2.5,5.2-4.7,8.1-6.2c2.5-1.4,5-2.5,7.6-3.3c3.4-1,7-1.5,10.7-1.5
	C644.9,341.4,648.5,341.8,651.8,342.9L651.8,342.9z M672.4,325.3l3.4-4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M590.1,383.5c-0.1-1.5-0.3-3-0.3-4.5c0-12.7,4-23.7,12.1-33
	c0.8-1.1,1.8-2.1,2.8-3c9.9-9.9,22-15,36-15c14.2,0,26.1,5,36.2,15c9.9,9.9,15,22,15,36.2v2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M672.4,325.3c-9.2-6.1-19.7-9.1-31.4-9.1c-16.1,0-29.7,5.6-41.1,17.1
	s-17.5,25.5-18.3,42.1l-0.4,8.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M575,383.6l0.3-5.5c0.8-19.1,7.6-35.2,20.1-48.4
	c12.5-13.1,27.6-19.7,45.4-19.7c13.1,0,24.6,3.6,34.9,10.6 M657.7,346.5c0.7,0.3,1.4,0.7,2.1,1.1c2.5,1.5,4.8,3.4,7,5.5l0.8,1
	c1.9,2.1,3.6,4.1,4.8,6.3 M660,350.1l0.1,0.1l-5.5,8.3 M670.1,360.1c-0.8-1.1-1.7-2.3-2.6-3.4l-2.3-2.5c-1.7-1.5-3.3-2.9-5-4.1
	 M661,365.7l0.5-0.3 M672.5,360.4l-2.5-0.3 M673.7,362.4l-1.7,0.8 M673.7,362.4l1.1,2.5l1,2.8 M661.5,365.5l8.4-5.5l0.1,0.1
	 M672.5,360.4l1.1,2.1 M656.7,348c-1.7-0.8-3.3-1.5-5-2.1 M652.3,344.1c1.8,0.5,3.6,1.4,5.4,2.2 M652.3,344.1l-0.5,1.8 M656.7,348
	l1-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M660,350.1l-0.4-2.3l-1,1.5c-1.1,1.6-2.2,3.3-3.3,5l-6.2,10l-5,8.7
	 M647.9,345c-2.2-0.4-4.5-0.7-6.9-0.7c-2.3,0-4.8,0.3-7,0.7 M636,355c1.7-0.3,3.3-0.4,5.1-0.4l4.8,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M645.9,354.9l0.1-0.8c0.7-2.9,1.2-5.9,1.7-9.1l0.1-0.1 M645.3,357.1
	l-4.3-0.4l-4.5,0.4 M645.3,357.1c-0.5,2.3-1.2,4.8-2.1,7l-0.4,1.1l-0.1,0.5 M654.7,358.4l-1.2,1.9 M654.7,358.4
	c1.2,1,2.6,1.9,3.7,3.2l3.2,3.9 M656.2,362.6c-0.8-0.8-1.8-1.5-2.8-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M661,365.7l-1.4-1.6c-0.8-0.8-1.7-1.8-2.8-2.5l-0.8,1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M656.2,362.6l-1.1,1.5c-0.8,1.4-1.5,3.2-2.2,5.1 M643.2,371l3.7-13.6
	l3-13.8l-2.1,1.4 M651.8,345.9l-2.8,9.8c1,0.3,1.8,0.7,2.6,1.1l5.1-8.8 M645.9,354.9l-0.5,2.2 M652.9,369.2v1.5 M652.2,371l0.7-0.3
	 M654.1,377H651 M651,377l0.3,0.1c0.4,0.4,0.5,1,0.5,1.6c0,0.5-0.1,1.1-0.5,1.5l-0.1,0.1 M650.7,378.8c0-0.4-0.1-0.7-0.4-1
	s-0.5-0.4-0.8-0.4c-0.3,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.5-0.4,1c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,1,0.4c0.4,0,0.7-0.1,0.8-0.4
	C650.5,379.5,650.7,379.2,650.7,378.8L650.7,378.8z M659.6,375.4l-3.6,1.1 M652.9,370.7l2.3-1.5 M655.2,369.2h-2.3 M656,376.5
	l-1.9,0.5 M642.7,369l-0.1-0.1c-0.4-0.4-1-0.7-1.7-0.7c-0.5,0-1.1,0.3-1.7,0.7l-0.1,0.1 M643.2,371v-0.4c0-0.5-0.1-1.1-0.5-1.5
	 M641.9,369.6c-0.3-0.3-0.5-0.4-0.8-0.4s-0.7,0.1-1,0.4s-0.4,0.5-0.4,0.8s0.1,0.7,0.4,1s0.5,0.4,1,0.4s0.7-0.1,0.8-0.4
	c0.3-0.3,0.4-0.5,0.4-1C642.3,370.1,642.1,369.9,641.9,369.6L641.9,369.6z M644.3,372.8c-0.6-0.3-1.1-0.5-1.8-0.7 M642.6,372.1
	l0.5-0.7l0.1-0.4 M639.3,372.1c0.5,0.4,1.1,0.7,1.7,0.7c0.7,0,1.2-0.3,1.7-0.7 M648.9,376.6h0.5c0.5,0,1.1,0.1,1.4,0.6 M648.9,376.6
	l-0.3,0.1c-0.3,0.1-0.5,0.3-0.8,0.5 M644.3,372.8c0.5,0.3,1.1,0.7,1.7,1.2c0.5,0.5,0.8,1,1.2,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M647.9,380.5c-0.4-0.5-0.7-1.1-0.7-1.6c0-0.7,0.3-1.2,0.7-1.6
	 M647.8,377.2c-0.1-0.5-0.4-1.1-0.7-1.6 M649,367.8l-4.7,5 M647.2,382.2l0.1,0.1 M647.9,380.5c-0.1,0.5-0.3,1.1-0.5,1.8 M649,381.1
	l-0.4-0.1c-0.3-0.1-0.5-0.3-0.7-0.4 M651,380.6c-0.4,0.4-1,0.6-1.5,0.6H649 M647.2,382.2l-1.2,1.6l-1.5,1.2 M644.5,385.1l-1.7,0.7
	 M642.8,385.8c-0.5-0.5-1.1-0.7-1.7-0.7c-0.7,0-1.2,0.3-1.7,0.7l-0.1,0.1 M639.4,388.8l0.1,0.1c0.4,0.4,1,0.7,1.7,0.7
	c0.5,0,1.1-0.3,1.7-0.7l0.1-0.1 M643,388.7c0.4-0.4,0.5-0.8,0.5-1.5v-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M643.4,386.8l-0.1-0.3l-0.4-0.7 M640.2,386.4c-0.3,0.3-0.4,0.5-0.4,1
	c0,0.3,0.1,0.7,0.4,0.8c0.3,0.3,0.5,0.4,0.8,0.4c0.4,0,0.7-0.1,0.8-0.4c0.3-0.3,0.4-0.5,0.4-0.8c0-0.4-0.1-0.7-0.4-1
	s-0.5-0.4-0.8-0.4S640.5,386.1,640.2,386.4L640.2,386.4z M649,390.1l-4.5-5 M654.1,380.6l0.4,0.1c0,0,0.1,0,0.1,0.1l0.4,0.1l0.8,0.3
	 M651,380.6h3.2 M652.2,386.8l0.7,0.4 M652.9,388.3v-1.1 M654.7,388.3h-1.8 M652.9,387.2l1.8,1.1 M647.2,375.5l5-4.5 M652.2,386.8
	l-3.2,3.3 M647.4,382.4l4.8,4.4 M654.1,377v3.6 M656,381.1v-4.7 M652.2,371l-3.2-3.2 M676.4,369.9l-1.5,1.9 M674.9,371.8l-0.1,0.1
	c-3.3,0.4-6.6,1.1-9.8,1.8 M675.7,367.7l0.7,2.2 M676.4,369.9l-1.7,0.3h-0.1c-3.4,0.7-6.7,1.4-9.9,2.2l-2.1,0.6
	c-4.7,1.1-9.2,2.3-13.6,3.7 M674.9,371.8c0.4,2.3,0.7,4.7,0.7,7.2c0,2.3-0.3,4.7-0.7,6.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M675.7,390.2l0.7-2.5c0.7-2.9,1.1-5.8,1.1-8.9s-0.3-6.2-1.1-9.1
	 M674.1,368.2l1.7-0.5 M665,373.9l-2.1,0.5 M672,363.3l-8.8,5.1l1.1,2.5l9.8-2.6 M665,383.8c0.3-1.5,0.5-3.2,0.5-4.8
	c0-1.8-0.1-3.4-0.5-5.1 M662.9,374.4c0.3,1.5,0.4,3,0.4,4.5s-0.1,2.9-0.4,4.3 M659.6,375.4c0.3,0.1,0.7,0.4,1,0.8
	c0.7,0.8,1.1,1.8,1.1,2.9c0,1.1-0.3,2.2-1.1,2.9l-0.5,0.5 M662.9,383.2l2.1,0.5 M660,382.4l2.9,0.8 M670.1,397.9l2.2-0.4
	c-0.4-0.3-1-0.7-1.5-1c-2.8-1.9-5.6-3.7-8.5-5.5l-1.8-1.1c-4.3-2.8-8.7-5.2-13.2-7.7 M674.1,389.7l-9.8-2.6l-1.1,2.6l8.8,5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M675,385.8l1.4,1.9l-1.7-0.3h-0.1l-12-2.8l-13.6-3.6 M665,383.8
	c3.2,0.8,6.5,1.4,9.8,1.8h0.1l0.1,0.1 M675.7,390.2l-1.7-0.5 M647.2,375.5c4.5-2.5,8.9-5,13.3-7.6l12-7.6 M662.9,374.4l-3.3,1
	 M642.7,369v-3.3 M656,381.1l4,1.2 M674.1,368.2c-0.5-1.8-1.2-3.4-2.1-5 M655.2,369.2l5.8-3.4 M653.4,360.4l-4.4,7.4 M700.1,381h5.8
	 M705.2,369.3v-0.1c-1.8-15.1-8.1-28.2-18.7-39.3c-3.3-3.4-6.9-6.5-10.6-9.1 M705.8,381l-0.1-3.6c0-2.8-0.3-5.5-0.5-8.1 M700.1,381
	c-0.1-1.9-0.3-4-0.3-6.2l-0.1-4.7 M692.1,381.6l8-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M699.5,370.1l-0.3-2.6c-1.7-13.1-7.3-24.5-17.1-34.3
	c-3-3.2-6.3-5.8-9.8-8.1 M705.2,369.3l-5.6,0.8 M675.8,320.6l8.9-14.9 M661.4,392.4l8.4,5.6l0.3-0.1 M672,394.6l1.5,1 M673.5,395.6
	l1.2-2.6l1-2.8 M659.6,410.3l2.6-1.8c1.7-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.5,5.6-7l1.1-2.1 M660.7,392l0.7,0.4 M659.9,408v-0.1
	c-1.8-2.8-3.6-5.6-5.4-8.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M659.9,408c1.9-1.2,3.9-2.9,5.6-4.7c1.7-1.6,3.2-3.6,4.5-5.5 M654.5,399.4
	l-1.1-1.8 M653.4,397.6l2.9-2.3 M654.5,399.4c1.2-1,2.6-1.9,3.8-3.2c1.1-1.2,2.2-2.5,3.2-3.7 M660.7,392c-1.1,1.4-2.3,2.8-3.9,3.9
	l-0.5-0.7 M636.2,403l4.8,0.4l5.1-0.5 M643,392l0.3,1c1,2.6,1.8,5.2,2.3,7.8 M646.1,402.9l-0.5-2.1 M645.6,400.8l-4.5,0.4l-4.4-0.3
	 M648.1,412.9c-2.3,0.4-4.7,0.7-7.2,0.7c-2.3,0-4.7-0.1-6.9-0.5 M646.1,402.9c0.4,1.8,0.8,3.7,1.2,5.6l0.7,4.1l0.1,0.1 M651.8,412
	l0.5,1.7 M650.1,414.3l2.2-0.7 M657.6,411.4l1.9-1.1h0.1 M652.3,413.6c1.8-0.5,3.6-1.2,5.2-2.2 M657.6,411.4l-0.8-1.5 M651.8,412
	c1.7-0.5,3.3-1.2,5-1.9 M629.8,413.6l2.3,0.7c2.9,0.7,5.8,1,8.8,1c3.2,0,6.2-0.4,9.1-1.1 M656.7,409.9l-5-8.8l-2.6,1.1l2.6,9.8
	 M650.1,414.3l-2.1-1.4 M643.4,386.8c1.4,4.4,2.6,8.9,3.7,13.8l3,13.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M644.5,385.1c2.5,4.5,5,8.9,7.6,13.3l7.6,12"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M590.1,383.5c1,12.1,5.9,22.7,14.7,31.5l2.1,1.9
	c9.6,8.7,20.9,13.1,34.1,13.1c14.2,0,26.1-5,36.2-15c1.5-1.5,2.8-2.9,4-4.4c6.9-8.4,10.5-18.2,11-29.2 M659.6,410.3l0.3-2.3
	 M705.8,381l25-4.8 M744.6,363.3l-39.5,6.1 M643,388.7v3.3 M656.3,395.3c-1.2-1.6-2.3-4-3.4-6.9 M653.4,397.6l-4.4-7.6 M672,394.6
	c0.8-1.5,1.5-3.2,2.1-5 M654.7,388.3l6.1,3.7 M736.4,250.5c-15.1-14.4-32.3-27.8-51.6-39.8 M740.5,272.5l0.1-4.1 M737.1,268.3
	l0.3,6.1 M740.6,268.3h-3.6 M737.3,274.4h3l0.1-1.9 M744.4,303.7c-2.2-2.8-4.3-5.8-6.2-9.4c-2.3-4.8-4.5-10-6.3-15.7 M731.8,278.7
	l-1.5-0.4 M736.4,250.5c-2.6-1.2-5.1-2.8-7.3-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M737.1,268.3l-0.3-6.1c0-1.2-0.1-2.3-0.5-3.4c-0.3-1-0.7-1.8-1.1-2.6
	l-6.1-10 M740.8,264.5l-0.1,3.9 M740.5,272.5h1l0.4-8h-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M740.8,264.5l0.1-2.9c0.1-1.9-0.3-3.9-1.2-5.6l-3.3-5.5 M680.1,219.2
	l-2.1,3.6 M697.3,282.6l3.3-13.5 M730.3,278.4L729,278l-0.8-0.3l-13.1-3.9c-5-1.5-9.8-3-14.4-4.7 M684.2,262.4
	c3.4,7.4,7.8,14.2,13.1,20.2 M685.6,281.5c0.1-0.8,0.3-1.5,0.3-2.5c0-0.7,0-1.4-0.1-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M685.6,277.1h-0.1c-1.1,0-2.2,0.3-3,0.7c-0.8,0.4-1.2,1-1.2,1.5
	s0.4,1.1,1.2,1.5c0.8,0.4,1.8,0.7,3,0.7h0.1 M700.6,269.2c-4.5-1.6-8.9-3.3-13.2-5.1c-1.1-0.4-2.2-1-3-1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M685.6,281.5c-0.4,1.8-1.4,3.3-2.6,4.5c-1.9,1.9-4.3,2.9-7,2.9
	s-5.1-1-7-2.9c-1.9-1.9-2.9-4.3-2.9-7c0-2.8,1-5.1,2.9-7s4.3-2.9,7-2.9s5.1,1,7,2.9c1.5,1.5,2.3,3.2,2.8,5.1 M678.1,222.8
	c-4.3-1.9-8.5-3.7-13.1-5.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M665,217.4l-2.3,8.7c-0.3,1.2-0.4,2.3-0.4,3.4c0,1.2,0.3,2.3,0.8,3.4
	l16,23.9c1.5,2.3,3.2,4.3,5.2,5.6 M631,236.8c1-0.4,1.7-1,2.3-1.6l2.1-2.2c3.2-3.4,6.9-8.5,11.1-15l2.6-4 M649.2,214.1l2.1-3.3
	 M643.2,219c-3.2,4.7-6.6,8.9-10.2,12.7c-0.7,0.6-1.4,0.6-1.9-0.1L620.4,213c-0.3-0.8-0.1-1.1,0.8-1.1c7.2,1.5,14.2,3.2,21.2,5
	C643.2,217.3,643.7,218,643.2,219L643.2,219z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.7,241.5c1.7-0.4,3.2-1.1,4.8-2.1l2.3-1.6c1.2-1.1,2.6-2.2,3.9-3.6
	c4.3-4.8,8.4-10.3,12.2-16.4c1.8-2.9,1.9-5.2,0.3-7 M651.2,210.8c-0.8-0.8-1.9-1.5-3.4-2.1l-3.2-0.8c-13.5-3.6-27.4-6.5-41.5-9.1
	 M680.1,219.2c10,4.8,20.1,10,29.9,15.5 M729,246.2c-6.1-3.9-12.2-7.6-18.3-11l-0.7-0.4 M730.3,278.4l-0.8-2.8
	c-0.4-1.2-0.7-2.5-0.8-3.7c-0.1-1.4-0.3-2.8-0.3-4.1v-8.9l-1.9-2.1c-5.2-5.2-10.7-10.2-16.8-14.9l-5.1-3.7 M705.6,238.1l-1.1,0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M731.8,278.7l-1-3.2c-0.4-1.2-0.7-2.5-0.8-3.7c-0.1-1.4-0.3-2.8-0.3-4.1
	v-9.2c-6.1-6.3-12.7-12.1-20.1-17.5l-4-2.9 M678.1,222.8c3.2,1.5,6.2,3,9.1,4.7l0.3,0.1c5.8,3.2,11.3,6.6,16.4,10.2l0.5,0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M705.6,238.1l-0.4-0.3c-5.5-3.7-11.4-7.3-17.7-10.5l-0.3-0.1
	c-2.9-1.5-5.9-3-9.1-4.4 M710,234.8l-25-16.1c-1.9-1.1-2.9-2.1-2.9-2.9 M684.7,210.7l-2.8,5.1 M681.9,215.8l-1.8,3.4 M649.2,193.4
	l11.1,1.8 M660.3,195.2c2.1,0.3,3.4-0.4,3.9-2.1l1.2-3.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M660.3,195.2l4.7,0.8c2.5,0.6,4.1,0,4.5-1.9l1.1-3.4 M684.7,210.7
	c2.9-5.6,5.5-11.1,7.7-16.5c-6.9-1.5-13.9-2.9-21.3-4.4l-0.3,0.8 M670.9,190.8l-5.5-1 M665.4,189.8l0.3-1l-16.9-2.9 M649.2,192.5
	v0.8 M648.8,185.9l0.4,6.6 M665,217.4c-6.9-7.6-12.1-15.1-15.7-22.8l-0.1-1.2 M636.9,181.7c3.4-4.1,7.2-7.8,11-11.1l18.7-15.1l1.5-1
	l0.8-0.8l0.4-0.6v-0.1c0.1-0.4,0.1-0.8,0-1.1v-0.1h-0.1c-0.1-0.3-0.5-0.3-1-0.3l-0.7,0.1c-0.4,0.1-0.7,0.4-1.1,0.7
	c-0.4,0.3-0.8,0.7-1.2,1.2l-23.5,19.1c-2.9,2.3-6.2,4.5-9.6,6.6 M744.4,303.7c-2.9-8.8-5.2-18.6-7-29.3 M688.9,298.3
	c-12.9-9.4-28.1-14-45.1-14c-8.4,0-16.2,1.1-23.5,3.3 M697.3,282.6l-8.4,15.7 M684.8,305.8c-12.5-8-27-12-43.2-12
	c-10.5,0-20.2,1.7-29.2,5.1 M619,181.9c-34.4-3.4-68.9-5.2-103.6-5.8c-14.4,0-28.8,0.4-42.9,1.2l-4.3,0.3
	c-20.9,1.4-49.5,5.9-86,13.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M385.9,195.4l19.7-4.5c22.3-5.1,44.7-8.3,67-9.4l12-0.3
	c51.6-0.8,100.1,2.1,145.8,8.7l18.8,2.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M649.2,193.4l-18.8-2.6c-45.7-6.6-94.2-9.5-145.8-8.7l-12,0.3
	c-22.3,1.1-44.7,4.3-67,9.4l-19,4.3 M382.3,191.7l-0.3,1.1 M386.4,196l-0.5-0.6 M385.9,195.4l-3.6-3.7 M385.9,196.9l-3.9-4.1
	 M386.4,196c-0.1,0.3-0.3,0.6-0.7,0.8 M382,192.8l-6.9,2.8 M379.1,199.8l4.3-1.5c1.1-0.4,1.9-0.8,2.3-1.4 M322.9,230.6l-1.1,6.2
	 M326.6,234.5c-1.6,0.8-3.2,1.5-4.8,2.3 M321.9,250.6l1.8,0.3 M323.8,250.6c1.2,0,2.3,0.3,3.4,0.7c2.2,1,4.1,2.2,5.8,3.9
	c1.2,1.1,1.9,3,2.1,5.8v3.3c0,1.5-0.1,2.8-0.4,3.9 M321.9,250.6h-0.3l-2.3,0.4 M323.8,250.6h-1.9 M323.7,250.9l0.1-0.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M332.6,259.9c-0.3-2.2-0.8-3.9-2.1-4.8c-1.6-1.6-3.6-2.9-5.9-3.9l-1.1-0.4
	 M326,263c-0.3-0.3-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.1-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4
	s0.5-0.1,0.8-0.4c0.1-0.1,0.3-0.4,0.3-0.8C326.3,263.4,326.2,263.1,326,263L326,263z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M326.9,262.1c-0.4-0.4-1-0.7-1.5-0.7s-1.1,0.3-1.6,0.7
	c-0.4,0.4-0.5,1-0.5,1.5c0,0.5,0.1,1.1,0.5,1.5s1,0.7,1.6,0.7c0.5,0,1.1-0.3,1.5-0.7c0.4-0.4,0.7-1,0.7-1.5S327.3,262.6,326.9,262.1
	L326.9,262.1z M326.9,273.2c1.2-0.3,2.3-0.7,3.2-1.4c1.1-0.8,1.8-1.9,2.2-3.4c0.3-0.7,0.4-1.5,0.4-2.2 M332.8,266l-0.1-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M332.5,271.5c-1.1,0.8-2.3,1.2-4,1.5l-1.6,0.1 M334.7,268.1
	c-0.4,1.5-1.2,2.6-2.2,3.4 M257.4,254.2c2.5-0.7,4.8-1.6,7-2.6l0.3-0.1c22.3-11.1,42.1-20.8,59.4-28.9c17.5-8.3,35.8-15.7,55-22.6
	 M326.6,234.5l-2.8,16.1 M413.8,281c-1,0.4-1.6,0.7-1.8,1.2 M419.8,279.9v-0.8c-0.1-0.7-0.4-1-1.1-0.7l-1,0.3
	c-1.8,0.8-3.2,1.5-4.1,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M411.9,282.2c0.5-1.4,2.3-2.8,5.5-4.1l0.4-0.1c3.9-1.5,8.3-2.5,13.6-3
	c5.5-0.5,10-0.3,13.9,0.5c1.8,0.4,3.3,1,4.3,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M419.8,279.9l-2.6,0.3c-1.5,0.3-2.6,0.4-3.4,0.8 M414.5,285.5
	c0.8,0.4,2.1,0.8,3.3,1.1l1.4,0.3c0.5,0,1-0.1,1.2-0.4c0.1-0.1,0.1-0.4,0.1-0.8l-0.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M413.4,285.5c1,0.5,2.3,1.1,4.1,1.5l0.3,0.1c3.9,0.8,8.4,0.8,13.6,0.4
	c5.5-0.5,10-1.5,13.9-3.2c2.9-1.1,4.7-2.3,5.4-3.6 M411.9,282.2c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.7,0.3,1.2,1,1.8 M414.5,285.5
	l-0.8-0.4 M412.7,284.8l0.7,0.7 M413.4,285.5h1.1 M420.4,285l-5.9,0.5 M441.3,275.4h-1c-0.5,0-0.8,0.1-1.1,0.5
	c-0.3,0.4-0.4,1-0.4,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M439.2,283.1l0.4,0.8c0.4,0.5,1,0.7,1.6,0.5h0.1l0.1-0.1 M438.8,277.6
	l-19,2.3 M420.4,285l18.8-1.9 M472.7,254l-0.4,3.2 M450.9,280.4l0.1-0.4l0.1-0.4v-0.3c0-0.7-0.3-1.2-0.8-1.8l-0.7-0.5 M450.8,280.7
	l0.1-0.3 M444.9,281.1l0.5-0.4c0.3-0.3,0.6-0.7,0.6-1.1c0-0.4-0.1-0.8-0.5-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4
	s-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.5,0.4,0.8,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M443.9,281.3h0.3l0.7-0.1 M444.9,281.1l-1,0.1 M449.1,281.7l0.8-0.4
	l0.8-0.5 M449.7,277l-1.2-0.3 M448.5,276.7c-0.7-0.3-1.7-0.5-2.6-0.8l-4.5-0.7 M440.9,277.3l0.4-1.9 M448.5,276.7l-7.6,0.5
	 M449.1,281.7c-0.8,0.3-1.7,0.5-2.9,0.7l-5.1,0.5 M441.2,282.9l0.5,1.5 M441.7,284.4c4.3-1.2,6.7-2.1,7.3-2.8 M441.2,282.9l-0.3-5.6
	 M469.1,278.2l3.2-21 M469.1,278.2l-0.3,6.5 M472.7,254l-14,1.4 M450.9,280.4l18.2-2.2 M439.2,283.1l1.9-0.1 M438.8,277.6l2.1-0.3
	 M482.4,190.2l-9.9,0.3c-50.5,2.6-100.4,16-149.7,40 M470.7,195.4c-48.7,2.8-96.8,15.8-144.2,39.1 M458.6,255.4l12.1-60
	 M334.7,268.1l123.9-12.7 M375.1,195.6l4,4.3 M332.5,271.5l139.8-14.3 M619.3,179.9l-0.3,2.1 M632.1,179.2c-1-0.3-1.9-0.4-3.2-0.4
	l-9.6,1.1 M637.9,182.2l-1-0.6 M637.6,184.3l0.3-2.1 M637.6,184.3l-9.8-1.4l-8.8-1 M637.9,182.2l-9.8-1.5l-8.8-0.8 M636.9,181.7
	l-4.8-2.5 M482.4,190.2l-0.8,5 M492.1,195c-3.4,0-6.9,0-10.5,0.1 M602.8,198.9l3.3,5.5 M612.8,205.7l-6.7-1.4 M649.2,214.1
	c-10.7-2.9-21.5-5.5-32.6-7.7l-3.7-0.7 M626.1,238.8l-2.1,0.6l-5.2,0.4 M472.3,257.2l152.1-15l3.3-0.7 M627.7,241.5l-1.7-2.8
	 M626.1,238.8c1.8-0.4,3.4-1.1,4.8-1.9 M606.1,204.4l19.9,34.4 M631,236.8l-18.2-31.1 M583.7,263.2l-2.6,0.3
	c-1.5,0.1-2.6,0.4-3.4,0.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M583.7,263.2v-1c0-0.7-0.4-0.8-1.1-0.5c-0.1,0-0.4,0.1-0.5,0.1l-0.4,0.1
	c-1.8,0.7-3.3,1.5-4.1,2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M613.4,260.4c-1-0.5-2.5-1.1-4.3-1.5c-3.9-0.8-8.5-1-13.9-0.4
	c-5.2,0.4-9.8,1.5-13.6,3l-0.4,0.1c-3.2,1.2-5.1,2.8-5.6,4.1 M580.1,269.6l2.1,0.5l0.7,0.1c0.5,0,1-0.1,1.2-0.4
	c0.1-0.1,0.3-0.4,0.1-0.7l-0.1-0.7 M575.7,265.6l-0.3,0.8 M577.5,264.5c-1,0.3-1.7,0.7-1.8,1.2 M577.1,268.9c0.5,0.4,1.2,0.7,1.9,1
	 M575.4,266.4c0,1,0.5,1.8,1.7,2.5 M578.2,268.9h-1.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M579.1,269.7l2.5,0.5c3.8,0.8,8.4,0.8,13.6,0.4c5.5-0.5,10.2-1.5,13.9-3.2
	c1.9-0.8,3.4-1.5,4.4-2.3 M579.1,269.7l1-0.1 M578.2,268.9l1.9,0.7 M584.1,268.3l-5.9,0.5 M631,263l0.4-0.8 M612.2,260.2
	c-0.7-0.3-1.7-0.5-2.6-0.8c-1.4-0.3-2.9-0.5-4.5-0.7 M605,258.7h-1c-0.5,0-0.8,0.1-1.1,0.5s-0.4,1-0.4,1.6 M602.7,260.9l1.9-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M602.9,266.4l0.4,0.8c0.4,0.5,1,0.7,1.7,0.5h0.1l0.1-0.1 M604.9,266.3
	l-1.9,0.1 M604.6,260.8l0.3,5.5 M604.9,266.3l0.5,1.5 M604.6,260.8l0.4-2.1 M614.5,264.1l0.4-0.7v-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M614.9,263.2v-0.6c0-0.7-0.3-1.2-0.8-1.8l-0.7-0.5 M613.4,260.4l-1.2-0.1
	 M605.4,267.8c3.6-1,5.8-1.8,6.9-2.3l0.5-0.4 M612.8,265l0.8-0.4l0.8-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M612.8,265l-2.3,0.7l-5.6,0.5 M614.5,264.1c-0.3,0.4-0.5,0.8-1.1,1.2
	 M612.2,260.2l-7.6,0.5 M620.1,287.7c1-1.2,1.9-3,2.8-5.2l8.3-19.4 M631.4,262.1l-16.5,1.1 M613.5,265.3L631,263 M631.4,262.1
	c-0.3-7-1.5-13.9-3.7-20.5 M606.1,204.4l-6.3-1.1 M602.7,260.9l-19,2.3 M584.1,268.3l18.8-1.9 M618.8,239.7l-19-36.5 M602.8,198.9
	c-37.7-6.6-77.9-9.5-120.5-8.7 M575.4,266.4l-34.5,3.4l-71.8,8.4 M599.8,203.3c-34.1-5.8-70-8.5-107.7-8.4 M492.1,195l-5.6,57.6
	 M486.4,252.7l132.3-12.9 M468.8,284.7c36.3-5.1,73-10,110.3-15 M481.6,195.2l-8.9,58.9 M486.4,252.7l-13.8,1.4 M481.6,195.2
	l-10.9,0.3 M155.3,285.7c-3.3,0.5-6.5,1.2-9.8,2.1c-7.6,2.3-13.9,4.5-19.3,6.9c-5.9,2.5-11.7,5.1-17.3,7.7c-3,1.4-6.1,2.9-8.8,4.5
	 M209.8,279.9l-28.8,4c-5,0.5-9.9,1-14.7,1h-0.4c-3.3,0-6.7,0.3-10,0.8 M254.1,255c-7.6,1.5-15,3-22.1,4.5l-1.1,0.3 M209.8,279.9
	l-4.5,2.5c-6.1,3.3-12.2,6.5-18.4,9.5c-5.4,2.6-10.9,5.2-16.2,7.7 M230.8,259.8h-16.2c-34.8,6.1-67.3,15.5-97.1,28.5
	c-8,3.6-15.5,7.4-22.7,11.7c-1.1,0.7-2.2,1.4-3.2,2.2 M317.9,238.8l-4,2.2c-8.8,4.7-17.5,9.6-26.3,15c-9.1,5.5-17.6,11.4-25.7,17.7
	l-0.1,0.1 M254.1,255l3.3-0.8 M290,275.9l-12.2,1.2c-5.1,0.8-9.8,0.8-14,0.1c-1.8-0.3-3.4-0.7-5.1-1.1l-5.8-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M261.6,273.7h0.1c1.8,0.4,3.6,0.5,5.5,0.7l10.5-0.5l13.5-1.4 M262.7,266.5
	l-5.4-12.4 M252.8,274c3.2-2.5,6.5-5,9.9-7.4 M261.6,273.7l-8.8,0.3 M311.2,253.9l1-4.7c0.3-1.8-0.5-2.3-2.3-1.6
	c-6.7,3.7-13.5,7.6-20.1,11.7c-5.5,3.4-10.9,7.3-16.1,11.6c1.4,0.5,4.4,0.7,8.9,0.3l20.9-2.2 M315.2,252.2c1.2-0.4,2.8-0.8,4.1-1.1
	 M311.2,253.9c1.2-0.7,2.6-1.2,4-1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M303.5,269l-1.5-5.4c-0.4-1.1-0.1-1.9,0.4-2.8c2.5-2.9,5.5-5.2,8.8-7
	 M310.6,268.2l-5.5,0.7 M305.1,268.9l-1.6,0.3 M303.5,269.2V269 M305.1,268.9l-1.6,0.1 M332.6,259.9l-15.4,2.2h-0.1
	c-1.9,0.3-3.6,1.1-4.7,2.3c-0.8,1-1.5,2.1-1.9,3.6 M332.8,266l-16.5,1.5l-5.6,0.7 M291.2,272.6l-1.2,2.9v0.4 M301.3,276.7l-2.2,6.5
	c-0.5,2.1-0.3,3.7,1,5.2s2.9,2.6,5,3.2c4.7,0.5,7.7-1.5,9.4-6.3l2.8-8 M318.3,274.4c0.4-0.4,0.8-0.5,1.4-0.5l7.2-0.7 M317.1,277.1
	l0.7-1.9c0.1-0.4,0.4-0.7,0.5-0.8 M302,274.7l-0.7,2.1 M303.5,269.2c0.1,0.7,0,1.5-0.3,2.2 M303.1,271.4l-1.1,3.3 M317.1,277.1
	c-5.2,1.2-10.6,1.1-15.8-0.5 M290,275.9l12-1.2 M318.3,274.4l-14.9-5.2 M291.2,272.6l11.8-1.2 M322.9,230.6
	c-11.8,5.8-23.7,12.2-35.5,19.3c-8.5,5.2-16.8,10.9-24.8,16.8 M317.9,238.8l-2.8,13.5 M252.8,274l-43.1,5.9 M285.3,311.7l-0.8-11.7
	 M281.5,322.1l1.6,7.6c-5.6-4.8-10.3-8.9-14.3-12.7c-14.3-13.1-31.6-19.7-51.9-19.7c-15.4,0-29.2,3.9-41.3,11.6 M279.4,312.6
	c-4.8-1.6-9.4-3.7-13.5-6.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M265.9,306.6c1.8,1.4,3.6,2.9,5.4,4.4c3.9,3.4,7.3,7.2,10.2,11.1
	 M260.5,302.9h-0.3c1.8,1.4,3.7,2.6,5.6,3.7 M266.5,389.4c0.7-3.4,1-6.9,1-10.5c0-12.7-4-23.7-12.1-33c-0.8-1.1-1.8-2.1-2.8-3
	c-10-9.9-22-15-36.2-15s-26.1,5-36.2,15c-9.9,9.9-15,22-15,36.2c0,3.7,0.4,7.2,1.1,10.6 M265.9,306.6c-1.8-1.4-3.6-2.6-5.4-3.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M242.9,352.4c-2.5-2.5-5.2-4.7-8.3-6.2c-2.3-1.4-5-2.5-7.6-3.3
	c-3.4-1-7-1.5-10.7-1.5c-3.9,0-7.4,0.5-10.7,1.5c-5.8,1.7-11.1,4.8-15.7,9.5h-0.1l-1.5,1.8c-2.3,2.5-4.1,5.2-5.6,8.1
	c-2.5,5.1-3.9,10.6-3.9,16.8c0,6.1,1.2,11.7,3.9,16.8c1.8,3.6,4.1,6.7,7.2,9.8c1.1,1.1,2.3,2.2,3.6,3.2c6.6,5.2,14.3,7.8,23.1,7.8
	c8.8,0,16.5-2.6,23.1-7.8l3.4-3.2c3.2-3.3,5.8-6.7,7.6-10.6c2.2-4.8,3.4-10.2,3.4-16s-1.1-11.1-3.4-16c-1.5-3.2-3.4-6.2-5.9-8.9
	L242.9,352.4L242.9,352.4z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M247.6,360.2c-1.2-2.2-2.9-4.1-4.7-6.2l-0.8-1c-2.3-2.3-4.7-4.1-7.3-5.8
	h-0.1l-1.8-1 M236.7,365.3l8.3-5.5h0.3 M248.9,362.4l-1.2-2.2 M251.1,367.7l-0.7-1.8c-0.4-1.2-1-2.3-1.5-3.4 M248.9,362.4l-1.5,0.8
	 M247.6,360.2l-2.3-0.4 M222.6,375.6c4.4-2.5,8.9-5.1,13.2-7.7l1.8-1.1c2.9-1.8,5.6-3.6,8.5-5.5c0.6-0.4,1-0.7,1.5-1 M251.7,388
	c0.7-2.9,1.1-5.9,1.1-9.1s-0.3-6.1-1-8.9l-0.6-2.3 M250.4,372.1c-3.4,0.5-6.7,1.2-9.9,1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250.2,386l0.1-1.1c0.3-1.9,0.4-3.9,0.4-5.9c0-2.1-0.1-4-0.4-5.9l-0.1-1
	 M250.2,386c-3.4-0.5-6.7-1.2-9.9-1.9 M251.1,390.2l0.7-2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M250.4,372.1l1.4-2.1l-13.6,3c-4.7,1.1-9.4,2.3-13.8,3.7 M249.4,368.2
	l1.6-0.5 M248.7,395.6c0.7-1.2,1.2-2.5,1.6-3.7l0.6-1.6 M251.7,388l-1.5-2.1 M251.1,390.2l-1.8-0.4 M275.7,389.4l0.1,2.1l6.1-0.1
	 M266.5,389.4h9.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M275.7,389.4l-0.5-11c-0.8-16.6-6.9-30.5-18.3-42.1
	c-11.4-11.4-25-17.1-41.1-17.1c-8.8,0-17.1,1.8-24.5,5.2 M247.3,363.3c0.8,1.5,1.5,3.2,2.1,5 M469.9,376.5L298,381
	c-3.3,0.1-5.2-1-6.1-3.2c-0.4-1.4-0.7-3.2-0.8-5.2l-0.3-2.3 M290.8,370.1c-3.6-0.1-5.5-1-6.1-2.6c-0.8-2.8,1-4.4,5.2-5.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M186.8,320.1c8.8-4.7,18.4-7,29-7c17.7,0,32.9,6.6,45.4,19.7
	c12.5,13.2,19.3,29.3,20.1,48.4l0.5,10.2 M290,362.6l0.8,7.6 M260.5,302.9l23.9-2.9 M285.3,311.7l-5.9,1 M290,362.6
	c-1.8-16.5-3.3-33.6-4.5-50.9 M279.4,312.6l2.1,9.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M225.3,343.5l-3.4-0.7l-5.5-0.4l-5.5,0.4c-1.2,0.1-2.3,0.4-3.4,0.7
	l-2.3,0.7 M175.5,308.9l-4-2.5h-0.1 M167.6,300.8l2.6-1.1 M166.7,301.2l0.8-0.4 M166.7,301.2l-3.4,1.5c-14.6,6.1-29.7,11.1-45.4,15
	l-19.8,4.4c-4.7,0.8-7.6-0.1-8.9-2.9l-1-2.9c-0.6-2.1-0.3-3.9,0.8-5.2c1.7-1.8,3.4-3.6,5.4-5.2c0.7-0.4,1.2-1,1.9-1.4 M171.4,306.4
	l-4.7-5.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M175.5,308.9c-3.7,2.3-7.3,5.1-10.7,8.1c-11.7,10.7-18.7,23.1-21.2,37.4
	c0.1-1.4,0.3-2.8,0.6-4.1c2.2-15.1,9.4-28.2,21.7-39.3c1.8-1.6,3.6-3.2,5.5-4.5 M191.2,324.5l-4.4-4.4 M155.9,389.5
	c0.7-3.3,1.1-7.3,1.1-11.7c0.1-2.5,0.3-5,0.6-7.3c1.6-13.1,7.4-24.5,17.1-34.3c5.1-5.1,10.7-9.1,16.8-11.8 M186.8,320.1l-11.3-11.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M150.4,390.2c0.4-2.9,0.6-6.2,0.7-9.8c0-2.9,0.3-5.6,0.6-8.4
	c1.8-15.1,8.1-28.2,18.7-39.3c5.1-5.2,10.6-9.5,16.5-12.7 M200.6,348l-1-1.5 M197.3,350.1l5.5,8.5 M212.9,372.8l-5-8.7l-10.5-16.4
	l-0.4,2.3 M197.3,350.1c-1.8,1.1-3.4,2.5-5,4.1l-2.3,2.5c-1,1.1-1.8,2.2-2.6,3.4 M199.7,346.5c-0.7,0.3-1.4,0.7-2.1,1.1
	c-2.5,1.5-4.8,3.4-7,5.5l-0.8,1c-1.9,2.1-3.4,4.1-4.8,6.3 M187.2,360.1l-2.3,0.4 M184.9,360.4c-0.4,0.7-0.8,1.4-1.1,2.1
	 M185.4,363.3l-1.5-0.8 M183.8,362.4c-0.4,0.8-0.8,1.6-1.2,2.5c-0.4,1-0.7,1.9-1.1,2.8 M195.9,365.5l-8.5-5.4h-0.1 M202.8,358.4
	c-1.2,1-2.6,1.9-3.7,3.2c-0.8,0.8-1.5,1.6-2.2,2.5l-1,1.4 M196.3,365.7c0.4-0.5,0.8-1.1,1.4-1.6c0.8-0.8,1.6-1.8,2.8-2.5l0.8,1
	 M195.9,365.5l0.6,0.3 M181,369.9c-0.7,2.9-1.1,5.9-1.1,9.1s0.3,6.1,1,8.9l0.7,2.5 M194.5,374.4l3.3,1 M197.8,375.4
	c-0.3,0.1-0.7,0.4-1,0.8c-0.7,0.8-1.1,1.8-1.1,2.9c0,1.1,0.4,2.2,1.1,2.9l0.6,0.5 M192.3,383.8c-0.3-1.5-0.4-3.2-0.4-4.8
	c0-1.8,0.1-3.4,0.6-5.1 M192.3,373.8l2.1,0.5 M194.5,374.4c-0.3,1.5-0.4,3-0.4,4.5s0.1,2.9,0.4,4.3 M183.4,368.2l9.8,2.6
	c0.3-1,0.7-1.8,1.1-2.6l-8.8-5 M208.4,376.6c-4.4-1.4-8.9-2.6-13.6-3.7l-2.1-0.5c-3.3-0.8-6.6-1.5-9.9-2.2h-0.1l-1.6-0.3
	 M181.6,367.7l1.8,0.5 M182.4,371.8l0.1,0.1c3.3,0.4,6.6,1.1,9.8,1.8 M182.4,385.8c-0.4-2.2-0.7-4.5-0.7-6.9c0-2.5,0.3-4.8,0.7-7.2
	 M192.3,383.8c-3.2,0.8-6.5,1.4-9.6,1.8h-0.1l-0.1,0.1 M181.6,390.2l1.8-0.5 M208.4,381.1l-7,1.9l-8.8,2.2l-9.9,2.2h-0.1l-1.6,0.3
	l1.4-1.9 M194.4,383.2l-2.1,0.5 M197.4,382.4l-3,0.8 M187.4,397.9l-2.3-0.4c0.6-0.3,1-0.7,1.5-1c2.8-1.9,5.6-3.7,8.5-5.5l1.8-1.1
	c4.3-2.8,8.7-5.2,13.2-7.7 M183.4,389.7l9.8-2.8c0.3,0.8,0.7,1.8,1.1,2.6l-8.8,5.1 M181.6,367.7c-0.3,0.7-0.4,1.4-0.6,2.2
	 M185.4,363.3c-0.8,1.5-1.5,3.2-2.1,5 M181,369.9l1.5,2.1 M184.9,360.4l12,7.6c4.4,2.6,8.8,5.1,13.3,7.6 M233,346.3l-1,1.6
	 M235.2,349.9l-2.6,4.3c-1,1.4-1.8,2.9-2.8,4.4 M245.3,359.8c-1.2-1.9-2.8-3.7-4.5-5.4l-0.4-0.4c-1.6-1.6-3.4-3-5.2-4.3
	 M235.2,349.9l-0.4-2.3l-7.4,11.8l-2.8,4.7c-1.7,2.9-3.3,5.8-4.8,8.7 M223.4,345c-0.6,3.2-1.1,6.1-1.8,9.1l-0.3,0.8 M227.7,344.1
	l-2.2-0.7h-0.1 M225.3,343.5l-1.9,1.5 M227.1,345.9l0.6-1.8 M229.9,358.4l-1.1,1.8 M232.1,348l-5,8.8c-0.8-0.4-1.8-0.8-2.6-1.1
	l2.6-9.8 M235,366.4l1.8-1.2 M236.7,365.3c-0.6-0.8-1.2-1.6-2.1-2.5l-1.1-1.2c-1.2-1.2-2.5-2.2-3.9-3.2 M235,366.4
	c-0.6-0.8-1.2-1.6-1.9-2.5l-1-1c-1.1-1.1-2.2-2.1-3.4-2.9 M233,346.3c-1.8-0.8-3.4-1.6-5.4-2.2 M232.1,348c-1.6-0.8-3.2-1.5-5-2.1
	 M218.7,371.1l3.7-13.6l2.9-13.9 M221.3,355c-1.6-0.3-3.3-0.4-5.1-0.4l-4.8,0.4 M211.4,354.9l-0.1-0.8c-0.7-2.9-1.2-5.9-1.8-9.1
	l-0.1-0.1 M205,344.1l0.6,1.8 M209.5,345l-2.1-1.4l0.3,1.8c0.6,3,1.2,5.9,1.9,8.8l4.4,16.9 M201.3,362.6l1.1,1.5
	c0.8,1.4,1.5,3.2,2.2,5.1 M201.3,362.6c0.8-0.8,1.8-1.5,2.8-2.2 M202.8,358.5l1.2,1.9 M200.6,348l5.1,8.8c0.8-0.4,1.8-0.8,2.6-1.1
	l-2.8-9.8 M218.2,365.7l0.6-1.8c0.8-2.3,1.5-4.7,2.2-7 M212,357.1c0.6,2.3,1.2,4.8,2.1,7l0.6,1.8 M220.9,357.1l-4.5-0.4l-4.4,0.4
	 M214.6,365.7h3.6 M223.4,345c-2.2-0.4-4.5-0.7-7-0.7c-2.3,0-4.7,0.3-6.9,0.7 M212,357.1l-0.6-2.1 M217.2,369.6
	c0.3,0.3,0.4,0.6,0.4,0.8s-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-0.8,0.4s-0.7-0.1-0.8-0.4c-0.3-0.3-0.4-0.5-0.4-1s0.1-0.7,0.4-0.8
	c0.3-0.3,0.6-0.4,0.8-0.4C216.7,369.3,216.9,369.3,217.2,369.6L217.2,369.6z M214.6,369l0.1-0.1c0.4-0.4,1-0.7,1.5-0.7
	c0.7,0,1.2,0.3,1.6,0.7l0.1,0.1 M218,372.1c-0.4,0.4-1,0.7-1.7,0.7s-1.1-0.3-1.5-0.7 M218,372.1l0.6-0.7l0.1-0.3 M218.7,371.1v-0.6
	c0-0.5-0.1-1-0.6-1.4 M219.7,372.8c-0.6-0.3-1.1-0.5-1.6-0.7 M214.6,369c-0.4,0.4-0.6,1-0.6,1.5v0.4 M214.2,371l0.1,0.4
	c0.1,0.3,0.3,0.6,0.4,0.7 M214.7,372.1c-0.7,0.1-1.2,0.3-1.8,0.7 M210.2,375.5c0.3-0.5,0.7-1.1,1.2-1.5c0.6-0.5,1.1-0.8,1.6-1.2
	 M202.1,369.2l2.5,1.5 M204.6,369.2h-2.5 M204.6,370.7v-1.5 M205.1,371c-0.3-0.1-0.4-0.3-0.7-0.3 M201.4,376.5l1.8,0.5 M206.5,377
	c0.4-0.3,0.8-0.5,1.5-0.5h0.6 M209.5,377.2c-0.3-0.3-0.6-0.4-0.8-0.5l-0.3-0.1h-0.1 M206.6,378.8c0-0.4,0.1-0.7,0.4-1
	c0.3-0.3,0.6-0.4,0.8-0.4c0.3,0,0.6,0.1,0.8,0.4s0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8c-0.3,0.3-0.6,0.4-0.8,0.4
	c-0.4,0-0.7-0.1-0.8-0.4C206.8,379.5,206.6,379.2,206.6,378.8L206.6,378.8z M206.5,380.6l-0.1-0.1c-0.4-0.4-0.7-1-0.7-1.5
	c0-0.7,0.3-1.2,0.7-1.7l0.1-0.1 M209.5,377.2c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.5v0.1 M210.2,375.5
	c-0.3,0.6-0.6,1.1-0.7,1.6 M208.4,367.8l-3.2,3.2 M203.2,377h3.3 M205.1,371l5,4.5 M209.5,380.5l-1.1,0.6 M209.5,380.5
	c0.1,0.5,0.3,1.1,0.7,1.8 M206.5,380.6c0.4,0.4,1,0.5,1.5,0.5h0.4 M201.4,381.1l0.8-0.3l0.4-0.1l0.1-0.1l0.4-0.1 M204.5,387.2
	c-0.6,0.4-1.2,0.7-1.9,1.1 M205.1,386.8l-0.7,0.4 M204.5,388.3v-1.1 M204.5,388.3h-1.9 M203.2,380.6h3.2 M210.1,382.4l-5,4.5
	 M205.1,386.8l3.2,3.2 M219.7,385.1c-0.6,0.3-1.1,0.5-1.8,0.7 M214.6,385.8c0.6-0.5,1.1-0.7,1.6-0.7s1.1,0.3,1.6,0.7l0.1,0.1
	 M214.6,385.8c-0.6-0.1-1.1-0.4-1.6-0.7 M212.9,385.1c-0.6-0.3-1.1-0.7-1.5-1.2c-0.6-0.5-1-1.1-1.2-1.6 M217.9,388.9l-0.1,0.1
	c-0.4,0.4-1,0.7-1.6,0.7c-0.7,0-1.2-0.3-1.6-0.7l-0.1-0.1 M214.5,388.7c-0.3-0.4-0.6-0.8-0.6-1.5v-0.5 M213.9,386.8l0.1-0.3l0.6-0.7
	 M215,387.3c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.7,0.1,0.8,0.4c0.3,0.3,0.4,0.5,0.4,1c0,0.3-0.1,0.7-0.4,0.8
	c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4C215.1,388,215,387.6,215,387.3L215,387.3z M218.4,386.9v0.4c0,0.6-0.1,1.1-0.6,1.5
	 M217.9,385.8c0.1,0.1,0.3,0.4,0.4,0.7l0.1,0.4 M203.2,377v3.6 M201.4,381.2v-4.7 M208.4,390.1l4.5-5 M212.9,372.8l-4.5-5
	 M238.3,374.5c0.3,1.4,0.4,2.9,0.4,4.4c0,1.5-0.1,3-0.4,4.5 M240.3,384c0.3-1.6,0.6-3.3,0.6-5.1c0-1.6-0.1-3.3-0.4-5 M240.3,374
	l-2.1,0.5 M247.3,363.3l-8.8,5.1l1.1,2.6l9.9-2.8 M226.1,377.3c-0.4-0.4-1-0.5-1.5-0.5h-0.4 M219.7,372.8c0.6,0.3,1.1,0.7,1.5,1.1
	c0.6,0.5,1,1.1,1.2,1.6 M223.1,377.3c-0.6,0.4-0.8,1-0.8,1.6c0,0.5,0.3,1.1,0.7,1.6h0.1 M226,378.9c0,0.3-0.1,0.7-0.4,1
	c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.1-1-0.4s-0.4-0.5-0.4-1c0-0.4,0.1-0.7,0.4-0.8c0.3-0.3,0.6-0.4,1-0.4s0.7,0.1,0.8,0.4
	C225.9,378.3,226,378.5,226,378.9L226,378.9z M224.2,376.7l-0.4,0.1c-0.3,0.1-0.4,0.1-0.7,0.4 M223.1,377.3l-0.7-1.6 M238.3,374.5
	c-2.8,0.7-5.4,1.5-7.8,2.5l-0.4,0.1l-0.6,0.1 M229.4,377.3h-3.2 M226.1,377.3l0.1,0.1c0.4,0.4,0.7,1,0.7,1.6c0,0.5-0.3,1.1-0.7,1.6
	l-0.1,0.1 M222.6,375.5l4.8-4.5 M227.4,371l-3.2-3.2 M229.4,380.9h-3.3 M238.3,383.5c-2.8-0.7-5.2-1.5-7.8-2.5l-0.4-0.1l-0.7-0.1
	 M226.1,380.7c-0.4,0.3-0.8,0.5-1.4,0.5h-0.6 M223.1,380.7l0.7,0.5l0.3,0.1 M222.4,382.4c0.3-0.5,0.6-1.1,0.7-1.6 M222.4,382.4
	c-0.3,0.5-0.7,1.1-1.2,1.5c-0.6,0.5-1.1,1-1.6,1.2 M227.4,386.9l-4.9-4.5 M224.2,390.1l3.2-3.2 M247.7,397.4l-1.5-1
	c-2.9-1.9-5.8-3.7-8.7-5.4l-1.8-1.1c-4.4-2.6-8.8-5.2-13.3-7.6 M249.3,389.8l-9.8-2.6l-1.1,2.6l8.8,5 M240.3,384l-2.1-0.5
	 M224.1,381.3c4.4,1.4,9.1,2.6,13.8,3.7l13.8,3 M229.4,380.9v-3.6 M235,391.3c-2.5-1.5-5.1-3-7.6-4.4 M227.4,371l7.4-4.5
	 M224.2,367.8l-4.5,5 M221.3,355l-0.6,2.1 M214.6,365.7v3.2 M218.2,369v-3.3 M219.7,385.1l4.5,5 M228.6,360.2l-4.4,7.6 M204,360.4
	l4.4,7.4 M205,344.1c-1.8,0.5-3.6,1.4-5.4,2.2 M205.5,345.9c-1.8,0.5-3.4,1.2-5,2.1 M197.4,382.4l4-1.2 M202.6,388.3
	c-1.6,1-3.3,1.9-4.9,3l-1.1,0.7 M197.8,375.4l3.6,1.2 M202.1,369.2l-5.8-3.4 M236.7,392.4c-1,1.4-1.9,2.6-3.2,3.9
	c-1.1,1.1-2.3,2.2-3.7,3.2 M219.7,385.1c2.5,4.5,5.1,8.9,7.7,13.3l1.1,1.8c1.8,2.9,3.6,5.6,5.5,8.4v0.1l1,1.4l0.4-2.2 M227.1,412
	l-2.8-9.8c0.8-0.3,1.8-0.7,2.6-1.1l5.1,8.8 M229.9,399.3l-1.1-1.8 M227.5,413.6l-0.4-1.8 M218.4,386.9c1.4,4.4,2.6,8.9,3.7,13.8
	l3,13.6l-1.9-1.2 M223.1,412.9l-0.1-0.1l-0.7-4.1c-0.3-1.9-0.7-3.9-1.1-5.6 M207.2,414.3c2.9,0.7,5.9,1.1,9.1,1.1c3,0,6.1-0.3,8.8-1
	l2.3-0.7 M231.9,409.9l0.8,1.5 M229.9,399.3l5.5,8.4v0.3 M232.9,411.4l4.7-2.9c1.5-1.2,3.2-2.5,4.5-4c2.2-2.2,4.1-4.7,5.6-7.3
	 M235,391.3c-0.8,1.2-1.8,2.3-2.9,3.4c-1.1,1.1-2.2,1.9-3.3,2.9 M227.5,413.6c1.8-0.5,3.6-1.2,5.4-2.2 M231.9,409.9
	c-1.6,0.8-3.2,1.5-5,1.9 M235.4,408c1.9-1.2,3.7-2.8,5.5-4.5s3.3-3.7,4.5-5.6 M220.6,401c-0.7-2.8-1.5-5.2-2.5-7.8l-0.3-1
	 M211.7,400.8c0.7-2.6,1.5-5.2,2.3-7.8l0.1-0.4l0.1-0.5 M211.7,400.8l-0.6,2.1 M220.6,401l-4.4,0.4l-4.5-0.4 M214.5,392h3.6
	 M221.2,403l-5,0.4l-5.1-0.4 M202.8,399.4c0.4-0.7,0.7-1.2,1.1-1.8 M205.5,412l2.6-9.8c-0.8-0.3-1.8-0.7-2.6-1.1l-5,8.8
	 M205.1,413.6l2.2,0.5 M209.2,412.9l-2.1,1.4 M211.3,402.9l-1.2,5.6l-0.7,4.1l-0.1,0.1 M205.1,413.6l0.6-1.8 M209.2,412.9
	c2.2,0.4,4.5,0.7,7,0.7c2.3,0,4.7-0.1,6.9-0.5 M213.9,386.8c-1.4,4.4-2.6,8.9-3.7,13.8l-3,13.8 M221.2,403l-0.6-2.1 M202.8,399.4
	c-1.4-1-2.6-1.9-3.9-3.2c-1.2-1.2-2.2-2.5-3.2-3.7 M196.6,392c1.1,1.4,2.3,2.8,3.9,3.9l0.6-0.7 M196.6,392l-0.7,0.4 M183.8,395.6
	l1.1,2.1c1.5,2.5,3.4,4.8,5.6,7c1.5,1.5,3,2.8,4.5,4c0.8,0.5,1.6,1.2,2.5,1.8 M195.9,392.4l-8.4,5.5h-0.1 M185.4,394.6l-1.5,1
	 M181.6,390.2l1.1,2.8l1.1,2.6 M197.4,408v-0.1l5.4-8.4 M197.7,410.3h0.1 M197.8,410.3l1.9,1.1 M197.8,410.3h-0.1 M200.7,409.9
	l-1,1.5 M197.7,410.3l-0.3-2.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M187.4,397.9c1.2,1.9,2.8,3.7,4.5,5.5c1.8,1.8,3.6,3.3,5.6,4.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M166.3,389.5c1.5,7.7,4.8,14.9,9.9,21.2c1.2,1.5,2.5,2.9,4,4.4
	c10,9.9,22,15,36.2,15c13.2,0,24.5-4.4,34.1-13.1l2.1-1.9c7.4-7.4,12.1-16,13.9-25.7 M199.7,411.4c1.6,0.8,3.4,1.6,5.2,2.2
	 M204,397.6l-2.9-2.3 M205.5,412c-1.6-0.5-3.3-1.2-5-1.9"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M212.9,385.1c-2.5,4.5-5,8.9-7.6,13.3l-7.6,12 M247.8,397.4l1-1.8
	 M247.2,394.8l1.5,0.8 M245.4,397.8h-0.1l-8.5-5.4 M247.8,397.4l-2.3,0.4 M183.4,389.7c0.6,1.6,1.2,3.3,2.1,5 M201.1,395.3
	c1.2-1.6,2.3-4,3.4-6.9 M208.4,390.1l-4.4,7.6 M236.7,392.4l-1.8-1.1 M228.8,397.5l-4.5-7.4 M217.9,392v-3.2 M214.5,392v-3.3
	 M247.2,394.8c0.8-1.6,1.5-3.3,2.1-5 M154.1,305.2c-12.5,3.9-24.5,7.2-36,10l-0.3,0.1l-19.1,4.4l-2.3-0.1l-1-0.5l-0.6-0.8l-1-2.9
	c-0.6-1.9-0.3-3.6,0.8-5l0.1-0.1l5.4-3.3 M96.6,304.7l-5.1-2.5 M96.6,304.7h0.1l3.4,2.2 M91.5,302.2c-1.4,1.1-2.8,2.2-3.9,3.6
	c-2.3,2.6-4.5,5.5-6.6,8.5 M84.6,327.4l0.4,1.4 M84.6,327.4l-0.6-1.4 M84.1,326h-0.8c-0.1,0-0.3,0.1-0.4,0.1
	c-0.1,0.1-0.1,0.3-0.1,0.4s0.1,0.3,0.1,0.4l0.4,0.1h1.2 M87.5,338.1c0-0.8-0.1-1.6-0.3-2.5l-0.8-2.5 M85,332h1 M86.4,333.3H85
	 M86.4,333.3L86,332 M85,332h-2.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0.1,0.4,0.1,0.5l0.4,0.1H85 M85,338.1h-2.5
	c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3-0.1,0.4s0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1H85 M87.4,339.5l0.1-1.4 M85.3,344.3
	c0.7-1.2,1.2-2.6,1.8-4.1l0.1-0.7"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M80.6,350.3h0.3c2.1-1,3.6-2.5,4.8-4.5l0.3-0.4l2.3-5v-0.1l0.3-1.1
	l0.1-1.2c0-1-0.1-1.9-0.3-2.8l-5.9-18.8c-0.4-1.1-0.8-1.8-1.5-2.3 M85,333.3v4.8 M85,338.1h2.5 M87.4,339.5H85 M85,328.7v3.3
	 M86,332l-1-3.3 M230.8,259.8c-45.7,9.5-86.3,22.1-121.7,38.1c-4.4,2.1-8.5,4.3-12.4,6.7 M85,344.8v-0.5 M85,344.3h0.3 M85,344.8
	l0.3-0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M84.6,345.5l0.1-0.3l0.3-0.4 M85,344.3h-2.9c-0.1,0-0.3,0-0.4,0.1
	c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0.1h2.5 M86.1,364.4l1.2-6.7c0.3-1-0.1-1.2-1-1.1l-5.8,1.9
	 M86,365.5l0.1-1.1 M100.4,357.5c4.1-2.5,9.4-4.3,15.5-5.5l19.7-4.3c2.5-0.5,4.1-0.4,5.1,0.4c1.1,0.8,1.2,2.3,0.6,4.7
	c-1.2,5.2-3,10-5.5,14.7c-1,1.8-2.6,3-5,3.6l-35.2,5.1l-4.5,0.3l-0.3-0.1c-1.2-0.3-1.8-1-1.7-2.1l0.1-0.3c1.1-2.8,2.3-5.4,3.8-7.8
	l3.2-4.8C97.5,359.5,98.9,358.3,100.4,357.5L100.4,357.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M99.2,364.5l-0.6,1l24.3-3.3v-2.3l-22.4,3.2
	C100.1,363.4,99.6,363.8,99.2,364.5L99.2,364.5z"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M91.2,375c0.6-1.2,1.1-2.3,1.8-3.6l1.4-2.5l0.1-0.1c0.6-1.1,1.2-2.1,1.9-3
	l0.6-0.8c0.6-0.7,1.1-1.2,1.7-1.8c0.7-0.7,1.5-1.2,2.2-1.5c4-2.3,9.1-4.1,15.1-5.2l13.3-2.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M137.7,348.8l-21.6,4.5c-6.1,1.2-11.1,3-15.1,5.4
	c-1.4,0.7-2.6,1.8-3.8,3.3l-2.5,3.7c-1.7,2.6-3,5.5-4.3,8.4c0,0.3,0.3,0.4,0.7,0.5h0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M95.1,371.1l27.8-3.7v-2.2l-26.6,3.6C95.9,369.5,95.5,370.3,95.1,371.1
	L95.1,371.1z M91.2,375l3.9-0.3l35.4-5.1 M85.4,369h-0.8c-0.1,0-0.3,0-0.4,0.1s-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0.1,0.5
	c0.1,0.1,0.3,0.1,0.4,0.1h0.7 M85.2,370.3l0.3-1.2 M88.6,378.2l-1.9,0.3c-1.1,0.1-2.1,0.5-3,1.2 M85.2,370.3l-1.5,9.5 M78.6,383.8
	v0.8c0,0.8,0.3,1.4,0.8,1.6c0.7,0.4,1.5,0.8,2.6,1.4l3.2,1.5c1.4,0.5,2.9,1,4.8,1.1h1.8 M96,390.2h-4.3"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M88.6,378.2c-1.2,0.3-2.6,1-4,2.2l-3.7,3.3 M115.4,390.2l-13.6,1.8
	l-5.8-1.8 M86,365.5l-0.6,3.6 M115.4,390.2H96 M94.8,354.7l48-11.6c-5.5-0.8-13.5,0-23.7,2.3C107.4,348.3,99.3,351.4,94.8,354.7z
	 M130.5,369.6c1.9-0.4,3.2-1.2,3.9-2.8c2.3-4.5,4.1-9.4,5.4-14.4v-0.1c0.4-1.5,0.4-2.5-0.1-3c-0.4-0.3-1.1-0.4-2.1-0.4"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M129.4,353.5l0.6-0.4c2.1-1.6,4.7-3,7.7-4.1 M127.4,355.6
	c0-0.3-0.1-0.5-0.6-0.5h-0.7c-0.4,0-0.6,0.1-0.6,0.5v13.3c0,0.3,0.1,0.5,0.6,0.5h0.7c0.3,0,0.6-0.1,0.6-0.5 M129.4,353.5l-2.1,2.1
	 M130.4,369.6h0.1 M127.4,368.9c1,0.4,1.9,0.5,3,0.7 M130.5,369.6v-0.1l-0.1,0.1 M80.9,383.8h2.6l67.3-12.4h-11.7
	c-2.5-0.1-5.1,0.1-7.8,0.5l-42.6,6.3 M150.4,390.2h5.4l0.1-0.7 M146.6,390.2h3.7 M127.4,368.9v-13.3 M85,339.5v4.8 M146.6,390.2
	h-31.2 M80.9,314.3l-0.7,1c0.4,0.4,0.8,1,1,1.7l2.9,9.1 M80.6,358.4v7H86 M80.6,350.3v8.1 M84.6,345.5c-1,1.6-2.3,2.9-3.9,3.6v1.2
	 M78.6,383.8h2.3 M91.8,390.2l10.7,3.3l44.2-3.3 M83.6,379.8l-5.1,4 M86.1,364.4H81 M155.9,389.5h10.5 M167.6,300.8l-13.3,4.3
	 M620.1,287.7c-10.5,3.2-19.9,8.7-28.5,16.4c-9.6,8.8-16.2,19-19.9,30.5c3.9-7.7,9.4-14.7,16.5-21c7.3-6.5,15.4-11.4,24.1-14.7
	 M581.3,383.5h8.8 M575,383.6l6.3-0.1 M636,355l-0.1-0.8c-0.7-2.9-1.2-5.9-1.7-9.1H634 M632.1,343.5l-0.1,0.1l-2.2,0.5 M634,345
	l-1.9-1.5 M637.6,372.8c-1.5-2.9-3.2-5.8-4.8-8.7l-2.8-4.7l-7.4-11.8v-0.1l-0.4,2.3 M629.8,344.1c-1.8,0.5-3.6,1.2-5.4,2.2
	 M624.4,346.3l1,1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M624.4,346.3c-0.5,0.3-1.2,0.5-1.8,1h-0.1c-2.6,1.5-5,3.4-7.3,5.8l-0.8,1
	c-1.8,1.9-3.4,4-4.7,6.2 M622.2,349.9v0.1l5.4,8.4 M627.6,358.4c-1.2,0.8-2.6,1.9-3.9,3.2l-1.1,1.2c-0.7,0.8-1.4,1.6-1.9,2.5
	 M622.5,366.4l-1.8-1.1 M628.7,360.2c-1.2,0.8-2.3,1.8-3.4,2.9l-0.8,1c-0.7,0.8-1.4,1.5-1.9,2.5 M627.6,358.4l1.1,1.8 M639.1,365.7
	l-0.1-0.5l-0.4-1.1c-0.8-2.3-1.5-4.7-2.2-7 M638.7,371.1c-0.7-2.3-1.4-4.7-1.9-7l-1.8-6.7l-2.9-13.9 M625.4,348l5,8.8
	c0.8-0.4,1.8-0.8,2.6-1.1l-2.6-9.8 M636.5,357.1L636,355 M630.2,345.9l-0.4-1.8 M625.4,348c1.7-0.8,3.2-1.5,5-2.1 M622.2,349.9
	c-1.8,1.2-3.6,2.6-5.2,4.3l-0.4,0.4c-1.7,1.6-3.2,3.4-4.5,5.4 M608.4,362.4c-0.5,1.1-1.1,2.3-1.5,3.4l-0.7,1.8 M609.7,360.2
	l-1.2,2.2"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M620.7,365.3c-2.9-1.8-5.6-3.6-8.4-5.5h-0.1 M612,359.8l-2.3,0.4
	 M610,363.3l-1.5-0.8 M617,384c-0.3-1.6-0.4-3.3-0.4-5.1c0-1.6,0.1-3.3,0.4-5 M617,374l2.1,0.5 M619,374.5c-0.3,1.4-0.4,2.9-0.4,4.4
	c0,1.5,0.1,3,0.4,4.5 M607.9,368.2l9.9,2.8c0.3-1,0.7-1.8,1.1-2.6l-8.8-5.1 M607.1,372.1l-0.1,1c-0.3,1.9-0.4,3.9-0.4,5.9
	s0.1,4,0.4,5.9l0.1,1.1 M606.2,367.7l-0.5,2.3c-0.7,2.9-1,5.8-1,8.9s0.3,6.2,1.1,9.1 M606.2,367.7l1.7,0.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M633.1,376.7c-4.4-1.4-8.9-2.6-13.8-3.7l-13.8-3l1.4,2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M617,374c-3.2-0.8-6.5-1.4-9.8-1.8l-0.1-0.1 M605.7,388l0.7,2.2
	 M606.4,390.2l0.5,1.6c0.4,1.2,1.1,2.5,1.7,3.7 M607.1,386l-1.4,2.1 M606.4,390.2l1.7-0.4 M607.1,386c3.4-0.5,6.7-1.2,9.9-1.9
	 M619,383.5L617,384 M608,389.8l9.8-2.6c0.3,0.8,0.7,1.8,1.1,2.6l-8.9,5 M633.2,381.3c-4.4,1.4-9.1,2.6-13.8,3.7l-13.8,3 M639.3,369
	c-0.4,0.4-0.6,0.8-0.6,1.4v0.5 M637.6,372.8c0.5-0.3,1.1-0.5,1.7-0.7 M638.7,371.1l0.1,0.3l0.4,0.7 M633.1,367.8l-3.2,3.2
	 M634.9,375.6v-0.1 M633.1,376.7h-0.4c-0.5,0-1.1,0.1-1.5,0.5 M631.4,378.9c0,0.3,0.1,0.7,0.4,1s0.5,0.4,0.8,0.4
	c0.4,0,0.7-0.1,1-0.4s0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.3-0.5-0.4-1-0.4c-0.3,0-0.7,0.1-0.8,0.4
	C631.6,378.3,631.4,378.5,631.4,378.9L631.4,378.9z M631.1,377.3l-0.1,0.1c-0.4,0.4-0.7,1-0.7,1.6c0,0.5,0.3,1.1,0.7,1.6l0.1,0.1
	 M634.2,380.7c0.5-0.5,0.7-1.1,0.7-1.6c0-0.7-0.1-1.2-0.5-1.6l-0.1-0.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M634.2,377.3c-0.1-0.1-0.4-0.3-0.7-0.4l-0.4-0.1 M634.2,377.3
	c0.1-0.5,0.4-1.2,0.7-1.6 M634.9,375.5c0.3-0.5,0.7-1.1,1.2-1.6c0.5-0.4,1-0.8,1.5-1.1 M633.1,367.8l4.5,5 M634.9,375.5l-5-4.5
	 M628,377.3l-0.7-0.1l-0.4-0.1c-2.6-1-5.2-1.8-7.8-2.5 M619,383.5c2.6-0.7,5.2-1.5,7.8-2.5l0.4-0.1l0.7-0.1 M637.7,385.1
	c-0.5-0.3-1.1-0.8-1.7-1.2c-0.4-0.4-0.8-1-1.1-1.5 M639.4,385.8c-0.5-0.1-1.1-0.4-1.7-0.7 M631.3,380.7c0.4,0.3,0.8,0.5,1.4,0.5h0.5
	 M634.9,382.4c-0.3-0.5-0.4-1.1-0.7-1.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M633.2,381.3l0.3-0.1l0.7-0.4 M633.2,390.1l-3.3-3.2 M638.8,386.9v0.4
	c0,0.6,0.1,1.1,0.5,1.5 M639.4,385.8c-0.1,0.1-0.3,0.4-0.4,0.7c-0.1,0.1-0.1,0.3-0.1,0.4 M629.9,386.9l5-4.5 M637.7,385.1l-4.5,5
	 M609.7,360.2l11.8,7.7c4.4,2.6,8.8,5.2,13.2,7.7 M628,380.9l3.3-0.1 M631.1,377.3H628 M629.9,386.9c-2.5,1.4-5.1,2.9-7.6,4.4
	 M609.5,397.4l1.5-1c2.9-1.9,5.8-3.7,8.7-5.4l1.8-1.1c4.4-2.6,8.8-5.2,13.3-7.6 M628,380.9v-3.7 M639.1,365.7l0.1,3.3 M629.9,371
	l-7.4-4.5 M610,363.3c-0.8,1.5-1.5,3.2-2.1,5 M628.7,360.2l4.4,7.6 M612.4,298.9c-16.8,24.8-33.8,47.6-51.3,68.4
	c-3.9,4.5-9.5,7-17.1,7.3l-74.1,1.9 M636.6,401c0.7-2.8,1.5-5.2,2.5-7.8l0.3-1 M630.3,412l2.8-9.8c-0.8-0.3-1.8-0.7-2.5-1.1
	l-5.1,8.8 M636.2,403l0.4-2.1"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M634.2,412.9l-1.9,1.2l2.5-11.6c0.1-0.7,0.3-1.4,0.4-2.1
	c1.1-4.7,2.3-9.4,3.7-13.8 M620.5,392.4c1,1.4,1.9,2.6,3.2,3.9c1.1,1.1,2.5,2.2,3.7,3.2 M628.7,397.5c-1.2-0.8-2.3-1.8-3.4-2.9
	c-1.1-1.1-2.1-2.2-2.9-3.4 M627.4,399.3l1.2-1.8 M624.4,411.4c1.8,0.8,3.4,1.6,5.4,2.2 M625.4,409.9l-1,1.5"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M627.4,399.3l-5.5,8.4l0.1,0.3 M622.1,408l0.3,2.2l7.6-11.7
	c2.8-4.4,5.2-8.8,7.8-13.3 M634.2,412.9l0.1-0.1l0.7-4.1c0.3-1.9,0.7-3.9,1.2-5.6 M629.8,413.6l0.5-1.7 M625.4,409.9
	c1.7,0.8,3.2,1.5,5,1.9 M622.1,408c-1.9-1.2-3.7-2.8-5.5-4.5c-1.8-1.8-3.3-3.7-4.7-5.6 M620.5,392.4c-2.9,1.8-5.6,3.6-8.4,5.4H612
	 M608.6,395.6l1,1.8"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            d="M609.5,397.4c1.5,2.6,3.4,5,5.6,7.3c1.5,1.5,3,2.8,4.5,4l4.5,2.9
	 M611.9,397.8l-2.3-0.4 M610.1,394.8l-1.5,0.8 M639.4,392v-3.2 M608,389.8c0.6,1.6,1.2,3.3,2.1,5 M622.3,391.3l-1.8,1.1
	 M628.7,397.5l4.5-7.4 M467.2,358.2l0.5-7.6 M469.9,376.5l-2.8-18.3 M290.8,370.1l78.8-3.4c31.8-1.5,64.2-4.4,97.5-8.5 M467.2,358.2
	c28.9-3.6,58.2-8.1,88.3-13.6l-87.8,6.1 M281.9,391.3l293.1-7.7 M375.1,195.6c-41.8,16.5-82.3,36.3-121.1,59.4 M467.7,350.6
	c-0.4-22,0-44,1.1-65.9 M612.4,298.9l7.7-11.1 M321.8,236.8l-3.9,1.9 M284.5,300l128.2-15.1 M468.8,284.7
	c-62.7,8.8-123.8,17.7-183.4,27 M284.5,300l5.5-24.1 M467.7,350.6l-177.7,12 M171.4,306.4c13.5-10,29.2-15.1,47.2-15.1
	c15.7,0,29.7,3.9,41.8,11.6 M319.3,251l2.5-14.2 M648.8,185.9l-11.1-1.6 M631,263l13.3-1.6l-12.9,0.8 M642.7,365.7h-3.6 M643,392
	h-3.6"
          />
          <path
            fill="none"
            stroke="#010101"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M303.3,270.8l-4.1,12.1c0,0-1.2,3.2,1.1,5.6
	c0,0,2.3,2.2,4.8,2.9c0,0,6.6,1.5,9.2-6.2l3.4-9.9c0,0,0.1-1.5,2.1-1.6l8.8-0.8c0,0,6.1-0.1,6.7-7.3v-5.2c0,0,0.1-4-2.9-6.2
	c0,0-4.5-4.4-10.5-3.7c0,0-7.4,0.7-13.6,5.1c0,0-3.7,2.5-5.6,5c0,0-1.6,1.4-0.8,3l1.4,4.8C303.3,268.6,303.7,269.7,303.3,270.8
	L303.3,270.8z"
          />
          <g>
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M191.2,324.5
		l-4.4-4.4l-11.3-11.1l-4.1-2.3l-4.7-5.2c0,0,3-1.1,18.4-8.5c0,0,13.2-6.6,24.5-12.8l43.1-5.9h0.6c0,0,9.9,5.5,24.5,3.2L290,276
		l-5.5,24.1l0.8,11.7c0,0,1.2,21.6,4.5,50.9l1.1,10l-6.9,6.5l-2.2,12.4l-6.2,0.1l-0.8-15.8c0,0-0.1-21.3-18.2-39.2
		c0,0-15.1-17.2-40.6-17.1C216.4,319.2,202.9,318.5,191.2,324.5z"
              id="Left Front - Fender"
              onClick={() => this.handlePress('Left Front - Fender')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontFenderColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M290,275.9l12-1.2
		l-2.9,8.5c0,0-1.1,2.8,1,5.2c0,0,2.3,2.5,5,3.2c0,0,6.3,1.2,8.9-5.4l3.7-10.6c0,0,0.3-1.5,2.1-1.7c1.8-0.1,8.8-0.8,8.8-0.8
		s2.1,0,4-1.5l139.8-14.2l-3.2,21c0,0-1.8,25.2-1.5,72.4l-0.4,7.6l2.8,18.2L298.5,381c0,0-3.9,0.4-5.8-1.8c0,0-1.2-1-1.6-6.5
		l-1.1-10.2c0,0-3.4-29.3-4.5-50.8l-0.8-11.7L290,275.9z"
              id="Left Front Door"
              onClick={() => this.handlePress('Left Front Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M323.7,250.6
		l2.8-16.1c0,0,32.7-16.8,71.7-27.2c0,0,31-9.4,72.5-11.8l-12.1,60l-123.8,12.5c0,0,0.4-1.5,0.4-2.3v-5.4c0,0,0-4-2.9-6.1
		C332.2,254.3,328.1,250.6,323.7,250.6z"
              id="Left Front Door Glass"
              onClick={() => this.handlePress('Left Front Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftFrontDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M486.4,252.7
		l5.6-57.6c0,0,48-1.8,107.7,8.4l19,36.5L486.4,252.7z"
              id="Left Rear Door Glass"
              onClick={() => this.handlePress('Left Rear Door Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M633.2,231.3
		c0,0-1,1.4-2.2,0L620.4,213c0,0-0.7-1.1,0.8-1.1l20.9,5c0,0,1.2,0.1,1.2,1c0,0,0.1,0.7-0.1,1.1C643.1,219,637.6,227.2,633.2,231.3z
		"
              id="Left Qtr Glass"
              onClick={() => this.handlePress('Left Qtr Glass')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftQtrGlassColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M472.3,257.2l-3.2,21
		l-0.3,6.5c0,0-1.5,37.7-1.1,65.9l-0.4,7.6l2.8,18.2l72.5-1.9c0,0,9.1,0.1,13.9-3.3c0,0,2.5-1.4,4.3-3.4l4.8-5.9
		c0,0,17.7-20.5,54.6-74.1c0,0,1-1.1,2.3-4.3l8.9-21.2c0,0,0.1-8.9-3.7-20.5l-3.3,0.6L472.3,257.2z"
              id="Left Rear Door"
              onClick={() => this.handlePress('Left Rear Door')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearDoorColor')
                  : '#83be97'
              }
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M672.4,325.3
		l3.4-4.7l8.9-14.9l12.5-23.2l3.2-13.5c0,0-12.9-4.5-16.8-7c0,0-2.9-1.8-4.8-5.2l-15.8-23.9c0,0-1.5-2.6-0.5-6.2l1.9-7.4l-12.4-4.3
		c0,0-0.1,1.1-1.4,3c-1.2,1.9-2.8,4.3-7.4,10.6c0,0-4.8,6.2-8.7,9.4c0,0-3.3,2.6-7.2,3.7c0,0,3.7,10.5,3.7,20.5l-9.5,22.6
		c0,0-0.8,1.7-1.8,3c0,0-23.1,33.8-34.5,48.6c0,0-11.7,15.7-22.8,29h14.3l5.2,4.5c0,0,1.1-9.6,5.1-18.4c0,0,12.1-27.1,39.6-33.6
		C626.9,317.9,650.8,311.4,672.4,325.3z"
              id="Left Rear - Quarter Panel"
              onClick={() => this.handlePress('Left Rear - Quarter Panel')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftRearQuarterPanelColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="216.4"
              cy="379.4"
              r="51.2"
              id="Wheels And Tires - Left Front"
              onClick={() =>
                this.handlePress('Wheels And Tires - Left Front')
              }
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftFrontColor')
                  : '#83be97'
              }
            />
            <circle
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              cx="640.6"
              cy="379.1"
              r="51.2"
              id="Wheels And Tires - Left Rear"
              onClick={() =>
                this.handlePress('Wheels And Tires - Left Rear')
              }
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('tiresLeftRearColor')
                  : '#83be97'
              }
            />
            <path
              fill="#2BACE2"
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M311.2,253.9l1-4.7c0,0,0.4-1.4-0.4-1.9
		c0,0-1-0.1-1.9,0.3c0,0-24.8,13.2-36.2,23.2c0,0,1.9,1.1,11,0.1l19-2.1l-1.5-5.4c0,0-0.8-1.5,0.8-3
		C302.8,260.6,305.3,257.1,311.2,253.9z"
            />
            <path
              stroke="#010101"
              strokeWidth="0.5"
              strokeMiterlimit="10"
              d="M302.2,274.3l1.1-3.5
		c0,0,0.4-0.8-0.1-2.9l-1.2-4.3c0,0-0.9-1.5,0.8-3c0,0,2.7-3.7,8.4-6.7c0,0,4.9-2.8,11.3-3.3c0,0,3-0.2,6.1,1.5c0,0,3.6,1.9,5,3.8
		c0,0,1.2,1.9,1.4,4.5v5.2c0,0,0.1,3-1.5,4.9c0,0-1.6,2.2-4.4,2.7l-8.8,0.7c0,0-1.9-0.1-2.5,1.2l-3.8,10.8c0,0-1.1,3.4-4,4.9
		c0,0-2.3,1.4-5.2,0.9c0,0-3-0.9-5.1-3.5c0,0-1.9-2.4-0.6-5.1l0.7-2.2L302.2,274.3z"
              id="Left Side Mirror"
              onClick={() => this.handlePress('Left Side Mirror')}
              fill={
                this.props.selectedCondition.area
                  ? this.splatColor('leftSideMirrorColor')
                  : '#83be97'
              }
            />
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedCondition } = state.core;
  return { selectedCondition };
};

export default connect(mapStateToProps)(Hatchback4DrLeft);
