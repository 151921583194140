import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import {
  loadAddWatchList,
  loadEventInfo,
  loadRemoveWatchList,
  loadRunListES,
  loadWatchlistObject,
  resetEventInfo,
  resetRunlistES,
} from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import { Button, Icon } from 'semantic-ui-react';
import styles from './styles';

class RunListEvent extends Component {
  state = {
    loading: null,
  };

  componentDidMount() {
    this.props.resetEventInfo();
    this.props.resetRunlistES();
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.eventId !== prevProps.params.eventId) {
      this.props.resetEventInfo();
      this.props.resetRunlistES();
      this.loadData();
    }

    if (this.props.runListES.hits && !prevProps.runListES.hits) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.props.resetEventInfo();
  }

  loadData = () => {
    const { params, listingStatusIds, unauthorizedEventIds } = this.props;
    const { eventId } = params;
    const mpId = getConfig('marketplaceId');

    this.setState({ loading: true }, () => {
      this.props.loadEventInfo(mpId, eventId);
      this.props.loadRunListES(
        listingStatusIds,
        null,
        unauthorizedEventIds,
        eventId
      );
    });
  };

  handleWatchlistClick = (itemId, isInWatchlist) => {
    const mpId = getConfig('marketplaceId');
    const watchlistAction = isInWatchlist
      ? this.props.loadRemoveWatchList
      : this.props.loadAddWatchList;

    const message = isInWatchlist
      ? 'Removed from your watchlist'
      : 'Added to your watchlist';

    watchlistAction(mpId, itemId).then(({ response }) => {
      if (response.wsStatus === 'Success') {
        this.props.loadWatchlistObject(mpId);
        toastr.success('Success', message, { timeOut: 2000 });
      }
    });
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item._id || item.id || item.itemId)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  makeActionButtons = (data) => {
    return {
      Header: 'Actions',
      id: 'buttons',
      headerStyle: { background: 'none' },
      sortable: false,
      filterable: false,
      Cell: row => {
        const itemId = row.original.itemId || row.original.id;
        const isInWatchlist = this.props.watchlistObject[itemId];

        return (
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              onClick={() => this.handleDetailsClick(itemId, data)}
            >
              View Details
            </Button>
            <Button
              style={styles.button}
              onClick={() =>
                this.handleWatchlistClick(itemId, isInWatchlist)
              }
            >
              <Icon name={isInWatchlist ? `check` : `add`} />
              {`Watchlist`}
            </Button>
          </div>
        );
      },
    }
  }

  makeColumns(data) {
    const hideQRCode = (this.props.marketplaceFeatures.features || '')
      .split(',')
      .includes('534');

    const columns = this.props.makeColumns(
      [
        'checkbox',
        'image',
        this.makeActionButtons(data),
        'vin',
        'year',
        'make',
        'model',
        'stockNumber',
        'mileage',
        'extColor',
        'engine',
        'transmission',
        'grade',
        'sellerName',
        'lotNumber',
        hideQRCode ? null : 'qrCode',
      ].filter(item => item)
    );

    return columns;
  }

  render() {
    const { runListES, eventInfo, selectedData } = this.props;
    const { loading } = this.state;

    const data = runListES.hits?.hits;
    const noResults = !loading && (!data || !data.length);
    const title = eventInfo.eventInfo
      ? `Run List - ${eventInfo.eventInfo.name}`
      : `Run List Event`;

    return (
      <div
        className="st-container shadow padding background-white padding"
        style={styles.container}
      >
        <div style={styles.title}>
          <h4>{title}</h4>
        </div>

        {loading === null ? null : loading ? (
          <div style={styles.message}>Loading...</div>
        ) : noResults ? (
          <div style={styles.message}>
            There are currently no items in this event
          </div>
        ) : (
          <DataTable
            loading={loading}
            title={title}
            data={data.map(item => item._source)}
            selectedData={selectedData}
            columns={this.makeColumns(data)}
            showCSVButton={true}
            showPrintButton={true}
            showWatchlistButton={true}
          />
        )}
      </div>
    );
  }
}

RunListEvent.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  loadEventInfo: PropTypes.func.isRequired,
  loadRunListES: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    eventInfo,
    listingStatusIds,
    runListES,
    unauthorizedEventIds,
    watchlistObject,
    watchlistUserItems,
  } = state.entities;
  return {
    eventInfo,
    listingStatusIds,
    runListES,
    unauthorizedEventIds,
    watchlistObject,
    watchlistUserItems,
  };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadAddWatchList,
    loadEventInfo,
    loadRemoveWatchList,
    loadRunListES,
    loadWatchlistObject,
    resetEventInfo,
    resetRunlistES,
  })
)(RunListEvent);
