import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import DocumentTitle from 'react-document-title';
import { toastr } from 'react-redux-toastr';
import QRCode from 'qrcode.react';
import { find, get } from 'lodash';
import IASConnectModal from './IASConnectModal';
import { loadMarketplaces, setCustomerPlatformLink, setTemplate } from '../actions';
import {
  checkLink,
  getConfig,
  getTemplate,
  isJSON,
  makeAssetLink,
  makeCompanyCopyright,
  makeTemplate,
} from '../utils/helpers';
import { externalAssetNames, urlsMap } from '../utils/constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sidebarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 0 500px',
    height: '100vh',
    overflowY: 'auto',
    padding: 35,
    backgroundColor: '#fff',
  },
  logo: {
    display: 'block',
    maxWidth: 400,
    maxHeight: 200,
    margin: '0px auto',
  },
  backgroundContainer: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    flex: '3 1 75%',
    height: '100vh',
  },
  link: {
    color: '#4183c4',
  },
};

class Login extends Component {
  state = {
    iasConnectUrl: '',
    iasConnectModalOpen: false,
  };

  componentDidMount() {
    toastr.clean();

    window.addEventListener('message', this.handleMessage);

    const mpId = getConfig('marketplaceId');
    if (mpId === '0') {
      this.props.navigate('/default');
    } else {
      this.loadPlatformLink();
      this.loadMarketplaceFeatures();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  handleMessage = e => {
    if (isJSON(e.data)) {
      const postMessage = JSON.parse(e.data);
      if (postMessage.channel === 'ias-connect') {
        if (postMessage.redirectURI) {
          window.location = postMessage.redirectURI;
        }
        this.setState({ iasConnectModalOpen: false, iasConnectUrl: '' });
      }
    }
  };

  loadGAID = marketplaceList => {
    const mpId = getConfig('marketplaceId');
    const parentMarketplace = find(
      marketplaceList,
      mp => mp.marketplaceId == mpId
    );
    const gaId = get(parentMarketplace, 'gaId') || getConfig('gaId');
    if (gaId) this.props.cookies.set('gaId', gaId, { path: '/' });
  };

  /** Load Platform TOU
   * @async
   * @description Sets Redux `state.entities.customerPlatformLink`. Checks
   *  if `platform_terms_of_use.pdf` exists in customer s3 bucket. 
   * * Defaults to: `urlsMap['PLATFORM_TERMS_OF_USE']`
   * @returns {void}
   */
  loadPlatformLink = async () => {
    if (!this.props.customerPlatformLink) {
      try {
        const platformTermsUrl = makeAssetLink(externalAssetNames.PLATFORM_TERMS_OF_USE);
        const customerTermsExists = await checkLink(platformTermsUrl);
        const customerPlatformLink = customerTermsExists 
          ? platformTermsUrl
          : urlsMap.get('PLATFORM_TERMS_OF_USE');
        this.props.setCustomerPlatformLink(customerPlatformLink);
      } catch (setPlatLinkErr) {
        console.error(setPlatLinkErr)
      }
    }
  }

  loadTemplate = marketplaceList => {
    // also happens in TopNav
    const mpId = getConfig('marketplaceId');
    const parentMarketplace = find(
      marketplaceList,
      mp => mp.marketplaceId == mpId
    );
    const cssConfig = get(parentMarketplace, 'cssConfig') || {};
    const template = makeTemplate(cssConfig);
    this.props.setTemplate(template); // puts it in the store
  };

  loadMarketplaceFeatures() {
    // we call marketplace-list api not the get-marketplace-features api
    // because we don't have an api key at this point
    const mpId = getConfig('marketplaceId');
    this.props
      .loadMarketplaces(mpId)
      .then(({ response }) => {
        if (response.wsStatus === 'Success') {
          const { marketplaceList } = response;
          this.loadTemplate(marketplaceList);
          this.loadGAID(marketplaceList);
        }
      })
      .catch(error => console.error(error));
  }

  handleLogin = e => {
    if (e) e.preventDefault();
    const iasConnectRoot = getConfig('iasConnectRoot');
    const iasConnectUrl = encodeURI(
      `${iasConnectRoot}/account/login` +
        `?mpId=${getConfig('marketplaceId')}` +
        `&mobile=false` +
        `&redirect_uri=${window.location.origin}`
    );
    this.setState({ iasConnectModalOpen: true, iasConnectUrl });
  };

  handleRegistration = e => {
    if (e) e.preventDefault();
    const iasConnectRoot = getConfig('iasConnectRoot');
    const iasConnectUrl = encodeURI(
      `${iasConnectRoot}/register` +
        `?mpId=${getConfig('marketplaceId')}` +
        `&mobile=false` +
        `&redirect_uri=${window.location.origin}` +
        `&register=true`
    );
    this.setState({ iasConnectModalOpen: true, iasConnectUrl });
  };

  renderSidebar() {
    const backgroundColor =
      getTemplate(this.props.template, 'signInButton.backgroundColor') ||
      '#6D6D6D';
    const color =
      getTemplate(this.props.template, 'signInButton.color') || '#FFF';
    const isQA = getConfig('apiRoot').includes('-qa');
    const isAppLinks = getConfig('appIOS') || getConfig('appAndroid');
    return (
      <div 
        className='marketplace-login__sidebar-container'
        // style={styles.sidebarContainer}
      >
        <img 
          alt="" 
          className='marketplace-login__sidebar-logo'
          src={makeAssetLink('logo.png')} 
          // style={styles.logo} 
        />
        <hr style={{ width: '100%', marginTop: 20, marginBottom: 20 }} />

        <button
          className="btn btn-block"
          style={{ backgroundColor, color, maxWidth: 200 }}
          onClick={this.handleLogin}
        >
          {isQA ? 'SIGN IN' : 'Sign In'}
        </button>
        <button
          className="btn btn-block"
          style={{ backgroundColor, color, maxWidth: 200, marginTop: 20 }}
          onClick={this.handleRegistration}
        >
          {isQA ? 'REGISTER' : 'Register'}
        </button>

        <hr style={{ width: '100%', marginTop: 20, marginBottom: 20 }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ marginBottom: 5 }}>A Superior Marketplace</div>
          <div>{makeCompanyCopyright()}</div>
          <div>
            <a
              className='marketplace-login__link'
              href={this.props.customerPlatformLink}
              target="_blank"
              rel="noreferrer"
              >
              Platform Terms of Use
            </a>
            {` and `}
            <a
              className='marketplace-login__link'
              href={urlsMap.get('PLATFORM_PRIVACY_POLICY')}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
        {isAppLinks && (
          this.renderLinksToApps()
        )}
      </div>
    );
  }
  
  renderBackgroundImage() {
    const image = makeAssetLink('bg.jpg');
    
    return (
      <div
        className='marketplace-login__bg_container'
        style={{
          backgroundImage: `url(${image})`,
          // ...styles.backgroundContainer,
        }}
      />
    );
  }
  /*  */
  renderLinksToApps() {
    const iosAppUrl = getConfig('appIOS');
    const androidAppUrl = getConfig('appAndroid');
    
    return (
      <div 
        className='marketplace-login__apps-container'
      >
        <hr style={{ width: '100%', marginTop: 10, marginBottom: 5 }} />
        <h3 
          style={{
            margin: '0', 
            paddingBottom: '5px'
          }} 
          >
            {"Download our App!"}
        </h3>
        <div
          className='marketplace-login__apps-wrapper'
        >
          {iosAppUrl && 
            this.renderAppQRCode('iOS:  ', iosAppUrl)
          }
          {androidAppUrl && 
            this.renderAppQRCode('Android:  ', androidAppUrl)
          }
        </div>
      </div>
    )
  }

  /**
   * @param {string} heading
   * @param {string} appUrl
   * @returns {React.ReactNode}
   */
  renderAppQRCode(heading, appUrl){
    return (
      <div
        className='marketplace-login__apps-qr_wrapper'
      >
      <h4>{heading}</h4>
        <QRCode 
          style={{margin: '0 auto .5em'}} 
          value={appUrl} 
          size={80} 
        />
      </div>
    )
  }

  render() {
    const title = getConfig('name') ? `${getConfig('name')} | Login` : 'Login';

    return (
      <div>
        <IASConnectModal
          isOpen={this.state.iasConnectModalOpen}
          onClose={() => this.setState({ iasConnectModalOpen: false })}
          url={this.state.iasConnectUrl}
        />
        <DocumentTitle title={title}>
          <div 
            // style={styles.container}
            className='marketplace-login__container'
          >
            <ReduxToastr
              timeOut={4000}
              newestOnTop={true}
              position="top-right"
            />
            {this.renderSidebar()}
            {this.renderBackgroundImage()}
          </div>
        </DocumentTitle>
      </div>
    );
  }
}

Login.propTypes = {
  loadMarketplaces: PropTypes.func.isRequired,
  setTemplate: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { customerPlatformLink, template } = state.entities;
  return { customerPlatformLink, template };
};

export default connect(mapStateToProps, {
  loadMarketplaces,
  setCustomerPlatformLink,
  setTemplate,
})(Login);
