import React from "react";
import { getConfig } from "../../utils/helpers";
import { useSelector } from "react-redux";
/**
 * 
 * @param {{comments: Array<{
 *  attachmentFileName: string,
 *  attachmentHash: string,
 *  attachmentUrl: string,
 *  comment: string,
 *  dateCreated: string,
 *  userRole: 'Buyer'|'Seller'|'Facilitator', 
 *  userName: string}>}} props 
 * 
*/
export function ArbitrationModalCommentHistory ({comments=[]}) {
    const apiKey = useSelector(reduxState => reduxState.entities.userProfile?.apiKey)
    const apiRoot = getConfig('apiRoot');
   return (
    <div style={{width: '100%', flexDirection: 'column', borderRadius: '5px', padding: 5, maxHeight: '350px'}}>
        <h4 style={{width: '100%', textAlign: 'center'}} >Claim History</h4>
        <div className="mp-arb-comment_history-wrapper" >
            {!(comments && comments.length) 
                ? <p style={{textAlign: 'center', padding: 10}}>No Comment History</p>
                : comments.map((com, i) => {
                    const comment = com.attachmentFileName 
                        ? `${com.comment} \n\n Attachment: ${com.attachmentFileName}`
                        : com.comment;
                    return (
                        <div key={i} style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', padding: '0 5px'}}>
                            <div style={{display: 'flex', alignItems: 'center', verticalAlign: 'middle'}}>
                                <strong style={{fontSize: '.85em'}}>
                                    {`${com.userRole} - `}  
                                </strong>
                                <p style={{margin: '0px 5px', }} >{com.userName}</p>
                                <code style={{backgroundColor: 'unset', fontSize: '0.70em', }}>
                                    {`${new Date(com.dateCreated).toUTCString()}`}
                                </code>
                            </div>
                            <pre style={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(211,243,231,0.05)',
                                display: 'flex',
                                fontFamily: 'inherit',
                                fontStyle: 'italic',
                                fontWeight: 'lighter',
                                justifyContent: 'space-between', 
                                letterSpacing: 0.5,
                                width: '100%', 
                            }}>
                                <p>{com.comment}</p>
                                 {com.attachmentFileName 
                                    ? (
                                        <a 
                                            href={`${apiRoot}${com.attachmentUrl}?apiKey=${apiKey}`}
                                            // target="_blank"
                                            // rel="noreferrer"
                                            style={{borderLeft: '2px solid #787878', height: '100%',color: '#4183c4', fontSize: 14,  margin: '0px 5px'}}
                                            title="Download attachment"
                                        >
                                            <i style={{ padding: '0 5px 0 10px', color: '#787878'}} title={`Download '${com.attachmentFileName}'`} className="fa fa-download"/>
                                        </a>
                                        )
                                    : null
                                }
                            </pre>
                        </div>
                    )
                })
            }
        </div>

    </div>
   )
}