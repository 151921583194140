import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { Button, Icon } from 'semantic-ui-react';
import {
  loadAddWatchList,
  loadEvents,
  loadNoSalesFromEventsES,
  loadRemoveWatchList,
  loadWatchlistObject,
} from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';

import $ from 'jquery';
window.$ = window.jQuery = $;

class RunListNoSales extends Component {
  state = {
    itemList: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.loadNoSales();
  };

  loadNoSales = () => {
    const mpId = getConfig('marketplaceId');
    this.props
      .loadEvents(mpId)
      .then(({ response }) => {
        if (response) {
          const eventIds = response.eventList
            .filter(event => {
              return (
                // must be Live Auction Event  AND marketplace must not have no-sales disabled
                event.eventTypeId === 3 &&
                !(event.marketplace.marketplaceFeaturesArray || []).includes(
                  '106'
                )
              );
            })
            .map(event => event.eventId);

          this.loadNoSalesFromEvents(eventIds);
        } else {
          this.setState({
            itemList: [],
          });
        }
      })
      .catch(error => console.error(error));
  };

  loadNoSalesFromEvents = eventIds => {
    this.props
      .loadNoSalesFromEventsES(eventIds, null, this.props.unauthorizedEventIds)
      .then(({ response }) => {
        if (response) {
          this.setState({
            itemList: response.hits.hits,
          });
        }
      })
      .catch(err => console.error(err));
  };

  handleWatchlistClick = (itemId, isInWatchlist) => {
    const mpId = getConfig('marketplaceId');
    const watchlistAction = isInWatchlist
      ? this.props.loadRemoveWatchList
      : this.props.loadAddWatchList;

    const message = isInWatchlist
      ? 'Removed from your watchlist'
      : 'Added to your watchlist';

    watchlistAction(mpId, itemId).then(({ response }) => {
      if (response.wsStatus === 'Success') {
        this.props.loadWatchlistObject(mpId);
        toastr.success('Success', message, { timeOut: 2000 });
      }
    });
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item._id || item.id || item.itemId)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  makeActionButtons = (data) => {
    return {
      Header: 'Actions',
      id: 'buttons',
      headerStyle: { background: 'none' },
      sortable: false,
      filterable: false,
      Cell: row => {
        const itemId = row.original.itemId || row.original.id;
        const isInWatchlist = this.props.watchlistObject[itemId];

        return (
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              onClick={() => this.handleDetailsClick(itemId, data)}
            >
              View Details
            </Button>
            <Button
              style={styles.button}
              onClick={() =>
                this.handleWatchlistClick(itemId, isInWatchlist)
              }
            >
              <Icon name={isInWatchlist ? `check` : `add`} />
              {`Watchlist`}
            </Button>
          </div>
        );
      },
    }
  }

  makeColumns(data) {
    const hideQRCode = (this.props.marketplaceFeatures.features || '')
      .split(',')
      .includes('534');
    const columns = this.props.makeColumns(
      [
        'checkbox',
        'image',
        this.makeActionButtons(data),
        'vin',
        'year',
        'make',
        'model',
        'stockNumber',
        'mileage',
        'extColor',
        'engine',
        'transmission',
        'grade',
        'sellerName',
        'lotNumber',
        hideQRCode ? null : 'qrCode',
      ].filter(item => item)
    );

    return columns;
  }

  render() {
    const data = this.state.itemList;
    const isLoading = !data;
    const noResults = data && !data.length;
    const title = `Run List - No Sales`;
    const selectedData = this.props.selectedData;

    return (
      <div
        className="st-container shadow padding background-white padding"
        style={styles.container}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 style={styles.title}>{title}</h4>
        </div>

        {isLoading ? (
          <div style={styles.message}>Loading...</div>
        ) : noResults ? (
          <div style={styles.message}>There are currently no items</div>
        ) : (
          <DataTable
            title={title}
            data={data.map(item => item._source)}
            selectedData={selectedData}
            columns={this.makeColumns(data)}
            showCSVButton={true}
            showPrintButton={true}
            showWatchlistButton={true}
          />
        )}
      </div>
    );
  }
}

RunListNoSales.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  loadEvents: PropTypes.func.isRequired,
  loadNoSalesFromEventsES: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    eventInfo,
    unauthorizedEventIds,
    watchlistUserItems,
    watchlistObject,
    marketplaceFeatures,
  } = state.entities;
  return {
    eventInfo,
    marketplaceFeatures,
    unauthorizedEventIds,
    watchlistObject,
    watchlistUserItems,
  };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadAddWatchList,
    loadEvents,
    loadNoSalesFromEventsES,
    loadRemoveWatchList,
    loadWatchlistObject,
  })
)(RunListNoSales);
