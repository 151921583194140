import React, { Component } from 'react';
import { getConfig } from '../utils/helpers';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { values } from 'lodash';
import { Link } from 'react-router-dom';

const timezone = jstz.determine().name();

class NotificationItem extends Component {
  state = {
    userId: '',
    notificationIds: [],
  };

  loadNotifications = () => {
    this.props.loadNotifications(getConfig('marketplaceId'));
  };

  markAsRead = event => {
    var notificationId = event.currentTarget.getAttribute('data-id');
    // TODO: - make action to pass thru middleware
    const url =
      `${getConfig('apiRoot')}` +
      `notification-read` +
      `?userid=${this.props.notificationItems[0].receiverUserId}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&apiKey=${this.props.apiKey}`;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        { notificationId: notificationId, markAsRead: true },
      ]),
    })
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => this.loadNotifications(getConfig('marketplaceId')));
  };

  markAllRead = () => {
    let notificationsArray = [];
    this.props.notificationItems.forEach(function(item) {
      notificationsArray.push({
        notificationId: item.notificationId,
        markAsRead: true,
      });
    });

    const url =
      `${getConfig('apiRoot')}` +
      `notification-read` +
      `?userid=${this.props.notificationItems[0].receiverUserId}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&apiKey=${this.props.apiKey}`;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(notificationsArray),
    })
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => this.loadNotifications(getConfig('marketplaceId')));
  };

  renderNotificationItems() {
    let notificationItems = this.props.notificationItems;

    if (notificationItems.length < 1) {
      return (
        <div className="notificationItem unread">
          <div className="notificationItemSubject clearfix">
            <div className="col-xs-12 no-padding">
              <p>
                There are currently no messages. Subscribe by going to your{' '}
                <Link to="/profile">profile</Link>.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      const items = values(notificationItems).map((item, index) => {
        const timeDifference = moment
          .tz(`${item.dateCreated}`, timezone)
          .fromNow();

        let notificationTypeId;

        if (item.notificationTypeId === 1001) {
          notificationTypeId = 'New Vehicle';
        } else if (item.notificationTypeId === 1002) {
          notificationTypeId = 'Wishlist';
        } else if (item.notificationTypeId === 1003) {
          notificationTypeId = 'Re-list';
        } else if (item.notificationTypeId === 1004) {
          notificationTypeId = 'Offers or bids received';
        } else if (item.notificationTypeId === 1005) {
          notificationTypeId = 'Offers or bids made';
        } else if (item.notificationTypeId === 1006) {
          notificationTypeId = 'Outbid';
        } else if (item.notificationTypeId === 1007) {
          notificationTypeId = 'Sales confirmation';
        } else if (item.notificationTypeId === 1008) {
          notificationTypeId = 'Purchase confirmation ';
        } else if (item.notificationTypeId === 1009) {
          notificationTypeId = 'If sale';
        } else if (item.notificationTypeId === 1010) {
          notificationTypeId = 'End of listing';
        } else if (item.notificationTypeId === 1011) {
          notificationTypeId = 'Price drop';
        } else if (item.notificationTypeId === 1012) {
          notificationTypeId = 'Event start';
        } else if (item.notificationTypeId === 1013) {
          notificationTypeId = 'Event end';
        } else if (item.notificationTypeId === 1014) {
          notificationTypeId = 'Condition report change';
        } else if (item.notificationTypeId === 1015) {
          notificationTypeId = 'Purchase recommendation';
        } else if (item.notificationTypeId === 1016) {
          notificationTypeId = 'Live auction watch list';
        } else if (item.notificationTypeId === 1017) {
          notificationTypeId = 'Vehicles ready for release';
        } else if (item.notificationTypeId === 1018) {
          notificationTypeId = 'Negotiate price';
        } else if (item.notificationTypeId === 1019) {
          notificationTypeId = 'Negotiate price';
        } else if (item.notificationTypeId === 1020) {
          notificationTypeId = 'Transport requested';
        } else if (item.notificationTypeId === 1021) {
          notificationTypeId = 'Transport scheduled';
        } else if (item.notificationTypeId === 1022) {
          notificationTypeId = 'Item delivered';
        } else if (item.notificationTypeId === 1023) {
          notificationTypeId = 'Item accepted';
        } else if (item.notificationTypeId === 1024) {
          notificationTypeId = 'Mediation';
        } else if (item.notificationTypeId === 1025) {
          notificationTypeId = 'Payment status update';
        } else if (item.notificationTypeId === 1026) {
          notificationTypeId = 'Title status update';
        } else if (item.notificationTypeId === 1027) {
          notificationTypeId = 'Unwind sale';
        } else if (item.notificationTypeId === 1028) {
          notificationTypeId = 'Received Bid';
        } else if (item.notificationTypeId === 1029) {
          notificationTypeId = 'Placed Bid';
        } else if (item.notificationTypeId === 1040) {
          notificationTypeId = 'Saved Searches';
        } else if (item.notificationTypeId === 2001) {
          notificationTypeId = 'New market vehicle';
        } else if (item.notificationTypeId === 2002) {
          notificationTypeId = 'Wish list matches';
        } else if (item.notificationTypeId === 2003) {
          notificationTypeId = 'Re-list report';
        } else if (item.notificationTypeId === 2004) {
          notificationTypeId = 'Offers or bids received';
        } else if (item.notificationTypeId === 2005) {
          notificationTypeId = 'Offers or bids made';
        } else if (item.notificationTypeId === 2007) {
          notificationTypeId = 'Sales report';
        } else if (item.notificationTypeId === 2008) {
          notificationTypeId = 'Purchase report';
        } else if (item.notificationTypeId === 2009) {
          notificationTypeId = 'If sale report';
        } else if (item.notificationTypeId === 2010) {
          notificationTypeId = 'End of Listing report';
        } else if (item.notificationTypeId === 2011) {
          notificationTypeId = 'Price drop';
        } else if (item.notificationTypeId === 2015) {
          notificationTypeId = 'Purchase recommendation';
        }

        if (item.notificationTypeId !== 1030 && item.markAsRead === false) {
          return (
            <div
              className="notificationDataId"
              data-id={item.notificationId}
              key={index}
            >
              <div className="notificationItem unread">
                <div className="notificationItemSubject clearfix">
                  <div className="col-xs-1" style={{ paddingTop: '2px' }}>
                    <span
                      className="fas fa-circle"
                      style={{ color: 'rgb(92, 190, 127)' }}
                    >
                      {' '}
                    </span>
                  </div>
                  <div className="col-xs-11">
                    <div>
                      <span>
                        <div
                          onClick={event => {
                            const url = `${getConfig(
                              'apiRoot'
                            )}notification-read?userid=${
                              this.props.notificationItems[0].receiverUserId
                            }&mpId=${getConfig('marketplaceId')}&apiKey=${
                              this.props.apiKey
                            }`;

                            fetch(url, {
                              method: 'POST',
                              headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify([
                                {
                                  notificationId: item.notificationId,
                                  markAsRead: true,
                                },
                              ]),
                            })
                              .then(res => res.json())
                              .catch(error => console.error('Error:', error))
                              .then(response =>
                                this.loadNotifications(
                                  getConfig('marketplaceId')
                                )
                              );
                          }}
                          className="notification-type"
                        >
                          {notificationTypeId}
                        </div>
                      </span>
                      <span
                        style={{
                          color: 'gray',
                          fontSize: '11px',
                          margin: '0 0 0 9.5em',
                        }}
                      >
                        {timeDifference}
                      </span>
                    </div>
                    <div style={{ color: 'gray' }}>{item.subject}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="notificationWithoutDataId" key={index}>
              <div className="notificationItem read">
                <div className="notificationItemSubject clearfix">
                  <div className="col-xs-1" style={{ paddingTop: '2px' }}>
                    <span
                      className="far fa-circle"
                      style={{ color: 'rgb(92, 190, 127)' }}
                    >
                      {' '}
                    </span>
                  </div>
                  <div className="col-xs-11">
                    <div>
                      <span>
                        <Link to="/notifications" className="notification-type">
                          {notificationTypeId}
                        </Link>
                      </span>
                      <span
                        style={{
                          color: 'gray',
                          fontSize: '11px',
                          margin: '0 0 0 9.5em',
                        }}
                      >
                        {timeDifference}
                      </span>{' '}
                    </div>
                    <div style={{ color: 'gray' }}>{item.subject}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });

      return items;
    }
  }

  render() {
    return (
      <div className="dropdown-menu dropdown-menu-right notificationsDDMenu">
        <div className="notifications-panel panel panel-primary">
          <div className="panel-heading">
            <h3 className="panel-title text-uppercase">
              <span className="panel-title-text">Recent Notifications</span>
              <Link to="/profile/alerts">
                <span
                  className="fas fa-cog"
                  style={{ float: 'right', color: 'white' }}
                >
                  {' '}
                </span>
              </Link>
            </h3>
          </div>
          <div className="panel-body notificationItems">
            {this.renderNotificationItems()}
          </div>
          <div className="panel-footer no-padding">
            <div className="col-xs-6 no-padding">
              <Link
                to="#"
                type="button"
                className="btn btn-default btn-block text-uppercase markAllReadBtn"
                onClick={this.markAllRead}
              >
                Clear List
              </Link>
            </div>
            <div className="col-xs-6 no-padding">
              <Link
                to="/notifications"
                type="button"
                className="btn btn-default btn-block text-uppercase viewAllBtn"
              >
                All Notifications
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationItem;
