import React from "react";

/**
 * 
 * @param {{arbitrationStatus: string, handleClose: ()=>void}} props 
 * @returns 
 */
export function ArbitrationModalHeader ({arbitrationStatus, handleClose}) {
    return (
        <div className='marketplace-reusable__modal-header' style={{ alignSelf: 'stretch' }}>
            {/* Form Title */}
            <div style={{ fontSize: 24, color: '#535768' }}>
                <div>
                    <span className="fa fa-handshake" />
                    <span style={{ marginLeft: 10 }}>
                        Arbitration
                    </span>
                    <span style={{fontSize: '.5em', paddingLeft: '25px', width: '100%'}}>{`Status: ${arbitrationStatus}`}</span>
                </div>
            </div>
            {/* Close Modal */}
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleClose()}
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
    );
}