import { FETCH_API } from "../../middleware";

// NOTE: the below actions are not used
//      - config is currently fetched during PersistStore initial load

/* * * * Fetch Marketplace config via MPconfig API * * * */
export const MARKETPLACE_CONFIG_REQUEST = 'MARKETPLACE_CONFIG_REQUEST';
export const MARKETPLACE_CONFIG_SUCCESS = 'MARKETPLACE_CONFIG_SUCCESS';
export const MARKETPLACE_CONFIG_FAILURE = 'MARKETPLACE_CONFIG_FAILURE';

const fetchMarketplaceConfiguration = ({devEndpoint}) => {
  return {
    [FETCH_API]: {
      types: [MARKETPLACE_CONFIG_REQUEST, MARKETPLACE_CONFIG_SUCCESS, MARKETPLACE_CONFIG_FAILURE],
      devEndpoint,
      endpoint: `/api/configuration`,
      method: 'GET',
    },
  };
};

/** 
 * 
 * @param {string|undefined} devEndpoint optional fully-qualified url when running locally
 * @returns 
 */
export const loadMarketplaceConfiguration = (devEndpoint='') => {
  return dispatch => {
    return dispatch(fetchMarketplaceConfiguration({devEndpoint}));
  };
};

/* * * * Set Marketplace config state * * * */
export const MARKETPLACE_CONFIG_SET = 'MARKETPLACE_CONFIG_SET';

/**
 * 
 * @param {*} mpConfiguration 
 * @returns 
 */
export const loadSetMarketplaceConfig = (mpConfiguration={}) => {
  return {
    response: mpConfiguration,
    type: MARKETPLACE_CONFIG_SET,
  }
}