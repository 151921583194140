import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { Button } from 'semantic-ui-react';
import { loadSellerSold, resetSellerArbitration, resetSellerSold } from '../../actions';
import { getConfig, compose } from '../../utils/helpers';
import styles from './styles';
import { ArbitrationModal } from '../arbitration';

class SellerSold extends Component {
  state = {
    title: 'sold',
    isArbitrationModalOpen: false,
    itemSelectedForArbitration: {
      arbitrationStatus: '', 
      enableRequestArbitration: 0, 
      enableAddArbitrationComment: 0, 
      enableCancelArbitration: 0,
      itemId: '', 
      rowIndex: null,
    },
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetSellerSold();
    this.props.loadSellerSold(getConfig('marketplaceId'));
  };

  handleCloseArbitrationModal = () => {
    this.props.loadSellerSold(getConfig('marketplaceId'));
    this.setState({isArbitrationModalOpen: false});
  }

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  onArbitrationClick = (e) => {
    e.preventDefault();
    const rowIndex = Number(e.target.dataset.vehicleIndex);
    const rowData = this.props.sellerSold.data[rowIndex];
    const {
      arbitrationStatus, 
      enableRequestArbitration, 
      enableAddArbitrationComment, 
      enableCancelArbitration,
      itemId, 
    } = rowData;
    this.setState({
      itemSelectedForArbitration: {
        arbitrationStatus, 
        enableRequestArbitration, 
        enableAddArbitrationComment, 
        enableCancelArbitration,
        itemId, 
        rowIndex,
      }},() => {
        this.setState({isArbitrationModalOpen: true})
      })
  }

  renderArbitrationModal = ({
    arbitrationStatus,
    itemId, 
    enableAddArbitrationComment, 
    enableCancelArbitration, 
    enableRequestArbitration
  }) => {

    return (
      <ArbitrationModal
        arbType={'seller'}
        arbitrationStatus={arbitrationStatus}
        enableRequestArbitration={enableRequestArbitration || 0}
        enableAddArbitrationComment={enableAddArbitrationComment}
        enableCancelArbitration={enableCancelArbitration || 0}
        isOpen={this.state.isArbitrationModalOpen}
        itemId={itemId}
        onClose={this.handleCloseArbitrationModal}
        onOpen={()=>null}
      />
    )
  }

  makeActionButtons = (data) => {
    return {
      Header: 'Actions',
      headerStyle: { background: 'none' },
      id: 'buttons',
      sortable: false,
      filterable: false,
      Cell: row => {
        const {enableRequestArbitration, enableCancelArbitration, enableAddArbitrationComment} = row.original;
        const showArbitrationButton = 
          (getConfig('enableBuyerSellerArbitration') === true)
          && Boolean(enableRequestArbitration || enableCancelArbitration || enableAddArbitrationComment);
        return (
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              onClick={() =>
                this.handleDetailsClick(row.original.itemId, data)
              }
            >
              View Details
            </Button>
            
            {row.original.sellerReceipt && (
              <a
                style={styles.button}
                href={getConfig('apiRoot') + row.original.sellerReceipt}
              >
                Receipt
              </a>
            )}

            {showArbitrationButton ? (
              <Button 
                style={{
                  ...styles.button,
                  backgroundColor: enableAddArbitrationComment ? '#ff9521' : styles.button.backgroundColor,
                }} 
                onClick={this.onArbitrationClick}
                data-vehicle-index={row.index}
                title={"Edit, view, or new Arbitration"}
              >
                Arbitration
              </Button>
              ) : null
            }
          </div>
        );
      },
    }
  }

  render() {
    const loading = isEmpty(this.props.sellerSold);
    const data = this.props.sellerSold.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      this.makeActionButtons(data),
      'vin',
      'year',
      'make',
      'model',
      'amount',
      'sellFee',
      'seller',
      'buyer',
      'soldDate',
      'paymentStatus',
      // 'arbitrationStatus', // 2024.10.07 - disabling column temporarily
      'titleStatus',
      'lotNumber',
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        {this.renderArbitrationModal({...this.state.itemSelectedForArbitration})}
        <h4>Sold Vehicles</h4>
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { sellerSold } = state.entities;
  return { sellerSold };
};

export default compose(
  withTable,
  connect(mapStateToProps, { loadSellerSold, resetSellerArbitration, resetSellerSold })
)(SellerSold);
