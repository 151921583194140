import React from 'react';
import { useSelector } from "react-redux";
import runbuggyImg from '../../assets/images/runbuggy.png';
import acertusImg from '../../assets/images/acertus.png';
import royaltyImg from '../../assets/images/royalty.png';
import { TransporationModalMode } from './TransporationConstants';

export default function TransportationProviderSelection({ onSelect, itemData }) {

  const styles = {
    transporterProviderLogo: {
      height: 40,
      border: '1px solid #ccc',
      borderRadius: 3,
      cursor: 'pointer',
      backgroundColor: '#fff',
      margin: 5,
      padding: 5
    }
  }

  const marketplaceFeatures = useSelector((state) => state.entities.marketplaceFeatures);

  function getShippingStatusText(shippingStatus) {
    switch (shippingStatus) {
      case 2:
        return 'Vehicle has been shipped'
      case 3:
        return 'Your transportation order has been accepted'
      case 4:
        return 'Vehicle was returned'
      case 5:
        return 'Your transportation order has been requested'
      case 6:
        return 'Your transportation order has been rejected'
      case 7:
        return 'Vehicle was shipped to auction'
      case 8:
        return 'Vehicle was delivered'
      case 9:
        return 'Vehicle is at auction'
      case 10:
        return 'Vehicle delivery is scheduled'
      case 11:
        return 'Your transportation order has been cancelled'
      case 12:
        return 'Your transportation order is pending'
      case 13:
        return 'Your transportation order has been booked'
      case 14:
        return 'Vehicle has been picked up'
      case 15:
        return 'Vehicle is in transit'
      case 16:
        return 'Your transportation order has been closed'
      default:
        return 'Shipping not available for this vehicle'
    }
  }

  const features = (marketplaceFeatures.features || '').split(',');

  return (

    <div style={{ paddingLeft: 10 }}>
      {itemData.shippingDetails?.shippingStatusId === 1 ?
        <>
          <label style={{ paddingBottom: 2.5 }}>
            Select a provider below to get a quote and ship your vehicle
          </label>
          <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap', gap: 15, justifyContent: 'center' }}>
            {features.includes('600') &&
            <img
              alt="acertus"
              src={acertusImg}
              style={styles.transporterProviderLogo}
              onClick={() => onSelect(TransporationModalMode.ACERTUS)}
            />}
            {features.includes('601') &&
            <img
              alt="runbuggy"
              src={runbuggyImg}
              style={{
                ...styles.transporterProviderLogo,
                backgroundColor: "#000000"
              }}
              onClick={() => onSelect(TransporationModalMode.RUNBUGGY)}
            />}
            {features.includes('604') &&
            <img
              alt="royalty"
              src={royaltyImg}
              style={{
                ...styles.transporterProviderLogo
              }}
              onClick={() => onSelect(TransporationModalMode.ROYALTY)}
            />}
          </div>
        </>
        :
        <>
          <label style={{ paddingBottom: 2.5 }}>
            {getShippingStatusText(itemData.shippingDetails?.shippingStatusId ?? 0)}
          </label></>
      }

    </div>

  );
}