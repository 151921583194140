import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import api from '../middleware';
import rootReducer from '../reducers';
import { supressRouterWarning } from '../utils/helpers';
import config from '../config';
import { urlsMap } from '../utils/constants';
import { prepareMpConfig } from '../utils/helpers/mpConfig';

const isDevEnv = process.env.NODE_ENV === 'development';

const findFallbackConfig = (mpConfigHostName) => {
  try {
    const mpConfigFallback = config[mpConfigHostName] || config.default;
    return mpConfigFallback;

  } catch (mpConfigError) {
    return config.default;
  }
}

/* Fetch Marketplace configuration */
const fetchConfig = async () => {
  const mpConfigApiPath = urlsMap.get('MP_CONFIG_API');
  const mpConfigHostName = isDevEnv 
    ? config.devEndpoint
    : window.location.hostname;
  try {
    const configEndpoint = isDevEnv ? `https://${mpConfigHostName}${mpConfigApiPath}` : mpConfigApiPath;
    const fetchInit = {method: 'GET', mode: 'cors'};
    const configRes = await fetch(configEndpoint, fetchInit)
      .then(res => res.json())
      .catch(err => console.error(err?.message || err));
    const preparedMpConfig = prepareMpConfig(configRes?.webConfig);
    return preparedMpConfig || findFallbackConfig(mpConfigHostName);

  } catch (fetchConfigError) {
    return findFallbackConfig(mpConfigHostName);
  }
}
/* 2024.10 Notes:  The Persist Store/Reducer
  - Wrapper for existing redux state/reducers
  - Previously only implements 'core' key,
  - Now persists 'mpConfig' key
*/
export default async function _configureStore(preloadedState) {
  const isEnvDevelopment = process.env.NODE_ENV === 'development';
  const persistConfig = { key: 'root', storage, whitelist: ['core', 'mpConfig'] };
  const mpConfig = await fetchConfig();
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middleware = [
    thunk,
    api,
    loadingBarMiddleware({
      promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }),
  ];

  if (isEnvDevelopment) {
    const logger = createLogger({
      collapsed: true,
      timestamp: false,
      level: { action: 'info', nextState: false, prevState: false },
    });
    middleware.push(logger);
  }

  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: preloadedState 
      ? { ...preloadedState, mpConfig } 
      : { mpConfig },
    devTools: isEnvDevelopment,
    middleware,
  });

  if (isEnvDevelopment && module.hot) {
    supressRouterWarning();
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers/index').default;
      store.replaceReducer(persistReducer(persistConfig, nextReducer));
    });
  }

  return store;
}
