import * as ActionTypes from '../../actions';
import {mpConfigInitialState} from './initialState'

export const mpConfigReducer = (state = mpConfigInitialState, action ) => {
    switch (action.type) {
        case ActionTypes.MARKETPLACE_CONFIG_SET:
            return { ...state, ...action.response}

        case ActionTypes.MARKETPLACE_CONFIG_SUCCESS:
            return { ...state, ...action.response}
     
        default:
            return state;
    }
}