import React from "react";
import { getConfig } from "../../utils/helpers"

/** Content wrapper for Terms agreement for Arbitration modal
 * 
 * @param {{onAgreedToggle:()=>void,termsUrl:string}} props 
 * @returns 
*/
export function ArbitrationModalTerms ({onAgreedToggle, termsUrl}) {
   const termsStyles = {
       linkTextWrapper: { padding: 10, verticalAlign: 'center',  fontSize: '1.15em', fontStyle: 'italic',}
   }
   const renderLink = (linkUrl="", linkText="") => {
       return (
           <a
               href={linkUrl}
               target="_blank"
               rel="noreferrer"
               style={{color: '#4183c4'}}
           >
               {linkText}
           </a>
       )
   }
   
   return (
       <div className='arbitration_modal-terms__wrapper' >
           <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', textAlign: 'center'}}>
               <label style={termsStyles.linkTextWrapper} >
                   You agree to the information outlined in the {' '}
                   {renderLink(termsUrl, `${getConfig('shortName')} Terms and Conditions`)}
               </label>
               <input
                   aria-label={'agree'}
                   defaultChecked={false}
                   onChange={onAgreedToggle}
                   type={'checkbox'}
                   style={{ verticalAlign: 'middle', margin: '0 1.25em', width: '1.25em', height: '1.25em' }}
               />
           </div>
       </div>
   )
}