import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import Modal from 'react-modal';
import { find, get, isEmpty } from 'lodash';
import {
  loadBiddingOn,
  loadBuyerBids,
  loadItemBid,
  loadWatchlistObject,
} from '../actions';
import {
  commafy,
  commafyCurrency,
  getConfig,
  isValidNumber,
} from '../utils/helpers';
import { localization } from '../utils/localization';
import Fees from './common/Fees';
import IDHorizontal from './common/IDHorizontal';
import { confirmAmount } from './common/confirm/CallConfirm';
import { ifSalePurchaseDisclaimer } from '../utils/constants';

const styles = {
  customStyles: {
    _deprecated__content: { /* Moved to 'assets/css/styles.css' */
      top: '47%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
      borderRadius: '8px',
      border: 'none',
      boxShadow:
        '0 10px 15px 3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      width: 650,
      minHeight: 300,
      maxHeight: 800,
    },
    overlay: {
      zIndex: 99,
      backgroundColor: 'rgba(0, 0, 0, .6)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  body: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#E5E5E5',
    width: '100%',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  proxyHardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    marginTop: 10,
  },
  proxyHardShared: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 100,
    border: '1px solid gray',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '0.9em',
  },
  proxyButtonOnly: {
    height: 40,
    width: '18%',
    margin: 'auto',
    marginBottom: 10,
    fontWeight: '700',
    display: 'block',
    fontSize: '0.9em',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: '#787878',
    color: '#ECF0F1',
    border: 'none',
    borderRadius: 3,
    outline: 'none',
  },
  pseudoDropdown: {
    border: '1px solid lightgray',
    borderRadius: 5,
    padding: '6px 16px',
    cursor: 'default',
    color: '#555',
  },
  link: {
    color: '#4183c4',
  },
};

const defaultState = {
  accountList: [],
  selectedAccount: {},
  paymentTypes: [],
  selectedPayment: '',
  isSubmitting: false,
  bidAmount: '',
  bidType: '1',
  agreed: !!getConfig('agreeToTermsAtLogin'),
  hideAccountsWithoutBidderBadges: undefined,
  hideFees: undefined,
  distanceInKM: undefined,
  loaded: false,
};

class BidModal extends Component {
  state = defaultState;

  onOpen = () => {
    this.loadFeatures();
  };

  onClose = () => {
    this.setState(defaultState, () => {
      this.props.onClose();
    });
  };

  loadFeatures = () => {
    const mpFeatures = (this.props.marketplaceFeatures.features || '').split(
      ','
    );
    const eventFeatures = this.props.item?.eventFeatures
      ? this.props.item.eventFeatures.split(',')
      : [];

    this.setState(
      {
        hideAccountsWithoutBidderBadges: mpFeatures.includes('469'),
        hideFees: mpFeatures.includes('548'),
        distanceInKM: mpFeatures.includes('389'),
        isIfSaleListing: eventFeatures.includes('53'),
      },
      () => {
        this.loadAccounts();
      }
    );
  };

  loadAccounts = () => {
    if (this.props.userProfile.user) {
      this.setState(
        prevState => {
          const accountList = (
            this.props.userProfile.user.accountList || []
          ).filter(account => {
            const isPersonalAccount = account.accountNumber.includes('@');
            const missingRequiredBadge =
              this.state.hideAccountsWithoutBidderBadges &&
              account.badgeNumber === null;
            return isPersonalAccount || missingRequiredBadge ? false : true;
          });

          const selectedAccount =
            prevState.selectedAccount && prevState.selectedAccount.accountId
              ? prevState.selectedAccount
              : accountList[0] || {};

          return { accountList, selectedAccount };
        },
        () => {
          this.loadPayments();
        }
      );
    }
  };

  loadPayments = () => {
    const { selectedAccount } = this.state;
    const paymentTypes = (selectedAccount.paymentTypesArray || []).filter(
      type => !['Pay Later', 'PayLater'].includes(type)
    );
    paymentTypes.unshift('Pay Later'); // puts Pay Later in every array as first option

    const selectedPayment =
      paymentTypes.find(
        paymentType => paymentType === selectedAccount.defaultPaymentType
      ) || paymentTypes[0];

    this.setState({ paymentTypes, selectedPayment, loaded: true });
  };

  checkAmount = () => {
    return new Promise(resolve => {
      const bidAmount = Number(this.state.bidAmount);
      const { bidIncrement, highBid, startingBid } = this.props.item;
      const nextBid = highBid
        ? Number(highBid) + Number(bidIncrement || 0)
        : Number(startingBid);

      if (
        bidAmount >= 100000 ||
        (bidAmount > bidIncrement && bidAmount >= nextBid * 10)
      ) {
        confirmAmount(
          `Confirm you want to bid ${commafyCurrency(bidAmount)} ?`,
          { paddingTop: 300 }
        ).then(
          () => {
            resolve(true);
          },
          () => {
            this.setState({ bidAmount: '' });
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  };

  handleAmountChange = e => {
    const bidAmount = String(e.target.value).replace(/\D/g, '');
    if (bidAmount === '' || isValidNumber(bidAmount)) {
      this.setState({ bidAmount });
    }
  };

  handleAccountSelect = e => {
    const userId = Number(e.target.value);
    const selectedAccount = find(this.state.accountList, { userId }) || {};

    this.setState({ selectedAccount }, () => {
      this.loadPayments();
    });
  };

  handlePaymentSelect = e => {
    const selectedPayment = e.target.value;

    if (selectedPayment) {
      this.setState({ selectedPayment });
    }
  };

  handleBid = async e => {
    const amountOk = getConfig('enableHighAmountConfirm')
      ? await this.checkAmount()
      : true;

    if (amountOk) {
      this.setState({ isSubmitting: true }, () => {
        const mpId = getConfig('marketplaceId');
        const itemId = this.props.item.itemId || this.props.item.id;
        const amount = this.state.bidAmount;
        const bidType = this.state.bidType;
        const userId = this.state.selectedAccount.userId;
        const paymentType =
          !this.state.selectedPayment ||
          this.state.selectedPayment === 'Pay Later'
            ? ''
            : this.state.selectedPayment;
        const transportationFeeId = 0;
        const transportFee = 0;

        this.props
          .loadItemBid(
            mpId,
            itemId,
            bidType,
            amount,
            userId,
            paymentType,
            transportationFeeId,
            transportFee
          )
          .then(({ response }) => {
            if (response) {
              if (response.responseCode > 1000) {
                this.onBidSuccess(response);
              } else {
                this.onBidWarning(response);
              }
            } else {
              throw new Error('Bid Error');
            }
          })
          .catch(error => this.onBidError(error));
      });
    }
  };

  onBidSuccess(response) {
    const mpId = getConfig('marketplaceId');
    this.props.loadWatchlistObject(mpId);
    toastr.success(response.wsStatus, response.wsMessage);
    this.onClose();
    this.props
      .loadBuyerBids(mpId)
      .then(({ response }) => {
        return response && response.data
          ? response.data.map(item => item.itemId)
          : [];
      })
      .then(ids => {
        return ids.length
          ? this.props.loadBiddingOn(ids, null, this.props.unauthorizedEventIds)
          : [];
      })
      .catch(error => console.error(error));

    setTimeout(() => {
      this.props.loadData();
    }, 1000);
  }

  onBidWarning(response) {
    toastr.warning('Attention', response.wsMessage, { icon: 'warning' });
    this.setState(
      {
        bidAmount: '',
        isSubmitting: false,
      },
      () => {
        setTimeout(() => {
          this.props.loadData();
        }, 1000);
      }
    );
  }

  onBidError(error) {
    console.error(error);
    toastr.error('Error', error.message || 'An unknown error occured');
    this.onClose();
    setTimeout(() => {
      this.props.loadData();
    }, 1000);
  }

  renderProxyHardTabs() {
    if (getConfig('disableHardBid')) {
      return (
        <div className='marketplace-reusable__modal-proxy_hard_container'>
          <button disabled className='marketplace-reusable__modal-proxy_button_only'>
            Proxy Bid
          </button>
        </div>
      );
    }

    return (
      <div className='marketplace-reusable__modal-proxy_hard_container'>
        <button
          type="button"
          className='marketplace-reusable__modal-proxy_hard_shared'
          style={{
            // ...styles.proxyHardShared,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            color: this.state.bidType === '1' ? '#FFF' : '#404040',
            backgroundColor: this.state.bidType === '1' ? '#787878' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.bidType !== '1') this.setState({ bidType: '1' });
          }}
          >
          Proxy
        </button>
        <button
          type="button"
          className='marketplace-reusable__modal-proxy_hard_shared'
          style={{
            // ...styles.proxyHardShared,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 'none',
            color: this.state.bidType === '2' ? '#FFF' : '#404040',
            backgroundColor: this.state.bidType === '2' ? '#787878' : '#F8F8F8',
          }}
          onClick={() => {
            if (this.state.bidType !== '2') this.setState({ bidType: '2' });
          }}
        >
          Hard
        </button>
      </div>
    );
  }

  renderAmountInput() {
    return (
      <div className="form-group">
        <label className="sr-only" htmlFor="bid-amount">
          Amount
        </label>
        <div className="input-group">
          <div className="input-group-addon">
            {localization[getConfig('localization')].currency}
          </div>
          <input
            type="text"
            id="bid-amount"
            className="form-control"
            placeholder={this.renderBidPlaceholder()}
            value={commafy(this.state.bidAmount)}
            onChange={this.handleAmountChange}
          />
        </div>
      </div>
    );
  }

  renderFees() {
    if (!this.state.hideFees) {
      return (
        <div>
          <div style={{ margin: '0 5px 15px 5px' }}>
            <strong>* Normal fees apply for simulcast auctions.</strong>
          </div>
          <Fees
            amount={this.state.bidAmount}
            type={this.state.bidType}
            itemId={this.props.item.itemId || this.props.item.id}
            userId={this.state.selectedAccount?.userId || ''}
          />
        </div>
      );
    }
  }

  renderAccountSelect() {
    // if no authorized accounts
    if (!this.state.accountList.length) {
      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Buyer Account</label>
          <div
            style={{
              ...styles.pseudoDropdown,
              color: this.state.loaded ? 'red' : 'transparent',
            }}
          >
            No Authorized Accounts
          </div>
        </div>
      );
    }

    // if single option don't use a dropdown
    if (this.state.accountList.length === 1) {
      const { accountName, city, state } = this.state.selectedAccount;

      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Buyer Account</label>
          <div style={styles.pseudoDropdown}>
            {`${accountName} – ${city}, ${state}`}
          </div>
        </div>
      );
    }

    // if multiple options show dropdown
    const options = (this.state.accountList || []).map((account, index) => {
      const text = `${account.accountName} – ${account.accountNumber} – ${account.city}, ${account.state}`;

      return (
        <option key={account.userId} value={account.userId}>
          {text}
        </option>
      );
    });

    return (
      <div className="form-group">
        <label htmlFor="buyer-account">Buyer Account</label>
        <select
          id="buyer-account"
          className="form-control"
          onChange={this.handleAccountSelect}
          value={this.state.selectedAccount.userId}
        >
          {options}
        </select>
      </div>
    );
  }

  renderPaymentSelect() {
    if (
      getConfig('hideSinglePaymentOption') &&
      this.state.paymentTypes.length <= 2 // if hideSinglePaymentOption feature is enabled you must have more than just Pay Later
    ) {
      return null;
    }

    // if single option don't use a dropdown
    if (this.state.paymentTypes.length < 2) {
      return (
        <div className="form-group">
          <label htmlFor="buyer-account">Payment Type</label>
          <div
            style={{
              ...styles.pseudoDropdown,
              color: this.state.loaded ? '#555' : 'transparent',
            }}
          >
            {this.state.selectedPayment || '-'}
          </div>
        </div>
      );
    }

    // if multiple options show dropdown
    const options = (this.state.paymentTypes || []).map((item, index) => (
      <option key={index} value={item}>
        {item}
      </option>
    ));

    return (
      <div className="form-group">
        <label htmlFor="payment-types">Payment Type</label>
        <select
          id="payment"
          className="form-control"
          onChange={this.handlePaymentSelect}
          value={this.state.selectedPayment}
        >
          {options}
        </select>
      </div>
    );
  }

  renderIfSaleDisclaimer () {
    if (!this.state.isIfSaleListing) return null;
    return (
      <div>
        <div style={{ 
            margin: '0 10px 10px 15px',
            border: '1px groove',
            background: 'rgba(125,125,55,.10)',
          }}
        >
            <strong>{ifSalePurchaseDisclaimer.HEADING}</strong>
            <p style={{marginTop: 5}}>
              {ifSalePurchaseDisclaimer.DISCLAIMER} 
            </p>
        </div>
      </div>
    )
  }

  renderTermsAndConditions() {
    const { customerTermsLink } = this.props;

    return (
      <div className="form-group">
        <input
          type="checkbox"
          id="agree"
          checked={this.state.agreed}
          disabled={getConfig('agreeToTermsAtLogin')}
          onChange={e => {
            this.setState(prevState => ({ agreed: !prevState.agreed }));
          }}
        />
        {customerTermsLink ? (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the{' '}
            <a
              href={customerTermsLink}
              target="_blank"
              rel="noreferrer"
              style={styles.link}
            >
              {`${getConfig('shortName')} Terms and Conditions`}
            </a>
          </label>
        ) : (
          <label htmlFor="agree" style={{ marginLeft: 5, color: '#787878' }}>
            I agree to the Terms and Conditions
          </label>
        )}
      </div>
    );
  }

  renderBasicInfo() {
    const { item } = this.props;
    const name = `${item.year} ${item.make} ${item.model}`;
    const itemLocation =
      item.itemCity && item.itemState
        ? `${item.itemCity}, ${item.itemState}`
        : item.vehicleCity && item.vehicleState
        ? `${item.vehicleCity}, ${item.vehicleState}`
        : '';

    return (
      <div>
        <h4 style={{ margin: '5px 0' }}>{name}</h4>
        <div style={{ margin: '5px 0' }}>
          <IDHorizontal
            vin={item.vin || item.vIN}
            stockNumber={item.stockNumber}
          />
        </div>
        {itemLocation && (
          <div style={{ margin: '5px 0' }}>
            <strong style={{ marginRight: 4 }}>Location:</strong>
            <span>{`${itemLocation}`}</span>
          </div>
        )}
      </div>
    );
  }

  renderBidAmountInfo() {
    let label = '';
    let value = '';

    let { highBid, startingBid } = this.props.item;

    if (highBid > 0) {
      label = 'Current Bid:';
      value = commafyCurrency(highBid);
    } else if (highBid === 0 && startingBid > 0) {
      label = 'Minimum Bid:';
      value = commafyCurrency(startingBid);
    }

    if (value) {
      return (
        <div style={{ margin: '5px 0' }}>
          <strong style={{ marginRight: 4 }}>{label}</strong>
          <span>{value}</span>
        </div>
      );
    }
  }

  renderBidIncrement() {
    const value = commafyCurrency(this.props.item.bidIncrement);
    if (value) {
      return (
        <div style={{ margin: '5px 0' }}>
          <strong style={{ marginRight: 4 }}>Bid Increment:</strong>
          <span>{value}</span>
        </div>
      );
    }
  }

  renderBidPlaceholder() {
    const { highBid, bidIncrement, startingBid } = this.props.item;

    const nextBid = highBid
      ? Math.max(
          Number(highBid || 0) + Number(bidIncrement || 0),
          Number(startingBid || 0)
        )
      : Number(startingBid || 0);

    const placeholder = nextBid
      ? `Next Minimum Bid: ${commafyCurrency(nextBid)}`
      : 'Enter your bid';

    return placeholder;
  }

  renderDivider() {
    return <hr style={styles.divider} />;
  }

  renderHeader() {
    return (
      <div
        className='marketplace-reusable__modal-header' 
        // style={styles.header} /* Moved to 'assets/css/styles.css' */
      >
        <div style={{ fontSize: 24, color: '#535768' }}>
          <span className="fa fa-car" />
          <span style={{ marginLeft: 10 }}>Bid</span>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }

  renderFooter() {
    const { agreed, isSubmitting, bidAmount, selectedAccount } = this.state;

    const isDisabled = !!(
      isSubmitting ||
      !agreed ||
      !bidAmount ||
      bidAmount == '0' || // the bid amount must be > 0
      !selectedAccount.userId
    );

    const buttonText = isSubmitting ? 'Submitting Bid...' : 'Bid';

    return (
      <div className='marketplace-reusable__modal-footer'>
        <div />
        <div className='marketplace-reusable__modal-buttons'>
          <button
            className="btn btn-primary button-cancel"
            style={{
              opacity: isSubmitting ? 0.5 : 1,
              minWidth: 100,
              marginRight: 10,
            }}
            disabled={isSubmitting}
            type="button"
            data-dismiss="modal"
            onClick={this.onClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary button-action"
            style={{
              opacity: isDisabled ? 0.5 : 1,
              minWidth: 100,
            }}
            type="button"
            disabled={isDisabled}
            onClick={this.handleBid}
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.item || !this.props.userProfile.user) {
      return null;
    }

    return (
      <Modal
        className='marketplace-reusable__modal-content'
        isOpen={this.props.isOpen}
        style={styles.customStyles}
        contentLabel="Bid Modal"
        ariaHideApp={false}
        onAfterOpen={this.onOpen}
      >
        <div className='marketplace-reusable__modal-container'>
          {this.renderHeader()}
          {this.renderDivider()}
          <div className='marketplace-reusable__modal-body' style={styles.body}>
            {this.renderBasicInfo()}
            {this.renderBidAmountInfo()}
            {this.renderBidIncrement()}
            <form autoComplete="off" style={{ marginTop: -20 }}>
              {this.renderProxyHardTabs()}
              {this.renderAmountInput()}
              {this.renderFees()}
              {this.renderAccountSelect()}
              {this.renderPaymentSelect()}
              {this.renderIfSaleDisclaimer()}
              {this.renderTermsAndConditions()}
            </form>
          </div>
          {this.renderDivider()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

BidModal.defaultProps = {
  loadData: () => null,
};

BidModal.propTypes = {
  customerTermsLink: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  marketplaceFeatures: PropTypes.object.isRequired,
  modalId: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    customerTermsLink,
    marketplaceFeatures,
    unauthorizedEventIds,
    userProfile,
  } = state.entities;
  return {
    customerTermsLink,
    marketplaceFeatures,
    unauthorizedEventIds,
    userProfile,
  };
};

export default connect(mapStateToProps, {
  loadBiddingOn,
  loadBuyerBids,
  loadItemBid,
  loadWatchlistObject,
})(BidModal);
