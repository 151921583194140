export const arbitrationModalConstants = {
    formId: {
        TERMS: '1',
        BUYER: '2',
        SELLER: '3',
    },
    terms: {
        DISCLAIMER_TEXT: "I acknowledge and agree to the Arbitration Policy as stated in the Lorensen Terms and Conditions.",
        DISCLAIMER_TEXT2: 
            "Your Auction Marketplace subscribes to NAAA Arbitration guidelines. " + 
            "For more information, go to the Host Auction's FAQ, Terms and Agreement, or Contact page to inquire directly. " +
            "See rules and regulations for arbitrations on the terms and conditions page. See Arbitration Rules provided by NAAA.",
        NAAA_URL: "https://www.naaa.com/Policy/Policy_PDFs/2024_ArbitrationPolicy_9_4_24/NAAA_Arbitration_Policy_9_4_24_Clean.pdf"
    }
}