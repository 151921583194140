import React from "react";
import { ArbitrationModalCommentHistory } from "./ArbitrationModalCommentHistory";

/** Arbitration Modal - Body content
 * - Comment History
 * - _Add a Comment_ section
 * 
 * @param {{inputTextValue: string, onFileSelect: ()=>void, onCommentChange()=>void, comments: object[]}} props 
 * @returns 
 */
export function ArbitrationModalMain ({inputTextValue,  onFileSelect, onCommentChange, comments=[]}) {
    const handleCommentInput = (e) => {
        e.preventDefault();
        onCommentChange(e);
    }
    
    const handleFileSelect = (e) => {
        const files = e.target.files;
        if (files) {
            onFileSelect(e)
        }
    }

    /* Sort comments in decending order */
    const sortComments = (comments=[]) => {
        if (comments && comments.length) {
            try {
                return comments.sort((a,b) => {
                    const date1 = new Date(a.dateCreated).valueOf();
                    const date2 = new Date(b.dateCreated).valueOf();
                    return date2 - date1;
                })
            } catch (sortError) {
                return comments;
            }
        } else {
            return comments;
        }
    }
    
    return (
        <div >
            
            {/* Comment History */}
            <ArbitrationModalCommentHistory comments={sortComments(comments)} />
            <hr className='marketplace-reusable__modal-divider__small' />
            
            {/* Add a Comment */}
            <h4 style={{textAlign: 'center', margin: '0px 0px 8px'}}>Please Provide Supporting Materials</h4>
            <textarea 
                style={{maxHeight: '200px', resize: 'none', padding: 10, margin: '0px auto', minHeight: 'unset' }}
                
                className='marketplace-reusable__modal-textarea'
                placeholder='Type your comment here'
                onChange={handleCommentInput}
                value={inputTextValue}
            />

            {/* Add Attachment */}
            <div style={{padding: 5, margin: '0 5px'}} className="file-picker-input-field-container">
                <p style={{marginBottom: 5}}>
                    Upload an attachment
                </p>
                <input
                    type="file"
                    accept={"*/*"}
                    onChange={handleFileSelect}
                />
          
            </div>
            
        </div>
    )
}