import React from "react";
import { toastr } from "react-redux-toastr";
import { AARequiredAlert } from "./AARequiredAlert";
import { toasterAlerts } from "../../utils/constants";

/**
 *
 * @param {string|undefined} title defaults to `toasterAlerts` value set in `src/utils/constants`
 */
export function aaRequiredToaster (title) {
  const {TITLE} = toasterAlerts.auctionAccess.userMustRegister
  const alertTitle = (title && typeof title === 'string')
    ? title
    : TITLE;
  toastr.warning(
    alertTitle,
    '',
    {
      component: <AARequiredAlert />,
      timeOut: 20000,
      showCloseButton: true,
      position: 'top-center',
      icon: 'warn',
      attention: true,
    }
  )
}