import React from "react";
import { toasterAlerts, urlsMap } from "../../utils/constants";

/** All params default to values set in `src/utils/constants`
 * 
 * @param {{body?: string, enrollLinkText?: string, enrollUrl?: string}} props 
 * @returns 
 */
export function AARequiredAlert ({body, enrollLinkText, enrollUrl}) {
    /* constants */
    const {BODY, ENROLL_URL_TEXT} = toasterAlerts.auctionAccess.userMustRegister;
    const AA_ENROLL_LINK = urlsMap.get('AA_ENROLLMENT');

    /* props */
    const msgBody = (body && typeof body === 'string') ? body : BODY;
    const msgEnrollLinkText = (enrollLinkText && typeof enrollLinkText === 'string') ? enrollLinkText : ENROLL_URL_TEXT;
    const msgEnrollLinkUrl = (enrollUrl && typeof enrollUrl === 'string') ? enrollUrl : AA_ENROLL_LINK;

    /* render */
    return (
        <div style={styles.container}>
            <span style={styles.messageBody} >{msgBody}</span>
            <a style={styles.anchor} href={msgEnrollLinkUrl} target="_blank" rel="noreferrer">
                <span style={styles.link} >
                    {msgEnrollLinkText}
                    <i style={styles.externalIcon} className="fa fa-external-link-alt"></i>
                </span>
            </a>
        </div>
    )  
}

const styles = {
    anchor: {
        fontSize: 16
    },
    container: {
        alignItems: 'center', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        padding: 2, 
        width: '100%', 
    },
    externalIcon: {
        margin: '0 3px', 
        flexShrink: 2,
    },
    link: {
        display: 'flex', 
        alignItems: 'center', 
        gap: 3,
    },
    messageBody: {
         marginTop: 5, 
        marginBottom: 10,
    },

}