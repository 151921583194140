import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { chain } from 'lodash';
import prodConfigs from '../config/index.prod.js';

const styles = {
  container: {
    margin: '0 auto',
    padding: 0,
    position: 'relative',
    backgroundColor: 'rgba(222,199,235,0.2)',
  },
  marketplacesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '30px 50px',
  },
  versionContainer: {},
  version: {
    textAlign: 'center',
    padding: 20,
  },
  title: {
    padding: '0.45em .75em',
    margin: 0,
    // background: `linear-gradient(90deg, #848dc3 0%, #239eb3 100%)`,
    backgroundColor: `ghostwhite`,
    // color: '#fff',
    color: 'black',
    textAlign: 'center',
    // fontFamily: 'auto',
    verticalAlign: 'center',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
  },
  link: {
    textAlign: 'center',
    marginRight: 10,
    marginBottom: 10,
    padding: 20,
    border: '1px solid #ccc',
    borderRadius: 5,
    height: 180,
    width: 300,
    backgroundColor: '#fff',
  },
  logo: {
    objectFit: 'contain',
    width: '4rem',
    height: '2.5rem',
    margin: 'auto 0',
    // backgroundColor: 'ghostwhite',
    
  },
  image: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
};

class Default extends Component {

  renderHeader() {
    return (
      <h1 style={{...styles.title, boxShadow: '2px 2px 5px rgba(0,5,0,0.2)'}}>
        <img 
          style={styles.logo}
          alt={" "}
          src={'https://cdn.integratedauctionsolutions.com/yourmarketplace.siamarketplace.com/logo.png'} 
        />
        {'SIA Marketplaces'}
      </h1>

    )
  }

  /* 2024.11.01 - HOTFIX:
    Rendering a 'Marketplace not found' message 
    on page instead of grid containing active MP's 
  */
  renderBody() {
    const messageBodyHeading = `
      The Autoremarketer you are looking for no longer exists, 
      please check the url and try again.
    `;
    const supportMessage = `
      For help with logging in, or if you have other questions - please contact support: 
    `;
    const supportNumber = `(855) 999-0303`;

    return (
      <div style={{
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '15px 25px',
        minHeight: '82vh', 
        textAlign: 'center', 
      }}>
        <h2>{messageBodyHeading}</h2>
        <div style={{padding: '10px 0px', margin: '20px 10px'}}>
          <span style={{margin: '10px 20px', fontSize: 18}}>{supportMessage}</span>
          <a style={{fontSize: 16}} href={`tel:${supportNumber}`}>
            {supportNumber}
          </a>
        </div>
      </div>
    )
  }

  renderMarketplaces() {
    const data = chain(Object.entries(prodConfigs))
      .map(([marketplace, config]) => ({
        marketplace,
        marketplaceId: config.marketplaceId,
        name: config.name,
        bucket: config.bucket,
      }))
      .filter(item => item.bucket)
      .uniqBy('bucket')
      .sort((a, b) => a.name - b.name)
      .value();

    return data.map((item, index) => {
      const link = `https://${item.marketplace}`;
      const src =
        `https://cdn.integratedauctionsolutions.com/` +
        `${item.bucket}/logo.png`;

      return (
        <Link key={item.marketplace + index} to={link} style={styles.link}>
          <img style={styles.image} src={src} alt={item.marketplace} />
          {false && <span>{link}</span>}
        </Link>
      );
    });
  }

  render() {
    return (
      <DocumentTitle title="SIA Marketplace">
        <div style={styles.container}>
          {this.renderHeader()}
          {/* <h1 style={styles.title}>
            SIA Marketplaces
          </h1> */}

          <div style={styles.marketplacesContainer}>
            {/* {this.renderMarketplaces()} */}
            {this.renderBody()}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default Default;
