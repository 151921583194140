import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { maxBy, isEmpty } from 'lodash';
import BidHistoryModal from '../common/BidHistoryModal';
import DataTable from '../tables/DataTable';
import withTable from '../tables/withTable';
import { confirm } from '../common/confirm/CallConfirm';
import {
  loadBidHistory,
  loadBuyerBids,
  resetBuyerBids,
  loadRemoveProxyBid,
  loadBiddingOn,
} from '../../actions';
import { getConfig, compose, mergeBidHistory } from '../../utils/helpers';
import styles from './styles';

class BuyerBids extends Component {
  state = {
    title: 'buyer bids',
    bidHistories: null,
    bidHistoryItemId: '',
    isBidHistoryModalOpen: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.resetBuyerBids();
    this.props
      .loadBuyerBids(getConfig('marketplaceId'))
      .then(({ response }) => {
        if (response && response.data) {
          return response.data.map(item => item.itemId);
        }
        return [];
      })
      .then(ids => {
        return ids.length
          ? this.props.loadBiddingOn(ids, null, this.props.unauthorizedEventIds)
          : [];
      })
      .catch(error => console.error(error));
  };

  handleRemoveProxyBid = row => {
    confirm('Are you sure you want to retract your proxy bid?').then(() => {
      const allBids = this.state.bidHistories[row.itemId] || [];
      const proxyBids = allBids.filter(bid => bid.bidType === 'Proxy Bid');
      const latestProxy = maxBy(proxyBids, 'bidAmount') || {}; // assume latest = highest
      const { offerId, userId } = latestProxy;
      const { eventId } = row;

      if (offerId) {
        this.props
          .loadRemoveProxyBid(
            getConfig('marketplaceId'),
            offerId,
            userId,
            eventId
          )
          .then(resp => {
            const { response = {} } = resp;
            if (response.wsStatus === 'Success') {
              toastr.success('Success', response.wsMessage);
              this.loadData();
            } else {
              toastr.error('Error', response.wsMessage);
            }
          })
          .catch(error => console.error(error));
      } else {
        toastr.error('Error', 'Invalid Offer Id');
      }
    });
  };

  handleBidHistoryModal = row => {
    this.setState({
      bidHistoryItemId: String(row.itemId),
      isBidHistoryModalOpen: true,
    });
  };

  handleDetailsClick = (itemId, data) => {
    const path = `/item/${itemId}`;
    this.props.navigate(path);
    const ids = (data || [])
      .map(item => item.itemId || item.id || item._id)
      .join(',');
    sessionStorage.setItem('navItems', ids);
  };

  makeActionButtons = (data) => {
    const mpAllowsProxyBidRemoved = (this.props.marketplaceFeatures.features || '')
      .split(',')
      .includes('336');

    return {
      Header: 'Actions',
      id: 'buttons',
      headerStyle: { background: 'none' },
      sortable: false,
      filterable: false,
      Cell: row => {
        
        const itemAllowsProxyBidRemoved = row.original.proxyRemovable;

        const showRemoveProxyBidButton =
          mpAllowsProxyBidRemoved &&
          itemAllowsProxyBidRemoved &&
          this.state.bidHistories;

        return (
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              onClick={() =>
                this.handleDetailsClick(row.original.itemId, data)
              }
            >
              View Details
            </Button>
            <Button
              style={styles.button}
              onClick={() => this.handleBidHistoryModal(row.original)}
            >
              Bid History
            </Button>

            {showRemoveProxyBidButton && (
              <div style={{ width: '100%' }}>
                <Button
                  style={{ ...styles.button, margin: 0, padding: 3 }}
                  onClick={e => this.handleRemoveProxyBid(row.original)}
                >
                  Remove Proxy
                </Button>
              </div>
            )}
          </div>
        );
      },
    }
  }

  render() {
    const loading = isEmpty(this.props.buyerBids);
    const data = this.props.buyerBids.data || [];
    const columns = this.props.makeColumns([
      'checkbox',
      'image',
      this.makeActionButtons(data),
      'vin',
      'year',
      'make',
      'model',
      'myHighBid',
      'currentHighBid',
      'highBidder',
      'listingStatus',
      'bidDate',
      'lotNumber',
    ]);

    return (
      <div className="st-container shadow padding background-white padding">
        <h4>Active Bids</h4>
        {this.state.isBidHistoryModalOpen
          ? <BidHistoryModal
            itemId={this.state.bidHistoryItemId || ""}
            userProfile={this.props.userProfile}
            isOpen={this.state.isBidHistoryModalOpen}
            onClose={() => this.setState({ isBidHistoryModalOpen: false })}
          />
          : ''
        }
        <DataTable
          loading={loading}
          title={this.state.title}
          data={data}
          columns={columns}
          showCSVButton={true}
          showPrintButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    bidHistory,
    bidMessageHistoryRealTime,
    buyerBids,
    marketplaceFeatures,
    unauthorizedEventIds,
    userProfile,
  } = state.entities;
  return {
    bidHistory,
    bidMessageHistoryRealTime,
    buyerBids,
    marketplaceFeatures,
    unauthorizedEventIds,
    userProfile,
  };
};

export default compose(
  withTable,
  connect(mapStateToProps, {
    loadBidHistory,
    loadBuyerBids,
    resetBuyerBids,
    loadRemoveProxyBid,
    loadBiddingOn,
  })
)(BuyerBids);
